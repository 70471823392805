<header>
    <h1>Настройки</h1>
    <button id="close-button" mat-fab color="" (click)="onCloseClick()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</header>
<section>
    <div class="profile__settings">
        <div class="profile__accounts mat-elevation-z2">
            <div class="acc__header">Подключённые аккаунты</div>
            <div class="acc__descr">
                Не все аккаунты являются источниками данных для работы с виджетами.
                На данный момент источниками являются страница доната, Twitch, YouTube и Trovo.
            </div>
            <div class="acc__list">
                <div
                    class="list__item list__item--{{account.getType()}}{{accountsService.disconnectedAccounts.has(account.getId()) ? ' disconnected' : ''}}"
                    *ngFor="let account of accountsService.accounts$ | async">
                    <div class="item__avatar">
                        <div
                            *ngIf="accountsService.disconnectedAccounts.has(account.getId()); then disconnectedIcon else accountIcon"></div>
                        <ng-template #accountIcon>
                            <mat-icon svgIcon="logo-{{account.getType()}}"></mat-icon>
                        </ng-template>
                        <ng-template #disconnectedIcon>
                            <mat-icon svgIcon="disconnected"></mat-icon>
                            <mat-icon svgIcon="disconnected" class="disconnected-glow"></mat-icon>
                        </ng-template>
                    </div>
                    <div class="description__controls">
                        <div class="item__description">
                            <div class="descr__soc">{{account.getType() | uppercase}}</div>
                            <div class="descr__nick">{{account.getNick()}}</div>
                        </div>
                        <div class="item__controls">
                            <button mat-button color="primary"
                                    *ngIf="accountsService.disconnectedAccounts.has(account.getId())"
                                    (click)="onRestoreAccount($event, account)">
                                Восстановить
                            </button>
                            <button mat-button color="primary"
                                    [disabled]="account.isMain()"
                                    (click)="onDeleteAccount($event, account)">
                                Отвязать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="acc__new">
                <button mat-button color="primary" (click)="onAddAccount($event)">
                    <mat-icon>add</mat-icon>
                    Привязать новый аккаунт (Twitch, YouTube и Trovo)
                </button>
            </div>
        </div>

        <app-export-transactions class="mat-elevation-z2"></app-export-transactions>
        <app-profanity-settings id="profanity-settings" class="mat-elevation-z2"></app-profanity-settings>
    </div>
</section>

<app-connect-social-dialog #dialog (addSocial)="onAddSocial($event)"></app-connect-social-dialog>
