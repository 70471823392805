import {TotalWidget} from "../../../../shared/src/lib/models/widget-props";

export const defaultTotalProps: TotalWidget["props"] = {
    data: {
        sources: {
            accounts: {
                donatty: {
                    donate: {
                        isEnabled: true
                    }
                }
            }
        },
        period: "all",
        headerTemplate: "Собрано донатов за всё время"
    },
    style: {
        mainRounding: 50,
        sumRounding: 50,
        layout: 0,
        widgetBackgroundColor: "#323336",
        widgetBackgroundColorEnabled: true,
        widgetBackgroundUnderSumColor: "#252628",
        widgetBackgroundUnderSumColorEnabled: true,
        stroke: {
            color0: "#1484FF",
            color1: "#D731B6",
            width: 4,
            enabled: true
        },
        shadow: {
            enabled: true,
            color: "rgba(0, 0, 0, 0.4)",
            offsetX: 0,
            offsetY: 4,
            blur: 4
        },
        header: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FFFFFF",
                align: "left",
                size: 20,
                bold: true,
                italic: false,
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: true,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
        },
        donationSum: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#EFA875",
                colorGradient: "#CBA7CB",
                size: 56,
                align: "left",
                bold: true,
                italic: false
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: false,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
        },
    },
};
