<header>
    <div>
        <h5>Рекомендации</h5>
        <div class="progress">
            <mat-progress-bar mode="determinate" [value]="progress$ | async"></mat-progress-bar>
            <span>{{(doneCount$ | async).length}}/{{(quests$ | async).length}}</span>
        </div>
    </div>
    <span>Что нужно сделать после регистрации в Donatty.</span>
</header>
<div class="quest-list" #list>
    <section *ngFor="let quest of (quests$ | async)"
             [ngClass]="{'goal-reached': quest.goal.isReached}">
        <div class="mask">
            <div class="title"
                 [style.color]="quest.goal.color"
                 [innerHTML]="quest.title"></div>
            <div class="icon"
                 [style.backgroundColor]="quest.goal.color">
                <mat-icon>done</mat-icon>
                <img [alt]="quest.id" src="assets/images/onboarding/{{quest.id}}.png">
            </div>
        </div>
        <div class="articles">
            <h6>Подходящие статьи</h6>
            <mat-list dense>
                <a *ngFor="let article of quest.goal.articles" href="{{article.url}}" target="_blank" mat-list-item>
                    <mat-icon svgIcon="article-black"></mat-icon>
                    <span [innerHTML]="article.title"></span>
                </a>
            </mat-list>
            <button mat-flat-button color="primary" class="small"
                    *ngIf="!quest.goal.isReached"
                    (click)="questDone$.next(quest.id)">Отметить как выполненное
            </button>
            <button mat-flat-button color="primary" class="small"
                    *ngIf="quest.goal.isReached"
                    (click)="questUndone$.next(quest.id)">Не выполнено
            </button>
        </div>
    </section>
    <section class="completed" *ngIf="allDone$ | async">
        <div class="congrats">
            <div class="title">Вы выполнили все рекомендации!</div>
            <button mat-flat-button color="primary" class="small"
                    (click)="close()">Завершить и закрыть
            </button>
        </div>
        <div class="icon">
            <img alt="completed" src="assets/images/onboarding/completed.png">
        </div>
    </section>
    <nav>
        <div>
            <button id="prev" mat-icon-button aria-label="Previous page"
                    [disableRipple]="true"
                    *ngIf="(page$ | async) > 1"
                    (click)="page$.next(page$.value - 1)">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </div>
        <div>
            <button id="next" mat-icon-button aria-label="Next page"
                    [disableRipple]="true"
                    *ngIf="(page$ | async) < (quests$ | async)?.length + +(allDone$ | async)"
                    (click)="page$.next(page$.value + 1)">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </nav>
</div>
