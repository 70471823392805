<div class="we__edit-data-params" [@collapse]="!isBlockShown[0]">
    <div class="we__row--header" (click)="toggleCollapse(0)">
        <div class="we__column--label"
             [innerHTML]="dataParamsSettings[type].header">
        </div>
        <div class="we__column--button">
            <button mat-icon-button aria-label="Expand data params" disableRipple="true">
                <mat-icon [@rotate]="!isBlockShown[0]">expand_more</mat-icon>
            </button>
        </div>
    </div>

    <div class="we__collapsable" [@fadeOut]="!isBlockShown[0]">
        <div class="we__description" [innerHTML]="dataParamsSettings[type].description"></div>

        <div class="param__row"
             *ngIf="((type === WidgetType.Alert) || (type === WidgetType.Events)) && areDonationEventsOn">
            <div class="column__icon">
                <mat-icon svgIcon="logo-donatty"></mat-icon>
            </div>
            <div class="column__descr">
                <div class="descr__param">
                    Донат
                </div>
                <div class="descr__nick">
                    {{(userService.currentUser$ | async)?.displayName}}
                </div>
            </div>
            <div class="column__range">
                <app-widget-edit-open-range #alertTotalRange
                                            [(from)]="alertRangeFrom"
                                            [(to)]="alertRangeTo"
                                            [min]="alertRangeMin"
                                            [max]="alertRangeMax"
                                            units="₽">
                </app-widget-edit-open-range>
            </div>
        </div>

        <div class="we__goal" *ngIf="type === WidgetType.Goal">
            <form [formGroup]="goalForm">
                <div class="we__row we__row--input">
                    <div class="we__label">
                        Цель сбора
                    </div>
                    <mat-form-field appearance="legacy">
                        <input type="number" min="0" matInput formControlName="goal">
                        <span matSuffix>₽</span>
                        <mat-error *ngIf="goalForm.get('goal').errors as errors">
                            <ng-container *ngIf="errors.required">Введите значение</ng-container>
                            <ng-container *ngIf="errors.min">Должно быть ⩾ {{errors.min.min | number}}</ng-container>
                            <ng-container *ngIf="errors.max">Должно быть ⩽ {{errors.max.max | number}}</ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="we__row we__row--input">
                    <div class="we__label">
                        Начать сбор с
                    </div>
                    <mat-form-field appearance="legacy">
                        <input type="number" min="0" matInput formControlName="goalFrom">
                        <span matSuffix>₽</span>
                        <mat-error *ngIf="goalForm.get('goalFrom').errors as errors">
                            <ng-container *ngIf="errors.required">Введите значение</ng-container>
                            <ng-container *ngIf="errors.min">Должно быть ⩾ {{errors.min.min | number}}</ng-container>
                            <ng-container *ngIf="errors.max">Должно быть ⩽ {{errors.max.max | number}}</ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="we__row--calendar">
                    <div class="we__label">
                        Завершить сбор в
                    </div>
                    <div class="we__content">
                        <calendar [(selected)]="goalDate"></calendar>
                    </div>
                </div>
                <div class="we__row--left">
                    <div class="we__label"></div>
                    <div class="we__content">
                        <div class="we__left" *ngIf="goalDate">
                            Осталось: <span [textContent]="remainsText"></span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="we__top" *ngIf="type === WidgetType.Top">
            <div class="we__row--date">
                <div class="we__label">
                    Какие брать данные
                </div>
                <div class="we__content">
                    <app-dropdown [items]="topWidgetDataTypes" [(value)]="dataType">
                    </app-dropdown>
                </div>
            </div>
            <div class="we__row--date we__row--margin">
                <div class="we__label">
                    За какое время брать данные
                </div>
                <div class="we__content">
                    <app-dropdown [items]="periods" [(value)]="period">
                    </app-dropdown>
                </div>
            </div>
        </div>

        <div class="we__total" *ngIf="type === WidgetType.Total">
            <div class="we__row--date">
                <div class="we__label">
                    За какое время брать данные
                </div>
                <div class="we__content">
                    <app-dropdown [items]="periods" [(value)]="period">
                    </app-dropdown>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let param of eventSourceParams"
                      [ngSwitch]="param.type">
            <app-widget-edit-event-source-param-range
                *ngSwitchCase="'range'"
                [param]="param"
                (paramChanged)="onEventSourceParamChanged($event)">
            </app-widget-edit-event-source-param-range>
            <app-widget-edit-event-source-param-list
                *ngSwitchCase="'list'"
                [param]="param"
                (paramChanged)="onEventSourceParamChanged($event)">
            </app-widget-edit-event-source-param-list>
        </ng-container>
    </div>
</div>

<div class="we__edit-data-params" *ngIf="type === WidgetType.Top" [@collapse]="!isBlockShown[1]">
    <div class="we__row--header" (click)="toggleCollapse(1)">
        <div class="we__column--label">
            Условия
        </div>
        <div class="we__column--button">
            <button mat-icon-button aria-label="Expand conditions block" disableRipple="true">
                <mat-icon [@rotate]="!isBlockShown[1]">expand_more</mat-icon>
            </button>
        </div>
    </div>

    <div class="we__collapsable" [@fadeOut]="!isBlockShown[1]">
        <div class="we__description">
            Укажите диапазоны, для которых должны показываться данные.
            Каждое условие соответствует одному источнику данных.
        </div>

        <div class="param__row">
            <div class="column__icon">
                <mat-icon svgIcon="logo-donatty"></mat-icon>
            </div>
            <div class="column__descr">
                <div class="descr__param" [innerText]="topWidgetDataTypeName">
                </div>
                <div class="descr__nick">
                    {{(userService.currentUser$ | async)?.displayName}}
                </div>
            </div>
            <div class="column__range">
                <app-widget-edit-open-range #topTotalRange
                                            [(from)]="topRangeFrom"
                                            [(to)]="topRangeTo"
                                            [min]="1"
                                            units="₽">
                </app-widget-edit-open-range>
            </div>
        </div>
    </div>
</div>
