import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "./login/login.component";
import {DonateEditComponent} from "./donate/donate-edit.component";
import {ButtonComponent} from "./button/button.component";
import {BalanceComponent} from "./finance/balance/balance.component";
import {PayoutComponent} from "./finance/payout/payout.component";
import {RadiobuttonComponent} from "./radiobutton/radiobutton.component";
import {IdentityComponent} from "./identity/identity.component";
import {NgxMaskModule} from "ngx-mask";
import {HintComponent} from "./identity/hint/hint.component";
import {ConfirmationCodeComponent} from "./confirmation-code/confirmation-code.component";
import {IdentityThanksComponent} from "./identity/thanks/identity.thanks.component";
import {IdentityInfoComponent} from "./finance/identity-info/identity-info.component";
import {StatisticsComponent} from "./finance/statistics/statistics.component";
import {EmptyTransactionsComponent} from "./finance/transaction-list/empty-transactions/empty-transactions.component";
import {WidgetsComponent} from "./widgets/widgets.component";
import {ClipboardModule} from "ngx-clipboard";
import {
    WidgetEditEventSourcesComponent
} from "./widgets/edit/widget-edit-sources/widget-edit-event-sources/widget-edit-event-sources";
import {WidgetEditComponent} from "./widgets/edit/widget-edit.component";
import {WidgetCopyLinkComponent} from "./widgets/edit/widget-copy-link/widget-copy-link.component";
import {WidgetEditSourcesComponent} from "./widgets/edit/widget-edit-sources/widget-edit-sources.component";
import {WidgetEditVisualsComponent} from "./widgets/edit/widget-edit-visuals/widget-edit-visuals.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {ColorSelectComponent} from "./color-select/color-select.component";
import {ColorPickerModule} from "ngx-color-picker";
import {SwitcherComponent} from "./switcher/switcher.component";
import {
    WidgetEditFontBasicsComponent
} from "./widgets/edit/widget-edit-visuals/widget-edit-text/widget-edit-font-basics/widget-edit-font-basics.component";
import {WidgetsListItemComponent} from "./widgets/list-item/widgets-list-item.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {AvatarComponent} from "./avatar/avatar.component";
import {MatButtonModule} from "@angular/material/button";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PaymentProcessingComponent} from "./finance/payout/payout-processing/payment-processing.component";
import {PayoutErrorComponent} from "./finance/payout/payout-error/payout-error.component";
import {
    WidgetEditLayoutComponent
} from "./widgets/edit/widget-edit-visuals/widget-edit-style/widget-edit-layout/widget-edit-layout.component";
import {
    WidgetEditStyleUploadButtonComponent
} from "./widgets/edit/widget-edit-visuals/widget-edit-style/widget-edit-style-upload-button/widget-edit-style-upload-button.component";
import {WidgetEditDataParamsComponent} from "./widgets/edit/widget-edit-data-params/widget-edit-data-params.component";
import {
    WidgetVisualsDonationComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-visuals-donation.component";
import {
    WidgetVisualsGoalComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-goal/widget-visuals-goal.component";
import {
    WidgetVisualsTopComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-top/widget-visuals-top.component";
import {
    WidgetVisualsTotalComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-total/widget-visuals-total.component";
import {
    WidgetVisualsEventsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-events/widget-visuals-events.component";
import {
    WidgetDonationsTabPropsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-donations-tab-props/widget-donations-tab-props.component";
import {
    WidgetDonationsTabImagesComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-donations-tab-images/widget-donations-tab-images.component";
import {
    WidgetDonationsTabAudioComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-donations-tab-audio/widget-donations-tab-audio.component";
import {
    WidgetDonationsTabHeaderComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-donations-tab-header/widget-donations-tab-header.component";
import {
    WidgetDonationsTabTextComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-donation/widget-donations-tab-text/widget-donations-tab-text.component";
import {
    WidgetGoalTabPropsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-goal/widget-goal-tab-props/widget-goal-tab-props.component";
import {
    WidgetGoalTabHeaderComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-goal/widget-goal-tab-header/widget-goal-tab-header.component";
import {
    WidgetGoalTabTargetComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-goal/widget-goal-tab-target/widget-goal-tab-target.component";
import {
    WidgetGoalTabCountdownComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-goal/widget-goal-tab-countdown/widget-goal-tab-countdown.component";
import {
    WidgetTopTabPropsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-top/widget-top-tab-props/widget-top-tab-props.component";
import {
    WidgetTopTabHeaderComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-top/widget-top-tab-header/widget-top-tab-header.component";
import {
    WidgetTopTabNameComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-top/widget-top-tab-name/widget-top-tab-name.component";
import {
    WidgetTopTabSumComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-top/widget-top-tab-sum/widget-top-tab-sum.component";
import {
    WidgetTotalTabPropsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-total/widget-total-tab-props/widget-total-tab-props.component";
import {
    WidgetTotalTabHeaderComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-total/widget-total-tab-header/widget-total-tab-header.component";
import {
    WidgetTotalTabSumComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-total/widget-total-tab-sum/widget-total-tab-sum.component";
import {
    WidgetTabStyleComponent
} from "./widgets/edit/widget-edit-visuals/widget-edit-style/widget-tab-style/widget-tab-style.component";
import {
    WidgetEventsTabPropsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-events/widget-events-tab-props/widget-events-tab-props.component";
import {
    WidgetEventsTabEventsComponent
} from "./widgets/edit/widget-edit-visuals/widget-visuals-events/widget-events-tab-events/widget-events-tab-events.component";
import {SliderModule} from "../modules/slider/slider.module";
import {TransactionListComponent} from "./finance/transaction-list/transaction-list.component";
import {PayoutYandexComponent} from "./finance/payout/methods/yandex/payout-yandex.component";
import {PayoutCardComponent} from "./finance/payout/methods/card/payout-card.component";
import {PayoutQiwiComponent} from "./finance/payout/methods/qiwi/payout-qiwi.component";
import {MobileCardComponent} from "./mobile-version/mobile-card/mobile-card.component";
import {ObsCardComponent} from "./mobile-version/obs-card/obs-card.component";
import {SecureInputComponent} from "./secure-input/secure-input.component";
import {MobileHeaderComponent} from "./mobile-version/mobile-header/mobile-header.component";
import {MobileFrontComponent} from "./mobile-version/mobile-front/mobile-front.component";
import {SharedModule} from "../../../../shared/src/lib/shared.module";
import {AuthGuard} from "../guards/auth.guard";
import {DesktopGuard} from "../guards/desktop.guard";
import {LoginService} from "../services/login/login.service";
import {FinanceService} from "../services/finance/finance.service";
import {DonationsService} from "../services/donation/donation.service";
import {WidgetService} from "../services/widgets/widget.service";
import {PageService} from "../services/page.service";
import {StreamerProfileComponent} from "./streamer-profile/streamer-profile.component";
import {ConnectSocialDialogComponent} from "./connect-social-dialog/connect-social-dialog.component";
import {
    WidgetEditEventSourceParamRangeComponent
} from "./widgets/edit/widget-edit-event-source-param/widget-edit-event-source-param-range/widget-edit-event-source-param-range.component";
import {
    WidgetEditEventSourceParamListComponent
} from "./widgets/edit/widget-edit-event-source-param/widget-edit-event-source-param-list/widget-edit-event-source-param-list.component";
import {DonationHelpComponent} from "./donate/donation-help/donation-help.component";
import {TagsSelectorComponent} from "./tags-selector/tags-selector.component";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {ExportTransactionsComponent} from "./export-transactions/export-transactions.component";
import {
    HelpTagsComponent
} from "./widgets/edit/widget-edit-visuals/widget-help-tags/help-tags/help-tags.component";
import {
    WidgetHelpTagsComponent
} from "./widgets/edit/widget-edit-visuals/widget-help-tags/widget-help-tags.component";
import {ItemWithdrawalComponent} from "./finance/transaction-list/transaction-list-items/item-withdrawal/item-withdrawal.component";
import {ItemDonateCardComponent} from "./finance/transaction-list/transaction-list-items/item-donate-card/item-donate-card.component";
import {EventsFilterPopupComponent} from "./finance/transaction-list/events-filter-popup/events-filter-popup.component";
import {WidgetEditOpenRangeComponent} from "./widgets/edit/widget-edit-open-range/widget-edit-open-range.component";
import {QRCodeModule} from "angularx-qrcode";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatRippleModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDividerModule} from "@angular/material/divider";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
    WidgetSelectGoogleFontComponent
} from "./widgets/edit/widget-edit-visuals/widget-edit-text/widget-select-google-font/widget-select-google-font.component";
import {SortablejsModule} from "ngx-sortablejs";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {VoiceSelectorComponent} from "./voice-selector/voice-selector.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSliderModule} from "@angular/material/slider";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {WidgetGroupService} from "../services/widgets/widget_groups/widget-group.service";
import {UserNameCardComponent} from "./mobile-version/user-name-card/user-name-card.component";
import {UserNameDialogComponent} from "./mobile-version/user-name-card/user-name-dialog/user-name-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {
    ItemTwitchHypeTrainCardComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-hype-train/item-twitch-hype-train.card.component";
import {
    ItemTwitchChannelPointsCardComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-channel-points/item-twitch-channel-points-card.component";
import {MatSelectModule} from "@angular/material/select";
import {
    EventSourceChipsComponent
} from "./widgets/edit/widget-edit-sources/widget-edit-event-sources/chips/event-source-chips.component";
import {
    ItemTwitchSubscriberGiftViewerComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-subscriber-gift-viewer/item-twitch-subscriber-gift-viewer-card.component";
import {
    ItemTwitchSubscriberGiftChannelComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-subscriber-gift-channel/item-twitch-subscriber-gift-channel-card.component";
import {
    ItemTwitchSubscriberGiftUpgradeComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-subscriber-gift-upgrade/item-twitch-subscriber-gift-upgrade-card.component";
import {
    ItemTwitchSubscriberUpgradeComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-subscriber-upgrade/item-twitch-subscriber-upgrade-card.component";
import {ProfanityModule} from "../modules/profanity/profanity.module";
import {
    ItemTwitchSubscriberCardComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-subscriber/item-twitch-subscriber-card.component";
import {
    ItemYoutubeSponsorCardComponent
} from "./finance/transaction-list/transaction-list-items/item-youtube-sponsor/item-youtube-sponsor-card.component";
import {
    ItemTwitchHostCardComponent
} from "./finance/transaction-list/transaction-list-items/item-twitch-host/item-twitch-host-card.component";
import {
    ItemFollowerCardComponent
} from "./finance/transaction-list/transaction-list-items/item-follower/item-follower-card.component";
import {
    ItemRaidCardComponent
} from "./finance/transaction-list/transaction-list-items/item-raid/item-raid-card.component";
import {
    ItemYoutubeFollowerCardComponent
} from "./finance/transaction-list/transaction-list-items/item-youtube-follower/item-youtube-follower-card.component";
import {
    ItemYoutubeSuperchatCardComponent
} from "./finance/transaction-list/transaction-list-items/item-youtube-superchat/item-youtube-superchat-card.component";
import {
    ItemSocialDonateCardComponent
} from "./finance/transaction-list/transaction-list-items/item-social-donate/item-social-donate-card.component";
import {TopDonatorsListComponent} from "./finance/top-donators-list/top-donators-list.component";
import {NgxPaginationModule} from "ngx-pagination";
import {OnboardingQuestComponent} from "./finance/onboarding/onboarding-quest.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {WidgetsMenuComponent} from "./widgets/widgets-menu/widgets-menu.component";
import {GuestGuard} from "../guards/guest.guard";
import {MatTabsModule} from "@angular/material/tabs";
import {DragScrollModule} from "ngx-drag-scroll";
import {IdentityV2Component} from "./identity/v2/identity-v2.component";
import {
    ItemTrovoSubscriberCardComponent
} from "./finance/transaction-list/transaction-list-items/item-trovo-subscriber/item-trovo-subscriber-card.component";
import {
    ItemTrovoSubscriberGiftViewerComponent
} from "./finance/transaction-list/transaction-list-items/item-trovo-subscriber-gift-viewer/item-trovo-subscriber-gift-viewer-card.component";
import {
    ItemTrovoSubscriberGiftChannelComponent
} from "./finance/transaction-list/transaction-list-items/item-trovo-subscriber-gift-channel/item-trovo-subscriber-gift-channel-card.component";
import {
    ItemTrovoSpellElixirCardComponent
} from "./finance/transaction-list/transaction-list-items/item-trovo-spell-elixir/item-trovo-spell-elixir-card.component";
import {
    ItemTrovoSpellManaCardComponent
} from "./finance/transaction-list/transaction-list-items/item-trovo-spell-mana/item-trovo-spell-mana-card.component";
import {TransactionAvatarComponent} from "./finance/transaction-list/transaction-avatar/transaction-avatar.component";
import {ModerationModule} from "../modules/moderation/moderation.module";
import {WidgetSizeControlComponent} from "./widgets/edit/widget-size-label/widget-size-control.component";
import {TransactionListItemsComponent} from "./finance/transaction-list/transaction-list-items/transaction-list-items.component";

@NgModule({
    declarations: [
        LoginComponent,
        DonateEditComponent,
        ButtonComponent,
        BalanceComponent,
        OnboardingQuestComponent,
        PayoutComponent,
        RadiobuttonComponent,
        IdentityComponent,
        IdentityV2Component,
        HintComponent,
        ConfirmationCodeComponent,
        IdentityThanksComponent,
        IdentityInfoComponent,
        StatisticsComponent,
        EmptyTransactionsComponent,
        WidgetsComponent,
        WidgetsMenuComponent,
        WidgetEditComponent,
        WidgetEditEventSourcesComponent,
        WidgetCopyLinkComponent,
        WidgetEditSourcesComponent,
        WidgetEditVisualsComponent,
        WidgetEditLayoutComponent,
        WidgetEditLayoutComponent,
        ColorSelectComponent,
        SwitcherComponent,
        WidgetEditFontBasicsComponent,
        WidgetsListItemComponent,
        CalendarComponent,
        AvatarComponent,
        PaymentProcessingComponent,
        PayoutErrorComponent,
        WidgetEditStyleUploadButtonComponent,
        WidgetEditDataParamsComponent,
        WidgetVisualsDonationComponent,
        WidgetVisualsEventsComponent,
        PayoutCardComponent,
        PayoutQiwiComponent,
        PayoutYandexComponent,
        WidgetVisualsGoalComponent,
        WidgetVisualsTopComponent,
        WidgetVisualsTotalComponent,
        WidgetDonationsTabPropsComponent,
        WidgetDonationsTabImagesComponent,
        WidgetDonationsTabAudioComponent,
        WidgetDonationsTabHeaderComponent,
        WidgetDonationsTabTextComponent,
        WidgetGoalTabPropsComponent,
        WidgetGoalTabHeaderComponent,
        WidgetGoalTabTargetComponent,
        WidgetGoalTabCountdownComponent,
        WidgetTabStyleComponent,
        WidgetTopTabPropsComponent,
        WidgetTopTabHeaderComponent,
        WidgetTopTabNameComponent,
        WidgetTopTabSumComponent,
        WidgetTotalTabPropsComponent,
        WidgetTotalTabHeaderComponent,
        WidgetTotalTabSumComponent,
        WidgetEventsTabPropsComponent,
        WidgetEventsTabEventsComponent,
        TransactionListComponent,
        TransactionListItemsComponent,
        TopDonatorsListComponent,
        MobileCardComponent,
        ObsCardComponent,
        SecureInputComponent,
        MobileHeaderComponent,
        MobileFrontComponent,
        StreamerProfileComponent,
        ConnectSocialDialogComponent,
        WidgetEditEventSourceParamRangeComponent,
        WidgetEditEventSourceParamListComponent,
        DonationHelpComponent,
        TagsSelectorComponent,
        ExportTransactionsComponent,
        HelpTagsComponent,
        WidgetHelpTagsComponent,
        ItemWithdrawalComponent,
        ItemDonateCardComponent,
        ItemTwitchHypeTrainCardComponent,
        ItemTwitchChannelPointsCardComponent,
        EventsFilterPopupComponent,
        WidgetEditOpenRangeComponent,
        DropdownComponent,
        WidgetSelectGoogleFontComponent,
        VoiceSelectorComponent,
        UserNameCardComponent,
        UserNameDialogComponent,
        EventSourceChipsComponent,
        ItemTwitchSubscriberGiftViewerComponent,
        ItemTwitchSubscriberGiftChannelComponent,
        ItemTwitchSubscriberGiftUpgradeComponent,
        ItemTwitchSubscriberUpgradeComponent,
        ItemTwitchSubscriberCardComponent,
        ItemTwitchHostCardComponent,
        ItemFollowerCardComponent,
        ItemRaidCardComponent,
        ItemTrovoSubscriberCardComponent,
        ItemTrovoSubscriberGiftViewerComponent,
        ItemTrovoSubscriberGiftChannelComponent,
        ItemTrovoSpellElixirCardComponent,
        ItemTrovoSpellManaCardComponent,
        ItemYoutubeSponsorCardComponent,
        ItemYoutubeFollowerCardComponent,
        ItemYoutubeSuperchatCardComponent,
        ItemSocialDonateCardComponent,
        TransactionAvatarComponent,
        WidgetSizeControlComponent
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        RouterModule,
        ClipboardModule,
        SliderModule,
        ColorPickerModule,
        NgxDaterangepickerMd.forRoot(),
        NgxMaskModule.forRoot(),
        MatButtonModule,
        SharedModule,
        QRCodeModule,
        MatIconModule,
        MatCardModule,
        MatRippleModule,
        MatMenuModule,
        DragDropModule,
        MatExpansionModule,
        MatDividerModule,
        MatSlideToggleModule,
        SortablejsModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        ScrollingModule,
        MatDialogModule,
        MatSelectModule,
        ProfanityModule,
        NgxPaginationModule,
        MatProgressBarModule,
        MatStepperModule,
        MatRadioModule,
        MatTabsModule,
        DragScrollModule,
        ModerationModule,
    ],
    exports: [
        LoginComponent,
        DonateEditComponent,
        ButtonComponent,
        BalanceComponent,
        OnboardingQuestComponent,
        PayoutComponent,
        RadiobuttonComponent,
        IdentityInfoComponent,
        StatisticsComponent,
        EmptyTransactionsComponent,
        WidgetsComponent,
        WidgetsMenuComponent,
        WidgetEditComponent,
        WidgetEditEventSourcesComponent,
        WidgetCopyLinkComponent,
        AvatarComponent,
        TransactionListComponent,
        TransactionListItemsComponent,
        TopDonatorsListComponent,
        MobileCardComponent,
        ObsCardComponent,
        DonationHelpComponent,
        DropdownComponent,
    ],
    providers: [
        AuthGuard,
        DesktopGuard,
        GuestGuard,
        AuthService,
        LoginService,
        FinanceService,
        DonationsService,
        WidgetService,
        WidgetGroupService,
        PageService,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ]
})
export class ComponentsModule {
}
