import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AccountsService} from "../../services/accounts/accounts.service";
import {merge, Subject} from "rxjs";
import {AccountType, AccountInfo} from "../../services/accounts/accounts";
import {UserService} from "../../services/user/user.service";
import {ConnectSocialDialogComponent} from "../connect-social-dialog/connect-social-dialog.component";
import {NavigationHistoryService} from "../../../../../shared/src/lib/navigation-history.service";
import {DonateThemeService} from "../../services/donate-theme.service";
import {filter, takeUntil, withLatestFrom} from "rxjs/operators";

@Component({
    selector: "app-streamer-profile",
    templateUrl: "./streamer-profile.component.html",
    styleUrls: ["./streamer-profile.component.scss"],
})
export class StreamerProfileComponent implements OnInit, OnDestroy {

    private readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef,
                       public readonly accountsService: AccountsService,
                       private readonly navigationHistoryService: NavigationHistoryService,
                       private readonly userService: UserService,
                       private readonly userStoreService: DonateThemeService) {
    }

    private readonly paypalAccountRemoved$ = this.accountsService.accountRemoved$.pipe(
        filter(account => account.getType() === AccountType.Paypal),
    );

    public ngOnInit(): void {
        this.paypalAccountRemoved$.pipe(
            withLatestFrom(this.userStoreService.donatePageTheme$), takeUntil(this.destroy$),
        ).subscribe(([, theme]) => {
            theme.paymentMethods.paypal = {isEnabled: false};
            this.userStoreService.save(theme);
        });

        merge(this.accountsService.accountConnected$, this.accountsService.accountReconnected$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.changeDetectorRef.detectChanges());
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public onAddAccount(e: MouseEvent): void {
        e.preventDefault();
        e.stopImmediatePropagation();

        this.connectDialog.show();
        this.changeDetectorRef.detectChanges();
    }

    public async onAddSocial(type: AccountType): Promise<void> {
        await this.accountsService.addAccount(type);
    }

    public onCloseClick(): void {
        this.navigationHistoryService.goBack();
    }

    public onDeleteAccount(e: MouseEvent, account: AccountInfo): void {
        e.stopImmediatePropagation();

        if (account.isMain()) {
            return;
        }

        this.accountsService.removeAccount(account);
    }

    public onRestoreAccount(e: MouseEvent, account: AccountInfo): Promise<void> {
        e.stopImmediatePropagation();
        return this.onAddSocial(account.getType());
    }

    @ViewChild("dialog", {static: true})
    private connectDialog: ConnectSocialDialogComponent;
}
