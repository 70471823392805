import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {fromEvent, of, Subject, animationFrames, timer, merge, from} from "rxjs";
import {MatMenuTrigger} from "@angular/material/menu";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModerationAction, ModerationService, ModerationSettings} from "../moderation.service";
import {catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {SwPush} from "@angular/service-worker";
import {environment} from "../../../../environments/environment";
import {ToastrService} from "ngx-toastr";
import {SSEService} from "../../../services/sse.service";
import {EnvironmentService} from "../../../../../../shared/src/lib/environment.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";

interface PushForm {
    isEnabled: AbstractControl<boolean>;
    isAlertSoundEnabled: AbstractControl<boolean>;
}

interface ModerationForm {
    timeoutSecs: AbstractControl<number>;
    action: AbstractControl<boolean>;
    isAlertSoundEnabled: AbstractControl<boolean>;
    push: FormGroup<PushForm>;
}

const actionToBoolean: { [key in ModerationAction]: boolean } = {
    [ModerationAction.Deny]: false,
    [ModerationAction.Allow]: true,
};

@Component({
    selector: "app-moderation-control-button",
    templateUrl: "./moderation-control-button.component.html",
    styleUrls: ["./moderation-control-button.component.scss"],
})
export class ModerationControlButtonComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatMenuTrigger)
    public menuTrigger: MatMenuTrigger;

    public readonly pushAvailable = !!environment.firebase;
    public readonly ModerationAction = ModerationAction;
    private readonly destroy$ = new Subject<void>();

    public constructor(public readonly moderationService: ModerationService,
                       private readonly sseService: SSEService,
                       private readonly toastr: ToastrService,
                       private readonly swPush: SwPush,
                       public readonly environmentService: EnvironmentService,
                       private readonly afMessaging: AngularFireMessaging) {
    }

    public ngOnInit() {
        this.saveSettings$.subscribe();
        this.updateSettings$.subscribe(value => this.form.setValue(value, {emitEvent: false}));
        this.enableSound$.subscribe(() => this.moderationService.playSound());
        this.enablePush$.subscribe();
    }

    public ngAfterViewInit() {
        this.menuTrigger.menuOpened.pipe(
            switchMap(() => fromEvent(window, "resize")),
            switchMap(() => animationFrames().pipe(
                takeUntil(merge(this.menuTrigger.menuClosed, timer(500))),
            )),
            takeUntil(this.destroy$),
        ).subscribe(() => this.menuTrigger.updatePosition());
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public readonly maxTimeout = 100;

    public readonly form = new FormGroup<ModerationForm>({
        timeoutSecs: new FormControl<number>(0, {
            validators: [Validators.min(0), Validators.max(this.maxTimeout)],
            nonNullable: true,
        }),
        action: new FormControl<boolean>(true, {nonNullable: true}),
        isAlertSoundEnabled: new FormControl<boolean>(false, {nonNullable: true}),
        push: new FormGroup<PushForm>({
            isEnabled: new FormControl<boolean>(false, {nonNullable: true}),
            isAlertSoundEnabled: new FormControl<boolean>(false, {nonNullable: true}),
        }),
    });

    private readonly updateSettings$ = this.moderationService.settings$.pipe(
        map((moderationSettings: ModerationSettings) => ({
            ...moderationSettings,
            action: actionToBoolean[moderationSettings.action],
        })),
        takeUntil(this.destroy$),
    );

    private readonly saveSettings$ = this.form.valueChanges.pipe(
        filter(() => this.form.valid),
        map(() => this.form.getRawValue()),
        map(formValue => ({
            ...formValue,
            action: Object.keys(actionToBoolean)
                .find(action => actionToBoolean[action] === formValue.action) as ModerationAction
        })),
        switchMap(value => this.moderationService.saveSettings(value)),
        takeUntil(this.destroy$),
    );

    private readonly enableSound$ = this.form.get("isAlertSoundEnabled").valueChanges.pipe(
        distinctUntilChanged(),
        filter(Boolean),
        takeUntil(this.destroy$),
    );

    private readonly enablePush$ = this.form.get("push").get("isEnabled").valueChanges.pipe(
        filter(Boolean),
        switchMap(() => this.afMessaging.requestPermission.pipe(
            tap(result => {
                if (result === "denied") {
                    throw new Error("Push permission denied");
                }
            }),
        )),
        switchMap(() => from(Notification.requestPermission()).pipe(
            tap(result => {
                if (result === "denied") {
                    throw new Error("Notification permission denied");
                }
            }),
        )),
    ).pipe(
        catchError(e => {
            const control: AbstractControl<boolean> = this.form.get("push").get("isEnabled");
            control.setValue(!control.value);
            this.toastr.error(e.message);
            return of(null);
        }),
    );

    public test() {
        this.moderationService.sendTest();
    }
}
