<div class="hdr__row">
    <div *ngFor="let tab of TABS"
         class="hdr__item"
         [ngClass]="{'hdr__item--active': tab.type === currentTab}"
         (click)="onTabClick(tab.type)">
        <div class="hdr__content">
            <div class="hdr__icon hdr__icon--{{tab.type}}">
                <img src="/assets/mobile/icons/{{tab.icon}}.svg"/>
            </div>
            <div class="hdr__text">{{tab.text}}</div>
        </div>
        <div class="hdr__selection"></div>
    </div>
</div>
