<div class="tsel__item">
    <span class="item__descr">Доступные теги:</span>
</div>
<div class="tsel__item" *ngFor="let item of items">
    <span class="item__tag">
        {{'{'}}<a (click)="onItemClicked(item)">{{item.tag}}</a>{{'}'}}
    </span>
    <span class="item__descr">
        &mdash;
        {{item.description}}
    </span>
</div>
