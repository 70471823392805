<div class="we__switcher-container" [ngClass]="{'we__switcher-container--disabled': disabled}">
  <div class="we__switcher"
       [ngClass]="{'we__switcher--on': value}"
       (click)="onClick()">
      <div class="we__circle">
      </div>
  </div>

  <div class="we__label" *ngIf="hasText">
    {{label}}
  </div>
</div>
