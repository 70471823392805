import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {PlatformInfoService} from "../../../../shared/src/lib/platform-info.service";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    public readonly loginUrlTree = this.router.parseUrl("/login");
    public readonly mainPageUrlTree = this.router.parseUrl(this.platformInfo.isDesktop ? "/finance" : "/mobile");
    public readonly mobilePageUrlTree = this.router.parseUrl( "/mobile");

    public constructor(private readonly platformInfo: PlatformInfoService,
                       private readonly router: Router) {
    }

    public navigateToAccountBlockPage(): Promise<boolean> {
        window.location.href = "https://donatty.com/pages/user_blocked";
        return Promise.resolve(true);
    }
}
