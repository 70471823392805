import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from "@angular/core";
import {DonationsService} from "../../../services/donation/donation.service";
import {BehaviorSubject, combineLatest, Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
    selector: "app-top-donators-list",
    templateUrl: "./top-donators-list.component.html",
    styleUrls: ["./top-donators-list.component.scss"],
})
export class TopDonatorsListComponent implements OnInit, AfterViewInit, OnDestroy {
    public paginate = {
        currentPage: 1,
        itemsPerPage: 2,
    };
    public paginationVisible = false;
    public emptyItems = Array(8).fill(1);
    public readonly donators$ = this.donationsService.donators$;
    public readonly page$ = new BehaviorSubject(1);
    public readonly size$ = new BehaviorSubject<[number, number]>([2, 2]);
    private readonly resizeObserver: ResizeObserver = new ResizeObserver(entries => this.onResize(entries));
    private readonly destroy$: Subject<void> = new Subject();
    public itemsOnPreviousPages = 0;

    constructor(public readonly donationsService: DonationsService,
                private readonly host: ElementRef,
                private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit() {
        const compareSizes = (prev, curr) => prev[0] - curr[0] + prev[1] - curr[1] === 0;
        combineLatest([this.donators$, this.page$, this.size$.pipe(distinctUntilChanged(compareSizes))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([donators, page, size]) => {
                this.paginate.itemsPerPage = size[0] * size[1];
                this.paginationVisible = donators?.length > this.paginate.itemsPerPage;
                if (this.paginationVisible) {
                    this.paginate.currentPage = page;
                } else {
                    this.paginate.currentPage = 1;
                }
                this.itemsOnPreviousPages = (this.paginate.currentPage - 1) * this.paginate.itemsPerPage;
                const emptyItemsCount = Math.max(0, this.paginate.itemsPerPage - ((donators?.length || 0) - this.itemsOnPreviousPages));
                this.emptyItems = Array(Math.max(0, emptyItemsCount)).fill(1);
                requestAnimationFrame(() => this.changeDetectorRef.detectChanges());
            });
        this.donators$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => requestAnimationFrame(() => this.changeDetectorRef.detectChanges()));
    }

    public ngAfterViewInit() {
        this.resizeObserver.observe(this.host.nativeElement);
    }

    public ngOnDestroy() {
        this.resizeObserver.unobserve(this.host.nativeElement);
        this.destroy$.next();
    }

    private onResize(entries) {
        const cardWidth = 186;
        const cardHeight = 76;
        const header = 46;
        const x = Math.round((entries[0].contentRect.width) / cardWidth);
        const y = Math.round((entries[0].contentRect.height - header) / cardHeight);
        this.size$.next([x, y]);
    }

}
