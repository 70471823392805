import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";

@Component({
    selector: "app-new-button",
    templateUrl: "./new-button.component.html",
    styleUrls: ["./new-button.component.scss", "./new-button-icons.scss"]
})
export class NewButtonComponent {
    @Input()
    public blockEvents = true;

    @Input("disabled")
    public isDisabled = false;

    @Input("icon")
    public iconName: string;

    @Input("type")
    public buttonType: string;

    @Output()
    public click: EventEmitter<any> = new EventEmitter();

    @ViewChild("rippleContainer", {static: true})
    private rippleContainerEl: ElementRef;

    public get buttonTypeClasses(): string {
        if (!this.buttonType) {
            return "";
        }

        return this.buttonType
            .split(" ")
            .map(t => `btn--${t}`)
            .join(" ");
    }

    public get contentPaddingClass(): string {
        return this.isIconPresent ? "btn__container--icon" : "btn__container--no-icon";
    }

    public get iconClassName(): string {
        if (this.isIconPresent) {
            return "btn__icon--" + this.iconName;
        }

        return "";
    }

    public get isIconPresent(): boolean {
        return (this.iconName && (this.iconName.length > 0));
    }

    public emitRipple(e: MouseEvent): void {
        if (this.isDisabled) {
            return;
        }

        const rippleContainerEl: HTMLElement = this.rippleContainerEl.nativeElement;

        const rippleContainerRect = rippleContainerEl.getBoundingClientRect();

        const rippleX = (e.pageX - rippleContainerRect.left - 25);
        const rippleY = (e.pageY - rippleContainerRect.top - window.scrollY - 25);

        const rippleEl = document.createElement("div");

        rippleEl.classList.add("btn__ripple");
        rippleEl.style.left = `${rippleX}px`;
        rippleEl.style.top = `${rippleY}px`;
        rippleEl.id = `btn-ripple-${++this.rippleId}`;

        rippleContainerEl.appendChild(rippleEl);
        setTimeout(() => rippleContainerEl.removeChild(rippleEl), 2000);
    }

    public onClick(event: MouseEvent) {
        if (this.blockEvents) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        if (this.isDisabled) {
            return;
        }

        try {
            this.click.emit();
        } catch (e) {
            console.warn("button click handler failed", e);
        }
    }

    private rippleId = 0;
}
