import {Component, Input, Output, EventEmitter} from "@angular/core";
import {WidgetType} from "../../../../../../../../../shared/src/lib/models/widget";

@Component({
    selector: "app-settings-edit-layout",
    templateUrl: "./widget-edit-layout.component.html",
    styleUrls: ["./widget-edit-layout.component.scss"]
})
export class WidgetEditLayoutComponent {

    @Input() value: number;
    @Output() valueChange = new EventEmitter<number>();

    @Input() type: WidgetType;

    @Input() options: Array<WidgetType> = [];

    public readonly styleTexts: { [key in WidgetType]: Array<string> } = {
        [WidgetType.Alert]: [
            "Изображение сверху, текст снизу",
            "Изображение снизу, текст сверху",
            "Изображение за текстом",
            "Изображение слева, текст справа",
            "Изображение справа, текст слева",
        ],
        [WidgetType.Goal]: [
            "Заголовок сверху слева, дата сверху справа",
            "Заголовок сверху справа, дата сверху слева",
            "Заголовок сверху слева, дата снизу слева",
            "Заголовок сверху слева, дата снизу справа",
            "Заголовок сверху посередине, дата снизу посередине",
            "Заголовок снизу посередине, дата сверху посередине",
        ],
        [WidgetType.Top]: [
            "Заголовок списка сверху",
            "Заголовок списка снизу",
            "Заголовок бегущей строки сверху",
            "Заголовок бегущей строки снизу",
            "Заголовок бегущей строки слева",
            "Заголовок бегущей строки справа",
        ],
        [WidgetType.Total]: [
            "Заголовок сверху",
            "Заголовок снизу",
            "Заголовок слева",
            "Заголовок справа",
        ],
        [WidgetType.Events]: [
            "Порядок списка снизу вверх",
            "Порядок списка сверху вниз",
        ],
        [WidgetType.Media]: [],
    };
}
