import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {JsonErrorObject} from "../../../../shared/src/lib/models/json-error-object";

export class BaseService {

    constructor(
            protected readonly authService: AuthService,
            protected readonly http: HttpClient) {
    }

    protected serviceUrl = "";

    protected static resolveRequest(result: any, resolve: any, reject: any) {
        if (result.error) {
            reject(result.error);
        } else {
            resolve(result.response);
        }
    }

    protected async uploadFile(url: string, file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file, file.name);

        const result: any = await this.http
            .post(`${environment.donationAPI}/${url}`, formData, {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.authService.getToken()}`
                })
            })
            .toPromise();

        return result.response;
    }

    protected postRequest(url: string, model: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(`${environment.donationAPI}/${this.serviceUrl}/${url}`, model, this.getHttpOptions()).toPromise()
                .then((result: any) => BaseService.resolveRequest(result, resolve, reject))
                .catch(error => reject(error));
        });
    }

    protected deleteRequest(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.delete(`${environment.donationAPI}/${this.serviceUrl}/${url}`, this.getHttpOptions()).toPromise()
                .then((result: any) => BaseService.resolveRequest(result, resolve, reject))
                .catch(error => reject(error));
        });
    }

    protected putRequest(url: string, model: any): Promise<any> {
        const fullUrl = `${environment.donationAPI}/${this.serviceUrl}/${url}`;
        return new Promise((resolve, reject) => {
            this.http
                .put(fullUrl, model, this.getHttpOptions())
                .toPromise()
                .then((result: any) => BaseService.resolveRequest(result, resolve, reject))
                .catch(error => reject(error));
        });
    }

    protected getRequest(url: string, subscription: Subscription): Promise<any> {
        return new Promise((resolve, reject) => {
            if (subscription) {
                subscription.unsubscribe();
            }
            subscription = this.http.get(
                `${environment.donationAPI}/${this.serviceUrl}/${url}`,
                this.getHttpOptions())
                .subscribe((result: any) => {
                    return result ? result.error ? reject(result.error)
                        : resolve(result.response) : reject();
                }, error => reject(error));
        });
    }

    protected getHttpOptions() {
        return {
            headers: new HttpHeaders(
                this.authService.getToken() ?
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.authService.getToken()}`
                    } : {
                        "Content-Type": "application/json"
                    }
            )
        };
    }
}
