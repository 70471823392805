import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import _ from "lodash";
import {TopWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {ProfanityService} from "../../../../../../modules/profanity/profanity.servoce";

@Component({
    selector: "app-widget-top-tab-props",
    templateUrl: "./widget-top-tab-props.component.html",
    styleUrls: ["./widget-top-tab-props.component.scss"]
})
export class WidgetTopTabPropsComponent implements OnInit {

    @Input()
    public widgetProps: TopWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    public constructor(public readonly profanityService: ProfanityService) {
    }

    public ngOnInit(): void {
        this.profanityService.fetchProfanity();
    }

    public get isLinksFilterEnabled(): boolean {
        return _.get(this.widgetProps.data, "filters.links", false);
    }

    public set isLinksFilterEnabled(value: boolean) {
        const oldValue = _.get(this.widgetProps.data, "filters.links", null);
        _.set(this.widgetProps.data, "filters.links", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get itemsRounding(): number {
        return _.get(this.widgetProps.style, "rowsRounding", 50);
    }

    public set itemsRounding(value: number) {
        const oldValue = _.get(this.widgetProps.style, "rowsRounding", 50);

        _.set(this.widgetProps.style, "rowsRounding", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get mainRounding(): number {
        return _.get(this.widgetProps.style, "mainRounding", 50);
    }

    public set mainRounding(value: number) {
        const oldValue = _.get(this.widgetProps.style, "mainRounding", 50);

        _.set(this.widgetProps.style, "mainRounding", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get visibleRows(): number {
        return _.get(this.widgetProps.data, "visibleRows", 5);
    }

    public set visibleRows(value: number) {
        const oldValue = _.get(this.widgetProps.data, "visibleRows", 5);

        _.set(this.widgetProps.data, "visibleRows", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }
}
