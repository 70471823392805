<div class="we__tab-container">
  <div class="we__header">
    Сумма
  </div>

  <app-widget-edit-font-basics id="donationSum" [gradient]="true" [showMaxWidth]="false"
                               text="суммы" [(item)]="widgetProps.style.donationSum" offsetBounds="200"
                               (itemChange)="widgetProps.style.donationSum = $event; widgetPropsChange.emit(widgetProps)">
  </app-widget-edit-font-basics>
</div>
