import {Injectable, InjectionToken, Injector, Type} from "@angular/core";
import {SidebarManagerService} from "./sidebar-manager.service";
import {ComponentPortal} from "@angular/cdk/portal";
import {SidebarEntryComponent} from "./sidebar-entry/sidebar-entry.component";

export const SIDEBAR_DATA = new InjectionToken<any>("SIDEBAR_DATA");

@Injectable()
export class SidebarService {
    constructor(private sidebarManager: SidebarManagerService,
                private injector: Injector) {
    }

    public open<TComponent, TData>(component: Type<TComponent>, data?: TData): void {
        const outerInjector = Injector.create({
            parent: this.injector,
            providers: [{provide: SIDEBAR_DATA, useValue: data}],
        });
        const outerPortal = new ComponentPortal(SidebarEntryComponent);
        const innerPortal = new ComponentPortal(component, outerPortal.viewContainerRef, outerInjector);
        this.sidebarManager.open(outerPortal, innerPortal);
    }

    public close(): void {
        this.sidebarManager.close();
    }

}
