import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import * as _ from "lodash";

@Component({
    selector: "app-widget-edit-style-upload-button",
    templateUrl: "./widget-edit-style-upload-button.component.html",
    styleUrls: ["./widget-edit-style-upload-button.component.scss"]
})
export class WidgetEditStyleUploadButtonComponent implements OnInit, OnChanges {
    @Input("accept")
    public acceptFiles: string;

    @Input("default")
    public defaultUris: string[];

    @Input("help")
    public helpText: string;

    @Input("icon")
    public iconName: string;

    @Input()
    public layout = "horizontal";

    @Input()
    public maxSizeMb = 0;

    @Input()
    public type = "lightgrey";

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() clear: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("fileInput", {static: true})
    private fileInput: ElementRef;

    public uploadedFilename = "";

    public constructor(private toastr: ToastrService) {}

    public get hasUploadedItem(): boolean {
        return (this.uploadedFilename.length > 0);
    }

    public clearUploaded() {
        this.fileElement.value = null;
        this.uploadedFilename = "";

        this.clear.emit();
    }

    public getFilename(src: string) {
        return src.split("/").reverse()[0];
    }

    public ngOnInit() {
        this.updateUploadedFilename();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.defaultUris) {
            return;
        }

        const defaultUris = changes.defaultUris;
        const currentValue = defaultUris.currentValue?.[0];
        const previousValue = defaultUris.previousValue?.[0];

        if (currentValue === previousValue) {
            return;
        }

        if (!currentValue) {
            this.clearUploaded();
            return;
        }

        const isDataUri = currentValue.startsWith("data:");
        if (isDataUri) {
            return;
        }

        this.fileElement.value = null;
        this.defaultUris = defaultUris.currentValue;
        this.uploadedFilename = this.getFilename(currentValue);
    }

    public onClick() {
        this.fileElement.click();
    }

    public proxyChange(event: Event): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        const files = this.fileElement.files;
        if (files.length === 0) {
            this.fileElement.value = null;
            return;
        }

        const file: File = files[0];
        const sizeInMb = (file.size / 1024 / 1024);
        if (sizeInMb > this.maxSizeMb) {
            this.fileElement.value = null;
            this.toastr.warning(`Максимальный размер файла ${this.maxSizeMb} МБ`);
            return;
        }

        if (!this.isExtensionAllowed(file.name)) {
            this.fileElement.value = null;
            this.toastr.warning("Недопустимый формат файла");
            return;
        }

        this.updateUploadedFilename();

        this.change.emit(event);
    }

    private get fileElement(): HTMLInputElement {
        return this.fileInput.nativeElement as HTMLInputElement;
    }

    private isExtensionAllowed(name: string): boolean {
        const acceptedFormats = this.acceptFiles.split(",");
        return _.some(acceptedFormats, fmt => name.endsWith(fmt));
    }

    private updateUploadedFilename(): void {
        this.uploadedFilename = "";

        const input = this.fileElement;
        const hasFileInInputControl = (
            input && input.files && (input.files.length > 0));
        if (hasFileInInputControl) {
            this.uploadedFilename =
                this.getFilename(input.files.item(0).name);
            return;
        }

        if (this.defaultUris && (this.defaultUris.length > 0)) {
            this.uploadedFilename = this.getFilename(
                this.defaultUris[0]);
            return;
        }
    }
}
