import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "app-radiobutton",
    templateUrl: "./radiobutton.component.html",
    styleUrls: ["./radiobutton.component.sass"]
})
export class RadiobuttonComponent {

    constructor(private ref: ChangeDetectorRef) {
    }

    @Input() group = "radio-group";
    @Input() selectedValue: string | number = "";
    @Input() items = [];
    @Input() direction = "vertical";
    @Output() changed = new EventEmitter<string | number>();
    @Output() delete = new EventEmitter<string | number>();

    onChange(ev) {
        setTimeout(() => {
            this.ref.detectChanges();
        }, 100);
        this.changed.emit(ev);
    }
}
