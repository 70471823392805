import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {DonationListResponse, DonationTarget, DonationTargetResponse, Donator, DonatorsResponse} from "./donation.api";
import {merge, Observable} from "rxjs";
import {StreamerEventsService} from "../events/streamer-events.service";
import {SSEService} from "../sse.service";
import {filter, map, shareReplay, switchMap} from "rxjs/operators";
import {UserService} from "../user/user.service";

@Injectable({
    providedIn: "root"
})
export class DonationsService {

    private readonly baseUrl = `${this.environmentService.backendApiUri}/donations`;
    private readonly getDonators$ = merge(
        this.getDonators(),
        merge(
            this.streamerEventsService.donationEvents$.pipe(filter(e => e.isLive)),
            this.sseService.reconnect$,
        ).pipe(switchMap(() => this.getDonators())),
    );
    public readonly donators$: Observable<Array<Donator>> = this.userService.permissions$.pipe(
        filter(Boolean),
        switchMap(() => this.getDonators$),
        shareReplay(1),
    );

    constructor(private readonly http: HttpClient,
                private readonly authService: AuthService,
                private readonly environmentService: EnvironmentService,
                private readonly userService: UserService,
                private readonly streamerEventsService: StreamerEventsService,
                private readonly sseService: SSEService) {
    }

    private async list(ids: Array<string>): Promise<Array<any>> {
        const response = await this.http.post<DonationListResponse>(
            `${this.baseUrl}/list`,
            {refs: ids},
            this.authService.makeTokenAuthHeaders()).toPromise();
        return response.response;
    }

    private getDonators(): Observable<Array<Donator>> {
        const url = `${this.baseUrl}/statistics/donators?maxResults=8`;
        return this.http.get<DonatorsResponse>(url, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response.items));
    }

    public async getDonationTarget(id: string): Promise<DonationTarget> {
        const response = await this.http.get<DonationTargetResponse>(
            `${this.baseUrl}/targets/${id}`,
            this.authService.makeTokenAuthHeaders()).toPromise();
        return response.response;
    }

}
