import {
    AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, ViewChild
} from "@angular/core";
import {
    debounceTime, delay, distinctUntilChanged, filter, map, mapTo, startWith, switchMap, take, takeUntil
} from "rxjs/operators";
import {BehaviorSubject, combineLatest, merge, Subject} from "rxjs";
import {onboardingQuests} from "./onboardingQuests";
import {QuestGroupState, QuestService} from "../../../modules/quest/quest.service";
import {NavigationEnd, Router} from "@angular/router";
import {Quest} from "../../../modules/quest/Quest";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";

@Component({
    selector: "app-onboarding-quest",
    templateUrl: "./onboarding-quest.component.html",
    styleUrls: ["./onboarding-quest.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingQuestComponent implements OnInit, AfterViewInit, OnDestroy {

    public readonly quests$ = new BehaviorSubject(onboardingQuests);
    public readonly questDone$: Subject<string> = new Subject<string>();
    public readonly questUndone$: Subject<string> = new Subject<string>();

    @HostBinding("hidden")
    public isClosed = true;

    public readonly progress$ = this.quests$.pipe(
        map(quests => (quests.filter(quest => quest.goal.isReached)).length / quests.length),
        distinctUntilChanged(),
        map(normalProgress => normalProgress * 100),
    );

    public readonly doneCount$ = this.quests$.pipe(
        map(quests => quests.filter(quest => quest.goal.isReached)),
        distinctUntilChanged(),
    );

    public readonly allDone$ = this.progress$.pipe(
        map(progress => progress >= 100),
        distinctUntilChanged(),
    );

    public readonly page$ = new BehaviorSubject<number>(1);

    public readonly pageCount$ = combineLatest([this.quests$, this.allDone$.pipe(delay(20))]).pipe(
        map(([quests, isAllCompleted]) =>
            quests.length + +isAllCompleted
        ),
        distinctUntilChanged(),
    );

    private readonly navigationEnd$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
    );

    private readonly scrollPosition$ = combineLatest([
        combineLatest([this.page$, this.pageCount$]).pipe(
            switchMap(([page, pageCount]) =>
                this.ngZone.onStable.pipe(take(1), map(() => [page, pageCount]))
            ),
            map(([page, pageCount]) => {
                const pageScrollWidth = this.scrollWidth / pageCount;
                return page * pageScrollWidth - pageScrollWidth;
            }),
        ), this.navigationEnd$.pipe(startWith(1))
    ]).pipe(
        debounceTime(30),
        map(([scrollPosition]) => scrollPosition),
    );

    private readonly ready$: Subject<void> = new Subject();
    private readonly destroy$: Subject<void> = new Subject();

    public constructor(public readonly questService: QuestService,
                       private readonly router: Router,
                       private readonly dataLayerService: DataLayerService,
                       private readonly ngZone: NgZone) {
    }

    public ngOnInit() {
        this.ready$.subscribe(() => {
            merge(this.quests$, this.questDone$.pipe(mapTo(this.quests$.value))).pipe(
                map(quests => OnboardingQuestComponent.getPage(quests, this.page$.value)),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            ).subscribe(nextPage => this.page$.next(nextPage));

            this.questDone$.pipe(takeUntil(this.destroy$)).subscribe(doneQuestId => {
                this.quests$.value.find(q => q.id === doneQuestId).goal.isReached = true;
                this.saveCurrentState().subscribe(() => {
                    this.quests$.next(this.quests$.value);
                    this.datalayerHandlers.questDone(doneQuestId);
                });
            });

            this.allDone$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(() =>
                this.datalayerHandlers.allQuestsDone()
            );

            this.questUndone$.pipe(takeUntil(this.destroy$)).subscribe(undoneQuestId => {
                this.quests$.value.find(q => q.id === undoneQuestId).goal.isReached = false;
                this.saveCurrentState().subscribe(() => {
                    this.quests$.next(this.quests$.value);
                    this.datalayerHandlers.questUndone(undoneQuestId);
                });
            });
        });

        this.fetchState().subscribe(onboardingState => {
            this.updateState(onboardingState);
            this.ready$.next();
        });
    }

    public ngAfterViewInit() {
        this.scrollPosition$
            .pipe(takeUntil(this.destroy$))
            .subscribe(left => this.scrollTo(left));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public close() {
        this.isClosed = true;
        this.saveCurrentState().subscribe();
        this.datalayerHandlers.closed();
    }

    private updateState(onboardingState: QuestGroupState) {
        this.isClosed = onboardingState.isClosed || false;
        if (this.isClosed) {
            return;
        }
        let updateRequired = false;
        const quests = this.quests$.value;
        for (const quest of quests) {
            const isCompleted = onboardingState.completedIds.includes(quest.id);
            if (quest.goal.isReached !== isCompleted) {
                quest.goal.isReached = isCompleted;
                updateRequired = true;
            }
        }
        if (updateRequired) {
            this.quests$.next(quests);
        }
    }

    private static getPage(quests: Array<Quest>, currentPage: number) {
        const len = quests.length;
        for (let i = currentPage - 1, maxI = currentPage + len; i < maxI; i++) {
            const page = (i % len) + 1;
            if (!quests[page - 1].goal.isReached) {
                return page;
            }
        }
        return len + 1;
    }

    @ViewChild("list")
    private readonly list: ElementRef<HTMLElement>;

    private get scrollWidth() {
        return this.list.nativeElement.scrollWidth;
    }

    private scrollTo(left: number) {
        this.list.nativeElement.scrollTo({left, behavior: "auto"});
    }

    private fetchState() {
        const defaultState: QuestGroupState = {
            isClosed: false,
            completedIds: [],
        };
        return this.questService.getInfo("onboarding-checklist")
            .pipe(
                take(1),
                map<QuestGroupState, QuestGroupState>(serverState => ({
                    ...defaultState,
                    ...serverState,
                }))
            );
    }

    private saveCurrentState() {
        const newState = {
            isClosed: this.isClosed,
            completedIds: this.quests$.value.filter(q => q.goal.isReached).map(q => q.id),
        };
        return this.questService.saveInfo("onboarding-checklist", newState)
            .pipe(take(1));
    }

    private datalayerHandlers = {
        questDone: questId => {
            const i = onboardingQuests.findIndex(q => q.id === questId) + 1;
            this.dataLayerService.emit({
                eventCategory: EventCategory.Onboarding,
                eventAction: EventAction.MarkedDone,
                eventLabel: `quest${i}`,
                eventValue: questId,
            });
        },
        questUndone: questId => {
            const i = onboardingQuests.findIndex(q => q.id === questId) + 1;
            this.dataLayerService.emit({
                eventCategory: EventCategory.Onboarding,
                eventAction: EventAction.MarkedUndone,
                eventLabel: `quest${i}`,
                eventValue: questId,
            });
        },
        allQuestsDone: () => {
            this.dataLayerService.emit({
                eventCategory: EventCategory.Onboarding,
                eventAction: EventAction.MarkedDone,
                eventValue: EventAction.MarkedDone,
                eventLabel: "all-quests",
            });
        },
        closed: () => {
            this.dataLayerService.emit({
                eventCategory: EventCategory.Onboarding,
                eventAction: EventAction.Closed,
                eventValue: EventAction.Closed,
                eventLabel: "quest-block",
            });
        },
    };

}
