import {Injectable} from "@angular/core";
import {AnalyticsEvent} from "./models/analytics-events";
import {PlatformInfoService} from "./platform-info.service";

declare global {
    interface Window {
        dataLayer: Array<any>;
    }
}

@Injectable({
    providedIn: "root"
})
export class DataLayerService {
    public constructor(private readonly platformInfoService: PlatformInfoService) {
    }

    public emit(event: AnalyticsEvent) {
        this.send({event: "event-to-ga", ...event});
    }

    public emitRaw(event: any) {
        this.send(event);
    }

    public emitPurchase(donation: any, acceptorName: string, orderType: string) {
        this.emitRaw({
            event: "checkoutOption",
            ecommerce: {
                checkout_option: {
                    actionField: {step: 2, option: orderType}
                }
            }
        });
        const amount = donation.order.amount.toString();
        this.emitRaw({
            ecommerce: {
                purchase: {
                    actionField: {
                        id: donation.refId,
                        revenue: amount
                    },
                    products: [{
                        name: acceptorName,
                        id: donation.acceptor.refId,
                        price: amount,
                        category: "donation",
                        quantity: 1
                    }]
                }
            }
        });
        this.emitRaw({event: "purchase", eventValue: donation.refId});
    }

    private readonly send =
        this.platformInfoService.isInIFrame ?
            () => undefined :
            eventData => window.dataLayer.push(eventData);

}
