import {PaymentMethodType} from "../../../../../shared/src/lib/models/finance.api";

export enum StreamerEventType {
    DonattyDonation = "DONATTY_DONATION",
    DonattyPayout = "DONATTY_PAYOUT",
    DonattyPayoutSuspicious = "DONATTY_PAYOUT_SUSPICIOUS",
    PaypalDonation = "PAYPAL_DONATION",
    TwitchBit = "TWITCH_BIT",
    TwitchChannelPoints = "TWITCH_REWARD_REDEMPTION",
    TwitchFollower = "TWITCH_FOLLOWER",
    TwitchHost = "TWITCH_HOST",
    TwitchHypeTrain = "TWITCH_HYPE_TRAIN",
    TwitchRaid = "TWITCH_RAID",
    TwitchSubscriber = "TWITCH_SUBSCRIBER",
    TwitchSubscriberGiftViewer = "TWITCH_SUBSCRIBER_GIFT_VIEWER",
    TwitchSubscriberGiftChannel = "TWITCH_SUBSCRIBER_GIFT_CHANNEL",
    TwitchSubscriberGiftUpgrade = "TWITCH_SUBSCRIBER_GIFT_UPGRADE",
    TwitchSubscriberUpgrade = "TWITCH_SUBSCRIBER_UPGRADE",
    TrovoFollower = "TROVO_FOLLOWER",
    TrovoRaid = "TROVO_RAID",
    TrovoSpellElixir = "TROVO_SPELL_ELIXIR",
    TrovoSpellMana = "TROVO_SPELL_MANA",
    TrovoSubscriber = "TROVO_SUBSCRIBER",
    TrovoSubscriberGiftChannel = "TROVO_SUBSCRIBER_GIFT_CHANNEL",
    TrovoSubscriberGiftViewer = "TROVO_SUBSCRIBER_GIFT_VIEWER",
    YoutubeFollower = "YOUTUBE_SUBSCRIBER",
    YoutubeSponsor = "YOUTUBE_SPONSOR",
    YoutubeSuperchat = "YOUTUBE_DONATION",
}

export const disabledStreamerEvents = {
    [StreamerEventType.TwitchSubscriberGiftUpgrade]: true,
    [StreamerEventType.TwitchSubscriberUpgrade]: true,
    [StreamerEventType.TwitchHost]: true,
};

export const STREAMER_EVENT_TYPES_ALL: readonly StreamerEventType[] = [
    StreamerEventType.DonattyDonation, StreamerEventType.DonattyPayout, StreamerEventType.DonattyPayoutSuspicious,
    StreamerEventType.PaypalDonation,

    StreamerEventType.TwitchBit, StreamerEventType.TwitchChannelPoints, StreamerEventType.TwitchFollower,
    StreamerEventType.TwitchHost, StreamerEventType.TwitchHypeTrain, StreamerEventType.TwitchRaid,
    StreamerEventType.TwitchSubscriber, StreamerEventType.TwitchSubscriberGiftViewer,
    StreamerEventType.TwitchSubscriberGiftChannel, StreamerEventType.TwitchSubscriberGiftUpgrade,
    StreamerEventType.TwitchSubscriberUpgrade,

    StreamerEventType.YoutubeFollower, StreamerEventType.YoutubeSponsor, StreamerEventType.YoutubeSuperchat,

    StreamerEventType.TrovoFollower, StreamerEventType.TrovoRaid, StreamerEventType.TrovoSubscriber,
    StreamerEventType.TrovoSubscriberGiftViewer, StreamerEventType.TrovoSubscriberGiftChannel,
    StreamerEventType.TrovoSpellElixir, StreamerEventType.TrovoSpellMana,
];

export const STREAMER_EVENT_TYPES: readonly StreamerEventType[] =
    STREAMER_EVENT_TYPES_ALL.filter(et => !disabledStreamerEvents[et]);

export const donationEvents: readonly StreamerEventType[] = [
    StreamerEventType.DonattyDonation,
    StreamerEventType.PaypalDonation,
    StreamerEventType.YoutubeSuperchat,
];

export enum StreamerEventCurrency {
    Bits = "TWITCH_BIT",
    Ruble = "RUB",
    Points = "POINTS",
    Mana = "TROVO_MANA",
    Elixir = "TROVO_ELIXIR",
}

export enum ModerationState {
    None = "NONE",
    Passed = "PASSED",
    Blocked = "BLOCKED",
}

export interface StreamerEventsList {
    nextPageToken: string;
    items: StreamerEventResponse[];
}

export interface StreamerEventsResponse {
    response: StreamerEventsList;
}

export interface StreamerEventResponse {
    refId: string;
    type: StreamerEventType;
    date: string;
    moderation?: ModerationState;
}

export interface StreamerEventGoalResponse {
    name: string;
}

export interface StreamerEventPictureResponse {
    source: string;
}

export type MediaProvider = "YOUTUBE";

export interface DonationAttachments {
    media: {
        url: string,
        info: {
            name: string,
            likes: number,
            views: number,
            isAdult: boolean,
            dislikes: number,
            provider: MediaProvider,
            isEmbeddable: boolean,
            durationSeconds: number,
            hasLicensedContent: boolean,
        },
    };
}

export interface DonationEventResponse extends StreamerEventResponse {
    amount: number;
    currency: StreamerEventCurrency;
    displayName: string;
    goal: StreamerEventGoalResponse;
    message: string;
    picture: StreamerEventPictureResponse;
    attachments?: DonationAttachments;
}

export interface PayoutEventResponse extends StreamerEventResponse {
    amount: number;
    commission: number;
    method: PaymentMethodType;
    number: string;
    orderId?: number;
}

export interface DonattyDonationEventResponse extends DonationEventResponse {
    method: PaymentMethodType;
    commission: number;
}

export interface PaypalDonationEventResponse extends DonationEventResponse {
}
