import {
    AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output
} from "@angular/core";
import {TagSelectorItem} from "../../../../../tags-selector/tags-selector.component";
import {AccountEventType} from "../../../../../../services/accounts/accounts";
import {WidgetType} from "../../../../../../../../../shared/src/lib/models/widget";
import {WidgetHelpTagsService} from "../../../../../../services/widgets/widget-help-tags.service";
import {Observable, Subject} from "rxjs";
import {WidgetGroupService} from "../../../../../../services/widgets/widget_groups/widget-group.service";
import {map, shareReplay, switchMap} from "rxjs/operators";

@Component({
    selector: "app-help-tags",
    templateUrl: "./help-tags.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpTagsComponent implements AfterViewInit, OnChanges {
    @Input()
    public eventTypes: Array<AccountEventType>;

    @Input()
    public includePurpose = true;

    @Input()
    public customTags: Array<TagSelectorItem> = [];

    @Output()
    public tagClick = new EventEmitter<string>();

    public readonly build$: Subject<void> = new Subject();

    private readonly hasPurpose$ = this.widgetGroupService.widgets$.pipe(
        map(widgets => widgets.findIndex(w => (w.getType() === WidgetType.Goal)) > -1),
        shareReplay(1),
    );

    public readonly availableTags$: Observable<TagSelectorItem[]> = this.build$.pipe(
        switchMap(() => this.hasPurpose$),
        map((hasPurpose) => this.buildAvailableTags(hasPurpose)));

    public constructor(private readonly widgetGroupService: WidgetGroupService) {
    }

    public ngAfterViewInit() {
        this.build$.next();
    }

    public ngOnChanges() {
        this.build$.next();
    }

    private buildAvailableTags(hasPurpose: boolean): TagSelectorItem[] {
        if (!this.eventTypes?.length) {
            return [];
        }
        const tags = WidgetHelpTagsService.formatDonationTags(this.eventTypes, this.includePurpose && hasPurpose);
        if (this.customTags.length > 0) {
            this.customTags.forEach(t => tags.push(t));
        }
        return tags;
    }

}
