import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({name: "niceDate"})
export class NiceDatePipe implements PipeTransform {

    public transform(date: string | Date): string {
        const thisDate = moment(date);

        const today = moment();
        if (thisDate.isSame(today, "day")) {
            return "Сегодня";
        }

        const yesterday = today.subtract(1, "day");
        if (thisDate.isSame(yesterday, "day")) {
            return "Вчера";
        }

        return thisDate
            .locale("ru")
            .format("D MMMM");
    }
}
