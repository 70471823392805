<div class="we__tab-container">
    <div class="we__header">
        Заголовок
    </div>

    <div class="we__template-row">
        <div class="we__row">
            <div class="we__label">Шаблон</div>
            <div class="we__controls">
                <input type="text"
                       (ngModelChange)="widgetProps.style.header.template = $event; widgetPropsChange.emit(widgetProps)"
                       [ngModel]="widgetProps.style.header.template"
                       #headerTemplate
                       (keyup)="caretPosition = headerTemplate.selectionStart"
                       (click)="caretPosition = headerTemplate.selectionStart"
                       (focusin)="caretPosition = headerTemplate.selectionStart">
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-widget-help-tags [widgetPropsData]="widgetProps.data"
                                      (tagClick)="onTagClicked($event)">
                </app-widget-help-tags>
            </div>
        </div>
    </div>

    <app-widget-edit-font-basics id="header" [(item)]="widgetProps.style.header" text="заголовка" offsetBounds="550"
                                 [showMaxWidth]="true"
                                 [showPositioning]="widgetProps.style.layout === 2"
                                 (itemChange)="widgetProps.style.header = $event; widgetPropsChange.emit(widgetProps)">
    </app-widget-edit-font-basics>
</div>
