<div class="progress__page">
    <div class="progress__loader">
        <div class="progress__spinner">
            <img alt="Loading..." src="/assets/icons/loader.svg" >
        </div>

        <div class="progress__logo">
            <img alt="Logo" src="/assets/icons/logo-mark.svg" >
        </div>
    </div>
    <div class="progress__title">Переводим деньги</div>
    <div class="progress__subtitle">Это займёт не больше минуты.</div>
</div>
