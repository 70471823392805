import {Inject, Injectable} from "@angular/core";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {VerificationState, Wallet} from "../models/finance.api";
import {Donator, MonthItem} from "../../../../streamer/src/app/services/donation/donation.api";
import {fakeStreamerEvents, makeTestStreamerEvent} from "./fakeStreamerEvents";
import {donationEvents, StreamerEventType} from "../../../../streamer/src/app/services/events/streamer-events.api";
import {DonationEvent} from "../../../../streamer/src/app/services/events/streamer-event";
import {Util} from "../util";
import {fake} from "./fake";
import {Currency, PayoutMethodType} from "../common/FinancialOperation";
import Identity from "../common/Identity";

export const fakeId = "123e4567-e89b-12d3-a456-315503063999";

type PayoutMethod = {
    refId: string,
    type: PayoutMethodType,
    currency: Currency,
    owner: Identity,
    number: string
};

export enum DevFlag {
    FakeStreamerEvents = "donatty/dev/fake-streamer-events",
    PaypalDonate = "donatty/dev/paypal-donate",
    UserVerified = "donatty/dev/user-verified",
    PlatformGuardDisabled = "donatty/dev/disable-platform-guard",
    FakeDonatorsEnabled = "donatty/dev/fake-donators",
}

@Injectable({
    providedIn: "root"
})
export class DevSettingsService {

    constructor(@Inject(LOCAL_STORAGE)
                private readonly storageService: StorageService) {
    }

    private walletBalance = 0;
    public fakeDonators: Array<Donator> = [];

    public buildFakeStatistics(): Array<MonthItem> {
        const d = new Date();
        return [
            {
                date: Util.formatDate(d),
                fee: this.walletBalance,
                feeCommission: this.walletBalance * .03,
                withdrawal: 0,
                withdrawalCommission: 0,
            },
        ];
    }

    public buildFakeWithdrawalOperation(req?: any): any {
        // To utilize local card form, build and run locally payment-form project
        const localCardFormUrl = `//localhost:8081`;
        return {
            amount: req?.amount || 300,
            payee: req?.payee || {number: "123", refId: fakeId},
            refId: req?.refId || fakeId,
            status: req?.status,
            commission: req?.commission || 300 * .03,
            cardNumber: req?.cardNumber,
            transactions: req?.transactions || [{
                type: "hz",
                refId: fakeId,
                state: "state",
                paymentUrl: localCardFormUrl,
            }],
        };
    }

    public saveFakePayoutMethod(method: PayoutMethod) {
        this.storageService.set("donatty/dev/fake-payout-method", method);
    }

    public deleteFakePayoutMethod() {
        this.storageService.remove("donatty/dev/fake-payout-method");
    }

    public getFakePayoutMethod() {
        const payoutOperation: PayoutMethod = this.storageService.get("donatty/dev/fake-payout-method");
        if (!payoutOperation) {
            return null;
        }
        return payoutOperation;
    }

    public get fakeVerificationState(): VerificationState {
        return (localStorage.getItem("donatty/dev/fake-verification") || null) as VerificationState;
    }

    public get fakeWallet(): Wallet {
        let wallet: Wallet = this.storageService.get("donatty/dev/fake-wallet");
        if (!wallet) {
            return null;
        }
        wallet = {
            ...fake.wallet(),
            balance: this.walletBalance,
            usage: {
                fee: this.walletBalance,
                withdrawal: 0
            },
            ...wallet,
            refId: fakeId
        };
        return wallet;
    }

    private onDonationEvent(event: DonationEvent) {
        const eventAmount = (event as any).amount || 0;
        this.walletBalance += eventAmount;
        const donator: Donator = this.fakeDonators.find(d => d.name === (event as any).displayName);
        if (donator) {
            donator.amount += eventAmount;
        } else {
            this.fakeDonators.push({
                name: (event as any).displayName,
                amount: eventAmount,
            });
            this.fakeDonators.push({
                name: 1 + (event as any).displayName,
                amount: 1 + eventAmount,
            });
            this.fakeDonators.push({
                name: 2 + (event as any).displayName,
                amount: 2 + eventAmount,
            });
        }
    }

    public getFakeStreamerEvent(eventType: StreamerEventType) {
        const event = makeTestStreamerEvent[eventType]();
        if (donationEvents.includes(event.type)) {
            this.onDonationEvent(event as any);
        }
        return event;
    }

    public getFakeStreamerEvents(maxResult: number) {
        const events = fakeStreamerEvents(maxResult);
        events
            .filter(event => donationEvents.includes(event.type))
            .forEach(event => this.onDonationEvent(event as any));
        return events;
    }

    public get fakePyout(): boolean {
        return this.isFlagSet("donatty/dev/fake-payout");
    }

    public get forcePayoutFailure() {
        return window.location.href.toLowerCase().includes("test-force-payout-failure");
    }

    public get isFakeDonatorsEnabled(): boolean {
        return this.isFlagSet(DevFlag.FakeDonatorsEnabled);
    }

    public get isFakeStreamEventsFeedEnabled(): boolean {
        return this.isFlagSet(DevFlag.FakeStreamerEvents);
    }

    public get isPlatformGuardDisabled(): boolean {
        return this.isFlagSet(DevFlag.PlatformGuardDisabled);
    }

    public get isUserVerified(): boolean {
        return this.isFlagSet(DevFlag.UserVerified);
    }

    public get isPaypalDonateEnabled(): boolean {
        return this.isFlagSet(DevFlag.PaypalDonate);
    }

    public isFlagSet(id: string): boolean {
        return !!this.storageService.get(id);
    }

}
