import {Component, OnInit} from "@angular/core";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {FormControl} from "@angular/forms";

@Component({
    selector: "app-export-transactions",
    templateUrl: "./export-transactions.component.html",
    styleUrls: ["./export-transactions.component.scss"]
})
export class ExportTransactionsComponent implements OnInit {
    public durationItems: DurationItem[];
    public formControl = new FormControl<string>(null);

    public constructor(private readonly environmentService: EnvironmentService) {
    }

    public ngOnInit(): void {
        const thisYear = new Date().getFullYear();
        const yesterYear = thisYear - 1;

        this.durationItems = [
            {text: `${thisYear} год`, value: `year/${thisYear}`},
            {text: `${yesterYear} год`, value: `year/${yesterYear}`},
            {text: "За всё время", value: ""},
        ];
        this.formControl.setValue(this.durationItems[0].value);
    }

    public onDownloadClick(): void {
        let uri = this.environmentService.backendApiUri;
        uri += "/donations/export/";
        uri += this.formControl.value;
        uri += "?zoneOffset=";
        uri += new Date().getTimezoneOffset();

        window.open(uri, "_blank");
    }
}

type DurationItem = { text: string, value: string };
