import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ReplaySubject} from "rxjs";

@Component({
    selector: "lib-progress-spinner",
    templateUrl: "./progress-spinner.component.html",
    styleUrls: ["./progress-spinner.component.scss"],
})
export class ProgressSpinnerComponent implements OnInit, OnDestroy {

    @Input()
    public timeout = 0;

    @Output()
    public readonly timeoutComplete = new EventEmitter<void>();

    public ngOnInit(): void {
        this.initTimeout(this.timeout);
    }

    public ngOnDestroy(): void {
        cancelAnimationFrame(this.rafHandle);
    }

    private rafHandle: number;
    public readonly secondsLeft$ = new ReplaySubject<number>(1);
    public readonly progressValue$ = new ReplaySubject<number>(1);

    private initTimeout(timeout: number) {
        const startedAt = performance.now();
        this.secondsLeft$.next(timeout * .001);
        this.progressValue$.next(100 / timeout * timeout);
        const animate = t => {
            const progressLeft = timeout - (t - startedAt);
            this.secondsLeft$.next(Math.round(progressLeft * .001));
            const progress = 100 / timeout * progressLeft;
            this.progressValue$.next(progress);
            if (progress > 0) {
                this.rafHandle = requestAnimationFrame(animate);
            } else {
                this.timeoutComplete.next();
            }
        };
        animate(0);
    }

}
