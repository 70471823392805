import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {UserService} from "../../../../services/user/user.service";
import {ToastrService} from "ngx-toastr";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {combineLatest, Observable, Subject, throwError} from "rxjs";
import {
    catchError, filter, finalize, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom
} from "rxjs/operators";
import {User} from "../../../../../../../shared/src/lib/models/user";

@Component({
    selector: "app-user-name-dialog",
    templateUrl: "./user-name-dialog.component.html",
    styleUrls: ["./user-name-dialog.component.scss"],
})
export class UserNameDialogComponent implements OnInit, OnDestroy {
    public hasChanges = false;

    public formGroup = new FormGroup({
        userName: this.userService.userNameFormControl
    });

    private readonly destroy$: Subject<void> = new Subject();
    private readonly currentUser$: Observable<User> = this.userService.currentUser$.pipe(
        filter(user => !!user),
        shareReplay(1),
    );
    public readonly saveTrigger$: Subject<void> = new Subject();
    public saveInProgress = false;
    private readonly saveUser$: Observable<User> = this.saveTrigger$.pipe(
        filter(() => this.formGroup.valid),
        withLatestFrom(this.currentUser$),
        map(([, user]) => user),
        tap(user => user.name = this.formGroup.controls.userName.value),
    );

    constructor(private readonly userService: UserService,
                private readonly toastr: ToastrService,
                private readonly dialogRef: MatDialogRef<UserNameDialogComponent, void>) {
    }

    public ngOnInit(): void {
        combineLatest([this.currentUser$, this.formGroup.controls.userName.valueChanges]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(([currentUser, userName]) => {
            this.hasChanges = userName !== currentUser.name;
        });

        combineLatest([this.currentUser$, this.dialogRef.afterClosed()]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(([currentUser]) => {
            this.userService.userNameFormControl.setValue(currentUser.name);
        });

        this.saveUser$.pipe(
            filter(() => !this.saveInProgress),
            tap(() => this.saveInProgress = true),
            switchMap(user => fromPromise(this.userService.save(user)).pipe(
                catchError(e => {
                    this.toastr.error("Изменения не сохранены");
                    return throwError(e);
                }),
                finalize(() => {
                    this.saveInProgress = false;
                    this.dialogRef.close();
                }),
            )),
        ).subscribe(() => this.toastr.success("Изменения сохранены"));
    }

    public ngOnDestroy(): void {
        requestAnimationFrame(() => this.destroy$.next());
    }

    public onCancelClick(): void {
        this.dialogRef.close(null);
    }
}
