<div class="we__page" *ngIf="widgetInfo$ | async as widgetInfo">
    <div class="we__stretch-container">
        <div class="we__settings-container">
            <div class="we__settings">
                <div class="settings__header">
                    <mat-form-field [formGroup]="formGroup">
                        <input id="widgetName"
                               formControlName="widgetName"
                               matInput
                               maxLength="100">
                        <mat-error
                            *ngFor="let error of formGroup.get('widgetName').errors?.widgetName">{{error.message}}</mat-error>
                    </mat-form-field>
                    <button id="close-button" mat-fab color="" (click)="close()">
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </div>
                <app-widget-copy-link [link]="widgetUrl$ | async"></app-widget-copy-link>
                <div class="banner" *ngIf="banners['frames'].widgetType === widgetInfo.getType() && banners['frames'].show">
                    <div class="banner-content">
                        <button class="close-banner" mat-mini-fab aria-label="Close banner"
                                (click)="closeBanner('frames')">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <mat-card>
                            <mat-card-title>
                                Готовые рамки для сбора средств
                            </mat-card-title>
                            <mat-card-content>
                                Недостаточно настроек для виджета? Мы сделали наборы
                                ярких рамок, выберите понравившийся и установите в OBS.
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-button color="primary"
                                        (click)="openBanner('frames')">Выбрать рамку
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>

                <app-widget-edit-sources [type]="widgetInfo.getType()" [widgetId]="widgetInfo.getId()"
                                         [eventSources]="widgetProps.data.sources"
                                         (eventSourcesChange)="onEventSourcesChange($event)"></app-widget-edit-sources>

                <app-widget-edit-data-params #widgetEditDataParams
                                             *ngIf="hasEditableDataParams && widgetProps"
                                             [type]="widgetInfo.getType()"
                                             [widgetId]="widgetInfo.getId()"
                                             [(widgetPropsData)]="widgetProps.data"
                                             (widgetPropsDataChange)="onWidgetPropsDataChanged($event)"></app-widget-edit-data-params>

                <app-widget-edit-visuals #widgetEditVisuals
                                         [widgetId]="widgetInfo.getId()"
                                         [type]="widgetInfo.getType()"
                                         (imageChanged)="imageChanged($event)"
                                         (audioChanged)="audioChanged($event)"
                                         [(widgetProps)]="widgetProps"
                                         (widgetPropsChange)="onWidgetPropsChange()"
                                         (tabChanged)="emitPreviewEvent$.next({action: 'SELECT', data: {element: $event}})"></app-widget-edit-visuals>
            </div>
            <div class="we__buttons">
                <app-new-button icon="bx-save" [disabled]="!hasUnsavedChanges || !isValid" type="blue" (click)="saveChanges()">
                    Сохранить настройки
                </app-new-button>

                <app-new-button icon="ic-refresh-18-px" [disabled]="!hasUnsavedChanges" (click)="cancelChanges()"
                                type="lightgrey">
                    Отменить изменения
                </app-new-button>
            </div>
        </div>

        <div class="we__preview-container">
            <div #frameContainer class="we__frame"
                 [ngClass]="{'we__show--loader': !(frameLoaded$ | async)}"
                 (click)="emitPreviewEvent$.next({action: 'CLEAR'})">
                <div class="scale-container">
                    <app-widget-size-control #sizeControl
                                             [value]="savedDimensions$ | async"
                                             [defaults]="defaultDimensions"
                                             (valueChange)="dimensionsByUser$.next($event)"
                                             (activeChange)="darkenPreview = sizeControl.active ? '60%' : sizeControl.mouseOver ? '30%' : '0'"
                                             (mouseenter)="darkenPreview = sizeControl.active ? '60%' : '30%'"
                                             (mouseleave)="darkenPreview = sizeControl.active ? darkenPreview : '0'">
                    </app-widget-size-control>
                    <ng-container *ngIf="widgetPreviewUrl$ | async as widgetPreviewUrl">
                        <iframe #frame
                                [ngStyle]="frameStyle$ | async"
                                [src]="widgetPreviewUrl"
                                allow="autoplay"
                                (mouseleave)="emitPreviewEvent$.next({action: 'CLEAR'})"></iframe>
                        <iframe #hiddenStaticFrameToCalculateWidgetSize
                                id="offscreen-frame"
                                [src]="widgetPreviewUrl"
                                allow="autoplay"></iframe>
                        <div #sizeHelper class="size-helper"
                             [hidden]="sizeControl.formGroup.pristine || sizeControl.formGroup.value.width === null || sizeControl.formGroup.value.height === null"
                             [style.width.px]="sizeControl.formGroup.value.width"
                             [style.height.px]="sizeControl.formGroup.value.height"
                             [style.bottom.px]="-.5 * (sizeControl.formGroup.value.height - frame.clientHeight)">
                        </div>
                    </ng-container>
                </div>
                <div class="we__preloader">
                    <img alt="Loader" src="/assets/images/loader.svg">
                </div>
            </div>
            <div class="we__buttons">
                <app-new-button type="lightgrey"
                                (click)="playAudio()">
                    <mat-icon svgIcon="play-circle"></mat-icon>
                    Проиграть
                </app-new-button>
                <app-new-button icon="ic-screen-share-24-px" type="lightgrey"
                                (click)="testOnStream()"
                                *ngIf="(widgetInfo$ | async)?.isAlertWidget()">
                    Тест на стриме
                </app-new-button>
            </div>
            <div class="we__preview-container-underlay"></div>
            <div class="we__preview-container-checkers"></div>
            <div class="we__preview-container-gradient"></div>
        </div>
    </div>
</div>

<lib-confirmation-modal #saveSettingsDialog header="Сохранить и выйти?"
                        confirmText="Сохранить и выйти" cancelText="Выйти без сохранения"
                        (confirm)="saveChangesAndExit()"
                        (cancel)="omitChanges()">
    Вы изменили настройки на странице. Хотите сохранить изменения?
</lib-confirmation-modal>
