<div class="obsc__body" [ngClass]="{loading: !(loginTokenService.obsLoginUrl$ | async)}">
    <header class="obsc__header">Версия для OBS</header>
    <div class="obsc__descr">
        Список донатов можно добавить в OBS.
        Скопируйте&nbsp;ссылку и вставьте её в разделе Вид > Док-панели > Пользовательские доки браузера.
        Никому не показывайте ссылку.
    </div>
    <div class="obsc__ref">
        <app-secure-input [text]="loginTokenService.obsLoginUrl$ | async"></app-secure-input>
    </div>
    <footer>
        <button mat-button color="primary"
                class="obsc__reset"
                (click)="loginTokenService.resetOBSLoginUrl$.next(); isResettingLoginToken = true;"
                [ngClass]="{'obsc__in-progress': isResettingLoginToken}">
            <mat-icon>autorenew</mat-icon>
            Сбросить ссылку
        </button>
    </footer>
</div>
