<div class="we__events-sources">
    <div class="we__row we__row--donatty" *ngIf="disabled else alertChips">
        <div class="we__column--image">
            <mat-icon svgIcon="logo-donatty"></mat-icon>
        </div>
        <div class="we__column--label">
            <div class="we__name">
                DONATTY
            </div>
            <div class="we__channel">
                {{(userService.currentUser$ | async)?.displayName}}
            </div>
        </div>
    </div>

    <ng-template #alertChips>
        <ng-container *ngIf="accountEvents$ | async as accountEvents">
            <div *ngFor="let account of accounts$ | async"
                 class="we__row we__row--{{account.getType()}}">
                <div class="we__column--image">
                    <mat-icon svgIcon="logo-{{account.getType()}}"></mat-icon>
                </div>
                <div class="we__column--label">
                    <div class="we__name">
                        {{account.getType() | uppercase}}
                    </div>
                    <div class="we__channel">
                        {{account.getNick()}}
                    </div>
                    <div class="we__controls">
                        <app-event-source-chips [accountEvents]="accountEvents[account.getId()]"
                                                (accountEventsChanged)="onEventSourcesChanged(account.getId(), $event)">
                        </app-event-source-chips>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
</div>

<div class="acc__new" *ngIf="!disabled">
    <button mat-button color="primary" (click)="connectDialog.show()">
        <mat-icon>add</mat-icon>Подключить новый источник&nbsp;&nbsp;(Twitch, YouTube и Trovo)
    </button>
</div>

<app-connect-social-dialog #dialog (addSocial)="externalAccountsService.addAccount($event)"></app-connect-social-dialog>
