export enum QuestGoalType {
    readArticle = "readArticle",
}

export interface QuestGoal<T extends QuestGoalType> {
    type: T;
    isReached: boolean;
}

export interface Quest<Goal extends QuestGoal<QuestGoalType> = any> {
    id: string;
    title: string;
    goal: Goal;
}
