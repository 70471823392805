<div class="dn_page" *ngIf="userService.currentUser$ | async">
    <div class="dn_settings"
         libFocus
         [focusItems]="[
             'themeNameInput','userNameInput','pageNameInput','pageDescriptionInput','topDescriptionTextInput',
             'rightDescriptionTextInput', 'twitchAddressInput', 'youtubeAddressInput', 'discordAddressInput',
             'vkAddressInput', 'zenAddressInput', 'instagramAddressInput', 'fbAddressInput', 'twitterAddressInput',
             'telegramAddressInput', 'tiktokAddressInput', 'minDonationAmountInput', 'defaultDonationAmount',
             'maxMessageLengthInput', 'donationButtonLabelInput', 'thanksPageHeaderInput', 'thanksPageTextInput',
             'qiwiSwitcher', 'minMediaDonationAmountInput'
         ]">
        <form [formGroup]="formGroup">
            <div class="settings__header">
                <mat-form-field>
                    <input id="themeNameInput"
                           formControlName="themeName"
                           matInput
                           maxLength="70">
                    <mat-error *ngFor="let error of formGroup.controls.themeName.errors?.themeName">
                        {{error.message}}
                    </mat-error>
                </mat-form-field>
                <button id="close-button" mat-fab color="" (click)="onCloseClicked()">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </div>

            <div class="settings__block-header">Страница приветствия</div>
            <div class="settings__block-description">
                Эту страницу пользователи увидят, когда перейдут по вашей ссылке.
            </div>

            <div class="settings__row settings__row--link">
                <div class="row__label"><label for="userNameInput">Ссылка на страницу</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <span matPrefix>{{frontUri}}/</span>
                        <textarea id="userNameInput"
                                  formControlName="userName"
                                  matInput
                                  maxLength="150"
                                  autofocus="autofocus"
                                  cdkTextareaAutosize="true"></textarea>
                        <button mat-icon-button
                                disableRipple="true"
                                matSuffix
                                (click)="copyLink()">
                            <mat-icon svgIcon="bx-copy"></mat-icon>
                        </button>
                        <mat-error *ngIf="formGroup.controls.userName.hasError('addressEmpty')">
                            Адрес не может быть пустым
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.userName.hasError('addressInvalid')">
                            Недопустимые символы
                        </mat-error>
                        <mat-error *ngIf="formGroup.controls.userName.hasError('addressOccupied')">
                            Такой адрес уже занят
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--banners">
                <div class="banners__content">
                    Готовые кнопки доната для Twitch
                    <app-new-button type="blue small" (click)="onShowBannersClick()">Перейти</app-new-button>
                </div>
            </div>

            <div class="settings__row">
                <div class="row__label"><label for="pageNameInput">Название страницы</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{pageName.length}} / 40</mat-hint>
                        <textarea id="pageNameInput"
                                  formControlName="pageName"
                                  matInput
                                  maxLength="40"
                                  cdkTextareaAutosize="true"></textarea>
                        <mat-error *ngFor="let error of formGroup.controls.pageName.errors?.pageName">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row">
                <div class="row__label"><label for="pageDescriptionInput">Описание страницы</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{pageDescription.length}} / 150</mat-hint>
                        <textarea id="pageDescriptionInput"
                                  formControlName="pageDescription"
                                  matInput
                                  maxLength="150"
                                  cdkTextareaAutosize="true"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row">
                <div class="row__label">Верхний текстовый блок</div>
                <div class="row__content">
                    <switcher #topDescriptionBlockSwitch [(value)]="isTopDescriptionBlockEnabled"></switcher>
                </div>
            </div>

            <div class="settings__row" *ngIf="topDescriptionBlockSwitch.value">
                <div class="row__label"><label for="topDescriptionTextInput">Текст блока</label></div>
                <div class="row__content">
                    <mat-form-field>
                    <textarea id="topDescriptionTextInput"
                              formControlName="topDescriptionText"
                              matInput
                              cdkTextareaAutosize="true"></textarea>
                        <mat-hint>
                            Поддерживает разметку <a [href]="markdownHelpUrl" target="_blank">Markdown</a>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row">
                <div class="row__label">Правый текстовый блок</div>
                <div class="row__content">
                    <switcher #rightDescriptionBlockSwitch [(value)]="isRightDescriptionBlockEnabled"></switcher>
                </div>
            </div>

            <div class="settings__row" *ngIf="rightDescriptionBlockSwitch.value">
                <div class="row__label"><label for="rightDescriptionTextInput">Текст блока</label></div>
                <div class="row__content">
                    <mat-form-field>
                    <textarea id="rightDescriptionTextInput"
                              formControlName="rightDescriptionText"
                              matInput
                              cdkTextareaAutosize="true"></textarea>
                        <mat-hint>
                            Поддерживает разметку <a [href]="markdownHelpUrl" target="_blank">Markdown</a>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--upload">
                <div class="row__label">Аватар</div>
                <div class="row__content">
                    <app-widget-edit-style-upload-button
                        icon="upload" type="grey" layout="vertical"
                        accept=".gif,.jpg,.jpeg,.png,.apng,.webp"
                        help="Рекомендуемый размер 360px - 360px. Максимум 20 Мб. Допустимые форматы: jpeg, png, gif, webp"
                        maxSizeMb="20"
                        [default]="[avatarFilename]"
                        (change)="onAvatarImageChanged($event)"
                        (clear)="onClearAvatarImage()">
                        Загрузить аватар
                    </app-widget-edit-style-upload-button>
                </div>
            </div>

            <div class="settings__row settings__row--upload">
                <div class="row__label">Обложка</div>
                <div class="row__content">
                    <app-widget-edit-style-upload-button
                        icon="upload" type="grey" layout="vertical"
                        accept=".gif,.jpg,.jpeg,.png,.apng,.webp"
                        help="Рекомендуемый размер 2880px - 480px. Максимум 20 Мб. Допустимые форматы: jpeg, png, gif, webp"
                        maxSizeMb="20"
                        [default]="[coverFilename]"
                        (change)="onCoverImageChanged($event)"
                        (clear)="onClearCoverImage()">
                        Загрузить обложку
                    </app-widget-edit-style-upload-button>
                </div>
            </div>

            <div class="settings__row settings__row--socials">
                <div class="row__label">Социальные сети</div>
                <div class="row__content">
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-twitch"></mat-icon>
                            twitch.tv/
                        </span>
                        <input id="twitchAddressInput"
                               formControlName="twitchAddress"
                               matInput
                               (input)="twitchAddress = correctSocialAddress(['twitch.tv'], twitchAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.twitchAddress.errors?.twitchAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-youtube"></mat-icon>
                            youtube.com/
                        </span>
                        <input id="youtubeAddressInput"
                               formControlName="youtubeAddress"
                               matInput
                               (input)="youtubeAddress = correctSocialAddress(['youtube.com'], youtubeAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.youtubeAddress.errors?.youtubeAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-trovo"></mat-icon>
                            trovo.live/
                        </span>
                        <input id="trovoAddressInput"
                               formControlName="trovoAddress"
                               matInput
                               (input)="trovoAddress = correctSocialAddress(['trovo.live'], trovoAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.trovoAddress.errors?.trovoAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-vk"></mat-icon>
                            vk.com/
                        </span>
                        <input id="vkAddressInput"
                               formControlName="vkAddress"
                               matInput
                               (input)="vkAddress = correctSocialAddress(['vk.com'], vkAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.vkAddress.errors?.vkAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-facebook"></mat-icon>
                            facebook.com/
                        </span>
                        <input id="fbAddressInput"
                               formControlName="fbAddress"
                               matInput
                               (input)="fbAddress = correctSocialAddress(['fb.com', 'facebook.com'], fbAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.fbAddress.errors?.fbAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-discord"></mat-icon>
                            discord.gg/
                        </span>
                        <input id="discordAddressInput"
                               formControlName="discordAddress"
                               matInput
                               (input)="discordAddress = correctSocialAddress(['discord.com', 'discordapp.com', 'discord.gg'], discordAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.discordAddress.errors?.discordAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-zen"></mat-icon>
                            zen.yandex.ru/
                        </span>
                        <input id="zenAddressInput"
                               formControlName="zenAddress"
                               matInput
                               (input)="zenAddress = correctSocialAddress(['zen.yandex.ru'], zenAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.zenAddress.errors?.zenAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-instagram"></mat-icon>
                            instagram.com/
                        </span>
                        <input id="instagramAddressInput"
                               formControlName="instagramAddress"
                               matInput
                               (input)="instagramAddress = correctSocialAddress(['instagram.com'], instagramAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.instagramAddress.errors?.instagramAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-twitter"></mat-icon>
                            twitter.com/
                        </span>
                        <input id="twitterAddressInput"
                               formControlName="twitterAddress"
                               matInput
                               (input)="twitterAddress = correctSocialAddress(['twitter.com'], twitterAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.twitterAddress.errors?.twitterAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-telegram"></mat-icon>
                            t.me/
                        </span>
                        <input id="telegramAddressInput"
                               formControlName="telegramAddress"
                               matInput
                               (input)="telegramAddress = correctSocialAddress(['telegram.me', 't.me'], telegramAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.telegramAddress.errors?.telegramAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon svgIcon="logo-tiktok"></mat-icon>
                            tiktok.com/@
                        </span>
                        <input id="tiktokAddressInput"
                               formControlName="tiktokAddress"
                               matInput
                               (input)="tiktokAddress = correctSocialAddress(['tiktok.com'], tiktokAddress)">
                        <mat-error *ngFor="let error of formGroup.controls.tiktokAddress.errors?.tiktokAddress">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--short">
                <div class="row__label"><label for="minDonationAmountInput">Минимальная сумма доната</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <input id="minDonationAmountInput"
                               formControlName="minDonationAmount"
                               matInput
                               type="number">
                        <mat-hint matSuffix align="end">₽</mat-hint>
                        <mat-error *ngFor="let error of formGroup.controls.minDonationAmount.errors?.minDonationAmount">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--short">
                <div class="row__label"><label for="defaultDonationAmount">Сумма доната по умолчанию</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <input id="defaultDonationAmount"
                               formControlName="defaultDonationAmount"
                               matInput
                               type="number">
                        <mat-hint matSuffix align="end">₽</mat-hint>
                        <mat-error
                            *ngFor="let error of formGroup.controls.defaultDonationAmount.errors?.defaultDonationAmount">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--short">
                <div class="row__label"><label for="maxMessageLengthInput">Количество символов</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <input id="maxMessageLengthInput"
                               formControlName="maxMessageLength"
                               matInput
                               type="number">
                        <mat-error *ngFor="let error of formGroup.controls.maxMessageLength.errors?.maxMessageLength">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row settings__row--short">
                <div class="row__label"><label for="donationButtonLabelInput">Текст кнопки доната</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{donationButtonLabel.length}} / 25</mat-hint>
                        <input id="donationButtonLabelInput"
                               formControlName="donationButtonLabel"
                               matInput
                               maxLength="25">
                        <mat-error
                            *ngFor="let error of formGroup.controls.donationButtonLabel.errors?.donationButtonLabel">
                            {{error.message}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__block-header">Дополнительные способы донатов</div>
            <div class="settings__block-description">
                Помимо банковской карты вы можете включить дополнительные способы донатов.
            </div>

            <div class="settings__row settings__row--method">
                <div class="row__label">
                    ЮMoney
                    <span>(комиссия 6%)</span>
                </div>
                <div class="row__content">
                    <switcher #yandexSwitcher
                              [disabled]="!(isUserIdentified$ | async)"
                              [value]="isYandexPaymentEnabled"
                              (valueChange)="onYandexSwitchChanged($event)"></switcher>
                    <app-donation-help *ngIf="!(isUserIdentified$ | async)">
                        Чтобы включить этот способ доната, пожалуйста,
                        пройдите идентификацию (Финансы – Идентифицироваться)
                    </app-donation-help>
                </div>
            </div>

            <div class="settings__row settings__row--method">
                <div class="row__label">
                    QIWI кошелёк
                    <span>(комиссия 7%)</span>
                </div>
                <div class="row__content">
                    <switcher #qiwiSwitcher
                              [disabled]="!(isUserIdentified$ | async)"
                              [value]="isQiwiPaymentEnabled"
                              (valueChange)="onQiwiSwitchChanged($event)"></switcher>
                    <app-donation-help *ngIf="!(isUserIdentified$ | async)">
                        Чтобы включить этот способ доната, пожалуйста,
                        пройдите идентификацию (Финансы – Идентифицироваться)
                    </app-donation-help>
                </div>
            </div>

            <!--
            <div class="settings__row settings__row--method">
                <div class="row__label">
                    PayPal
                    <span>
                    (<a href="https://www.paypal.com/ru/webapps/mpp/ua/useragreement-full#Fee_table" target="_blank">комиссия PayPal</a>)
                </span>
                </div>
                <div class="row__content">
                    <switcher #paypalSwitcher
                              [disabled]="!isUserIdentified"
                              [value]="isPaypalPaymentEnabled"
                              (valueChange)="onPaypalSwitchChanged($event)"></switcher>
                    <app-donation-help *ngIf="!isUserIdentified">
                        Чтобы включить этот способ доната, пожалуйста,
                        пройдите идентификацию (Финансы – Идентифицироваться)
                    </app-donation-help>
                </div>
            </div>
            -->

            <div class="settings__block-header">Страница благодарности</div>
            <div class="settings__block-description">
                Эту страницу пользователи увидят после отправки доната.
            </div>

            <div class="settings__row">
                <div class="row__label"><label for="thanksPageHeaderInput">Заголовок</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{thanksPageHeader.length}} / 30</mat-hint>
                        <textarea id="thanksPageHeaderInput"
                                  formControlName="thanksPageHeader"
                                  matInput
                                  cdkTextareaAutosize="true"
                                  maxlength="30"
                                  (focus)="showThanksPreview = true"
                                  (blur)="showThanksPreview = false"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="settings__row">
                <div class="row__label"><label for="thanksPageTextInput">Текст</label></div>
                <div class="row__content">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{thanksPageText.length}} / 200</mat-hint>
                        <textarea id="thanksPageTextInput"
                                  formControlName="thanksPageText"
                                  matInput
                                  cdkTextareaAutosize="true"
                                  maxlength="200"
                                  (focus)="showThanksPreview = true"
                                  (blur)="showThanksPreview = false"></textarea>
                        <mat-hint>
                            Поддерживает разметку <a [href]="markdownHelpUrl" target="_blank">Markdown</a>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="mediaDonationFeatureEnabled">
                <div class="settings__block-header">Добавление медиа к донату</div>
                <div class="settings__block-description">
                    Вы можете разрешить своим пользователям добавлять медиа к сообщениям
                    на странице доната.
                </div>

                <div class="settings__row">
                    <div class="row__label"><label for="isMediaEnabledInput">Разрешить медиа на стриме</label></div>
                    <div class="row__content">
                        <switcher id="isMediaEnabledInput"
                                  [value]="isMediaEnabled"
                                  (valueChange)="onMediaSwitchChanged($event)"></switcher>
                    </div>
                </div>
                <ng-container *ngIf="isMediaEnabled">
                    <div class="settings__row">
                        <div class="row__label"><label for="minMediaDonationAmountInput">Минимальная сумма доната для
                            медиа</label>
                        </div>
                        <div class="row__content">
                            <mat-form-field>
                                <span matPrefix>от&nbsp;</span>
                                <input id="minMediaDonationAmountInput"
                                       formControlName="minMediaDonationAmount"
                                       matInput
                                       type="number">
                                <mat-hint matSuffix align="end">₽</mat-hint>
                                <mat-error
                                    *ngFor="let error of formGroup.controls.minMediaDonationAmount.errors?.minMediaDonationAmount">
                                    {{error.message}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--Useless until media widget
                    <div class="settings__row">
                        <div class="row__label">Максимальная продолжительность</div>
                        <div class="row__content">
                            <mat-form-field>
                                <input id="maxMediaDurationInput"
                                       formControlName="maxMediaDuration"
                                       matInput
                                       type="number"
                                       [defaultValue]="0"
                                       [defaultChecked]="!maxMediaDuration">
                                <mat-hint matSuffix align="end">сек.</mat-hint>
                                <mat-error *ngFor="let error of formGroup.controls.maxMediaDuration.errors?.maxMediaDuration">
                                    {{error.message}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    -->
                    <div class="settings__row settings__row--media">
                        <div class="row__label">Уровень фильтрации</div>
                        <div class="row__content">
                            <span class="label">{{mediaDonationFilterSteps[mediaDonationFilterValue]?.label}}</span>
                            <mat-slider id="mediaDonationFilterInput"
                                        [max]="4"
                                        [min]="0"
                                        [step]="1"
                                        tickInterval="1"
                                        [value]="mediaDonationFilterValue"
                                        (input)="onMediaDonationFilterChanged($event.value)">
                            </mat-slider>
                            <span class="comment">{{mediaDonationFilterSteps[mediaDonationFilterValue].legend}}</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="settings__buttons">
                <app-new-button type="blue" icon="bx-save"
                                [disabled]="!isSaveChangesEnabled"
                                (click)="onSaveChangesClicked()">
                    Сохранить настройки
                </app-new-button>
                <app-new-button type="lightgrey" icon="ic-refresh-18-px"
                                [disabled]="!isCancelChangesEnabled"
                                (click)="onCancelChangesClicked()">
                    Отменить изменения
                </app-new-button>
            </div>
        </form>
    </div>
    <div class="dn_preview">
        <div class="dn__preview-container-underlay"></div>
        <div class="dn__preview-container-checkers"></div>
        <div class="dn__preview-container-gradient"></div>

        <div class="dn__frame-part">
            <div class="dn__frame-container">
                <div class="dn__preview-header">
                    <div class="dn__circle dn__circle--red"></div>
                    <div class="dn__circle dn__circle--yellow"></div>
                    <div class="dn__circle dn__circle--green"></div>
                </div>
                <div class="dn__preview-frame">
                    <iframe #preview class="dn__frame dn__frame--donate" [ngClass]="{'shown': !showThanksPreview}"
                            [src]="donatePreviewUri" (load)="onPreviewFrameLoaded()"></iframe>
                    <iframe #preview class="dn__frame dn__frame--thanks" [ngClass]="{'shown': showThanksPreview}"
                            [src]="thanksPreviewUri"></iframe>
                </div>
            </div>
        </div>

        <div class="dn__buttons-part">
            <app-new-button icon="ic-screen-share-24-px" type="lightgrey" (click)="openDonationPage()">
                Открыть в браузере
            </app-new-button>
        </div>
    </div>
</div>

<lib-confirmation-modal #paypalDialog header="Включение способа доната"
                        confirmIcon="bxl-paypal" confirmText="Подключить PayPal" cancelText="Отмена"
                        (cancel)="onPaypalSwitchCancelled()"
                        (close)="onPaypalSwitchCancelled()"
                        (confirm)="onPaypalSwitchConfirmed()">
    <div class="payment__dialog">
        Нажмите на кнопку "Подключить PayPal", чтобы принимать платежи напрямую на ваш PayPal счет,
        как если бы пользователь перевел средства вам лично.
    </div>
</lib-confirmation-modal>

<lib-confirmation-modal #qiwiDialog header="Включение способа доната"
                        confirmIcon="qiwi-small" confirmText="Подключить Qiwi" cancelText="Отмена"
                        (cancel)="onQiwiSwitchCancelled()"
                        (close)="onQiwiSwitchCancelled()"
                        (confirm)="onQiwiSwitchConfirmed()">
    <div class="payment__dialog">
        Комиссия на приём донатов с кошелька QIWI составляет <span>7%</span> согласно
        <a href="https://donatty.com/pages/agreement" target="_blank">Пользовательскому соглашению</a>.
    </div>
</lib-confirmation-modal>

<lib-confirmation-modal #yandexDialog header="Включение способа доната"
                        confirmIcon="you-money-small" confirmText="Подключить ЮMoney" cancelText="Отмена"
                        (cancel)="onYandexSwitchCancelled()"
                        (close)="onYandexSwitchCancelled()"
                        (confirm)="onYandexSwitchConfirmed()">
    <div class="payment__dialog">
        Комиссия на приём донатов с кошелька ЮMoney составляет <span>6%</span> согласно
        <a href="https://donatty.com/pages/agreement" target="_blank">Пользовательскому соглашению</a>.
    </div>
</lib-confirmation-modal>

<lib-confirmation-modal #saveChangesOnCloseDialog header="Сохранить и выйти?"
                        confirmText="Сохранить и выйти" cancelText="Выйти без сохранения"
                        (cancel)="onDitchChangesOnCloseClicked()"
                        (confirm)="onSaveChangesOnCloseClicked()">
    <div class="close__dialog">
        Вы изменили настройки на странице. Хотите сохранить изменения?
    </div>
</lib-confirmation-modal>

