<div class="we__tab-container">
    <div class="we__header">
        Скругление углов виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Скругление виджета
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="mainRounding" unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Скругление фона данных
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="sumRounding" unit="%">
            </app-single-slider>
        </div>
    </div>


    <div class="we__hor-line"></div>

    <div class="we__header">
        Основные цвета виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Цвет виджета
        </div>
        <div class="we__controls we__controls--hor">
            <div class="we__control">
                <switcher #backgroundColorEnabled [(value)]="widgetProps.style.widgetBackgroundColorEnabled"
                          (valueChange)="widgetProps.style.widgetBackgroundColorEnabled = $event; widgetPropsChange.emit(widgetProps)">
                </switcher>
            </div>
            <div class="we__control">
                <app-color-select [color]="widgetProps.style.widgetBackgroundColor"
                              (colorChange)="widgetProps.style.widgetBackgroundColor = $event; widgetPropsChange.emit(widgetProps)"
                              [disabled]="!backgroundColorEnabled.value"
                              [short]="true">
                </app-color-select>
            </div>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Цвет фона данных
        </div>
        <div class="we__controls we__controls--hor">
            <div class="we__control">
                <switcher #backgroundUnderSumColor
                          [(value)]="widgetProps.style.widgetBackgroundUnderSumColorEnabled"
                          (valueChange)="widgetProps.style.widgetBackgroundUnderSumColorEnabled = $event; widgetPropsChange.emit(widgetProps)">
                </switcher>
            </div>
            <div class="we__control">
                <app-color-select [color]="widgetProps.style.widgetBackgroundUnderSumColor"
                              (colorChange)="widgetProps.style.widgetBackgroundUnderSumColor = $event; widgetPropsChange.emit(widgetProps)"
                              [disabled]="!backgroundUnderSumColor.value"
                              [short]="true">
                </app-color-select>
            </div>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Обводка виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать обводку
        </div>
        <div class="we__controls">
            <switcher [(value)]="widgetProps.style.stroke.enabled"
                      (valueChange)="widgetProps.style.stroke.enabled = $event; widgetPropsChange.emit(widgetProps)"></switcher>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.stroke.enabled">
        <div class="we__gradient-row">
            <div class="we__row">
                <div class="we__label">Градиент обводки</div>
                <div class="we__controls">
                    <app-color-select [color]="widgetProps.style.stroke.color0"
                                      [showHints]="false"
                                      (colorChange)="widgetProps.style.stroke.color0 = $event; widgetPropsChange.emit(widgetProps)">
                    </app-color-select>
                </div>
            </div>

            <div class="we__row we__row--second-color">
                <div class="we__label"></div>
                <div class="we__controls">
                    <app-color-select [color]="widgetProps.style.stroke.color1"
                                  (colorChange)="widgetProps.style.stroke.color1 = $event; widgetPropsChange.emit(widgetProps)">
                    </app-color-select>
                </div>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Толщина обводки
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="20" [(value)]="widgetProps.style.stroke.width"
                               (valueChange)="widgetProps.style.stroke.width = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px">
                </app-single-slider>
            </div>
        </div>
    </ng-container>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Тень виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать тень
        </div>
        <div class="we__controls">
            <switcher [(value)]="widgetProps.style.shadow.enabled"
                      (valueChange)="widgetProps.style.shadow.enabled = $event; widgetPropsChange.emit(widgetProps)"></switcher>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.shadow.enabled">
        <div class="we__row">
            <div class="we__label">
                Цвет тени
            </div>
            <div class="we__controls">
                <app-color-select [color]="widgetProps.style.shadow.color"
                                  (colorChange)="widgetProps.style.shadow.color = $event; widgetPropsChange.emit(widgetProps)">
                </app-color-select>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Размытость тени
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="20" [(value)]="widgetProps.style.shadow.blur"
                               (valueChange)="widgetProps.style.shadow.blur = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по X
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.shadow.offsetX"
                               (valueChange)="widgetProps.style.shadow.offsetX = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по Y
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.shadow.offsetY"
                               (valueChange)="widgetProps.style.shadow.offsetY = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>
    </ng-container>
</div>
