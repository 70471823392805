<div class="tl__root" [ngClass]="{'tl__root--mobile': !platformInfoService.isDesktop }">
    <div class="tl__list-view">
        <div class="tl__header"
             [class]="{empty: (isEmptyState$ | async)}"
             *ngIf="platformInfoService.isDesktop">
            <div class="hdr__logo">
                <img alt="Logo" src="/assets/images/transaction-list/logo.svg">
            </div>
            <div class="controls" *ngIf="!(isInitState$ | async)">
                <app-moderation-control-button></app-moderation-control-button>
                <button class="tl__filter-button" mat-icon-button aria-label="Фильтр списка транзакций"
                        [matMenuTriggerFor]="filterPopup.menu">
                    <mat-icon svgIcon="filter"></mat-icon>
                </button>
            </div>
        </div>
        <div class="tl__empty-placeholder" *ngIf="isEmptyState$ | async">
            <div class="tl__empty">
                <div class="tl__shadow-overlay"></div>
                <div class="tl__crystal-ball">
                    <figure>
                        <img alt="" class="glow" src="/assets/icons/notransactions.png">
                        <img alt="No transactions" src="/assets/icons/notransactions.png">
                    </figure>
                    <div class="tl__header">
                        Вангуем:<br>скоро тут будут донаты
                    </div>
                    <div class="tl__text">
                        Мы в тебя верим!
                    </div>
                </div>
            </div>
        </div>
        <app-empty-transactions *ngIf="(isEmptyState$ | async) || (isInitState$ | async)"
                                [isAnimating]="(isInitState$ | async)"></app-empty-transactions>
        <ng-container *ngIf="!(isEmptyState$ | async)">
            <div class="tl__top-date-block" *ngIf="topEventsContainer">
                <div class="tl__date" [innerHTML]="topEventsContainer.date.toDate() | niceDate"></div>
                <div class="tl__total" *ngIf="topEventsContainer.total">
                    + {{topEventsContainer.total | currency:'RUB':'symbol':'1.0-0'}}
                </div>
            </div>
            <div #eventsByDateList id="tl__list" class="tl__list" (scroll)="onScrolled()">
                <div class="tl__date-block"
                     *ngFor="let events of eventsByDate; let i = index; trackBy: trackEventsByDateBy">
                    <ng-container *ngIf="events.hasFilteredEvents">
                        <div class="tl__item tl__item--date" [attr.data-transactions-index]="i">
                            <div class="tl__date" [innerHTML]="events.date.toDate() | niceDate"></div>
                            <div class="tl__total" *ngIf="events.total">
                                + {{events.total | currency:'RUB':'symbol':'1.0-0'}}
                            </div>
                        </div>
                    </ng-container>
                    <app-transaction-list-items [events]="events"
                                                (retry)="onRetryClick($event)"
                                                [showTargets]="filterPopup.settings.showTargets"></app-transaction-list-items>
                </div>
                <div #listLoaderTrigger class="tl__loader-trigger"></div>
            </div>
            <div class="tl__footer__wrapper" *ngIf="!platformInfoService.isDesktop">
                <div class="tl__footer show-above-overlay">
                    <app-moderation-control-button></app-moderation-control-button>
                    <span #filterTrigger
                          *ngIf="eventsByDate.length"
                          (click)="$event.stopImmediatePropagation()"
                          [matMenuTriggerFor]="filterPopup.menu">
                        Фильтр<mat-icon svgIcon="filter"></mat-icon>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<lib-confirmation-modal #retryDialog
                        header="Повторить на стриме?"
                        confirmText="Запустить повтор"
                        cancelText="Отменить"
                        (confirm)="retryDonation()">
    Все уведомления об этом событии будут повторно выведены на стрим. Будут использованы текущие настройки виджетов.
</lib-confirmation-modal>

<app-events-filter-popup #filterPopup [mobile]="!platformInfoService.isDesktop">
</app-events-filter-popup>
