import {StreamerEventType} from "./services/events/streamer-events.api";

export default [
    {name: "question", url: "assets/icons/question.svg"},
    {name: "source-donatty", url: "assets/images/widget-tablet/source-donatty.svg"},
    {name: "source-paypal", url: "assets/images/widget-tablet/source-paypal.svg"},
    {name: "source-twitch", url: "assets/images/widget-tablet/source-twitch.svg"},
    {name: "source-trovo", url: "assets/images/widget-tablet/source-trovo.svg"},
    {name: "source-youtube", url: "assets/images/widget-tablet/source-youtube.svg"},
    {name: "on-air", url: "assets/images/widget-tablet/on-air.svg"},
    {name: "bx-link", url: "assets/icons/bx-link.svg"},
    {name: "bx-copy", url: "assets/icons/bx-copy.svg"},
    {name: "content-copy", url: "assets/icons/content-copy.svg"},
    {name: "bx-show", url: "assets/icons/bx-show.svg"},
    {name: "bx-hide", url: "assets/icons/bx-hide.svg"},
    {name: "add-social", url: "assets/icons/external-accounts/add-social.svg"},
    {name: "logo-donatty", url: "assets/icons/logo/donatty.svg"},
    {name: "logo-paypal", url: "assets/icons/logo/paypal.svg"},
    {name: "logo-twitch", url: "assets/icons/logo/twitch.svg"},
    {name: "logo-youtube", url: "assets/icons/logo/youtube.svg"},
    {name: "logo-youtube-color", url: "assets/icons/logo/youtube-color.svg"},
    {name: "logo-discord", url: "assets/icons/logo/discord.svg"},
    {name: "logo-vk", url: "assets/icons/logo/vk.svg"},
    {name: "logo-zen", url: "assets/icons/logo/zen.svg"},
    {name: "logo-instagram", url: "assets/icons/logo/instagram.svg"},
    {name: "logo-facebook", url: "assets/icons/logo/facebook.svg"},
    {name: "logo-twitter", url: "assets/icons/logo/twitter.svg"},
    {name: "logo-telegram", url: "assets/icons/logo/telegram.svg"},
    {name: "logo-tiktok", url: "assets/icons/logo/tiktok.svg"},
    {name: "logo-yandex", url: "assets/icons/logo/yandex.svg"},
    {name: "logo-you-money", url: "assets/icons/logo/you-money.svg"},
    {name: "logo-qiwi", url: "assets/icons/logo/qiwi.svg"},
    {name: "logo-tinkoff", url: "assets/icons/logo/tinkoff.svg"},
    {name: "logo-google", url: "assets/icons/logo/google.svg"},
    {name: "logo-amazon", url: "assets/icons/logo/amazon.svg"},
    {name: "logo-trovo", url: "assets/icons/logo/trovo.svg"},
    {name: "trovo-mana", url: "assets/icons/trovo-mana.svg"},
    {name: "trovo-elixir", url: "assets/icons/trovo-elixir.svg"},
    {name: "disconnected", url: "assets/icons/external-accounts/disconnected.svg"},
    {name: "close", url: "assets/icons/close.svg"},
    {name: "adblock", url: "assets/images/adblock-notification/adblock.svg"},
    {name: "add", url: "assets/icons/ic-add-24-px-white.svg"},
    {name: "play-circle", url: "assets/icons/play-circle.svg"},
    {name: "stop-circle", url: "assets/icons/stop-circle.svg"},
    {name: "close-outline", url: "assets/icons/close-outline.svg"},
    {name: "info-outline", url: "assets/icons/info-outline.svg"},
    {name: "find-replace", url: "assets/icons/find-replace.svg"},
    {name: "userpic", url: "assets/icons/userpic.svg"},
    {name: "article-black", url: "assets/icons/article-black.svg"},
    {name: "card", url: "assets/icons/bx-credit-card.svg"},
    {name: "shield", url: "assets/icons/bx-shield.svg"},
    {name: "analyse", url: "assets/icons/bx-analyse.svg"},
    {name: "analyse-error", url: "assets/icons/bx-analyse-error.svg"},
    {name: "goal", url: "assets/images/transaction-list/goal.svg"},
    {name: "retry", url: "assets/images/transaction-list/retry.svg"},
    {name: "filter", url: "assets/images/transaction-list/filter.svg"},
    {name: "notifications-off", url: "assets/images/transaction-list/notifications-off.svg"},
    {name: "transaction-card", url: "assets/images/transaction-list/payment/card.svg"},
    {name: "transaction-qiwi", url: "assets/images/transaction-list/payment/qiwi.svg"},
    {name: "transaction-yandex", url: "assets/images/transaction-list/payment/you-money.svg"},
    {name: "transaction-google", url: "assets/images/transaction-list/payment/google-pay.svg"},
    {name: "transaction-apple", url: "assets/images/transaction-list/payment/apple-pay.svg"},
    {name: "transaction-suspicious", url: "assets/images/transaction-list/payment/suspicious.svg"},
    ...Object.values(StreamerEventType).map(eventType => eventType.toUpperCase()).map(eventType => (
        {name: `digest-${eventType}`, url: `assets/images/transaction-list/digest/${eventType}.svg`}
    )),
    {name: "widget-alert", url: "assets/icons/widget-alert.svg"},
    {name: "widget-goal", url: "assets/icons/widget-goal.svg"},
    {name: "widget-top", url: "assets/icons/widget-top.svg"},
    {name: "widget-media", url: "assets/icons/widget-media.svg"},
    {name: "widget-total", url: "assets/icons/widget-total.svg"},
    {name: "widget-events", url: "assets/icons/widget-events.svg"},
    {name: "widget-new-group", url: "assets/icons/widget-new-group.svg"},
    {name: "1", url: "assets/icons/number/one.svg"},
    {name: "2", url: "assets/icons/number/two.svg"},
    {name: "3", url: "assets/icons/number/three.svg"},
    {name: "check", url: "assets/icons/check.svg"},
    {name: "bold", url: "assets/icons/bold.svg"},
    {name: "italic", url: "assets/icons/italic.svg"},
    // Deprecated. Use logo-*
    {name: "yandex", url: "assets/icons/logo/yandex.svg"},
    {name: "tinkoff", url: "assets/icons/logo/tinkoff.svg"},
    {name: "google", url: "assets/icons/logo/google.svg"},
    {name: "amazon", url: "assets/icons/logo/amazon.svg"},
];
