import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

export interface TagSelectorItem {
    tag: string;
    description: string;
}

@Component({
    selector: "app-tags-selector",
    templateUrl: "./tags-selector.component.html",
    styleUrls: ["./tags-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSelectorComponent {
    @Input()
    public items: TagSelectorItem[] = [];

    @Output()
    public tagClick = new EventEmitter<string>();

    public onItemClicked(item: TagSelectorItem): void {
        this.tagClick.emit(item.tag);
    }
}
