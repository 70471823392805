<mat-tab-group ffScrollFix mat-align-tabs="start" dynamicHeight [disableRipple]="true">
    <mat-tab aria-label="style" label="Стиль">
        <app-widget-tab-style [widgetType]="WidgetType.Total"
                              [(widgetProps)]="widgetProps"
                              (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-tab-style>
    </mat-tab>
    <mat-tab aria-label="properties" label="Свойства">
        <app-widget-total-tab-props [(widgetProps)]="widgetProps"
                                    (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-total-tab-props>
    </mat-tab>
    <mat-tab aria-label="header" label="Заголовок">
        <app-widget-total-tab-header [(widgetProps)]="widgetProps"
                                     (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-total-tab-header>
    </mat-tab>
    <mat-tab aria-label="sum" label="Сумма">
        <app-widget-total-tab-sum [(widgetProps)]="widgetProps"
                                  (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-total-tab-sum>
    </mat-tab>
</mat-tab-group>
