<div class="tl__item" [ngClass]="itemClasses" (touchstart)="selected.emit(item)">
    <app-transaction-avatar [picture]="item.picture"></app-transaction-avatar>
    <div class="tl__right-block">
        <div class="tl__top-row">
            <div class="tl__nick">{{item.nickname || "Anonymous"}}</div>
            <div class="tl__payment-method tl__payment-method--{{item.paymentMethod | lowercase}}">
                <mat-icon svgIcon="transaction-{{item.paymentMethod | lowercase}}"></mat-icon>
            </div>
            <div class="tl__moderated" *ngIf="item.moderation === ModerationState.Blocked">
                <mat-icon svgIcon="notifications-off"></mat-icon>
            </div>
            <div class="tl__timestamp">в {{item.date.format("HH:mm:ss")}}</div>
            <div class="tl__retry" (click)="retry.emit(item)"
                 matTooltip="Повторить на стриме">
                <mat-icon svgIcon="retry"></mat-icon>
            </div>
            <div class="tl__total">+&nbsp;<span [innerHTML]="item.amount | currency:item.currency:'symbol':'1.0'"></span></div>
        </div>
        <div class="tl__commission">-&nbsp;<span [innerHTML]="item.commission | currency:item.currency:'symbol':'1.0'"></span></div>
        <div class="tl__bottom-row">
            <div class="tl__comment">{{item.message || '–'}}</div>
        </div>
        <mat-chip-list [selectable]="false">
            <mat-chip *ngIf="item.attachments" class="media"
                      [selectable]="false"
                      (click)="copyMediaUrl(item.attachments.media); $event.stopPropagation()"
                      (touchstart)="copyMediaUrl(item.attachments.media); $event.stopPropagation()">
                <mat-icon svgIcon="logo-youtube-color"></mat-icon>
                <span matTooltip="{{item.attachments.media.info.name}}">
                    {{item.attachments.media.info.name}}
                </span>
                <button mat-icon-button disableRipple="true"
                        matTooltip="Копировать ссылку на медиа"
                        (click)="copyMediaUrl(item.attachments.media); $event.stopPropagation()"
                        (touchstart)="copyMediaUrl(item.attachments.media); $event.stopPropagation()">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <a mat-icon-button disableRipple="true"
                   matTooltip="Открыть медиа в новом окне"
                   (click)="$event.stopPropagation()"
                   (touchstart)="$event.stopPropagation()"
                   [href]="item.attachments.media.url" target="_blank">
                    <mat-icon>open_in_new</mat-icon>
                </a>
            </mat-chip>
            <mat-chip *ngIf="isGoalVisible" [selectable]="false" class="goal">
                <mat-icon svgIcon="goal"></mat-icon>
                <span>{{item.goal}}</span>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>
