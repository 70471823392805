<header>
    <h5 class="white">Топ донатеров</h5>
    <span class="note">(за всё время)</span>
    <pagination-template #p="paginationApi"
                         [style.opacity]="+paginationVisible"
                         (pageChange)="page$.next($event)"
                         (pageBoundsCorrection)="page$.next($event)">
        <button mat-icon-button aria-label="Previous page"
                (click)="p.previous();"
                [disabled]="p.isFirstPage()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button aria-label="Next page"
                (click)="p.next()"
                [disabled]="p.isLastPage()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </pagination-template>
</header>
<section [ngClass]="{loading: !(donators$ | async)}">
    <div class="list">
        <div class="item" *ngFor="let item of (donators$ | async) | paginate: paginate; index as i">
            <div class="avatar">
                <img alt="userpic" *ngIf="item" src="/assets/icons/userpic.svg">
            </div>
            <div class="info">
                <div class="username">
                    {{itemsOnPreviousPages + i + 1}}. {{ item.name || 'Anonymous' }}
                </div>
                <div class="amount">{{ item.amount | number:'':'ru' }} ₽</div>
            </div>
        </div>
        <div class="empty item" *ngFor="let item of emptyItems">
            <div class="avatar"></div>
            <div class="info">
                <div class="username"></div>
                <div class="amount"></div>
            </div>
        </div>
    </div>
</section>
