import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GoalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-goal-tab-header",
    templateUrl: "./widget-goal-tab-header.component.html",
    styleUrls: ["./widget-goal-tab-header.component.scss"]
})
export class WidgetGoalTabHeaderComponent {

    @Input()
    public widgetProps: GoalWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    public get isValid(): boolean {
        return this.widgetProps.data.headerTemplate.length > 0;
    }

}
