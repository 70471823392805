import {Component, Input, OnChanges} from "@angular/core";
import {WithdrawalEvent} from "../../../../../services/events/streamer-event";
import {ModerationState} from "../../../../../services/events/streamer-events.api";
import {ItemClasses} from "../item-donate-card/item-donate-card.component";
import {StreamerEventType} from "../../../../../services/events/streamer-events.api";

interface WithdrawalItemClasses extends ItemClasses {
    "tl__item--suspicious": boolean;
}

@Component({
    selector: "app-item-withdrawal",
    templateUrl: "./item-withdrawal.component.html",
    styleUrls: ["../item-donate-card/item-donate-card.component.scss", "./item-withdrawal.component.scss"],
})
export class ItemWithdrawalComponent implements OnChanges {
    @Input()
    public item: WithdrawalEvent;

    public icon: string;

    public isSuspicious: boolean;

    public itemClasses: ItemClasses;

    public readonly ModerationState = ModerationState;

    public ngOnChanges() {
        this.isSuspicious = (this.item.type === StreamerEventType.DonattyPayoutSuspicious);

        this.icon = this.isSuspicious
            ? "transaction-suspicious"
            : `transaction-${this.item.paymentMethod.toLowerCase()}`;

        this.itemClasses = this.getItemClasses();
    }

    private getItemClasses(): WithdrawalItemClasses {
        return {
            "tl__item--live": this.item.isLive,
            "tl__item--suspicious": this.isSuspicious
        };
    }
}
