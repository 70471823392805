import {
    ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren
} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {DonateThemeService} from "../../services/donate-theme.service";
import {NavigationEnd, Router} from "@angular/router";
import {isEqual, cloneDeep, set} from "lodash";
import {UserService} from "../../services/user/user.service";
import {ClipboardService} from "ngx-clipboard";
import {SwitcherComponent} from "../switcher/switcher.component";
import {
    ConfirmationModalComponent
} from "../../../../../shared/src/lib/confirmation-modal/confirmation-modal.component";
import {VerificationState} from "../../../../../shared/src/lib/models/finance.api";
import {User, UserFeatureId, UserImageMedia} from "../../../../../shared/src/lib/models/user";
import {DonatePageTheme, Social} from "../../../../../shared/src/lib/models/theme";
import {AccountsService} from "../../services/accounts/accounts.service";
import {AccountInfo, AccountType} from "../../services/accounts/accounts";
import {firstValueFrom, Subject} from "rxjs";
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {filter, map, shareReplay, take, takeUntil} from "rxjs/operators";
import {FinanceSSEService} from "../../services/finance/finance-sse.service";
import {AccountLimits} from "../../services/finance/account-limits";

type MediaDonationFilterSteps = Array<{
    value: number,
    label: string,
    legend: string,
    restrictions: {
        viewsMin: number;
        likesRate: number;
    }
}>;

@Component({
    selector: "app-donate-edit",
    templateUrl: "./donate-edit.component.html",
    styleUrls: ["./donate-edit.component.scss"]
})
export class DonateEditComponent implements OnDestroy, OnInit {
    public donatePreviewUri: SafeResourceUrl;
    public readonly frontUri = "donatty.com";
    public thanksPreviewUri: SafeResourceUrl;
    public maxDonationAmount = 15000;
    public maxMaxMessageLength = 300;
    public showThanksPreview = false;
    public mediaDonationFilterValue = 0;
    public mediaDonationFeatureEnabled = false;
    public markdownHelpUrl = "//donatty.com/help/stranitsa-donata/kak-nastroit-tekstovyye-bloki-na-stranitse-donata#rec261555846";

    public readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly sanitizer: DomSanitizer,
                       private readonly accountsService: AccountsService,
                       private readonly changeDetectorRef: ChangeDetectorRef,
                       private readonly clipboardService: ClipboardService,
                       private readonly financeSSEService: FinanceSSEService,
                       public readonly userService: UserService,
                       private readonly router: Router,
                       private readonly donateThemeService: DonateThemeService,
                       private readonly toastr: ToastrService) {
    }

    public async ngOnInit(): Promise<void> {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd), takeUntil(this.destroy$))
            .subscribe(() => this.userService.fetchUser());

        this.userService.currentUser$.pipe(filter<User>(Boolean), takeUntil(this.destroy$))
            .subscribe(user => {
                this.currentUser = user.clone();
                this.savedUser = user.clone();
                this.mediaDonationFeatureEnabled = user.isFeatureEnabled(UserFeatureId.mediaDonation);

                this.donatePreviewUri = this.sanitizer.bypassSecurityTrustResourceUrl(user.pageUrl);
                this.thanksPreviewUri = this.sanitizer.bypassSecurityTrustResourceUrl(user.thanksPageUrl);
            });

        this.financeSSEService.limits$
            .pipe(filter(Boolean), takeUntil(this.destroy$))
            .subscribe((limits: AccountLimits) => {
                this.maxDonationAmount = limits.current.credit.max || Infinity;
            });

        const paypalFilter = filter((account: AccountInfo) => account.getType() === AccountType.Paypal);
        this.accountsService.accountConnected$
            .pipe(paypalFilter, takeUntil(this.destroy$))
            .subscribe(() => this.onPaypalAccountConnected());

        this.accountsService.accountRemoved$
            .pipe(paypalFilter, takeUntil(this.destroy$))
            .subscribe(() => this.onPaypalAccountRemoved());

        this.donateThemeService.donatePageTheme$.pipe(take(1))
            .subscribe(theme => this.initTheme(theme));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public readonly isUserIdentified$ = this.financeSSEService.verificationState$.pipe(
        filter(Boolean),
        map(verificationState =>
            [VerificationState.VERIFIED, VerificationState.NOT_APPLICABLE].includes(verificationState)
        ),
        shareReplay(1),
        takeUntil(this.destroy$),
    );

    public get avatarFilename(): string {
        return this.currentUser.avatarFilename;
    }

    public get coverFilename(): string {
        return this.currentUser.coverFilename;
    }

    public get defaultDonationAmount(): number {
        return this.currentTheme?.defaultDonateAmount ?? 0;
    }

    public set defaultDonationAmount(value: number) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.defaultDonateAmount = (value ? +value : null);
        this.refreshThemePreview();
    }

    public get discordAddress(): string {
        return this.getSocialAddress(Social.Discord);
    }

    public set discordAddress(value: string) {
        this.setSocialAddress(Social.Discord, value);
    }

    public get donationButtonLabel(): string {
        return this.currentTheme?.donateButtonText ?? "";
    }

    public set donationButtonLabel(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.donateButtonText = value;
        this.refreshThemePreview();
    }

    public get fbAddress(): string {
        return this.getSocialAddress(Social.Facebook);
    }

    public set fbAddress(value: string) {
        this.setSocialAddress(Social.Facebook, value);
    }

    public get instagramAddress(): string {
        return this.getSocialAddress(Social.Instagram);
    }

    public set instagramAddress(value: string) {
        this.setSocialAddress(Social.Instagram, value);
    }

    public get isCancelChangesEnabled(): boolean {
        if (this.isModalOperationInProgress) {
            return false;
        }

        return (
            !this.currentUser.isEqual(this.savedUser) ||
            !isEqual(this.currentTheme, this.savedTheme));
    }

    public get isPaypalPaymentEnabled(): boolean {
        if (!this.currentTheme) {
            return false;
        }

        return this.currentTheme.paymentMethods.paypal.isEnabled;
    }

    public get isRightDescriptionBlockEnabled(): boolean {
        if (!this.currentTheme) {
            return false;
        }

        return this.currentTheme.descriptionBlocks.right.isEnabled;
    }

    public set isRightDescriptionBlockEnabled(value: boolean) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.descriptionBlocks.right.isEnabled = value;
        this.refreshThemePreview();
    }

    public get isSaveChangesEnabled(): boolean {
        if (this.isModalOperationInProgress) {
            return false;
        }
        if (this.hasSafeChanges) {
            return true;
        }
        if (this.formGroup.invalid) {
            return false;
        }
        return (
            !this.currentUser.isEqual(this.savedUser) ||
            !isEqual(this.currentTheme, this.savedTheme));
    }

    public get isTopDescriptionBlockEnabled(): boolean {
        if (!this.currentTheme) {
            return false;
        }

        return this.currentTheme.descriptionBlocks.top.isEnabled;
    }

    public set isTopDescriptionBlockEnabled(value: boolean) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.descriptionBlocks.top.isEnabled = value;
        this.refreshThemePreview();
    }

    public get isQiwiPaymentEnabled(): boolean {
        if (!this.currentTheme) {
            return false;
        }

        return this.currentTheme.paymentMethods.qiwi.isEnabled;
    }

    public get isYandexPaymentEnabled(): boolean {
        if (!this.currentTheme) {
            return false;
        }

        return this.currentTheme.paymentMethods.yandexMoney.isEnabled;
    }

    public get minDonationAmount(): number {
        return this.currentTheme?.minDonateAmount ?? 0;
    }

    public set minDonationAmount(value: number) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.minDonateAmount = +value;
        this.refreshThemePreview();
    }

    public get maxMessageLength(): number {
        return this.currentTheme?.maxMessageLength ?? 150;
    }

    public set maxMessageLength(value: number) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.maxMessageLength = +value;
        this.refreshThemePreview();
    }

    public get pageDescription(): string {
        return this.currentTheme?.description ?? "";
    }

    public set pageDescription(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.description = value;
        this.refreshThemePreview();
    }

    public get pageName(): string {
        return this.currentTheme?.title ?? "";
    }

    public set pageName(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.title = value;
        this.refreshThemePreview();
    }

    public get rightDescriptionText(): string {
        if (!this.currentTheme) {
            return "";
        }

        return this.currentTheme.descriptionBlocks.right.text;
    }

    public set rightDescriptionText(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.descriptionBlocks.right.text = value;
        this.refreshThemePreview();
    }

    public get thanksPageHeader(): string {
        return this.currentTheme?.thankyou?.title ?? "";
    }

    public set thanksPageHeader(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.thankyou.title = value;
        this.refreshThemePreview();
    }

    public get thanksPageText(): string {
        return this.currentTheme?.thankyou?.description ?? "";
    }

    public set thanksPageText(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.thankyou.description = value;
        this.refreshThemePreview();
    }

    public get themeName(): string {
        return this.currentTheme?.name ?? "";
    }

    public set themeName(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.name = value;
        this.refreshThemePreview();
    }

    public get topDescriptionText(): string {
        if (!this.currentTheme) {
            return "";
        }

        return this.currentTheme.descriptionBlocks.top.text;
    }

    public set topDescriptionText(value: string) {
        if (!this.currentTheme) {
            return;
        }

        this.currentTheme.descriptionBlocks.top.text = value;
        this.refreshThemePreview();
    }

    public get twitchAddress(): string {
        return this.getSocialAddress(Social.Twitch);
    }

    public set twitchAddress(value: string) {
        this.setSocialAddress(Social.Twitch, value);
    }

    public get twitterAddress(): string {
        return this.getSocialAddress(Social.Twitter);
    }

    public set twitterAddress(value: string) {
        this.setSocialAddress(Social.Twitter, value);
    }

    public get telegramAddress(): string {
        return this.getSocialAddress(Social.Telegram);
    }

    public set telegramAddress(value: string) {
        this.setSocialAddress(Social.Telegram, value);
    }

    public get tiktokAddress(): string {
        return this.getSocialAddress(Social.Tiktok);
    }

    public set tiktokAddress(value: string) {
        this.setSocialAddress(Social.Tiktok, value);
    }

    public get userName(): string {
        return this.currentUser?.name || "";
    }

    public set userName(value: string) {
        if (!this.currentUser) {
            return;
        }

        this.currentUser.name = value;
        this.refreshUserPreview();
    }

    public get vkAddress(): string {
        return this.getSocialAddress(Social.Vk);
    }

    public set vkAddress(value: string) {
        this.setSocialAddress(Social.Vk, value);
    }

    public get zenAddress(): string {
        return this.getSocialAddress(Social.Zen);
    }

    public set zenAddress(value: string) {
        this.setSocialAddress(Social.Zen, value);
    }

    public get youtubeAddress(): string {
        return this.getSocialAddress(Social.Youtube);
    }

    public set youtubeAddress(value: string) {
        this.setSocialAddress(Social.Youtube, value);
    }

    public get trovoAddress(): string {
        return this.getSocialAddress(Social.Trovo);
    }

    public set trovoAddress(value: string) {
        this.setSocialAddress(Social.Trovo, value);
    }

    public get minMediaDonationAmount() {
        return this.currentTheme?.mediaDonation.amountMin;
    }

    public set minMediaDonationAmount(amount: number) {
        this.currentTheme.mediaDonation.amountMin = +amount;
    }

    /* Useless until media widget
    public get maxMediaDuration() {
        return this.currentTheme?.mediaDonation.restrictions.maxMediaDuration;
    }

    public set maxMediaDuration(maxMediaDuration: number) {
        this.currentTheme.mediaDonation.restrictions.maxMediaDuration = maxMediaDuration;
    }
    */

    public onMediaSwitchChanged(isEnabled: boolean): void {
        this.currentTheme.mediaDonation.isEnabled = isEnabled;
        this.refreshThemePreview();
    }

    public get isMediaEnabled(): boolean {
        return this.currentTheme?.mediaDonation.isEnabled;
    }

    public onMediaDonationFilterChanged(value: number) {
        this.mediaDonationFilterValue = value;
        const stepRestrictions = this.mediaDonationFilterSteps[value].restrictions;
        this.currentTheme.mediaDonation.restrictions.likesRate = stepRestrictions.likesRate;
        this.currentTheme.mediaDonation.restrictions.viewsMin = stepRestrictions.viewsMin;
    }

    public copyLink() {
        if (!this.savedUser) {
            return;
        }

        this.clipboardService.copyFromContent(this.savedUser.pageUrl);
        this.toastr.success("Ссылка скопирована");
    }

    public icon(name: string): string {
        return `/assets/images/donate-page-settings/${name}.svg`;
    }

    public async onCancelChangesClicked(): Promise<void> {
        const isPaypalAccountRemoved = (
            this.currentTheme.paymentMethods.paypal.isEnabled &&
            !this.savedTheme.paymentMethods.paypal.isEnabled
        );
        if (isPaypalAccountRemoved) {
            await this.removePaypalAccounts();
        }
        this.restoreFormValues();
        this.currentUser = this.savedUser.clone();
        this.currentTheme = cloneDeep(this.savedTheme);
        this.setMediaDonationFilterStep();
        this.refreshThemePreview();
        this.refreshUserPreview();
        this.checkChanges(this.formGroup.value);
    }

    public onClearAvatarImage(): void {
        this.currentUser.removeAvatar();
        this.avatarImage = null;
        this.refreshUserPreview();
    }

    public async onAvatarImageChanged(event: any): Promise<void> {
        this.currentUser.setAvatar(
            await this.modalReadFileContents(event));
        this.avatarImage = event.target.files[0];
        this.refreshUserPreview();
    }

    public onClearCoverImage(): void {
        this.currentUser.removeCover();
        this.coverImage = null;
        this.refreshUserPreview();
    }

    public onCloseClicked(): void {
        if (this.isModalOperationInProgress) {
            return;
        }

        if (!this.isSaveChangesEnabled) {
            if (this.formGroup.invalid) {
                this.restoreInvalidFormValues();
            }
            this.router.navigateByUrl(`/widgets#donation-card`);
            return;
        }

        this.saveChangesOnCloseDialog.show();
    }

    public async onCoverImageChanged(event: any): Promise<void> {
        this.currentUser.setCover(
            await this.modalReadFileContents(event));
        this.coverImage = event.target.files[0];
        this.refreshUserPreview();
    }

    public async onDitchChangesOnCloseClicked(): Promise<void> {
        await this.onCancelChangesClicked();

        await this.router.navigateByUrl("/widgets#donation-card");
    }

    public onPaypalSwitchCancelled(): void {
        this.paypalSwitcher.value = false;
    }

    public async onPaypalSwitchChanged(isEnabled: boolean): Promise<void> {
        if (!this.currentTheme) {
            return;
        }

        if (!isEnabled) {
            this.currentTheme.paymentMethods.paypal = {isEnabled: false};
            this.refreshThemePreview();
            return;
        }

        this.paypalSwitcher.value = false;
        this.paypalDialog.show();
    }

    public async onPaypalSwitchConfirmed(): Promise<void> {
        await this.removePaypalAccounts();
        await this.accountsService.addAccount(AccountType.Paypal);
        this.refreshThemePreview();
    }

    public onPreviewFrameLoaded(): void {
        if (this.currentTheme) {
            this.refreshThemePreview();
        }
        if (this.currentUser) {
            this.refreshUserPreview();
        }
    }

    public onQiwiSwitchCancelled(): void {
        this.currentTheme.paymentMethods.qiwi.isEnabled = false;
        this.qiwiSwitcher.value = false;
    }

    public onQiwiSwitchChanged(isEnabled: boolean): void {
        if (!this.currentTheme) {
            return;
        }

        if (!isEnabled) {
            this.currentTheme.paymentMethods.qiwi.isEnabled = false;
            this.qiwiSwitcher.value = false;
            this.refreshThemePreview();
            return;
        }

        this.qiwiDialog.show();
    }

    public onQiwiSwitchConfirmed(): void {
        this.currentTheme.paymentMethods.qiwi.isEnabled = true;
        this.refreshThemePreview();
    }

    public async onSaveChangesClicked(): Promise<void> {
        if (this.isModalOperationInProgress) {
            return;
        }

        this.isModalOperationInProgress = true;

        try {
            if (this.formGroup.invalid) {
                this.restoreInvalidFormValues();
            }

            const isPaypalAccountRemoved = (
                this.savedTheme.paymentMethods.paypal.isEnabled &&
                !this.currentTheme.paymentMethods.paypal.isEnabled);
            if (isPaypalAccountRemoved) {
                await this.removePaypalAccounts();
            }

            let uploadAvatarTask: Promise<string> = null;
            const isAvatarImageChanged = !this.currentUser.isAvatarEqual(this.savedUser);
            if (isAvatarImageChanged) {
                if (this.avatarImage) {
                    uploadAvatarTask = this.userService.uploadPicture(this.avatarImage);
                } else {
                    this.currentUser.removeAvatar();
                }
            }

            let uploadCoverTask: Promise<string> = null;
            const isCoverImageChanged = !this.currentUser.isCoverEqual(this.savedUser);
            if (isCoverImageChanged) {
                if (this.coverImage) {
                    uploadCoverTask = this.userService.uploadCover(this.coverImage);
                } else {
                    this.currentUser.removeCover();
                }
            }

            await firstValueFrom(this.donateThemeService.save(this.currentTheme));

            if (uploadAvatarTask) {
                this.currentUser.setAvatarImage(await uploadAvatarTask);
            }

            if (uploadCoverTask) {
                this.currentUser.setCoverImage(await uploadCoverTask);
            }

            await this.userService.save(this.currentUser);

            this.savedUser = this.currentUser.clone();
            this.savedTheme = cloneDeep(this.currentTheme);

            this.refreshUserPreview();
            this.refreshThemePreview();
            this.formGroupInitialValue = this.formGroup.value;
            this.toastr.success("Настройки сохранены");
            this.checkChanges(this.formGroup.value);
        } finally {
            this.isModalOperationInProgress = false;
        }
    }

    public async onSaveChangesOnCloseClicked(): Promise<void> {
        await this.onSaveChangesClicked();

        await this.router.navigateByUrl("/widgets#donation-card");
    }

    public onShowBannersClick(): void {
        const tab = window.open("https://donatty.com/pages/twitch-panel-logo", "_blank");
        if (tab) {
            tab.focus();
        }
    }

    public onYandexSwitchCancelled(): void {
        this.currentTheme.paymentMethods.yandexMoney.isEnabled = false;
        this.yandexSwitcher.value = false;
    }

    public onYandexSwitchChanged(isEnabled: boolean): void {
        if (!this.currentTheme) {
            return;
        }

        if (!isEnabled) {
            this.currentTheme.paymentMethods.yandexMoney.isEnabled = false;
            this.yandexSwitcher.value = false;
            this.refreshThemePreview();
            return;
        }

        this.yandexDialog.show();
    }

    public onYandexSwitchConfirmed(): void {
        this.currentTheme.paymentMethods.yandexMoney.isEnabled = true;
        this.yandexSwitcher.value = true;
        this.refreshThemePreview();
    }

    public openDonationPage(): void {
        if (!this.savedUser) {
            return;
        }

        const tab = window.open(this.savedUser.pageUrl, "_blank");
        if (!tab) {
            return;
        }

        tab.focus();
    }

    public correctSocialAddress(domains: string[], text: string): string {
        const beginRe = new RegExp(`(http(s)?://)?(www.)?`);
        text = text.replace(beginRe, "");
        for (const domain of domains) {
            text = text.replace(domain + "/", "");
        }

        return text;
    }

    private setMediaDonationFilterStep() {
        const themeRestrictions = this.currentTheme.mediaDonation.restrictions;
        for (const step of this.mediaDonationFilterSteps) {
            if (themeRestrictions.viewsMin === step.restrictions.viewsMin ||
                themeRestrictions.likesRate === step.restrictions.likesRate) {
                this.mediaDonationFilterValue = step.value;
                return;
            }
        }
        this.mediaDonationFilterValue = this.mediaDonationFilterSteps[0].value;
    }

    private async initTheme(theme: DonatePageTheme): Promise<void> {
        this.isModalOperationInProgress = true;
        try {
            this.currentTheme = cloneDeep(theme);
            this.savedTheme = cloneDeep(theme);
            this.setMediaDonationFilterStep();
            this.setFormValues();
            this.refreshThemePreview();
            this.initFormGroup();
        } finally {
            this.isModalOperationInProgress = false;
        }
    }

    private async modalReadFileContents(event: any): Promise<UserImageMedia> {
        this.isModalOperationInProgress = true;

        try {
            const file = event.target.files[0] as File;
            const contents = await this.getFileContentsBase64(file);

            return {
                source: contents,
                attributes: {
                    originalName: file.name
                }
            };
        } finally {
            this.isModalOperationInProgress = false;
        }
    }

    private getFileContentsBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);

            reader.readAsDataURL(file);
        });
    }

    private getSocialAddress(social: Social): string {
        const link = this.currentTheme.socials[social] || "";
        return link.replace(this.SOCIAL[social].filterRegex, "");
    }

    private onPaypalAccountConnected(): void {
        this.currentTheme.paymentMethods.paypal = {isEnabled: true};
        this.paypalSwitcher.value = true;
        this.changeDetectorRef.detectChanges();
    }

    private onPaypalAccountRemoved(): void {
        this.currentTheme.paymentMethods.paypal = {isEnabled: false};
        this.paypalSwitcher.value = false;
        this.changeDetectorRef.detectChanges();
    }

    @ViewChildren("preview")
    public iframeRefs: QueryList<ElementRef<HTMLIFrameElement>>;

    private refreshPreview(topic: string, updates: object): void {
        this.iframeRefs
            ?.filter(frameRef => !!frameRef.nativeElement.contentWindow)
            .map(frameRef => frameRef.nativeElement.contentWindow)
            .forEach(contentWindow => contentWindow.postMessage({topic, updates}, "*"));
    }

    private refreshThemePreview(): void {
        if (!this.currentTheme) {
            throw new Error("Trying to send empty theme data in iFrame message");
        }
        this.refreshPreview("theme", this.currentTheme);

        const methodNameMap = {
            qiwi: "QIWI",
            paypal: "PAYPAL",
            yandexMoney: "YANDEX",
        };
        this.refreshPreview("payment-methods", [{type: "CARD"}, ...Object.keys(this.currentTheme.paymentMethods)
            .filter(pmk => this.currentTheme.paymentMethods[pmk].isEnabled)
            .map(pmk => ({type: methodNameMap[pmk]}))]);
    }

    private refreshUserPreview(): void {
        if (!this.currentUser) {
            throw new Error("Trying to send empty user data in iFrame message");
        }
        this.refreshPreview("user", this.currentUser.cloneModel());
    }

    private async removePaypalAccounts(): Promise<void> {
        const promises = this.accountsService.accounts$.value
            .filter(account => account.getType() === AccountType.Paypal)
            .map(acc => this.accountsService.removeAccount(acc));
        await Promise.all(promises);
    }

    private setSocialAddress(social: Social, value: string): void {
        if (!this.currentTheme) {
            return;
        }
        const fullUri = value ? (this.SOCIAL[social].baseurl + value) : "";
        set(this.currentTheme.socials, social, fullUri);

        this.refreshThemePreview();
    }

    private validateSocialAddress(address: string): { isSucceed: boolean, errorText?: string, } {
        if (!address) {
            return null;
        }

        const prohibitedRe = /(http(s)?:)|(([^\w]|^)www\.)/gi;
        if (!prohibitedRe.test(address)) {
            return null;
        }

        return {
            isSucceed: false,
            errorText: "Поле содержит недопустимые элементы"
        };
    }

    @ViewChild("paypalDialog")
    private paypalDialog: ConfirmationModalComponent;

    @ViewChild("paypalSwitcher")
    private paypalSwitcher: SwitcherComponent;

    @ViewChild("qiwiDialog")
    private qiwiDialog: ConfirmationModalComponent;

    @ViewChild("qiwiSwitcher")
    private qiwiSwitcher: SwitcherComponent;

    @ViewChild("saveChangesOnCloseDialog", {static: true})
    private saveChangesOnCloseDialog: ConfirmationModalComponent;

    @ViewChild("yandexDialog", {static: true})
    private yandexDialog: ConfirmationModalComponent;

    @ViewChild("yandexSwitcher")
    private yandexSwitcher: SwitcherComponent;

    private avatarImage: File = null;
    private coverImage: File = null;
    // TODO: Remove currentTheme, use this.formGroup instead
    private currentTheme: DonatePageTheme;
    private currentUser: User;
    private isModalOperationInProgress = false;
    // TODO: Remove savedTheme, use this.donateThemeService.donatePageTheme$ instead
    private savedTheme: DonatePageTheme;
    private savedUser: User;
    public hasSafeChanges = false;

    private bindSanitizationEvents(): void {
        this.formGroup.controls.minDonationAmount.valueChanges.pipe(
            map(value => value && Math.min(value, this.maxDonationAmount)),
            filter(sanitizedValue => sanitizedValue !== this.formGroup.controls.minDonationAmount.value),
            takeUntil(this.destroy$),
        ).subscribe(sanitizedValue => this.formGroup.controls.minDonationAmount.setValue(sanitizedValue));

        this.formGroup.controls.defaultDonationAmount.valueChanges.pipe(
            map(value => value && Math.min(value, this.maxDonationAmount)),
            filter(sanitizedValue => sanitizedValue !== this.formGroup.controls.defaultDonationAmount.value),
            takeUntil(this.destroy$),
        ).subscribe(sanitizedValue => this.formGroup.controls.defaultDonationAmount.setValue(sanitizedValue));

        this.formGroup.controls.maxMessageLength.valueChanges.pipe(
            map(value => value && Math.round(value)),
            map(value => value && Math.min(value, this.maxMaxMessageLength)),
            filter(sanitizedValue => sanitizedValue !== this.formGroup.controls.maxMessageLength.value),
            takeUntil(this.destroy$),
        ).subscribe(sanitizedValue => this.formGroup.controls.maxMessageLength.setValue(sanitizedValue));

        this.formGroup.controls.minMediaDonationAmount.valueChanges.pipe(
            map(value => value && Math.round(value)),
            map(value => value && Math.min(value, this.maxDonationAmount)),
            filter(sanitizedValue => sanitizedValue !== this.formGroup.controls.minMediaDonationAmount.value),
            takeUntil(this.destroy$),
        ).subscribe(sanitizedValue => this.formGroup.controls.minMediaDonationAmount.setValue(sanitizedValue));

        const sanitizeSocial = (formControl: AbstractControl, regexp: RegExp) => {
            formControl.valueChanges.pipe(
                map(value => value && value.replace(regexp, "")),
                filter(sanitizedValue => sanitizedValue !== formControl.value),
                takeUntil(this.destroy$),
            ).subscribe(sanitizedValue => formControl.setValue(sanitizedValue));
        };
        sanitizeSocial(this.formGroup.controls.twitchAddress, this.SOCIAL[Social.Twitch].filterRegex);
        sanitizeSocial(this.formGroup.controls.youtubeAddress, this.SOCIAL[Social.Youtube].filterRegex);
        sanitizeSocial(this.formGroup.controls.trovoAddress, this.SOCIAL[Social.Trovo].filterRegex);
        sanitizeSocial(this.formGroup.controls.twitterAddress, this.SOCIAL[Social.Twitter].filterRegex);
        sanitizeSocial(this.formGroup.controls.telegramAddress, this.SOCIAL[Social.Telegram].filterRegex);
        sanitizeSocial(this.formGroup.controls.tiktokAddress, this.SOCIAL[Social.Tiktok].filterRegex);
        sanitizeSocial(this.formGroup.controls.vkAddress, this.SOCIAL[Social.Vk].filterRegex);
        sanitizeSocial(this.formGroup.controls.zenAddress, this.SOCIAL[Social.Zen].filterRegex);
        sanitizeSocial(this.formGroup.controls.discordAddress, this.SOCIAL[Social.Discord].filterRegex);
        sanitizeSocial(this.formGroup.controls.fbAddress, this.SOCIAL[Social.Facebook].filterRegex);
        sanitizeSocial(this.formGroup.controls.instagramAddress, this.SOCIAL[Social.Instagram].filterRegex);

        this.formGroup.controls.tiktokAddress.valueChanges.pipe(
            filter(v => v.length > 0),
            filter(v => v.startsWith("@")),
            takeUntil(this.destroy$),
        ).subscribe(value => this.formGroup.controls.tiktokAddress.setValue(value.slice(1), {
            onlySelf: true, emitEvent: false,
        }));
    }

    private initFormGroup() {
        for (const controlName of Object.keys(this.formGroup.controls)) {
            this.formGroup.controls[controlName].valueChanges.subscribe(v => this[controlName] = v);
        }
        this.formGroup.controls.minDonationAmount.valueChanges.subscribe(() => {
            if (this.isMediaEnabled) {
                this.formGroup.controls.minMediaDonationAmount.updateValueAndValidity();
            }
        });
        this.bindSanitizationEvents();
        this.formGroupInitialValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe(values => this.checkChanges(values));
    }

    private checkChanges(values) {
        this.hasSafeChanges = false;
        for (const field of Object.keys(values)) {
            const control = this.formGroup.get(field);
            // tslint:disable-next-line:triple-equals
            if (control.valid && (control.value != this.formGroupInitialValue[field])) {
                this.hasSafeChanges = true;
                break;
            }
        }
    }

    private setFormValues() {
        Object.keys(this.formGroup.controls)
            .forEach(controlName => this.formGroup.controls[controlName].setValue(this[controlName]));
    }

    private restoreFormValues() {
        Object.keys(this.formGroup.controls)
            .forEach(controlName => this.formGroup.controls[controlName].reset(this.formGroupInitialValue[controlName]));
    }

    private restoreInvalidFormValues() {
        Object.keys(this.formGroup.controls)
            .filter(controlName => this.formGroup.controls[controlName].invalid)
            .forEach(controlName => this.formGroup.controls[controlName].reset(this.formGroupInitialValue[controlName]));
    }

    private formGroupInitialValue;
    public readonly formGroup = new FormGroup({
        themeName: new FormControl<string>(this.themeName, (control: AbstractControl): ValidationErrors => {
            if (!control.value.trim()) {
                return {themeName: [{message: "Название страницы не может быть пустым"}]};
            }
            return null;
        }),
        userName: this.userService.userNameFormControl,
        pageName: new FormControl<string>(this.pageName, (control: AbstractControl): ValidationErrors => {
            const errors = [];
            if (!control.value) {
                errors.push({message: "Имя страницы не может быть пустым"});
            }
            if (errors.length) {
                return {pageName: errors};
            }
            return null;
        }),
        pageDescription: new FormControl<string>(this.pageDescription),
        topDescriptionText: new FormControl<string>(this.topDescriptionText),
        rightDescriptionText: new FormControl<string>(this.rightDescriptionText),
        twitchAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {twitchAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        youtubeAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {youtubeAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        trovoAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {trovoAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        twitterAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {twitterAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        telegramAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {telegramAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        tiktokAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {tiktokAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        vkAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {vkAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        zenAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {zenAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        discordAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {discordAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        fbAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {fbAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        instagramAddress: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const result = this.validateSocialAddress(control.value);
            if (result && !result.isSucceed) {
                return {instagramAddress: [{message: result.errorText}]};
            }
            return null;
        }),
        minDonationAmount: new FormControl<number>(this.minDonationAmount, {
            validators: (control: AbstractControl): ValidationErrors => {
                if (control.value <= 0) {
                    control.setValue(1);
                }
                const errors = [];
                if (control.value > this.maxDonationAmount) {
                    errors.push({message: "не может быть больше максимальной суммы доната"});
                }
                if (errors.length) {
                    return {minDonationAmount: errors};
                }
                return null;
            },
            updateOn: "blur",
        }),
        defaultDonationAmount: new FormControl<number>(this.defaultDonationAmount, {
            validators: (control: AbstractControl): ValidationErrors => {
                if (control.value < 0 || control.value === null) {
                    control.setValue(0);
                }
                const errors = [];
                if (control.value > this.maxDonationAmount) {
                    errors.push({message: "не может быть больше максимальной суммы доната"});
                }
                if (errors.length) {
                    return {defaultDonationAmount: errors};
                }
                return null;
            },
            updateOn: "blur",
        }),
        maxMessageLength: new FormControl<number>(this.maxMessageLength, {
            validators: (control: AbstractControl): ValidationErrors => {
                if (control.value <= 0) {
                    control.setValue(1);
                }
                const errors = [];
                if (control.value > this.maxMaxMessageLength) {
                    errors.push({message: `не может быть больше ${this.maxMaxMessageLength}`});
                }
                if (errors.length) {
                    return {maxMessageLength: errors};
                }
                return null;
            },
            updateOn: "blur",
        }),
        donationButtonLabel: new FormControl<string>(this.donationButtonLabel),
        thanksPageHeader: new FormControl<string>(this.thanksPageHeader),
        thanksPageText: new FormControl<string>(this.thanksPageText),
        minMediaDonationAmount: new FormControl<number>(this.minMediaDonationAmount, {
            validators: (control: AbstractControl): ValidationErrors => {
                const errors = [];
                if (control.value > this.maxDonationAmount) {
                    errors.push({message: "не может быть больше максимальной суммы доната"});
                }
                if (control.value < this.minDonationAmount) {
                    errors.push({message: `не может быть меньше минимальной суммы доната (${this.minDonationAmount} ₽)`});
                }
                if (errors.length) {
                    return {minMediaDonationAmount: errors};
                }
                return null;
            },
        }),
        /*maxMediaDuration: new FormControl(this.maxMediaDuration, {
            validators: (control: AbstractControl): ValidationErrors => {
                if (control.value === null) {
                    control.setValue(0);
                }
                const errors = [];
                if (errors.length) {
                    return {maxMediaDuration: errors};
                }
                return null;
            },
        }),*/
    });

    public readonly SOCIAL: { [key in Social]: { prefix: string, baseurl: string, filterRegex: RegExp } } = {
        [Social.Discord]: {
            prefix: "discord.gg/",
            baseurl: "https://discord.gg/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?discord(app)?.(com|gg)/"),
        },
        [Social.Facebook]: {
            prefix: "facebook.com/",
            baseurl: "https://facebook.com/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?facebook.com/"),
        },
        [Social.Instagram]: {
            prefix: "instagram.com/",
            baseurl: "https://instagram.com/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?instagram.com/"),
        },
        [Social.Twitch]: {
            prefix: "twitch.tv/",
            baseurl: "https://twitch.tv/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?twitch.tv/"),
        },
        [Social.Twitter]: {
            prefix: "twitter.com/",
            baseurl: "https://twitter.com/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?twitter.com/"),
        },
        [Social.Telegram]: {
            prefix: "t.me/",
            baseurl: "https://t.me/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?(t|telegram).me/"),
        },
        [Social.Tiktok]: {
            prefix: "tiktok.com/@",
            baseurl: "https://tiktok.com/@",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?tiktok.com/@"),
        },
        [Social.Vk]: {
            prefix: "vk.com/",
            baseurl: "https://vk.com/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?vk.com/")
        },
        [Social.Zen]: {
            prefix: "zen.yandex.ru/",
            baseurl: "https://zen.yandex.ru/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?zen.yandex.ru/"),
        },
        [Social.Youtube]: {
            prefix: "youtube.com/",
            baseurl: "https://youtube.com/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?youtube.com/"),
        },
        [Social.Trovo]: {
            prefix: "trovo.live/",
            baseurl: "https://trovo.live/",
            filterRegex: new RegExp("(http(s)?://)?(www\\.)?trovo.live/")
        },
    };

    public mediaDonationFilterSteps: MediaDonationFilterSteps = [
        {
            value: 0,
            label: "Выключен",
            legend: "Выключен: без защиты",
            restrictions: {
                likesRate: 0,
                viewsMin: 0,
            }
        },
        {
            value: 1,
            label: "Низкий",
            legend: "Низкий: 65%+ лайков, 5k+ просмотров",
            restrictions: {
                likesRate: .65,
                viewsMin: 5000,
            }
        },
        {
            value: 2,
            label: "Средний",
            legend: "Средний: 75%+ лайков, 40k+ просмотров",
            restrictions: {
                likesRate: .75,
                viewsMin: 40000,
            }
        },
        {
            value: 3,
            label: "Высокий",
            legend: "Высокий: 80%+ лайков, 300k+ просмотров",
            restrictions: {
                likesRate: .8,
                viewsMin: 300000,
            }
        },
        {
            value: 4,
            label: "Очень высокий",
            legend: "Очень высокий: 85%+ лайков, 900k+ просмотров",
            restrictions: {
                likesRate: .85,
                viewsMin: 900000,
            }
        },
    ];
}
