<div id="{{buttonId}}" (click)="click($event)"
     class="button primary {{buttonClass}}"
     color="primary" [attr.disabled]="disabled">
  <div class="content">
    <div [class]="'icon-left ' + iconTuningClass" *ngIf="isLeftIconPresent">
      <img src="/assets/icons/{{icon}}{{disabled ? '-disabled' : ''}}.svg">
    </div>
    <div [class]="textClass">
      <ng-content></ng-content>
    </div>
    <div [class]="'icon-right ' + iconTuningClass" *ngIf="isRightIconPresent">
      <img src="/assets/icons/{{iconRight}}.svg">
    </div>
  </div>
</div>
