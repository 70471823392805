import {TopWidget} from "../../../../shared/src/lib/models/widget-props";

export const defaultTopProps: TopWidget["props"] = {
    data: {
        isListInverted: false,
        filters: {
            emoji: false,
            links: true,
            profanity: {
                automatic: true,
                custom: false,
            },
        },
        sources: {
            accounts: {
                donatty: {
                    donate: {
                        isEnabled: true
                    }
                }
            }
        },
        dataType: "donators",
        period: "all",
        rows: 20,
        visibleRows: 5,
        headerTemplate: "Топ донатеров за всё время"
    },
    style: {
        layout: 0,
        rowsRounding: 50,
        mainRounding: 50,
        widgetBackgroundColor: "#2F3033",
        widgetBackgroundColorEnabled: true,
        widgetBackgroundUnderSumColor: "#202124",
        widgetBackgroundUnderSumColorEnabled: true,
        rowsMargin: 8,
        rowsPadding: 16,
        isIndexEnabled: true,
        updateSpeed: 30,
        stroke: {
            color0: "#1484FF",
            color1: "#D731B6",
            width: 4,
            enabled: true
        },
        shadow: {
            enabled: true,
            color: "rgba(0, 0, 0, 0.14)",
            offsetX: 0,
            offsetY: 2,
            blur: 2
        },
        header: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FFFFFF",
                align: "left",
                size: 20,
                bold: true,
                italic: false,
                colorGradient: "",
            },
            shadow: {
                enabled: true,
                color: "rgba(0, 0, 0, 0.8)",
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
        },
        subscriberNick: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FF9600",
                size: 20,
                bold: true,
                italic: false,
                align: "left",
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: false,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
        },
        subscriberAmount: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FFFFFF",
                size: 20,
                bold: true,
                italic: false,
                align: "left",
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: false,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
        }
    }
};
