import {
    ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output
} from "@angular/core";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {WidgetEventSources} from "../../../../../../../shared/src/lib/models/widget-props";
import {WidgetType} from "../../../../../../../shared/src/lib/models/widget";
import widgetEditSectionAnimations from "../widget-edit-section.animations";

@Component({
    selector: "app-widget-edit-sources",
    templateUrl: "./widget-edit-sources.component.html",
    styleUrls: ["./widget-edit-sources.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: widgetEditSectionAnimations,
})
export class WidgetEditSourcesComponent implements OnInit {
    @Input()
    public eventSources: WidgetEventSources;

    @Output()
    public readonly eventSourcesChange = new EventEmitter<WidgetEventSources>();

    @Input()
    public type: string;

    @Input()
    public widgetId: string;

    public isShown = true;

    public readonly WidgetType = WidgetType;

    public constructor(@Inject(LOCAL_STORAGE) private readonly storageService: StorageService) {
    }

    public ngOnInit(): void {
        if (this.storageService.has(this.formatStorageKey())) {
            this.isShown = this.storageService.get(this.formatStorageKey()) as boolean;
        }
    }

    public toggleCollapse(): void {
        this.isShown = !this.isShown;

        this.storageService.set(this.formatStorageKey(), this.isShown);
    }

    private formatStorageKey(): string {
        return `widget-edit-event-sources/${this.widgetId}/isShown`;
    }

}
