import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GoalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-goal-tab-countdown",
    templateUrl: "./widget-goal-tab-countdown.component.html",
    styleUrls: ["./widget-goal-tab-countdown.component.scss"]
})
export class WidgetGoalTabCountdownComponent {

    @Input()
    public widgetProps: GoalWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

}
