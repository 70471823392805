import {
    AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges,
    OnDestroy, OnInit, Output, SimpleChanges, ViewChild,
} from "@angular/core";
import {FinanceService} from "../../../../../services/finance/finance.service";
import {
    OrderState, OrderStateEvent, PayoutMethod, WithdrawalState,
} from "../../../../../../../../shared/src/lib/common/FinancialOperation";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {fromEvent, Observable, Subject} from "rxjs";
import {debounceTime, delay, distinctUntilChanged, map, shareReplay, switchMap, takeUntil} from "rxjs/operators";
import {FinanceSSEService} from "../../../../../services/finance/finance-sse.service";

@Component({
    selector: "app-payout-card",
    templateUrl: "./payout-card.component.html",
    styleUrls: ["./payout-card.component.scss", "../methods.scss"],
})
export class PayoutCardComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    public readonly initWithdrawal$: Subject<void> = new Subject<void>();

    @Input() public readonly amount: number;
    @Input() public readonly method: PayoutMethod;
    @Output() public readonly order$: Observable<OrderStateEvent<WithdrawalState>> = this.initWithdrawal$.pipe(
        debounceTime(100),
        switchMap(() => this.financeService.createWithdrawal({
            amount: this.amount,
            method: {id: this.method.id},
        })),
        shareReplay(1));

    @ViewChild("iFrame")
    private iFrame: ElementRef<HTMLIFrameElement>;

    public readonly paymentFormLoaded$ = new Subject<HTMLIFrameElement>();

    public readonly paymentUrl$: Observable<SafeResourceUrl> = this.order$.pipe(
        map(withdrawalState => withdrawalState.state === OrderState.FormReady ?
            withdrawalState.data.withdrawal.paymentForm.url : ""
        ),
        distinctUntilChanged(),
        map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
        shareReplay(1));

    public readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly financeService: FinanceService,
                       private readonly financeSSEService: FinanceSSEService,
                       private readonly sanitizer: DomSanitizer,
                       private readonly ngZone: NgZone) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.method) {
            return;
        }
        if (this.method?.isNew) {
            this.initWithdrawal$.next();
        }
    }

    public ngOnInit(): void {
        fromEvent(window, "message")
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => this.ngZone.run(() => this.iframeListener(event as MessageEvent)));

        this.paymentFormLoaded$
            .pipe(takeUntil(this.destroy$), delay(150))
            .subscribe(iFrame => {
                iFrame.contentWindow.postMessage({
                    buttonText: `Вывести ${this.amount} ₽`
                }, "*");
            });
    }

    public ngAfterViewInit() {
        if (this.method?.isNew) {
            this.initWithdrawal$.next();
        }
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private iframeListener(event: MessageEvent) {
        if (event.data === "new-card-complete") {
            console.log("withdrawal check complete, WIDGET");
            return;
        }

        const isRepeatPayment = (
            (event.data === "payout-repeat") || // own payout error page
            (event.data === "donate-repeat")); // card form on the Moneta server
        if (isRepeatPayment) {
            this.initWithdrawal$.next();
        }
    }

}
