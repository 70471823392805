import {Injectable} from "@angular/core";
import * as moment from "moment";

@Injectable({
    providedIn: "root"
})
export class CookiePolyfillService {
    public constructor() {
    }

    public deleteCookie(id: string): boolean {
        console.log("deleting cookie", id);

        const flags: [boolean, "Strict" | "None" | "Lax"][] = [
            [true, "Strict"],
            [true, "Lax"],
            [true, "None"],
            [false, "Strict"],
            [false, "Lax"],
            [false, "None"],
        ];

        const locations = [
            ".donatty.com",
            ".develop.donatty.com",
            ".staging.donatty.com",
            window.location.hostname
        ];

        if (!this.isCookieSet(id)) {
            console.log("cookie", id, "doesn't present, nothing to do");
            return true;
        }

        for (const f of flags) {
            const [isSecure, sameSite] = f;
            for (const l of locations) {
                console.log("removing cookie", id, "on location", l, "with flags", f);

                this.trySetCookie(
                    id,
                    "",
                    moment().subtract(1, "year").toDate(),
                    "/",
                    l,
                    isSecure,
                    sameSite);

                if (!this.isCookieSet(id)) {
                    console.log("cookie", id, "removed successfully");
                    return true;
                }
            }
        }

        console.error("failed to remove cookie", id);
        return false;
    }

    public getCookie(id: string): string {
        if (!this.isCookieSet(id)) {
            return null;
        }

        const encodedId = encodeURIComponent(id);
        const regExp = this.buildCookieRegex(encodedId);
        const result = regExp.exec(document.cookie);
        const value = result[1];

        try {
            return decodeURIComponent(value);
        } catch {
        }

        return value;
    }

    public setCookie(id: string, value: string, expire: Date): boolean {
        this.deleteCookie(id);

        const flags: [boolean, "Strict" | "None" | "Lax"][] = [
            [true, "Strict"],
            [false, "Strict"],
            [true, "Lax"],
            [false, "Lax"],
            [true, "None"],
            [false, "None"],
        ];

        const locations = [
            ".donatty.com",
            window.location.hostname
        ];

        for (const f of flags) {
            const [isSecure, sameSite] = f;
            for (const l of locations) {
                this.trySetCookie(id, value, expire, "/", l, isSecure, sameSite);

                if (this.isCookieSet(id)) {
                    return true;
                }
            }
        }

        return false;
    }

    private isCookieSet(id: string): boolean {
        return this.buildCookieRegex(id).test(document.cookie);
    }

    private buildCookieRegex(id: string): RegExp {
        const escapedId = id.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, "\\$1");
        return new RegExp("(?:^" + escapedId + "|;\\s*" + escapedId + ")=(.*?)(?:;|$)", "g");
    }

    private trySetCookie(
            id: string,
            value: string,
            expire: Date,
            path: string,
            domain: string,
            isSecure: boolean,
            sameSite: SameSiteFlag): void {
        const cookie =
            `${encodeURIComponent(id)}=${encodeURIComponent(value)};` +
            `expires=${expire.toUTCString()};` +
            `path=${path};` +
            `domain=${domain};` +
            (isSecure ? "secure;" : "") +
            `sameSite=${sameSite}`;
        document.cookie = cookie;
    }
}

type SameSiteFlag = "None" | "Lax" | "Strict";
