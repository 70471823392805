import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

export type GlobalAlert = {
    message: string;
    icon: string;
    id: string;
    class: string;
    cookie: string;
    isClosable: boolean;
    action?: {
        text: string;
        handler: (alert: GlobalAlert) => any;
    };
};

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    public alerts: Set<GlobalAlert> = new Set();
    public readonly alertAdded$ = new Subject();
    public readonly alertRemoved$ = new Subject();

    public addAlert(alert: GlobalAlert) {
        this.alerts.add(alert);
        this.alertAdded$.next(alert);
    }

    public removeAlert(alert: GlobalAlert) {
        this.alerts.delete(alert);
        this.alertRemoved$.next(alert);
    }

}
