<mat-tab-group ffScrollFix mat-align-tabs="start" dynamicHeight [disableRipple]="true">
    <mat-tab aria-label="style" label="Стиль">
        <app-widget-tab-style [widgetType]="WidgetType.Events" [(widgetProps)]="widgetProps" (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-tab-style>
    </mat-tab>
    <mat-tab aria-label="properties" label="Свойства">
        <app-widget-events-tab-props [(widgetProps)]="widgetProps" (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-events-tab-props>
    </mat-tab>
    <mat-tab aria-label="events" label="События">
        <app-widget-events-tab-events [(widgetProps)]="widgetProps" (widgetPropsChange)="widgetPropsChange.emit(widgetProps)"
                                      [widgetId]="widgetId">
        </app-widget-events-tab-events>
    </mat-tab>
</mat-tab-group>
