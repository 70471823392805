import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from "@angular/core";

@Component({
    selector: "checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent {
    @Input()
    public value = false;

    @Output()
    public valueChange = new EventEmitter<boolean>();

    @Input()
    public type = "default";

    @Input()
    public labelPadding = "";

    public get labelStyle(): object {
        if (!this.labelPadding) {
            return {};
        }

        return {
            ["padding-left"]: this.labelPadding
        };
    }

    public onClick(event: MouseEvent): void {
        const isTargetClick = (
            (event.target === this.iconEl.nativeElement) ||
            (event.target === this.iconImageEl.nativeElement) ||
            (event.target === this.labelEl.nativeElement));
        if (!isTargetClick) {
            return;
        }

        this.value = !this.value;
        this.valueChange.emit(this.value);
    }

    @ViewChild("icon", { static: true })
    private iconEl: ElementRef;

    @ViewChild("iconImage", { static: true })
    private iconImageEl: ElementRef;

    @ViewChild("label", { static: true })
    private labelEl: ElementRef;
}
