import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class PlatformInfoService {
    public readonly isMobile: boolean;
    public readonly isDesktop: boolean;
    public readonly isObs: boolean;
    public readonly isInIFrame: boolean;
    public readonly isFirefox: boolean;
    public readonly isSafari: boolean;
    public readonly deviceName: string;

    public constructor() {
        const ua = window.navigator.userAgent.toLowerCase();
        this.isFirefox = ua.indexOf("firefox") > -1;
        this.isSafari = ua.indexOf("safari") > -1 && ua.indexOf("chrome") === -1;
        this.isObs = /obs/i.test(ua);
        this.isMobile = /mobi/i.test(ua);
        this.isDesktop = !(this.isMobile || this.isObs);
        this.isInIFrame = window?.top !== window?.self;
        this.deviceName = this.buildDeviceName();
    }

    private buildDeviceName() {
        const segments = [];
        if (this.isFirefox) {
            segments.push("firefox");
        } else if (this.isSafari) {
            segments.push("safari");
        } else {
            segments.push("chrome");
        }
        if (this.isMobile) {
            segments.push("mobile");
        } else if (this.isDesktop) {
            segments.push("desktop");
        }
        return segments.join(" ");
    }
}
