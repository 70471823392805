import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {AdblockDetectorService} from "../../../../../../../shared/src/lib/adblock-detector.service";
import * as moment from "moment";
import {CookiePolyfillService} from "../../../../../../../shared/src/lib/cookie-polyfill.service";
import {AccountsService} from "../../../../services/accounts/accounts.service";
import {merge, Subject} from "rxjs";
import {GlobalAlert, NotificationService} from "../../notification.service";
import {map, takeUntil, takeWhile} from "rxjs/operators";
import {PlatformInfoService} from "../../../../../../../shared/src/lib/platform-info.service";

@Component({
    selector: "app-notification-bar",
    templateUrl: "./notification-bar.component.html",
    styleUrls: ["./notification-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBarComponent implements OnInit, OnDestroy {
    private readonly destroy$: Subject<void> = new Subject();

    public constructor(public readonly notificationService: NotificationService,
                       private readonly adblockDetectorService: AdblockDetectorService,
                       private readonly accountsService: AccountsService,
                       private readonly cookieService: CookiePolyfillService,
                       private readonly platformInfoService: PlatformInfoService,
                       private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        if (this.platformInfoService.isInIFrame) {
            return;
        }
        this.initAdblockNotification();
        this.initSocialNotification();
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public onCloseClick(alert?: GlobalAlert): void {
        this.notificationService.removeAlert(alert);
        const retryDate = moment().add(1, "days").toDate();
        this.cookieService.setCookie(alert.cookie, "true", retryDate);
        this.changeDetectorRef.detectChanges();
    }

    private initAdblockNotification() {
        const adblockCookie = "adblock-alert-closed";
        if ((this.cookieService.getCookie(adblockCookie) !== "true")) {
            setTimeout(async () => {
                if (await this.adblockDetectorService.isAdblockPresent()) {
                    this.notificationService.addAlert({
                        id: "adblock",
                        message: "Adblock может блокировать функционал сайта. Пожалуйста, добавьте Donatty в белый список.",
                        icon: "adblock",
                        class: "adblock",
                        cookie: adblockCookie,
                        isClosable: true,
                    });
                    this.changeDetectorRef.detectChanges();
                }
            }, 3000);
        }
    }

    private initSocialNotification() {
        this.accountsService.accountReconnected$
            .pipe(takeUntil(this.destroy$))
            .subscribe(account => {
                const cookie = `account-${account.getId()}-disconnected-alert-closed`;
                if (this.cookieService.getCookie(cookie) === "true") {
                    return;
                }
                const id = `disconnected-${account.getId()}`;
                for (const existingAlert of this.notificationService.alerts.values()) {
                    if (existingAlert.id === id) {
                        return;
                    }
                }
                const accountType = account.getType()[0].toUpperCase() + account.getType().slice(1);
                const alert = {
                    id,
                    message: `Связь с ${accountType}-аккаунтом ${account.getNick()} потеряна`,
                    icon: "disconnected",
                    class: `disconnected-${account.getType()}`,
                    cookie,
                    isClosable: true,
                    action: {
                        text: "Восстановить",
                        handler: async () => {
                            await this.accountsService.addAccount(account.getType());
                        },
                    },
                };
                this.notificationService.addAlert(alert);
                this.changeDetectorRef.detectChanges();
            });

        merge(this.accountsService.accountConnected$, this.accountsService.accountRemoved$).pipe(
            takeWhile(() => this.notificationService.alerts.size > 0),
            takeUntil(this.destroy$),
            map(account => `disconnected-${account.getId()}`)
        ).subscribe(alertId => {
            for (const alert of this.notificationService.alerts.values()) {
                if (alert.id === alertId) {
                    this.notificationService.removeAlert(alert);
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

}
