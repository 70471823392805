import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "app-widget-copy-link",
    templateUrl: "./widget-copy-link.component.html",
    styleUrls: ["../widget-edit.component.scss", "./widget-copy-link.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetCopyLinkComponent {
    @Input() public link;
}
