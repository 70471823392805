import {EventEmitter, Injectable, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../base.service";
import {Subscription} from "rxjs";
import {EventParamType, EventSourceParam, WidgetSettingsService} from "../settings/widget-settings.service";
import {AccountsService} from "../accounts/accounts.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {
    AlertWidgetInfo, ConcreteWidgetInfo, EventsWidgetInfo, GoalWidgetInfo, IGenericWidgetInfo,
    jsonType2WidgetPathName, MediaWidgetInfo, TopWidgetInfo, TotalWidgetInfo, WidgetType,
} from "../../../../../shared/src/lib/models/widget";
import {ApiCreateWidgetRequest, ApiWidgetInfo} from "../../../../../shared/src/lib/models/api";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import _ from "lodash";
import {WidgetGroup} from "./widget_groups/widget-group";
import {AccountEventType, AccountType, eventParamUnits} from "../accounts/accounts";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {CommandService} from "../command.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {ToastrService} from "ngx-toastr";
import {EventSourceParamLimits} from "../../../../../shared/src/lib/models/widget-props";
import {SSEService} from "../sse.service";

export enum WidgetAction {
    create = "create",
    change = "change",
    remove = "remove",
}

export interface WidgetEventPayload {
    action: WidgetAction;
    widget: IGenericWidgetInfo;
}

@Injectable({
    providedIn: "root"
})
export class WidgetService extends BaseService {
    @Output()
    public readonly widgetChanged$ = new EventEmitter<WidgetEventPayload>();

    public readonly markedForRemoval: Set<string> = new Set();

    public constructor(http: HttpClient,
                       auth: AuthService,
                       private readonly accountsService: AccountsService,
                       private readonly environmentService: EnvironmentService,
                       private readonly sseService: SSEService,
                       private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly commandService: CommandService,
                       private readonly dataLayerService: DataLayerService,
                       private readonly toastr: ToastrService) {
        super(auth, http);
        this.serviceUrl = "widgets";
    }

    public formatWidgetLink(widget: IGenericWidgetInfo, isPreview: boolean): string {
        const type = jsonType2WidgetPathName(widget.getType());

        // TODO: trailing slash before '?' is mandatory, NGINX widgets host redirects
        //       from :8888 to :80 if the slash is omitted
        let href =
            `${this.environmentService.widgetsUri}/${type}/?ref=${widget.getId()}` +
            `&token=${widget.getToken()}`;

        if (isPreview) {
            href += `&flags=preview`;

            if (widget.isAlertWidget()) {
                href += ",centered";
            }

            href += ",wysiwyg";
        }

        if (!isPreview && !this.environmentService.isProdBuild) {
            const pongIntervalSec = (5 * 60);
            href += `&pongInterval=${pongIntervalSec}`;
        }

        if (!this.environmentService.isProdBuild) {
            href +=
                `&api=${this.environmentService.backendApiUri}` +
                `&logLevel=error`;
        }

        return href;
    }

    public async get(id: string): Promise<IGenericWidgetInfo> {
        if (this.hasCachedWidgetWithStyle(id)) {
            return this.getCachedWidget(id);
        }

        const response = await this.getRequest(id, this.getWidgetSubscription);
        return this.putWidgetToCache(this.makeWidgetInfo(response));
    }

    public async createWithDefaultProps(type: WidgetType, widgetGroup?: WidgetGroup): Promise<IGenericWidgetInfo> {
        const initialProps = this.widgetSettingsService.generateDefaultWidgetProps(type);
        if (widgetGroup) {
            Object.assign(initialProps, {groupId: widgetGroup.id});
        }
        const response = await this.postRequest(type, initialProps);
        const result = await this.putRequest(response.refId, response);
        return this.putWidgetToCache(this.makeWidgetInfo(result));
    }

    public async create(widgetData: ApiCreateWidgetRequest): Promise<IGenericWidgetInfo> {
        console.group("WidgetService.create");

        console.log("creating widget, data=", widgetData);
        const response = await this.postRequest(widgetData.type.toLowerCase(), widgetData);

        console.log("updating widget, response=", response);
        const model = await this.putRequest(response.refId, response);

        console.log("widget updated, model=", model);
        const widget = this.putWidgetToCache(this.makeWidgetInfo(model));
        console.groupEnd();
        return widget;
    }

    public async delete(ids: Array<string>) {
        if (!ids.length) {
            return;
        }
        const url = `${this.environmentService.backendApiUri}/${this.serviceUrl}`;
        const requestOptions = {
            ...this.getHttpOptions(),
            body: {
                widgetIds: ids
            },
        };
        await this.http.delete(url, requestOptions).toPromise();
        ids.map(this.deleteCacheEntry.bind(this));
    }

    public async deleteDeferred(widget: IGenericWidgetInfo, timeout: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const command = this.commandService.run({
                timeout,
                handler: async () => {
                    await this.delete([widget.getId()]);
                    this.dataLayerService.emit({
                        eventCategory: EventCategory.Widget,
                        eventAction: EventAction.Delete,
                        eventLabel: widget.getType().toLowerCase(),
                        eventValue: "success"
                    });
                    resolve(true);
                },
            });
            this.widgetChanged$.emit({action: WidgetAction.remove, widget});
            this.toastr.show("Виджет удалён", "", {
                timeOut: timeout,
                progressBar: true,
                tapToDismiss: false,
                extendedTimeOut: 0,
                disableTimeOut: false,
                // @ts-ignore
                action: "Восстановить",
            }).onAction.subscribe(() => {
                this.commandService.cancel(command);
                this.widgetChanged$.emit({action: WidgetAction.create, widget});
                resolve(false);
            });
        });
    }

    public async emitEvent(id: string, action: string, data: object): Promise<void> {
        return await this.postRequest(`${id}/action`, {action, data});
    }

    public async update(widget: IGenericWidgetInfo): Promise<IGenericWidgetInfo> {
        const response = await this.putRequest(widget.getId(), widget.serialize());
        return this.putWidgetToCache(this.makeWidgetInfo(response));
    }

    public makeWidgetInfo(model: ApiWidgetInfo): IGenericWidgetInfo {
        const widgetClasses: { [key in WidgetType]: new(...args: any[]) => ConcreteWidgetInfo } = {
            [WidgetType.Alert]: AlertWidgetInfo,
            [WidgetType.Goal]: GoalWidgetInfo,
            [WidgetType.Top]: TopWidgetInfo,
            [WidgetType.Total]: TotalWidgetInfo,
            [WidgetType.Media]: MediaWidgetInfo,
            [WidgetType.Events]: EventsWidgetInfo,
        };
        if (!widgetClasses[model.type]) {
            throw new Error(`unknown widget type to instantiate: '${model.type}'`);
        }
        const widget = new widgetClasses[model.type](model);
        widget.setCondition(this.getCondition(widget) || "Без условий");
        widget.setSources(this.getSources(widget));
        return widget;
    }

    private deleteCacheEntry(id: string): void {
        this.widgetsCache.delete(id);
    }

    private getCachedWidget(id: string): IGenericWidgetInfo {
        return this.widgetsCache.get(id).widgetInfo;
    }

    private hasCachedWidgetWithStyle(id: string): boolean {
        if (!this.widgetsCache) {
            return false;
        }

        const cacheEntry = this.widgetsCache.get(id);
        if (!cacheEntry) {
            return false;
        }

        return !_.isNil(cacheEntry.widgetInfo.getGenericProps().style);
    }

    public putWidgetToCache(widgetInfo: IGenericWidgetInfo): IGenericWidgetInfo {
        this.widgetsCache.set(
            widgetInfo.getId(),
            new WidgetCacheEntry(widgetInfo));
        return widgetInfo;
    }

    public getSources(widget: IGenericWidgetInfo): AccountType[] {
        if (!widget.hasProps()) {
            return [];
        }

        const settings = widget.getGenericProps();
        const accounts = this.accountsService.accounts$.value;
        if (!accounts) {
            return [];
        }

        return this.widgetSettingsService.listEventSources(settings.data?.sources?.accounts, accounts);
    }

    // TODO: Move back into WidgetsListItemComponent
    public getCondition(widget: IGenericWidgetInfo): string {
        if (!widget.hasProps()) {
            return "";
        }

        switch (widget.getType()) {
            case WidgetType.Alert:
                return this.fmtAlertCondition(widget);
            case WidgetType.Goal:
                return this.fmtGoalCondition(widget);
            case WidgetType.Top:
                return this.fmtTopCondition(widget);
            case WidgetType.Total:
                return this.fmtTopCondition(widget);
            case WidgetType.Events:
                return this.fmtAlertCondition(widget);
            default:
                return "";
        }
    }

    private fmtAlertCondition(widget: IGenericWidgetInfo): string {
        const accounts = this.accountsService.accounts$.value;
        const eventSourcesAccounts = widget.getGenericProps().data?.sources?.accounts ?? {};
        const params = this.widgetSettingsService.listEventSourceParams(accounts, eventSourcesAccounts);

        if (params.length === 0) {
            return "";
        }

        if (params.length > 1) {
            return "Несколько условий";
        }

        const param: EventSourceParam = params[0];

        const isDonattyDonateParam = (param.accountEventType === AccountEventType.DonattyDonate);
        if (isDonattyDonateParam && (widget.isAlertWidget() || widget.isEventsWidget())) {
            const propsData = widget.getProps().data;

            const MAX_DONATION_AFTER_SID = 60000;
            let result = "";
            const unit = eventParamUnits[param.accountEventType] || "";

            const hasMinDuration = (propsData.switchDurationMin != null);
            if (hasMinDuration) {
                result += `От ${Math.max(propsData.switchDurationMin, 1)} ${unit}`;
            }

            const hasMaxDuration = (
                propsData.switchDurationMax != null &&
                propsData.switchDurationMax !== MAX_DONATION_AFTER_SID);
            if (hasMaxDuration) {
                if (hasMinDuration) {
                    result += ` до ${propsData.switchDurationMax} ${unit}`;
                } else {
                    result += `До ${propsData.switchDurationMax} ${unit}`;
                }
            }
            return result;
        } else {
            const formatters: { [key in EventParamType]: (param: EventSourceParam) => string } = {
                list: (listParam: EventSourceParam) => {
                    if (listParam.value.selectedItems.length === 0) {
                        return "";
                    }
                    if (listParam.value.selectedItems.length === 1) {
                        return listParam.value.selectedItems.map(id =>
                            listParam.items$.value.find(item => item.value === id)?.text ?? "").join(",");
                    }
                    if (listParam.value.selectedItems.length < listParam.items$.value.length) {
                        return "Несколько наград";
                    }
                    return "Все награды";
                },
                range: (rangeParam: EventSourceParam) => {
                    const paramValue = rangeParam.value as EventSourceParamLimits;
                    const unit = eventParamUnits[rangeParam.accountEventType] || "";
                    let result = `От ${paramValue.from} ${unit}`;
                    const isUpToTheLimit = (paramValue.to === rangeParam.range.to);
                    if (!isUpToTheLimit && paramValue.to) {
                        result += ` до ${paramValue.to} ${unit}`;
                    }
                    return result;
                },
            };
            return formatters[param.type](param);
        }
    }

    private fmtGoalCondition(widget: IGenericWidgetInfo): string {
        if (!widget.isGoalWidget()) {
            throw new Error("unexpected widget type");
        }

        const data = widget.getProps().data;
        const collected = _.get(data, "goalCollected", 0);

        return `${collected} ₽ из ${data.goal} ₽`;
    }

    private fmtTopCondition(widget: IGenericWidgetInfo): string {
        const period = _.get(widget.getGenericProps(), "data.period", "");
        switch (period) {
            case "all":
                return "За всё время";
            case "day":
                return "За день";
            case "week":
                return "За неделю";
            case "month":
                return "За месяц";
            case "year":
                return "За год";
            case "past24h":
                return "За последние 24 часа";
            case "past7d":
                return "За последние 7 дней";
            case "past30d":
                return "За последние 30 дней";
        }

        return "";
    }

    private getWidgetSubscription: Subscription;
    private widgetsCache = new Map<string, WidgetCacheEntry>();
}

class WidgetCacheEntry {
    public constructor(
        public readonly widgetInfo: IGenericWidgetInfo) {
    }
}

