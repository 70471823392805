import {Injectable} from "@angular/core";
import {CanActivate, UrlTree} from "@angular/router";
import {NavigationService} from "../services/navigation.service";
import {PlatformInfoService} from "../../../../shared/src/lib/platform-info.service";
import {DevSettingsService} from "../../../../shared/src/lib/dev/dev-settings.service";

@Injectable()
export class DesktopGuard implements CanActivate {
    public constructor(private readonly devSettingsService: DevSettingsService,
                       private readonly navigationService: NavigationService,
                       private readonly platformInfoService: PlatformInfoService) {
    }

    public canActivate(): true | UrlTree {
        if (this.devSettingsService.isPlatformGuardDisabled) {
            return true;
        }

        if (!this.platformInfoService.isDesktop) {
            return this.navigationService.mainPageUrlTree;
        }
        return true;
    }
}
