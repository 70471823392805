import {Component, Input} from "@angular/core";

@Component({
    selector: "app-empty-transactions",
    templateUrl: "./empty-transactions.component.html",
    styleUrls: ["./empty-transactions.component.scss"]
})
export class EmptyTransactionsComponent {
    @Input()
    public isAnimating = true;
}
