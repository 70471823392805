import {StreamerEventType} from "../events/streamer-events.api";

export enum AccountType {
    Donatty = "donatty",
    Paypal = "paypal",
    Twitch = "twitch",
    Trovo = "trovo",
    Youtube = "youtube",
}

export enum AccountEventType {
    DonattyDonate = "donate",
    PaypalDonate = "paypal-donate",
    TwitchBits = "twitch-bits",
    TwitchFollowers = "twitch-followers",
    TwitchSubscribers = "twitch-subscribers",
    TwitchGiftSubscribersViewer = "twitch-gift-subscribers-viewer",
    TwitchGiftSubscribersChannel = "twitch-gift-subscribers-channel",
    TwitchGiftSubscribersUpgrade = "twitch-gift-subscribers-upgrade",
    TwitchSubscribersUpgrade = "twitch-subscribers-upgrade",
    TwitchChannelPoints = "twitch-channel-points",
    TwitchHypeTrain = "twitch-hype-train",
    TwitchHost = "twitch-host",
    TwitchRaids = "twitch-raids",
    YoutubeMembers = "youtube-members",
    YoutubeSubscribers = "youtube-subscribers",
    YoutubeSuperchat = "youtube-superchat",
    TrovoFollowers = "trovo-followers",
    TrovoGiftSubscribersChannel = "trovo-gift-subscribers-channel",
    TrovoGiftSubscribersViewer = "trovo-gift-subscribers-viewer",
    TrovoRaids = "trovo-raids",
    TrovoSpellElixir = "trovo-spell-elixir",
    TrovoSpellMana = "trovo-spell-mana",
    TrovoSubscribers = "trovo-subscribers",
}

export const disabledAccountEvents = new Set([
    AccountEventType.TwitchGiftSubscribersUpgrade,
    AccountEventType.TwitchSubscribersUpgrade,
    AccountEventType.TwitchHost
]);

export const accountTypeEvents: { [key in AccountType]: Array<AccountEventType> } = {
    [AccountType.Donatty]: [
        AccountEventType.DonattyDonate,
    ],
    [AccountType.Paypal]: [
        AccountEventType.PaypalDonate,
    ],
    [AccountType.Twitch]: [
        AccountEventType.TwitchBits,
        AccountEventType.TwitchFollowers,
        AccountEventType.TwitchSubscribers,
        AccountEventType.TwitchGiftSubscribersViewer,
        AccountEventType.TwitchGiftSubscribersChannel,
        AccountEventType.TwitchGiftSubscribersUpgrade,
        AccountEventType.TwitchSubscribersUpgrade,
        AccountEventType.TwitchChannelPoints,
        AccountEventType.TwitchHypeTrain,
        AccountEventType.TwitchHost,
        AccountEventType.TwitchRaids,
    ],
    [AccountType.Trovo]: [
        AccountEventType.TrovoSpellMana,
        AccountEventType.TrovoSpellElixir,
        AccountEventType.TrovoFollowers,
        AccountEventType.TrovoSubscribers,
        AccountEventType.TrovoGiftSubscribersViewer,
        AccountEventType.TrovoGiftSubscribersChannel,
        AccountEventType.TrovoRaids,
    ],
    [AccountType.Youtube]: [
        AccountEventType.YoutubeMembers,
        AccountEventType.YoutubeSubscribers,
        AccountEventType.YoutubeSuperchat
    ],
};

export const eventParamUnits: { [key in AccountEventType]?: string } = {
    [AccountEventType.DonattyDonate]: "₽",
    [AccountEventType.PaypalDonate]: "₽",
    [AccountEventType.YoutubeSuperchat]: "₽",
    [AccountEventType.TwitchBits]: "bits",
    [AccountEventType.TwitchSubscribers]: "мес.",
    [AccountEventType.YoutubeMembers]: "мес.",
};

export const eventParamLabels: { [key in AccountEventType]?: string } = {
    [AccountEventType.DonattyDonate]: "Донат",
    [AccountEventType.PaypalDonate]: "Донат",
    [AccountEventType.TrovoSubscribers]: "Subscribers",
    [AccountEventType.TwitchBits]: "Bits",
    [AccountEventType.TwitchSubscribers]: "Subscribers",
    [AccountEventType.TwitchHypeTrain]: "Hype Train",
    [AccountEventType.TwitchChannelPoints]: "Points",
    [AccountEventType.YoutubeMembers]: "Members",
    [AccountEventType.YoutubeSuperchat]: "Superchat",
};

export const eventTypesMap: { [key in AccountEventType]: StreamerEventType } = {
    [AccountEventType.DonattyDonate]: StreamerEventType.DonattyDonation,
    [AccountEventType.PaypalDonate]: StreamerEventType.PaypalDonation,
    [AccountEventType.TwitchBits]: StreamerEventType.TwitchBit,
    [AccountEventType.TwitchFollowers]: StreamerEventType.TwitchFollower,
    [AccountEventType.TwitchHost]: StreamerEventType.TwitchHost,
    [AccountEventType.TwitchHypeTrain]: StreamerEventType.TwitchHypeTrain,
    [AccountEventType.TwitchRaids]: StreamerEventType.TwitchRaid,
    [AccountEventType.TwitchChannelPoints]: StreamerEventType.TwitchChannelPoints,
    [AccountEventType.TwitchSubscribers]: StreamerEventType.TwitchSubscriber,
    [AccountEventType.TwitchGiftSubscribersUpgrade]: StreamerEventType.TwitchSubscriberGiftUpgrade,
    [AccountEventType.TwitchGiftSubscribersChannel]: StreamerEventType.TwitchSubscriberGiftChannel,
    [AccountEventType.TwitchGiftSubscribersViewer]: StreamerEventType.TwitchSubscriberGiftViewer,
    [AccountEventType.TwitchSubscribersUpgrade]: StreamerEventType.TwitchSubscriberUpgrade,
    [AccountEventType.YoutubeSuperchat]: StreamerEventType.YoutubeSuperchat,
    [AccountEventType.YoutubeSubscribers]: StreamerEventType.YoutubeFollower,
    [AccountEventType.YoutubeMembers]: StreamerEventType.YoutubeSponsor,
    [AccountEventType.TrovoFollowers]: StreamerEventType.TrovoFollower,
    [AccountEventType.TrovoGiftSubscribersChannel]: StreamerEventType.TrovoSubscriberGiftChannel,
    [AccountEventType.TrovoGiftSubscribersViewer]: StreamerEventType.TrovoSubscriberGiftViewer,
    [AccountEventType.TrovoRaids]: StreamerEventType.TrovoRaid,
    [AccountEventType.TrovoSpellElixir]: StreamerEventType.TrovoSpellElixir,
    [AccountEventType.TrovoSpellMana]: StreamerEventType.TrovoSpellMana,
    [AccountEventType.TrovoSubscribers]: StreamerEventType.TrovoSubscriber,
};

export function isKnownExternalAccountType(type: string) {
    return ["GOOGLE", "TWITCH", "PAYPAL", "TROVO"].includes(type);
}

export function makeAccountInfo(id: string, type: string, nick: string, isMain: boolean, isNew: boolean) {
    const accountType: AccountType = {
        GOOGLE: AccountType.Youtube,
        TWITCH: AccountType.Twitch,
        TROVO: AccountType.Trovo,
        PAYPAL: AccountType.Paypal,
    }[type];
    if (!accountType) {
        throw new Error(`unknown account type ${type}`);
    }
    return new AccountInfo(id, accountType, nick, isMain, isNew);
}

export class AccountInfo {

    public constructor(private readonly id: string,
                       private readonly type: AccountType,
                       private readonly nick: string,
                       private readonly isMainAcc: boolean,
                       private readonly isNewAcc: boolean) {
    }

    public getId(): string {
        return this.id;
    }

    public getNick(): string {
        return this.nick;
    }

    public getType(): AccountType {
        return this.type;
    }

    public isMain(): boolean {
        return this.isMainAcc;
    }

    public isNew(): boolean {
        return this.isNewAcc;
    }
}
