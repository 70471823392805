import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {FinanceService} from "../../../../services/finance/finance.service";

@Component({
    selector: "app-payment-processing",
    templateUrl: "./payment-processing.component.html",
    styleUrls: ["./payment-processing.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PaymentProcessingComponent implements OnInit {
    public constructor(
        private route: ActivatedRoute,
        private financeService: FinanceService) {
    }

    public async ngOnInit(): Promise<void> {
        const transactionId = this.route.snapshot.queryParams.MNT_TRANSACTION_ID;
        console.log("PaymentProcessingComponent.ngOnInit, transaction-id=", transactionId);

        if (transactionId) {
            await this.confirmPayment(transactionId);
        }

        parent.postMessage("new-card-complete", "*");
    }

    private async confirmPayment(transactionId: string): Promise<void> {
        for (let tries = 0; tries < 1; ++tries) {
            try {
                await this.financeService.confirmPayment(transactionId);
            } catch (e) {
                // console.log(`try #${tries}, payment confirmation failed`, e);
            }
        }
    }
}
