import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, UrlTree} from "@angular/router";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {NavigationService} from "../services/navigation.service";

export const REDIRECT_KEY = "redirect_uri";

@Injectable()
export class RedirectGuard implements CanActivate {
    public constructor(private readonly authService: AuthService,
                       private readonly navigationService: NavigationService) {
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        if (route.queryParams[REDIRECT_KEY]) {
            this.authService.removeAccessToken();
            return this.navigationService.loginUrlTree;
        }
        return true;
    }
}
