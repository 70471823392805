import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import {ChangeContext, Options} from "@angular-slider/ngx-slider";
import {CustomStepDefinition} from "@angular-slider/ngx-slider/options";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: "app-single-slider",
    templateUrl: "./single-slider.component.html",
    styleUrls: ["./single-slider.component.scss"]
})
export class SingleSliderComponent implements OnInit, OnChanges {

    @Input()
    public unit: string;

    @Input()
    public logScale = false;

    @Input()
    public min: number;

    @Input()
    public max: number;

    @Input()
    public step = 1;

    @Input()
    public steps: Array<CustomStepDefinition>;

    @Input()
    public showTicks = false;

    @Input()
    public showLabel = true;

    @Input()
    public showTickValues = false;

    @Input()
    public showStepLegend = false;

    @Input()
    public value: number;

    @Input()
    public zeroAdjust: number = null;

    @Output()
    public valueChange = new EventEmitter<number>();

    public stepLegend: string;

    public options: Options = {};

    public ngOnInit() {
        this.options = {
            floor: this.min,
            ceil: this.max,
            animate: false,
            logScale: this.logScale,
            step: this.step,
            stepsArray: this.steps,
            showTicks: this.showTicks,
            showTicksValues: this.showTickValues,
        };

        const decimalPipe = new DecimalPipe("RU-ru");
        this.options.translate = (value: number): string => {
            if (!this.showLabel) {
                return "";
            }
            const fmtValue = decimalPipe.transform(value);

            if (this.unit?.length > 0) {
                return `${fmtValue} ${this.unit}`;
            }

            return fmtValue;
        };

        if (this.zeroAdjust !== null) {
            this.options.showSelectionBarFromValue = this.zeroAdjust;
        }
        else {
            this.options.showSelectionBar = true;
        }
        this.updateStepLegend();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.min || changes.max) {
            // https://github.com/angular-slider/ngx-slider/blob/master/typedoc-theme/README.md#options
            const newOptions: Options = Object.assign({}, this.options);
            if (changes.min) {
                newOptions.floor = changes.min.currentValue;
            }
            if (changes.max) {
                newOptions.ceil = changes.max.currentValue;
            }
            this.options = newOptions;
        }
    }

    public onValueChanged(event: ChangeContext) {
        this.valueChange.emit(event.value);
        this.updateStepLegend();
    }

    private updateStepLegend() {
        if (!this.showStepLegend) {
            return;
        }
        this.stepLegend = this.options.stepsArray.find((step) => {
            return step.value === this.value;
        }).legend;
    }

}
