import {Component, HostBinding} from "@angular/core";
import {FinanceService} from "../../../services/finance/finance.service";

@Component({
    selector: "app-mobile-front",
    templateUrl: "./mobile-front.component.html",
    styleUrls: ["./mobile-front.component.scss"]
})
export class MobileFrontComponent {
    @HostBinding("class.folded")
    public isHeaderFolded = false;

    public constructor(public readonly financeService: FinanceService) {
    }

    public onScrolled(scrollY: number, delta: number) {
        if ((delta === 0)) {
            return;
        }

        const SHOW_HEADER_UNCOND_PX = 30;
        if (scrollY < SHOW_HEADER_UNCOND_PX) {
            this.isHeaderFolded = false;
            this.lastDeltaSign = 0;
            this.accumulatedDelta = 0;
            MobileFrontComponent.unfoldHeader();
            return;
        }

        const deltaSign = Math.sign(delta);
        if (deltaSign !== this.lastDeltaSign) {
            this.lastDeltaSign = deltaSign;
            this.accumulatedDelta = 0;
            return;
        }

        this.accumulatedDelta += delta;
        const HEADER_TOGGLE_DELTA_PX = 150;
        if (Math.abs(this.accumulatedDelta) < HEADER_TOGGLE_DELTA_PX) {
            return;
        }

        const newFoldedState = (this.accumulatedDelta > 0);
        if (this.isHeaderFolded === newFoldedState) {
            return;
        }

        this.isHeaderFolded = newFoldedState;

        if (!this.isHeaderFolded) {
            MobileFrontComponent.unfoldHeader();
        }
    }

    private static unfoldHeader(): void {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    private accumulatedDelta = 0;
    private lastDeltaSign = 0;
}
