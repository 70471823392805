<div>
    <div class="we__input-container">
        <div class="we__hash">#</div>
        <input #colorInput class="we__color" maxlength="6" type="text"
               (keyup)="onColorInputKeyUp($event)"
               (input)="colorInputChanged($event)"
               (blur)="onColorInputBlur()"
               [disabled]="disabled">
    </div>
    <div *ngIf="showHints" class="hint">Hex</div>
</div>

<div>
    <div class="we__alpha-container">
        <input #alphaInput class="we__alpha" type="text" maxlength="3"
               (keyup)="onAlphaInputKeyUp($event)"
               (input)="alphaInputChanged($event)"
               (blur)="onAlphaInputBlur()"
               [disabled]="disabled">
        <div class="we__percent">%</div>
    </div>
    <div *ngIf="showHints" class="hint">Opacity</div>
</div>

<div class="we__sample-back">
    <div class="we__sample"
         (cpToggleChange)="colorPickerChanged($event)"
         [style.background-color]="color"
         [cpDisableInput]="false"
         [cpPosition]="dialogPosition"
         [cpOutputFormat]="'hex'"
         [cpAlphaChannel]="'always'"
         [(colorPicker)]="color"
         [cpDisabled]="disabled">
    </div>
</div>
