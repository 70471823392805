import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AdblockDetectorService {

    public async isAdblockPresent(): Promise<boolean> {
        const url = "https://yandex.ru/ads/system/context.js";

        try {
            await fetch(url, {method: "HEAD", mode: "no-cors"});
        } catch (e) {
            return true;
        }

        return false;
    }
}
