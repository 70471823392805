import {AccountEventType, accountTypeEvents} from "../accounts/accounts";
import {StreamerEventType} from "./streamer-events.api";

export const accountEventVisuals: { [key in AccountEventType]: { label: string, order: number, icon?: string } } = {
    // AccountType.Donatty
    [AccountEventType.DonattyDonate]: {
        label: "Donate",
        order: 0,
    },
    // AccountType.Paypal
    [AccountEventType.PaypalDonate]: {
        label: "Donate",
        order: 1,
    },
    // AccountType.Twitch
    [AccountEventType.TwitchBits]: {
        label: "Bits",
        order: 2,
    },
    [AccountEventType.TwitchFollowers]: {
        label: "Followers",
        order: 3,
    },
    [AccountEventType.TwitchSubscribers]: {
        label: "Subscribers",
        order: 4,
    },
    [AccountEventType.TwitchSubscribersUpgrade]: {
        label: "Subscribers (Upgrade)",
        order: 5,
    },
    [AccountEventType.TwitchGiftSubscribersViewer]: {
        label: "Gifts (Viewer)",
        order: 6,
    },
    [AccountEventType.TwitchGiftSubscribersChannel]: {
        label: "Gifts (Channel)",
        order: 7,
    },
    [AccountEventType.TwitchGiftSubscribersUpgrade]: {
        label: "Gifts (Upgrade)",
        order: 8,
    },
    [AccountEventType.TwitchChannelPoints]: {
        label: "Points",
        order: 9,
    },
    [AccountEventType.TwitchHypeTrain]: {
        label: "Hype Trains",
        order: 10,
    },
    [AccountEventType.TwitchHost]: {
        label: "Hosts",
        order: 11,
    },
    [AccountEventType.TwitchRaids]: {
        label: "Raids",
        order: 12,
    },

    // AccountType.Trovo
    [AccountEventType.TrovoSpellMana]: {
        label: "Spell (Mana)",
        order: 13,
    },
    [AccountEventType.TrovoSpellElixir]: {
        label: "Spell (Elixir)",
        order: 14,
    },
    [AccountEventType.TrovoFollowers]: {
        label: "Followers",
        order: 15,
    },
    [AccountEventType.TrovoSubscribers]: {
        label: "Subscribers",
        order: 16,
    },
    [AccountEventType.TrovoGiftSubscribersViewer]: {
        label: "Gifts (Viewer)",
        order: 17,
    },
    [AccountEventType.TrovoGiftSubscribersChannel]: {
        label: "Gifts (Channel)",
        order: 18,
    },
    [AccountEventType.TrovoRaids]: {
        label: "Raids",
        order: 19,
    },

    // AccountType.Youtube
    [AccountEventType.YoutubeSuperchat]: {
        label: "Super Chat",
        order: 20,
    },
    [AccountEventType.YoutubeMembers]: {
        label: "Members",
        order: 21,
    },
    [AccountEventType.YoutubeSubscribers]: {
        label: "Subscribers",
        order: 22,
    },
};

const eventAccountType = eventType => Object.keys(accountTypeEvents).find(accountType => accountTypeEvents[accountType].includes(eventType));

export const accountEventIcons = Object.values(AccountEventType).reduce((acc: { [key in AccountEventType]?: string }, eventType: AccountEventType) => {
    acc[eventType] = "logo-" + eventAccountType(eventType);
    return acc;
}, {});

export const streamerEventVisuals: { [key in StreamerEventType]: { label: string, order: number } } = {
    // AccountType.Donatty
    [StreamerEventType.DonattyDonation]: {
        label: "Donate",
        order: 0,
    },
    [StreamerEventType.DonattyPayout]: {
        label: "Payout",
        order: 1,
    },
    [StreamerEventType.DonattyPayoutSuspicious]: {
        label: "Suspicious Payout",
        order: 2,
    },
    // AccountType.Paypal
    [StreamerEventType.PaypalDonation]: {
        label: "Donate",
        order: 3,
    },
    // AccountType.Twitch
    [StreamerEventType.TwitchBit]: {
        label: "Bits",
        order: 4,
    },
    [StreamerEventType.TwitchFollower]: {
        label: "Followers",
        order: 5,
    },
    [StreamerEventType.TwitchSubscriber]: {
        label: "Subscribers",
        order: 6,
    },
    [StreamerEventType.TwitchSubscriberUpgrade]: {
        label: "Subscribers (Upgrade)",
        order: 7,
    },
    [StreamerEventType.TwitchSubscriberGiftViewer]: {
        label: "Gifts (Viewer)",
        order: 8,
    },
    [StreamerEventType.TwitchSubscriberGiftChannel]: {
        label: "Gifts (Channel)",
        order: 9,
    },
    [StreamerEventType.TwitchSubscriberGiftUpgrade]: {
        label: "Gifts (Upgrade)",
        order: 10,
    },
    [StreamerEventType.TwitchChannelPoints]: {
        label: "Points",
        order: 11,
    },
    [StreamerEventType.TwitchHypeTrain]: {
        label: "Hype Trains",
        order: 12,
    },
    [StreamerEventType.TwitchHost]: {
        label: "Hosts",
        order: 13,
    },
    [StreamerEventType.TwitchRaid]: {
        label: "Raids",
        order: 14,
    },
    // AccountType.Trovo
    [StreamerEventType.TrovoSubscriber]: {
        label: "Subscribers",
        order: 15,
    },
    [StreamerEventType.TrovoSubscriberGiftViewer]: {
        label: "Gifts (Viewer)",
        order: 16,
    },
    [StreamerEventType.TrovoSubscriberGiftChannel]: {
        label: "Gifts (Channel)",
        order: 17,
    },
    [StreamerEventType.TrovoFollower]: {
        label: "Followers",
        order: 18,
    },
    [StreamerEventType.TrovoRaid]: {
        label: "Raids",
        order: 19,
    },
    [StreamerEventType.TrovoSpellElixir]: {
        label: "Spell (Elixir)",
        order: 20,
    },
    [StreamerEventType.TrovoSpellMana]: {
        label: "Spell (Mana)",
        order: 21,
    },
    // AccountType.Youtube
    [StreamerEventType.YoutubeSuperchat]: {
        label: "Super Chat",
        order: 22,
    },
    [StreamerEventType.YoutubeSponsor]: {
        label: "Members",
        order: 23
    },
    [StreamerEventType.YoutubeFollower ]: {
        label: "Subscribers",
        order: 24,
    },
};
