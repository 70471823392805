import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {DonationEvent} from "../../../../../services/events/streamer-event";
import {PlatformInfoService} from "../../../../../../../../shared/src/lib/platform-info.service";
import {ItemClasses} from "../item-donate-card/item-donate-card.component";
import {ModerationState} from "../../../../../services/events/streamer-events.api";

@Component({
    selector: "app-item-youtube-superchat-card",
    templateUrl: "./item-youtube-superchat-card.component.html",
    styleUrls: ["../item-donate-card/item-donate-card.component.scss"],
})
export class ItemYoutubeSuperchatCardComponent implements OnChanges {
    @Input()
    public item: DonationEvent;

    @Input()
    public active = false;

    @Input()
    public showGoal = false;

    @Output()
    public readonly retry = new EventEmitter<DonationEvent>();

    @Output()
    public selected = new EventEmitter<DonationEvent>();

    public itemClasses: ItemClasses;

    public readonly ModerationState = ModerationState;

    public constructor(private readonly platformInfoService: PlatformInfoService) {
    }

    public ngOnChanges(): void {
        this.itemClasses = this.getItemClasses();
    }

    private getItemClasses(): ItemClasses {
        return {
            "tl__item--desktop": this.platformInfoService.isDesktop,
            "tl__item--live": this.item.isLive,
            "tl__item--mobile": this.platformInfoService.isMobile,
            "tl__item--selected": this.active,
            "tl__item--obs": this.platformInfoService.isObs,
        };
    }

}
