<div class="we__tab-container">
    <div class="we__header">
        Шрифт ника
    </div>

    <app-widget-edit-font-basics id="subscriberNick"
                                 [showAlign]="true"
                                 [(item)]="widgetProps.style.subscriberNick"
                                 text="ника" offsetBounds="200"
                                 (itemChange)="widgetProps.style.subscriberNick = $event; widgetPropsChange.emit(widgetProps)">
    </app-widget-edit-font-basics>
</div>
