<div class="btn__container {{buttonTypeClasses}} {{contentPaddingClass}}" (click)="onClick($event)" [ngClass]="{'btn--disabled': isDisabled}">
  <div class="btn__icon {{iconClassName}}" *ngIf="isIconPresent">
    <img src="/assets/icons/{{iconName}}.svg" *ngIf="!isDisabled" />
    <img src="/assets/icons/{{iconName}}-disabled.svg" *ngIf="isDisabled" />
  </div>
  <div class="btn__text">
    <ng-content></ng-content>
  </div>
  <div class="btn__shade">
  </div>
  <div #rippleContainer class="btn__ripple-container" (mousedown)="emitRipple($event)"></div>
</div>
