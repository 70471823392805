<div class="we__tab-container">
    <div class="we__header">
        Изображение
    </div>

    <div class="we__block">
        <app-widget-edit-style-upload-button icon="ic-attach-image"
                                             accept=".gif,.jpg,.jpeg,.png,.apng,.webp,.webm,.mp4"
                                             help="Максимум 100 Мб. Допустимые форматы: {{allowedFormatsText}}."
                                             [default]="widgetProps.data.imageUri"
                                             (clear)="clearImage()"
                                             (change)="setImage($event)"
                                             maxSizeMb="100">
            Загрузить изображение
        </app-widget-edit-style-upload-button>
    </div>

    <div class="we__row">
        <div class="we__label">
            Размер
        </div>
        <div class="we__controls">
            <app-single-slider [min]="1" [max]="300" unit="%"
                           [(value)]="widgetProps.style.image.scale"
                           (valueChange)="widgetProps.style.image.scale = $event; widgetPropsChange.emit(widgetProps)"></app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Смещение по X
        </div>
        <div class="we__controls">
            <app-single-slider [min]="-550" [max]="550" unit="px" zeroAdjust="0"
                           [(value)]="widgetProps.style.image.offsetX"
                           (valueChange)="widgetProps.style.image.offsetX = $event; widgetPropsChange.emit(widgetProps)"></app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Смещение по Y
        </div>
        <div class="we__controls">
            <app-single-slider [min]="-550" [max]="550" unit="px" zeroAdjust="0"
                           [(value)]="widgetProps.style.image.offsetY"
                           (valueChange)="widgetProps.style.image.offsetY = $event; widgetPropsChange.emit(widgetProps)"></app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Прозрачность
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" unit="%"
                           [(value)]="widgetProps.style.image.transparency"
                           (valueChange)="widgetProps.style.image.transparency = $event; widgetPropsChange.emit(widgetProps)"></app-single-slider>
        </div>
    </div>
</div>
