import {
    STREAMER_EVENT_TYPES, DonattyDonationEventResponse, PayoutEventResponse, PaypalDonationEventResponse,
    StreamerEventCurrency, StreamerEventResponse, StreamerEventType
} from "../../../../streamer/src/app/services/events/streamer-events.api";
import {fake} from "./fake";
import {PaymentMethodType} from "../models/finance.api";
import {Util} from "../util";
import {
    TwitchBitEventResponse, TwitchChannelPointsEventResponse, TwitchFollowerEventResponse,
    TwitchHostEventResponse, TwitchHypeTrainEventResponse, TwitchRaidEventResponse, TwitchSubscriberEventResponse,
    TwitchSubscriberGiftChannelEventResponse, TwitchSubscriberGiftUpgradeEventResponse,
    TwitchSubscriberGiftViewerEventResponse, TwitchSubscriberUpgradeEventResponse
} from "../../../../streamer/src/app/services/events/streamer-events.twitch.api";
import {
    TrovoFollowerEventResponse, TrovoRaidEventResponse, TrovoSpellEventResponse,
    TrovoSubscriberEventResponse, TrovoSubscriberGiftChannelEventResponse,
    TrovoSubscriberGiftViewerEventResponse
} from "../../../../streamer/src/app/services/events/streamer-events.trovo.api";
import {
    YoutubeDonationEventResponse, YoutubeFollowerEventResponse, YoutubeSponsorEventResponse
} from "../../../../streamer/src/app/services/events/streamer-events.youtube.api";

export const fakeStreamerEvents = (count: number): Array<StreamerEventResponse> => {
    const result = [];
    const eventTypesCount = STREAMER_EVENT_TYPES.length;
    for (let i = 0; i < count; i++) {
        result.push(makeTestStreamerEvent[STREAMER_EVENT_TYPES[i % eventTypesCount]]());
    }
    return result;
};

const paymentCommission = {
    [PaymentMethodType.Card]: .035,
    [PaymentMethodType.Apple]: .035,
    [PaymentMethodType.Google]: .035,
    [PaymentMethodType.Qiwi]: .07,
    [PaymentMethodType.Yandex]: .06,
};

const withdrawalCommission = {
    [PaymentMethodType.Qiwi]: .03,
    [PaymentMethodType.Yandex]: .03,
};

export const makeTestStreamerEvent: { [key in StreamerEventType]: () => StreamerEventResponse } = {
    [StreamerEventType.DonattyDonation]: (): DonattyDonationEventResponse => {
        const amount = fake.donationAmount();
        const method = fake.paymentMethod();
        return {
            refId: fake.refId(),
            date: fake.date(),
            type: StreamerEventType.DonattyDonation,
            amount,
            currency: StreamerEventCurrency.Ruble,
            commission: amount * paymentCommission[method],
            displayName: Util.randomName(),
            goal: fake.goal(),
            attachments: fake.attachments(),
            message: Util.randomMessage(),
            method,
            picture: fake.picture(),
        };
    },
    [StreamerEventType.DonattyPayout]: makeTestPayoutGenerator(false),
    [StreamerEventType.DonattyPayoutSuspicious]: makeTestPayoutGenerator(true),
    [StreamerEventType.PaypalDonation]: (): PaypalDonationEventResponse => ({
        amount: fake.donationAmount(),
        currency: StreamerEventCurrency.Ruble,
        date: fake.date(),
        displayName: Util.randomName(),
        goal: fake.goal(),
        attachments: fake.attachments(),
        message: Util.randomMessage(),
        picture: fake.picture(),
        refId: fake.refId(),
        type: StreamerEventType.PaypalDonation,
    }),
    [StreamerEventType.TwitchBit]: (): TwitchBitEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchBit,
        amount: fake.donationAmount(),
        displayName: Util.randomName(),
        message: Util.randomMessage(),
    }),
    [StreamerEventType.TwitchChannelPoints]: (): TwitchChannelPointsEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchChannelPoints,
        subscriber: "TwitchSub",
        title: "CP2077",
        description: "Кинуть гранату",
        message: Util.randomMessage(),
        amount: 114,
        bgColor: "#ff6905",
        picture: fake.picture(),
    }),
    [StreamerEventType.TwitchFollower]: (): TwitchFollowerEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchFollower,
        displayName: Util.randomName(),
    }),
    [StreamerEventType.TwitchHost]: (): TwitchHostEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchHost,
        displayName: Util.randomName(),
    }),
    [StreamerEventType.TwitchHypeTrain]: (): TwitchHypeTrainEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchHypeTrain,
        level: Math.floor(Math.random() * 14 + 1),
    }),
    [StreamerEventType.TwitchRaid]: (): TwitchRaidEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchRaid,
        displayName: Util.randomName(),
        viewers: fake.viewersAmount(),
    }),
    [StreamerEventType.TwitchSubscriber]: (): TwitchSubscriberEventResponse => {
        const months = fake.subscriptionMoths();
        return {
            refId: fake.refId(),
            date: fake.date(),
            type: StreamerEventType.TwitchSubscriber,
            displayName: Util.randomName(),
            tier: Math.round(1 + Math.random() * 2),
            total: months + Math.round(Math.random()),
            streak: months + Math.round(Math.random()),
            months,
            message: Util.randomMessage(),
        };
    },
    [StreamerEventType.TwitchSubscriberGiftViewer]: (): TwitchSubscriberGiftViewerEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchSubscriberGiftViewer,
        months: fake.subscriptionMoths(),
        giftedBy: Util.randomName(),
        message: Util.randomMessage(),
        tier: Math.round(1 + Math.random() * 2),
        giftedTo: Util.randomName(),
    }),
    [StreamerEventType.TwitchSubscriberGiftChannel]: (): TwitchSubscriberGiftChannelEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchSubscriberGiftChannel,
        giftedBy: Util.randomName(),
        months: 2,
        tier: Math.round(1 + Math.random() * 2),
        monthsTotal: 12,
    }),
    [StreamerEventType.TwitchSubscriberGiftUpgrade]: (): TwitchSubscriberGiftUpgradeEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TwitchSubscriberGiftUpgrade,
        giftedTo: Util.randomName(),
        giftedBy: Util.randomName(),
    }),
    [StreamerEventType.TwitchSubscriberUpgrade]: (): TwitchSubscriberUpgradeEventResponse => {
        const tier = Math.round(1. + Math.random() * 2);
        return {
            refId: fake.refId(),
            date: fake.date(),
            type: StreamerEventType.TwitchSubscriberUpgrade,
            oldTier: tier - 1,
            tier,
            displayName: Util.randomName(),
        };
    },
    [StreamerEventType.TrovoSubscriber]: (): TrovoSubscriberEventResponse => {
        return {
            refId: fake.refId(),
            date: fake.date(),
            type: StreamerEventType.TrovoSubscriber,
            subscriber: Util.randomName(),
            tier: Math.round(1 + Math.random() * 2),
            picture: fake.picture(),
        };
    },
    [StreamerEventType.TrovoSubscriberGiftViewer]: (): TrovoSubscriberGiftViewerEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoSubscriberGiftViewer,
        giftedBy: Util.randomName(),
        tier: Math.round(1 + Math.random() * 2),
        giftedTo: Util.randomName(),
        picture: fake.picture(),
    }),
    [StreamerEventType.TrovoSubscriberGiftChannel]: (): TrovoSubscriberGiftChannelEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoSubscriberGiftChannel,
        giftedBy: Util.randomName(),
        months: Math.floor(Math.random() * 15 + 1),
        tier: Math.round(1 + Math.random() * 2),
        picture: fake.picture(),
    }),
    [StreamerEventType.TrovoFollower]: (): TrovoFollowerEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoFollower,
        displayName: Util.randomName(),
        picture: fake.picture(),
    }),
    [StreamerEventType.TrovoRaid]: (): TrovoRaidEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoRaid,
        displayName: Util.randomName(),
        viewers: fake.viewersAmount(),
        picture: fake.picture(),
    }),
    [StreamerEventType.TrovoSpellElixir]: (): TrovoSpellEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoSpellElixir,
        subscriber: Util.randomName(),
        title: "Unicorn",
        amount: Math.round(1 + Math.random() * 200),
        value: Math.round(1 + Math.random() * 5),
        picture: fake.picture(),
    }),
    [StreamerEventType.TrovoSpellMana]: (): TrovoSpellEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.TrovoSpellMana,
        subscriber: Util.randomName(),
        title: "Unicorn",
        amount: Math.round(1 + Math.random() * 200),
        value: Math.round(1 + Math.random() * 5),
        picture: fake.picture(),
    }),
    [StreamerEventType.YoutubeFollower]: (): YoutubeFollowerEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.YoutubeFollower,
        displayName: Util.randomName(),
        picture: fake.picture(),
    }),
    [StreamerEventType.YoutubeSponsor]: (): YoutubeSponsorEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.YoutubeSponsor,
        displayName: Util.randomName(),
        months: fake.subscriptionMoths(),
        channel: Util.randomMessage(),
        picture: fake.picture(),
        since: "???",
    }),
    [StreamerEventType.YoutubeSuperchat]: (): YoutubeDonationEventResponse => ({
        refId: fake.refId(),
        date: fake.date(),
        type: StreamerEventType.YoutubeSuperchat,
        displayName: Util.randomName(),
        currency: StreamerEventCurrency.Ruble,
        amount: fake.donationAmount(),
        message: Util.randomMessage(),
    }),
};

function makeTestPayoutGenerator(isPayoutSuspicious: boolean): (() => PayoutEventResponse) {
    const type = isPayoutSuspicious
        ? StreamerEventType.DonattyPayoutSuspicious
        : StreamerEventType.DonattyPayout;

    return (): PayoutEventResponse => {
        const amount = fake.amount();
        const method = fake.withdrawalMethod();
        let commission;
        if (method === PaymentMethodType.Card) {
            if (amount < 10000) {
                commission = 30;
            } else {
                commission = 0;
            }
        } else {
            commission = amount * withdrawalCommission[method];
        }

        return {
            amount,
            commission,
            date: fake.date(),
            method,
            number: fake.accountNumber(),
            refId: fake.refId(),
            type,
        };
    };
}
