<div class="confirmation">
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[0]" maxlength="1" id="c-0"
           (keydown)="keyDown($event, 0)" (keyup)="keyUp($event, 0)" placeholder="" />
  </div>
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[1]" maxlength="1" id="c-1"
           (keydown)="keyDown($event, 1)" (keyup)="keyUp($event, 1)" placeholder="" />
  </div>
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[2]" maxlength="1" id="c-2"
           (keydown)="keyDown($event, 2)" (keyup)="keyUp($event, 2)" placeholder="" />
  </div>
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[3]" maxlength="1" id="c-3"
           (keydown)="keyDown($event, 3)" (keyup)="keyUp($event, 3)" placeholder="" />
  </div>
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[4]" maxlength="1" id="c-4"
           (keydown)="keyDown($event, 4)" (keyup)="keyUp($event, 4)" placeholder="" />
  </div>
  <div class="confirmation__digit">
    <input type="number" [(ngModel)]="confirmCode[5]" maxlength="1" id="c-5"
           (keydown)="keyDown($event, 5)" (keyup)="keyUp($event, 5)" placeholder="" />
  </div>
</div>
