import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AlertWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {Subject} from "rxjs";

@Component({
    selector: "app-widget-donations-tab-audio",
    templateUrl: "./widget-donations-tab-audio.component.html",
    styleUrls: ["./widget-donations-tab-audio.component.scss"]
})
export class WidgetDonationsTabAudioComponent implements OnChanges, OnDestroy {

    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    @Output()
    public audioChanged = new EventEmitter<File>();

    public audioFormats = [".mp3", ".wav"];

    private readonly destroy$: Subject<void> = new Subject();

    private audioFile: File;

    constructor(private readonly toastr: ToastrService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetProps) {
            const previousValue = changes.widgetProps.previousValue;
            const currentValue = changes.widgetProps.currentValue;
            if (previousValue?.data.audioUri?.length &&
                !currentValue.data.audioUri?.length) {
                this.audioFile = null;
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public get allowedFormatsText(): string {
        return this.audioFormats.map(s => s.substring(1)).join(", ");
    }

    public setAudio(event) {
        const file = event.target.files[0] as File;
        if (this.checkFileFormat(file.name, this.audioFormats)) {
            this.audioFile = file;
            this.audioChanged.emit(file);
        }
    }

    private checkFileFormat(name, formats) {
        let result = false;
        formats.map(x => {
            if (name.toLowerCase().endsWith(x)) {
                result = true;
                return;
            }
        });
        if (!result) {
            this.toastr.warning(`Допустимые форматы: ${formats.join(", ")}`, "Неправильный формат файла");
        }
        return result;
    }

    public clearAudio() {
        this.widgetProps.data.audioUri = [];
        this.widgetPropsChange.emit(this.widgetProps);
        this.audioFile = null;
        this.audioChanged.emit(null);
    }

}
