<div class="we__tab-container">
  <div class="we__header">
    Сумма
  </div>

  <app-widget-edit-font-basics [showAlign]="false"
                               [(item)]="widgetProps.style.subscriberAmount"
                               text="суммы" offsetBounds="200"
                               (itemChange)="widgetProps.style.subscriberAmount = $event; widgetPropsChange.emit(widgetProps)">
  </app-widget-edit-font-basics>
</div>
