<div class="row mx-0 pb-5">
  <div class="col-12">
    <div class="row justify-content-center">
      <div class="col-auto text-center hint position-relative">
        <img class="pt-3" src="/assets/icons/{{image}}.png">
        <img class="background" src="/assets/icons/{{image}}.png">
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 px-0 white text-center pt-4 pb-3">
        <h1>{{titleText}}</h1>
      </div>
    </div>
  </div>
  <div class="col-6 offset-3 pt-2 px-0 focus-text text-center">
    <ng-content></ng-content>
  </div>
</div>