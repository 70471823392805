import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output
} from "@angular/core";
import {AccountType} from "../../services/accounts/accounts";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PlatformInfoService} from "../../../../../shared/src/lib/platform-info.service";

@Component({
    selector: "app-connect-social-dialog",
    templateUrl: "./connect-social-dialog.component.html",
    styleUrls: ["./connect-social-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectSocialDialogComponent implements OnInit, OnDestroy {
    public isShown = false;

    @Output()
    public readonly addSocial = new EventEmitter<AccountType>();

    public readonly useBackdropWorkaround = this.platformInfoService.isFirefox;

    public readonly AccountType = AccountType;

    private readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef,
                       private readonly platformInfoService: PlatformInfoService) {
    }

    public ngOnInit(): void {
        this.addSocial.pipe(takeUntil(this.destroy$)).subscribe(() => this.setVisible(false));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public show(): void {
        this.setVisible(true);
    }

    public onClose(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.setVisible(false);
    }

    public onFadeClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        const root = document.getElementById("add-social-dialog");
        if (event.target === root) {
            this.setVisible(false);
        }
    }

    private setVisible(isVisible: boolean): void {
        if (this.isShown === isVisible) {
            return;
        }

        this.isShown = isVisible;
        this.changeDetectorRef.detectChanges();
    }
}
