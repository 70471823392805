import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {SSEService} from "../sse.service";
import {AccountEventType} from "../../../../../shared/src/lib/common/FinancialOperation";
import {SocialNetworkInfo} from "./accounts.api";
import {AccountInfo, makeAccountInfo} from "./accounts";

@Injectable({
    providedIn: "root"
})
export class AccountsSseService implements OnDestroy {

    private readonly destroy$: Subject<void> = new Subject();

    public readonly addSocial$: Observable<AccountInfo> = this.sseService.message$.pipe(
        filter(m => m.type === AccountEventType.AddSocial),
        map(m => m.event as SocialNetworkInfo),
        map(account => makeAccountInfo(account.id, account.type, account.name, account.main, account.new)),
        takeUntil(this.destroy$),
    );

    public readonly removeSocial$: Observable<AccountInfo> = this.sseService.message$.pipe(
        filter(m => m.type === AccountEventType.RemoveSocial),
        map(m => m.event as SocialNetworkInfo),
        map(account => makeAccountInfo(account.id, account.type, account.name, account.main, account.new)),
        takeUntil(this.destroy$),
    );

    public readonly reconnectSocial$: Observable<AccountInfo> = this.sseService.message$.pipe(
        filter(m => m.type === AccountEventType.ReconnectSocial),
        map(m => m.event as SocialNetworkInfo),
        map(account => makeAccountInfo(account.id, account.type, account.name, account.main, account.new)),
        takeUntil(this.destroy$),
    );

    public constructor(private readonly sseService: SSEService) {
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

}
