import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TagSelectorItem} from "../../../../../tags-selector/tags-selector.component";
import {GoalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-goal-tab-target",
    templateUrl: "./widget-goal-tab-target.component.html",
    styleUrls: ["./widget-goal-tab-target.component.scss"]
})
export class WidgetGoalTabTargetComponent implements OnInit {

    @Input()
    public widgetProps: GoalWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    public readonly AVAILABLE_TAGS: readonly TagSelectorItem[] = [
        {tag: "amount", description: "уже собранная сумма"},
        {tag: "goal", description: "общая сумма сбора"},
        {tag: "percentage", description: "процент достижения цели"}
    ];

    public caretPosition: number;

    public async ngOnInit() {
        this.caretPosition = this.widgetProps.style.progressBar.template.length;
    }

    @ViewChild("progressBarTemplate")
    public progressBarTemplate: ElementRef<HTMLInputElement>;

    public onTagClicked(tag: string) {
        const tagTemplate = `{${tag}}`;
        this.widgetProps.style.progressBar.template = [
            this.widgetProps.style.progressBar.template.slice(0, this.caretPosition),
            tagTemplate,
            this.widgetProps.style.progressBar.template.slice(this.caretPosition),
        ].join("");
        this.caretPosition += tagTemplate.length;
        this.widgetPropsChange.next(this.widgetProps);
        requestAnimationFrame(() => {
            this.progressBarTemplate.nativeElement.setSelectionRange(this.caretPosition, this.caretPosition);
            this.progressBarTemplate.nativeElement.focus();
        });
    }

}
