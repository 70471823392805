import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {DonationEvent} from "../../../../../services/events/streamer-event";
import {DonationAttachments, ModerationState} from "../../../../../services/events/streamer-events.api";
import {ClipboardService} from "ngx-clipboard";
import {ToastrService} from "ngx-toastr";
import {PlatformInfoService} from "../../../../../../../../shared/src/lib/platform-info.service";

export interface ItemClasses {
    "tl__item--desktop"?: boolean;
    "tl__item--live"?: boolean;
    "tl__item--mobile"?: boolean;
    "tl__item--selected"?: boolean;
    "tl__item--obs"?: boolean;
}

@Component({
    selector: "app-item-donate-card",
    templateUrl: "./item-donate-card.component.html",
    styleUrls: ["./item-donate-card.component.scss"],
})
export class ItemDonateCardComponent implements OnChanges {
    @Input()
    public item: DonationEvent;

    @Input()
    public active = false;

    @Input()
    public showGoal = false;

    @Output()
    public selected = new EventEmitter<DonationEvent>();

    @Output()
    public readonly retry = new EventEmitter<DonationEvent>();
    public isGoalVisible: boolean;
    public itemClasses: ItemClasses;
    public readonly ModerationState = ModerationState;

    constructor(private readonly clipboardService: ClipboardService,
                private readonly toastr: ToastrService,
                private readonly platformInfoService: PlatformInfoService) {
    }

    public ngOnChanges(): void {
        this.isGoalVisible = this.getIsGoalVisible();
        this.itemClasses = this.getItemClasses();
    }

    private getIsGoalVisible(): boolean {
        return (this.showGoal && !!this.item.goal);
    }

    private getItemClasses(): ItemClasses {
        return {
            "tl__item--desktop": this.platformInfoService.isDesktop,
            "tl__item--live": this.item.isLive,
            "tl__item--mobile": this.platformInfoService.isMobile,
            "tl__item--selected": this.active,
            "tl__item--obs": this.platformInfoService.isObs,
        };
    }

    public copyMediaUrl(media: DonationAttachments["media"]) {
        this.clipboardService.copyFromContent(media.url);
        this.toastr.success("Ссылка скопирована");
    }

}
