<button mat-icon-button aria-label="Размер элемента браузера"
        [disabled]="formGroup.dirty"
        (click)="active = !active; activeChange.next(active)">
    <mat-icon [svgIcon]="active ? 'bx-show' : 'bx-hide'"></mat-icon>
</button>
<button mat-button [disableRipple]="true"
        (click)="formGroup.pristine && (active = !active); activeChange.next(active)">
    Размер элемента браузера:
</button>
<form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <input formControlName="width" (keydown.enter)="save(false)" [min]="min" [max]="max" type="number">
    ×
    <input formControlName="height" (keydown.enter)="save(false)" [min]="min" [max]="max" type="number">
    <div class="action-buttons" [hidden]="!formGroup.dirty && formGroup.controls.autoDetect.value">
        <span class="divider"> </span>
        <button mat-icon-button aria-label="Авто размер"
                [disableRipple]="true"
                matTooltip="Авто"
                [hidden]="formGroup.dirty || formGroup.controls.autoDetect.value"
                (click)="save(true)">
            <mat-icon>autorenew</mat-icon>
        </button>
        <button mat-icon-button aria-label="Сохранить размеры"
                [disableRipple]="true"
                [hidden]="!formGroup.dirty"
                [disabled]="formGroup.invalid"
                (click)="save(false)">
            <mat-icon svgIcon="check"></mat-icon>
        </button>
        <button mat-icon-button aria-label="Отменить изменения размеров"
                [disableRipple]="true"
                [hidden]="!formGroup.dirty"
                (click)="formGroup.reset(defaults)">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
</form>
