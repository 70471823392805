import {AccountEventType} from "../accounts/accounts";
import {TagSelectorItem} from "../../components/tags-selector/tags-selector.component";

interface EventTypesBusinessInfo {
    hasAmount: boolean;
    hasDonation: boolean;
    hasDonationBits: boolean;
    hasDonationDonatty: boolean;
    hasDonationPaypal: boolean;
    hasDonationSuperchat: boolean;
    hasFollowers: boolean;
    hasHost: boolean;
    hasMessages: boolean;
    hasRaids: boolean;
    hasSubscribersWithAmount: boolean;
    hasSubscribersTwitch: boolean;
    hasSubscribersTrovo: boolean;
    hasHypeTrain: boolean;
    hasReward: boolean;
    hasGiftViewer: boolean;
    hasGiftViewerTwitch: boolean;
    hasGiftChannel: boolean;
    hasUpgrade: boolean;
    hasGiftUpgrade: boolean;
    hasSpellMana: boolean;
    hasSpellElixir: boolean;
}

export enum EventTag {
    Username = "username",
    Amount = "amount",
    Unit = "unit",
    Message = "message",
    Purpose = "purpose",
    Level = "level",
    Tier = "tier",
    OldTier = "old-tier",
    Giver = "giver",
    Receiver = "receiver",
    Reward = "reward",
    Streak = "streak",
    Total = "total",
    UnitTotal = "unit-total",
    UnitStreak = "unit-streak",
    UnitTier = "unit-tier",
    SpellName = "spell-name",
    SpellValue = "spell-value",
}

export class WidgetHelpTagsService {

    public static formatDonationTags(eventTypes: Array<AccountEventType>, hasPurpose: boolean): TagSelectorItem[] {
        const info = WidgetHelpTagsService.getBusinessInfo(eventTypes);
        const nickTypes = WidgetHelpTagsService.formatNickTypes(info);
        let amountTypes = WidgetHelpTagsService.formatAmountTypes(info);
        const unitTypes = WidgetHelpTagsService.formatUnitTypes(info);
        const tierTypes = WidgetHelpTagsService.formatTierTypes(info);

        const tags: TagSelectorItem[] = [];

        if (nickTypes.length) {
            tags.push({tag: EventTag.Username, description: `ник ${nickTypes}`});
        }

        if (info.hasSpellElixir || info.hasSpellMana) {
            tags.push({tag: EventTag.SpellName, description: "название заклинания"});
            tags.push({tag: EventTag.SpellValue, description: "стоимость заклинания"});
        }

        if (info.hasAmount) {
            if (amountTypes !== "количество заклинаний") {
                amountTypes = "сумма " + amountTypes;
            }
            tags.push({tag: EventTag.Amount, description: amountTypes});
            tags.push({tag: EventTag.Unit, description: `ед.изм.: ${unitTypes}`});
        } else {
            if (info.hasHypeTrain) {
                tags.push({tag: EventTag.Unit, description: `ед.изм.: ${unitTypes}`});
            }
        }

        if (info.hasMessages) {
            tags.push({tag: EventTag.Message, description: "сопровождающее сообщение"});
        }

        if (hasPurpose && (info.hasDonationDonatty || info.hasDonationPaypal)) {
            tags.push({tag: EventTag.Purpose, description: "цель доната"});
        }

        if (tierTypes.length) {
            tags.push({tag: EventTag.Tier, description: tierTypes});
            if (info.hasUpgrade) {
                tags.push({tag: EventTag.OldTier, description: "старый уровень подписки"});
            }
            tags.push({tag: EventTag.UnitTier, description: "ед.изм.: уровни подписки"});
        }

        if (info.hasSubscribersTwitch) {
            tags.push({tag: EventTag.Streak, description: "месяцев подписки подряд"});
            tags.push({tag: EventTag.UnitStreak, description: "ед.изм.: месяцы подписки подряд"});
            tags.push({tag: EventTag.Total, description: "всего месяцев подписки"});
            tags.push({tag: EventTag.UnitTotal, description: "ед.изм.: месяцы подписки всего"});
        }

        if (info.hasHypeTrain) {
            tags.push({tag: EventTag.Level, description: "уровень Hype Trains"});
        }

        if (info.hasReward) {
            tags.push({tag: EventTag.Reward, description: "название награды за баллы канала"});
        }

        if (info.hasGiftUpgrade) {
            tags.push({tag: EventTag.Giver, description: "ник исходного дарителя"});
        }

        if (info.hasGiftViewer) {
            tags.push({tag: EventTag.Receiver, description: "ник получателя"});
        }

        return tags;
    }

    private static formatTierTypes(info: EventTypesBusinessInfo) {
        const tierParts: string[] = [];

        if (info.hasSubscribersTwitch || info.hasSubscribersTrovo) {
            tierParts.push("уровень подписки");
        }
        if (info.hasUpgrade) {
            tierParts.push("новый уровень подписки");
        }
        if (info.hasGiftChannel || info.hasGiftViewer) {
            tierParts.push("уровень подписки в подарке");
        }

        return tierParts.join(" / ");
    }

    private static formatNickTypes(info: EventTypesBusinessInfo): string {
        const nickParts: string[] = [];

        if (info.hasDonation) {
            nickParts.push("донатера");
        }

        if (info.hasFollowers) {
            nickParts.push("фолловера");
        }

        if (info.hasSubscribersWithAmount || info.hasSubscribersTrovo || info.hasUpgrade) {
            nickParts.push("подписчика");
        }

        if (info.hasGiftViewer || info.hasGiftChannel) {
            nickParts.push("дарителя");
        }

        if (info.hasGiftUpgrade) {
            nickParts.push("подписчика");
        }

        if (info.hasReward) {
            nickParts.push("пользователя");
        }

        if (info.hasHost) {
            nickParts.push("хостера");
        }

        if (info.hasRaids) {
            nickParts.push("рейдера");
        }

        if (info.hasSpellMana || info.hasSpellElixir) {
            nickParts.push("заклинателя");
        }

        const uniquePredicate = (nickPart, i) => nickParts.indexOf(nickPart) === i;
        return nickParts.filter(uniquePredicate).join(" / ");
    }

    private static formatAmountTypes(info: EventTypesBusinessInfo): string {
        const unitParts: string[] = [];

        if (info.hasDonationDonatty || info.hasDonationPaypal || info.hasDonationSuperchat) {
            unitParts.push("доната");
        }

        if (info.hasDonationBits) {
            unitParts.push("Bits");
        }

        if (info.hasSubscribersWithAmount) {
            unitParts.push("месяцев подписки");
        }

        if (info.hasGiftViewerTwitch) {
            unitParts.push("месяцев в подарке");
        }

        if (info.hasGiftChannel) {
            unitParts.push("подписок в подарке");
        }

        if (info.hasReward) {
            unitParts.push("баллов канала");
        }

        if (info.hasRaids) {
            unitParts.push("зрителей в рейде");
        }

        if (info.hasSpellMana || info.hasSpellElixir) {
            unitParts.push("количество заклинаний");
        }

        return unitParts.join(" / ");
    }

    private static formatUnitTypes(info: EventTypesBusinessInfo): string {
        const unitTypesParts: string[] = [];

        if (info.hasDonationDonatty || info.hasDonationPaypal || info.hasDonationSuperchat) {
            unitTypesParts.push("₽");
        }

        if (info.hasDonationBits) {
            unitTypesParts.push("Bits");
        }

        if (info.hasRaids) {
            unitTypesParts.push("зрители");
        }

        if (info.hasSubscribersWithAmount || info.hasGiftViewerTwitch) {
            unitTypesParts.push("месяцы");
        }

        if (info.hasGiftChannel) {
            unitTypesParts.push("подписки");
        }

        if (info.hasReward) {
            unitTypesParts.push("Points");
        }

        if (info.hasHypeTrain) {
            unitTypesParts.push("уровни");
        }

        if (info.hasSpellMana) {
            unitTypesParts.push("мана");
        }

        if (info.hasSpellElixir) {
            unitTypesParts.push("эликсир");
        }

        return unitTypesParts.join(" / ");
    }

    private static getBusinessInfo(eventTypes: AccountEventType[]): EventTypesBusinessInfo {
        const hasDonationBits = eventTypes.includes(AccountEventType.TwitchBits);
        const hasDonationDonatty = eventTypes.includes(AccountEventType.DonattyDonate);
        const hasDonationPaypal = eventTypes.includes(AccountEventType.PaypalDonate);
        const hasDonationSuperchat = eventTypes.includes(AccountEventType.YoutubeSuperchat);
        const hasDonation = hasDonationBits || hasDonationDonatty || hasDonationSuperchat || hasDonationPaypal;

        const hasFollowers =
            eventTypes.includes(AccountEventType.YoutubeSubscribers) ||
            eventTypes.includes(AccountEventType.TwitchFollowers) ||
            eventTypes.includes(AccountEventType.TrovoFollowers);

        const hasReward = eventTypes.includes(AccountEventType.TwitchChannelPoints);

        const hasGiftViewerTwitch = eventTypes.includes(AccountEventType.TwitchGiftSubscribersViewer);
        const hasGiftViewer = hasGiftViewerTwitch || eventTypes.includes(AccountEventType.TrovoGiftSubscribersViewer);

        const hasGiftChannel = eventTypes.includes(AccountEventType.TwitchGiftSubscribersChannel) ||
            eventTypes.includes(AccountEventType.TrovoGiftSubscribersChannel);

        const hasMessages = hasDonation || hasReward || hasGiftViewerTwitch;

        const hasRaids = eventTypes.includes(AccountEventType.TwitchRaids) ||
            eventTypes.includes(AccountEventType.TrovoRaids);

        const hasSubscribersTwitch = eventTypes.includes(AccountEventType.TwitchSubscribers);

        const hasSubscribersTrovo = eventTypes.includes(AccountEventType.TrovoSubscribers);

        const hasSubscribers = hasSubscribersTwitch || eventTypes.includes(AccountEventType.YoutubeMembers);

        const hasGiftUpgrade = eventTypes.includes(AccountEventType.TwitchGiftSubscribersUpgrade);

        const hasHypeTrain = eventTypes.includes(AccountEventType.TwitchHypeTrain);

        const hasUpgrade = eventTypes.includes(AccountEventType.TwitchSubscribersUpgrade);

        const hasSpellMana = eventTypes.includes(AccountEventType.TrovoSpellMana);
        const hasSpellElixir = eventTypes.includes(AccountEventType.TrovoSpellElixir);

        const hasAmount = (
            hasDonation || hasRaids || hasSubscribers ||
            hasReward || hasGiftViewerTwitch || hasGiftChannel ||
            hasSpellMana || hasSpellElixir);

        const hasHost = eventTypes.includes(AccountEventType.TwitchHost);

        return {
            hasAmount,
            hasDonation, hasDonationBits, hasDonationDonatty, hasDonationPaypal, hasDonationSuperchat,
            hasSubscribersWithAmount: hasSubscribers, hasSubscribersTwitch, hasSubscribersTrovo,
            hasFollowers, hasHost, hasMessages, hasRaids, hasHypeTrain, hasReward,
            hasGiftViewer, hasGiftViewerTwitch, hasGiftChannel, hasUpgrade, hasGiftUpgrade,
            hasSpellMana, hasSpellElixir,
        };
    }

}
