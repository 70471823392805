<div class="param__row">
    <div class="column__icon">
        <mat-icon svgIcon="logo-{{param.account.getType()}}"></mat-icon>
    </div>
    <div class="column__descr">
        <div class="descr__param">
            {{param.label}}
        </div>
        <div class="descr__nick">
            {{param.account.getNick()}}
        </div>
    </div>
    <div class="column__range">
        <app-widget-edit-open-range [(from)]="from"
                                    [(to)]="to"
                                    [min]="limits.from"
                                    [max]="limits.to"
                                    [units]="unit">
        </app-widget-edit-open-range>
    </div>
</div>
