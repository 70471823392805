<mat-form-field *ngIf="method.isNew" appearance="outline" [hideRequiredMarker]="true">
    <mat-label>Личный номер мобильного телефона</mat-label>
    <span matPrefix>+&nbsp;</span>
    <input #numberInput matInput [formControl]="formControl" required>
    <mat-icon svgIcon="logo-qiwi" matSuffix [inline]="true"></mat-icon>
    <mat-error *ngIf="formControl.hasError('required')">Заполните поле</mat-error>
</mat-form-field>
<button mat-button mat-flat-button color="primary"
        [disabled]="method.isNew && formControl.invalid"
        (click)="initWithdrawal$.next(formControl.value)">
    Продолжить
</button>
