import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TopWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-top-tab-name",
    templateUrl: "./widget-top-tab-name.component.html",
    styleUrls: ["./widget-top-tab-name.component.scss"]
})
export class WidgetTopTabNameComponent {

    @Input()
    public widgetProps: TopWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();
}
