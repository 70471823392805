import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit,
    QueryList, ViewChild, ViewChildren
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetService} from "../../../services/widgets/widget.service";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {WidgetPreviewService, WidgetTestGenerator} from "../../../services/widgets/widget-preview.service";
import {PageService} from "../../../services/page.service";
import {DonationsService} from "../../../services/donation/donation.service";
import {
    EventType, WidgetEvent, WidgetEventDragElement, WidgetEventSelectElement
} from "../interop/widget-event";
import {
    BehaviorSubject, combineLatest, combineLatestWith, fromEvent, merge, Observable, pipe, ReplaySubject, Subject
} from "rxjs";
import {UserService} from "../../../services/user/user.service";
import {WidgetSettingsService} from "../../../services/settings/widget-settings.service";
import * as _ from "lodash";
import {
    ConfirmationModalComponent
} from "../../../../../../shared/src/lib/confirmation-modal/confirmation-modal.component";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";
import {IGenericWidgetInfo, WidgetType} from "../../../../../../shared/src/lib/models/widget";
import {
    AlertWidget, GoalWidget, TopWidget, TotalWidget, WidgetEventSources, WidgetProps, WidgetPropsData
} from "../../../../../../shared/src/lib/models/widget-props";
import {WidgetEditDataParamsComponent} from "./widget-edit-data-params/widget-edit-data-params.component";
import {WidgetFileUploadService, WidgetUploadFileType} from "../../../services/widgets/widget-file-upload.service";
import {
    debounceTime, distinctUntilChanged, filter, map, pairwise, share, shareReplay,
    startWith, switchMap, take, takeUntil, tap, withLatestFrom,
} from "rxjs/operators";
import {WidgetEditVisualsComponent} from "./widget-edit-visuals/widget-edit-visuals.component";
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {
    CookiesBackedLocalStorageService
} from "../../../../../../shared/src/lib/services/cookies-backed-local-storage/cookies-backed-local-storage.service";
import {WidgetSizeControlComponent} from "./widget-size-label/widget-size-control.component";
import {WidgetStoreService} from "../../../services/widgets/widget-store.service";

interface PreviewEvent {
    action: string;
    data?: any;
}

interface Banner {
    url: string;
    widgetType: WidgetType;
    show: boolean;
}

export interface WidgetDimensions {
    width: number;
    height: number;
    autoDetect: boolean;
}

export type DragProps = {
    [TWidget in WidgetType]?: {
        [key: WidgetEventDragElement["tab"]]: (widgetProps: WidgetProps) => {
            offsetX: number,
            offsetY: number,
        },
    };
};

const parseEventFromIframe = pipe(
    filter(([e, frame]) => frame.contentWindow === e.source),
    map<[MessageEvent, HTMLElement], WidgetEvent>(([event]) => JSON.parse(event.data)),
    share(),
);

@Component({
    selector: "app-widget-edit",
    templateUrl: "./widget-edit.component.html",
    styleUrls: [
        "./widget-edit.component.scss",
        "./widget-edit-refactored.component.scss"
    ]
})
export class WidgetEditComponent implements OnInit, AfterViewInit, OnDestroy {

    public readonly widgetInfo$ = new BehaviorSubject<IGenericWidgetInfo>(null);

    public readonly formGroup = new FormGroup({
        widgetName: new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            if (!control.value.trim()) {
                return {widgetName: [{message: "Название виджета не может быть пустым"}]};
            }
            return null;
        }),
    });

    private readonly widgetName = this.formGroup.get("widgetName");

    public hasUnsavedChanges = false;
    private isSaveInProgress = false;
    private readonly destroy$: Subject<void> = new Subject<void>();
    public widgetProps: WidgetProps;
    private readonly previewProps$ = new BehaviorSubject<WidgetProps>(null);
    private readonly previewPropsChange$: Observable<[WidgetProps, WidgetProps]> = this.previewProps$.pipe(
        pairwise(),
        filter(([prev, cur]) => !!prev && !!cur),
        shareReplay(1),
    );
    private unsavedProps: WidgetProps = null;
    private widgetsUrl: string;

    public readonly widgetUrl$ = this.widgetInfo$.pipe(
        filter(Boolean),
        map(widgetInfo => `${this.widgetService.formatWidgetLink(widgetInfo, false)}`),
        distinctUntilChanged(),
    );

    public readonly widgetPreviewUrl$: Observable<SafeUrl> = this.widgetInfo$.pipe(
        filter(Boolean),
        map(widgetInfo => `${this.widgetService.formatWidgetLink(widgetInfo, true)}`),
        distinctUntilChanged(),
        map(unsafeUrl => this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl)),
    );

    @ViewChild("saveSettingsDialog")
    private saveSettingsDialog: ConfirmationModalComponent;

    @ViewChild("widgetEditDataParams")
    private widgetEditDataParams: WidgetEditDataParamsComponent;

    @ViewChild("widgetEditVisuals")
    private widgetEditVisuals: WidgetEditVisualsComponent;

    @ViewChildren("frameContainer")
    private frameContainerQueryList: QueryList<ElementRef<HTMLElement>>;
    private readonly frameContainer$ = new ReplaySubject<HTMLElement>(1);

    @ViewChildren("sizeHelper")
    private sizeHelperQueryList: QueryList<ElementRef<HTMLElement>>;
    private readonly sizeHelper$ = new ReplaySubject<HTMLElement>(1);

    @ViewChildren(WidgetSizeControlComponent)
    private sizeControlQueryList: QueryList<ElementRef<WidgetSizeControlComponent>>;
    private readonly sizeControl$ = new ReplaySubject<WidgetSizeControlComponent>(1);

    @ViewChildren("frame")
    private frameQueryList: QueryList<ElementRef<HTMLIFrameElement>>;
    private readonly frame$ = new ReplaySubject<HTMLIFrameElement>(1);

    @ViewChildren("hiddenStaticFrameToCalculateWidgetSize")
    private hiddenStaticFrameToCalculateWidgetSizeQueryList: QueryList<ElementRef<HTMLIFrameElement>>;
    private readonly hiddenStaticFrameToCalculateWidgetSize$ = new ReplaySubject<HTMLIFrameElement>(1);

    @ViewChild("hiddenStaticFrameToCalculateWidgetSize")
    private hiddenStaticFrameToCalculateWidgetSize: ElementRef<HTMLIFrameElement>;

    @HostBinding("style.--darken-preview")
    public darkenPreview = "0";

    public readonly emitPreviewEvent$: Subject<PreviewEvent> = new Subject<PreviewEvent>();

    public banners: { [key: string]: Banner } = {
        frames: {
            widgetType: WidgetType.Goal,
            url: "https://donatty.com/pages/twitch-panel-logo#rec299318463",
            show: true,
        },
    };

    private readonly windowMessage$ = fromEvent<MessageEvent>(window, "message").pipe(
        filter<MessageEvent>(event => typeof event.data === "string"),
        share(),
    );
    private readonly frameMessage$: Observable<WidgetEvent> = this.windowMessage$.pipe(
        withLatestFrom(this.frame$),
        parseEventFromIframe,
    );
    public readonly frameLoaded$: Observable<true> = this.frameMessage$.pipe(
        filter(evt => evt.type === EventType.WidgetLoaded),
        map(() => true),
    );
    private readonly frameTabSelected$: Observable<WidgetEventSelectElement> = this.frameMessage$.pipe(
        filter(evt => evt.type === EventType.SelectTab),
        map(evt => evt.message),
    );
    private readonly frameTabDragged$: Observable<WidgetEventDragElement> = this.frameMessage$.pipe(
        filter<WidgetEvent<WidgetEventDragElement>>(evt => evt.type === EventType.Drag),
        map(evt => evt.message),
    );

    // ----------------------------------------------------------------
    // constructor

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef,
                       private readonly el: ElementRef<HTMLElement>,
                       private readonly donationsService: DonationsService,
                       private readonly pageService: PageService,
                       private readonly previewService: WidgetPreviewService,
                       private readonly route: ActivatedRoute,
                       private readonly router: Router,
                       private readonly sanitizer: DomSanitizer,
                       private readonly toastr: ToastrService,
                       private readonly userService: UserService,
                       private readonly widgetService: WidgetService,
                       private readonly dataLayerService: DataLayerService,
                       private readonly widgetFileUploadService: WidgetFileUploadService,
                       private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly localStorageService: CookiesBackedLocalStorageService,
                       public readonly widgetStoreService: WidgetStoreService) {
    }

    // ----------------------------------------------------------------
    // system calls

    public async ngOnInit(): Promise<void> {

        Object.keys(this.banners).forEach(bannerName =>
            this.banners[bannerName].show = this.localStorageService.getItem(`banners/${bannerName}/closed`) !== "true"
        );

        requestAnimationFrame(() => {
            document.querySelector("#main-content").scrollTo({behavior: "auto", top: 0});
        });

        this.frameLoaded$.pipe(take(1)).subscribe(() => this.play());
        this.frameTabSelected$.subscribe(evt => this.widgetEditVisuals.selectTab(evt.tab));

        this.emitPreviewEvent$.pipe(
            withLatestFrom(this.frame$, this.hiddenStaticFrameToCalculateWidgetSize$),
            takeUntil(this.destroy$),
        ).subscribe(([event, frame, hiddenFrame]) => {
            frame.contentWindow.postMessage(event, "*");
            const eventForHiddenIframe = {...event};
            if (eventForHiddenIframe.data?.mute?.audio === false) {
                eventForHiddenIframe.data.mute.audio = true;
            }
            if (eventForHiddenIframe.data?.mute?.voice === false) {
                eventForHiddenIframe.data.mute.voice = true;
            }
            hiddenFrame.contentWindow.postMessage(eventForHiddenIframe, "*");
        });

        this.watchFiles();
        this.watchWidgetName();
        this.watchGoalLayout();
        this.watchPreviewProps();

        this.widgetInfo$.pipe(filter(Boolean), take(1)).subscribe(widgetInfo => {
            this.dataLayerService.emit({
                eventCategory: EventCategory.Widget,
                eventAction: EventAction.View,
                eventLabel: widgetInfo.getType().toLowerCase(),
            });
        });

        this.drag$.subscribe();

        this.saveDimensionsTrigger$.pipe(
            debounceTime(800),
            withLatestFrom(this.widgetInfo$),
            switchMap(([dim, widget]) =>
                this.widgetStoreService.saveWidgetStore(widget.getId(), {dimensions: dim})
            ),
            takeUntil(this.destroy$),
        ).subscribe();

        const style = this.el.nativeElement.style;
        this.scale$.pipe(takeUntil(this.destroy$)).subscribe(frameScale => {
            style.setProperty("--frame-scale", "" + round(frameScale, 3));
            style.setProperty("--frame-counter-scale", "" + round(1 / frameScale, 3));
        });
        this.sizeHelper$.subscribe(sh => this.sizeHelperObserver.observe(sh));
        this.destroy$.subscribe(() => this.sizeHelperObserver.disconnect());

        this.reloadSettingsData();
    }

    public ngAfterViewInit() {
        this.querylistToSubject(this.frameContainerQueryList, this.frameContainer$);
        this.querylistToSubject(this.sizeHelperQueryList, this.sizeHelper$);
        this.sizeControlQueryList.changes.subscribe(c => this.sizeControl$.next(c.first));
        this.querylistToSubject(this.hiddenStaticFrameToCalculateWidgetSizeQueryList, this.hiddenStaticFrameToCalculateWidgetSize$);
        this.querylistToSubject(this.frameQueryList, this.frame$);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.widgetInfo$.complete();
    }

    private watchFiles() {
        this.widgetFileUploadService.fileDataSubject$
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.widgetProps.data[data.tag] = [data.dataUrl];
                this.refreshWidgetProps(this.widgetProps);
            });

        this.widgetFileUploadService.fileUploadedSubject$
            .pipe(takeUntil(this.destroy$))
            .subscribe(file => this.updateMediaUri(file.tag, [file.uri]));

        this.widgetFileUploadService.uploadErrorSubject$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.toastr.error("Не удалось загрузить файл"));
    }

    public get hasEditableDataParams(): boolean {
        return this.widgetSettingsService.hasEditableDataParams(this.widgetProps?.data?.sources.accounts);
    }

    public onEventSourcesChange(newValue: WidgetEventSources) {
        this.widgetProps.data.sources = newValue;
        this.widgetSettingsService.eventSourcePropsChanged$.next(this.widgetProps);
        this.onWidgetPropsChange();
    }

    public onWidgetPropsDataChanged(newValue: WidgetPropsData) {
        this.widgetProps.data = newValue;
        this.onWidgetPropsChange();
    }

    public onWidgetPropsChange() {
        const isPreviewPropertiesChanged = !_.isEqual(this.previewProps$.value, this.widgetProps);
        if (isPreviewPropertiesChanged) {
            this.refreshWidgetProps(this.widgetProps);
        }

        this.detectPropertiesChanged();
    }

    public async cancelChanges(): Promise<void> {
        this.widgetFileUploadService.abortWidgetUploads(this.widgetInfo$.value.getId());

        await this.reloadSettingsData().catch(e => console.error("reloadSettingsData, failed, e=", e));
        this.refreshWidgetProps(this.widgetProps);
        this.hasUnsavedChanges = false;
    }

    public close() {
        const showConfirmationDialog = (!this.isSaveInProgress && this.hasUnsavedChanges);
        if (showConfirmationDialog) {
            this.saveSettingsDialog.show();
            return;
        }

        this.router.navigateByUrl(this.widgetsUrl);
    }

    public imageChanged(newFile: File): void {
        console.log("WidgetEditComponent.imageChanged");

        if (!newFile) {
            this.updateMediaUri("imageUri", []);
            return;
        }

        this.widgetFileUploadService.uploadFile(
            this.widgetInfo$.value.getId(),
            "imageUri",
            WidgetUploadFileType.Image,
            newFile);
    }

    public audioChanged(newFile: File): void {
        console.log("WidgetEditComponent.audioChanged");

        if (!newFile) {
            this.updateMediaUri("audioUri", []);
            return;
        }

        this.widgetFileUploadService.uploadFile(
            this.widgetInfo$.value.getId(),
            "audioUri",
            WidgetUploadFileType.Audio,
            newFile);
    }

    public async omitChanges(): Promise<void> {
        await this.router.navigateByUrl(this.widgetsUrl);
    }

    private resetInvalidValues() {
        if (!this.widgetName.value.trim()) {
            this.widgetName.setValue(this.widgetInfo$.value.getName().trim());
        }
        if (!this.widgetEditDataParams.isValid) {
            this.widgetEditDataParams.resetInvalidValues();
        }
    }

    public async saveChanges(): Promise<boolean> {
        if (!this.widgetName.value) {
            this.toastr.error("Имя виджета не может быть пустым");
            return false;
        }

        try {
            this.isSaveInProgress = true;
            if (!this.isValid) {
                this.resetInvalidValues();
            }
            await this.widgetFileUploadService.waitForUploadCompletion(this.widgetInfo$.value.getId());
            this.widgetInfo$.value.setName(this.widgetName.value);
            this.widgetInfo$.value.setGenericProps(this.widgetProps);
            this.widgetInfo$.next(await this.widgetService.update(this.widgetInfo$.value));
            this.unsavedProps = this.widgetInfo$.value.getGenericProps();
            this.hasUnsavedChanges = false;
            this.toastr.success("Настройки виджета сохранены");
            return true;
        } catch (e) {
            console.log("WidgetEditComponent.saveChanges, failed, e=", e);
            this.toastr.error("Не удалось сохранить настройки виджета");
            return false;
        } finally {
            this.isSaveInProgress = false;
        }
    }

    public async saveChangesAndExit() {
        if (await this.saveChanges()) {
            await this.router.navigateByUrl(this.widgetsUrl);
        }
    }

    private readonly mockGenerator$: Observable<WidgetTestGenerator<WidgetProps>> = this.widgetInfo$.pipe(
        filter<IGenericWidgetInfo>(Boolean),
        map(widgetInfo => widgetInfo.getType()),
        distinctUntilChanged(),
        map(widgetType => this.previewService.generateTestData[widgetType]),
    );

    private readonly resetPreviewPropsGenerator$: Subject<void> = new Subject();

    public play() {
        this.resetPreviewPropsGenerator$.next();
        this.mockGenerator$.pipe(
            switchMap(generate => generate(this.previewProps$, {isAudioMuted: true, isVoiceMuted: true})),
            takeUntil(merge(this.destroy$, this.resetPreviewPropsGenerator$)),
        ).subscribe(data => this.emitPreviewEvent$.next({action: "DATA", data}));
    }

    public playAudio() {
        this.resetPreviewPropsGenerator$.next();
        this.mockGenerator$.pipe(
            switchMap(generate => generate(this.previewProps$, {isAudioMuted: false, isVoiceMuted: false})),
            take(1),
        ).subscribe(data => this.emitPreviewEvent$.next({action: "DATA", data}));
    }

    public testOnStream() {
        this.mockGenerator$.pipe(
            switchMap(gen => gen(this.previewProps$, {isAudioMuted: false, isVoiceMuted: false})),
            take(1),
        ).subscribe(testData =>
            this.widgetService.emitEvent(this.widgetInfo$.value.getId(), "DATA", testData)
        );
    }

    public get isValid(): boolean {
        if (this.widgetName.invalid) {
            return false;
        } else if (this.widgetEditDataParams && !this.widgetEditDataParams.isValid) {
            return false;
        } else if (this.widgetEditVisuals && !this.widgetEditVisuals.isValid) {
            return false;
        }
        return true;
    }

    private detectPropertiesChanged(): void {
        const initialValue = this.hasUnsavedChanges;

        if (this.unsavedProps === null) {
            this.hasUnsavedChanges = false;
        } else {
            this.hasUnsavedChanges = !_.isEqual(this.unsavedProps, this.widgetProps);
        }

        if (initialValue !== this.hasUnsavedChanges) {
            this.changeDetectorRef.detectChanges();
        }
    }

    private refreshWidgetProps(props: WidgetProps) {
        this.previewProps$.next(_.cloneDeep(props));
    }

    private async reloadSettingsData(): Promise<void> {
        const widgetInfo = await this.widgetService.get(this.route.snapshot.params.id);

        if (widgetInfo.isGoalWidget()) {
            // TODO: Return data.goalFrom from backend (?)
            const newProps = widgetInfo.getProps();

            const targetRefId = newProps.data.refId;
            const goal = await this.donationsService.getDonationTarget(targetRefId);
            newProps.data.goalFrom = goal.collected;

            widgetInfo.setGenericProps(newProps);
        }
        this.widgetInfo$.next(widgetInfo);
        this.widgetsUrl = `/widgets#widget-${this.widgetInfo$.value.getId()}`;
        this.widgetProps = this.widgetInfo$.value.getGenericProps();

        this.widgetSettingsService.eventSourcePropsChanged$.next(this.widgetInfo$.value.getGenericProps());
        this.unsavedProps = _.cloneDeep(this.widgetProps);
        this.onWidgetPropsChange();
        this.widgetName.setValue(this.widgetInfo$.value.getName().trim());
        this.pageService.setPageTitle(this.widgetName.value);
    }

    private updateMediaUri(propName: string, uri: string[]): void {
        this.widgetProps.data[propName] = uri;
        this.hasUnsavedChanges = true;
    }

    public openBanner(bannerName: string): void {
        window.open(this.banners[bannerName].url, "_blank")?.focus();
    }

    public closeBanner(banner: string): void {
        this.localStorageService.setItem(`banners/${banner}/closed`, "" + true);
        this.banners[banner].show = false;
    }

    private querylistToSubject<T>(queryList: QueryList<ElementRef<T>>, subj: Subject<T>) {
        if (queryList.first) {
            subj.next(queryList.first.nativeElement);
        }
        queryList.changes.pipe(
            map(ql => ql.first?.nativeElement), filter(Boolean), takeUntil(this.destroy$),
        ).subscribe(el => subj.next(el));
    }

    private watchWidgetName() {
        this.widgetName.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(name => {
                this.pageService.setPageTitle(name);
                if (this.widgetName.value.trim() !== this.widgetInfo$.value.getName()) {
                    this.hasUnsavedChanges = true;
                } else {
                    this.detectPropertiesChanged();
                }
            });
    }

    private watchGoalLayout() {
        const goalHeaderAlignByLayout: Array<GoalWidget["props"]["style"]["goal"]["font"]["align"]> = [
            "left", "right", "left", "left", "center", "center",
        ];
        this.widgetInfo$.pipe(
            filter(widget => widget?.getType() === WidgetType.Goal),
            switchMap(() => this.previewPropsChange$.pipe(
                filter(([prev, cur]) => prev.style?.layout !== cur.style?.layout),
            )),
            takeUntil(this.destroy$),
        ).subscribe(() => {
            const style = (this.widgetProps as GoalWidget["props"]).style;
            style.goal.font.align = goalHeaderAlignByLayout[style.layout];
            this.onWidgetPropsChange();
        });
    }

    private watchPreviewProps() {
        this.previewProps$.pipe(
            filter(props => !!props),
            map(props => ({name: this.widgetName.value.trim(), props})),
            takeUntil(this.destroy$),
        ).subscribe(data => this.emitPreviewEvent$.next({action: "REFRESH", data}));
    }

    public readonly defaultDimensions = this.widgetStoreService.defaultState.dimensions;
    private readonly loadDimensions$: Observable<WidgetDimensions> = this.widgetInfo$.pipe(
        filter(Boolean),
        switchMap(widget => this.widgetStoreService.loadWidgetStore(widget.getId()).pipe(
            map(store => store.dimensions)
        )),
    );

    public readonly dimensionsByUser$: Subject<WidgetDimensions> = new ReplaySubject(1);
    private readonly dimensionsByPreview$: Observable<WidgetDimensions> = this.windowMessage$.pipe(
        withLatestFrom(this.hiddenStaticFrameToCalculateWidgetSize$),
        parseEventFromIframe,
        filter(evt => evt.type === EventType.WidgetLayoutChanged),
        map(event => event.message),
        map(event => ({
            width: Math.round(event.right - event.left),
            height: Math.round(event.bottom - event.top),
            autoDetect: true,
        })),
        shareReplay(1),
    );

    private readonly saveDimensionsTrigger$: Observable<WidgetDimensions> = combineLatest([
        this.dimensionsByUser$, this.dimensionsByPreview$
    ]).pipe(
        switchMap(([dim]) => dim.autoDetect ? this.dimensionsByPreview$ : this.dimensionsByUser$),
        distinctUntilChanged((prev, cur) =>
            prev.autoDetect === cur.autoDetect &&
            prev.width === cur.width &&
            prev.height === cur.height
        ),
    );

    public readonly savedDimensions$: Observable<WidgetDimensions> = merge(
        this.loadDimensions$,
        this.saveDimensionsTrigger$,
    ).pipe(
        filter(Boolean),
        shareReplay(1),
        takeUntil(this.destroy$),
    );

    private readonly sizeHelperSize$ = new ReplaySubject<{ width: number, height: number }>(1);
    private readonly sizeHelperObserver = new ResizeObserver(e => this.sizeHelperSize$.next({
        width: e[0].contentRect.width, height: e[0].contentRect.height,
    }));

    private readonly scale$: Observable<number> = combineLatest([
        this.savedDimensions$,
        this.frameContainer$,
        this.sizeHelperSize$.pipe(startWith({width: 0, height: 0})),
        fromEvent(window, "resize").pipe(startWith(0)),
    ]).pipe(
        map(([dim, frameContainer, helperSize]) => this.calcScale(dim, frameContainer, helperSize)),
        distinctUntilChanged(),
        shareReplay(1),
    );

    public readonly frameStyle$: Observable<Partial<CSSStyleDeclaration>> = this.savedDimensions$.pipe(
        map(dim => ({
            width: `${dim.width}px`, height: `${dim.height}px`,
            minWidth: `${dim.width}px`, minHeight: `${dim.height}px`,
        })),
    );

    private calcScale(res: WidgetDimensions, container: HTMLElement, helperSize: { width: number, height: number }): number {
        const MARGIN = 104 * 2;
        const hRatio = (container.clientWidth - MARGIN) / Math.max(helperSize.width, res.width);
        const vRatio = (container.clientHeight - MARGIN) / Math.max(helperSize.height, res.height);
        return Math.min(1, hRatio, vRatio);
    }

    private readonly dragProps: DragProps = {
        [WidgetType.Alert]: {
            image: (props: AlertWidget["props"]) => props.style.image,
            header: (props: AlertWidget["props"]) => props.style.header.geometry,
            text: (props: AlertWidget["props"]) => props.style.message.geometry,
        },
        [WidgetType.Goal]: {
            header: (props: GoalWidget["props"]) => props.style.goal.geometry,
            goal: (props: GoalWidget["props"]) => props.style.progressBar.geometry,
            countdown: (props: GoalWidget["props"]) => props.style.countdown.geometry,
        },
        [WidgetType.Top]: {
            name: (props: TopWidget["props"]) => props.style.subscriberNick.geometry,
            sum: (props: TopWidget["props"]) => props.style.subscriberAmount.geometry,
            header: (props: TopWidget["props"]) => props.style.header.geometry,
        },
        [WidgetType.Total]: {
            header: (props: TotalWidget["props"]) => props.style.header.geometry,
            sum: (props: TotalWidget["props"]) => props.style.donationSum.geometry,
        },
    };

    private readonly drag$ = this.widgetInfo$.pipe(
        filter(Boolean),
        combineLatestWith(this.frameTabDragged$.pipe(
            combineLatestWith(this.scale$),
            map(([dragEvent, scale]) => {
                dragEvent.event.movementX = Math.round(dragEvent.event.movementX / scale);
                dragEvent.event.movementY = Math.round(dragEvent.event.movementY / scale);
                return dragEvent;
            }),
        )),
        tap(([widgetInfo, dragEvent]) => {
            const prop = this.dragProps[widgetInfo.getType()][dragEvent.tab](this.widgetProps);
            prop.offsetX += dragEvent.event.movementX;
            prop.offsetY += dragEvent.event.movementY;
            this.refreshWidgetProps(this.widgetProps);
            this.detectPropertiesChanged();
        }),
        share(),
        takeUntil(this.destroy$),
    );

}

const round = (n: number, digits: number, power = 10 ** digits) => Math.round((n + Number.EPSILON) * power) / power;
