<ng-container [ngSwitch]="type">
    <app-widget-visuals-goal #visuals
                             *ngSwitchCase="WidgetType.Goal"
                             [(widgetProps)]="widgetProps"
                             (widgetPropsChange)="widgetPropsChange.emit($event)">
    </app-widget-visuals-goal>
    <app-widget-visuals-donation #visuals
                                 *ngSwitchCase="WidgetType.Alert"
                                 [(widgetProps)]="widgetProps"
                                 (widgetPropsChange)="widgetPropsChange.emit($event)"
                                 (imageChanged)="imageChanged.emit($event)"
                                 (audioChanged)="audioChanged.emit($event)">
    </app-widget-visuals-donation>

    <app-widget-visuals-top #visuals
                            *ngSwitchCase="WidgetType.Top"
                            [(widgetProps)]="widgetProps"
                            (widgetPropsChange)="widgetPropsChange.emit($event)">
    </app-widget-visuals-top>

    <app-widget-visuals-total #visuals
                              *ngSwitchCase="WidgetType.Total"
                              [(widgetProps)]="widgetProps"
                              (widgetPropsChange)="widgetPropsChange.emit($event)">
    </app-widget-visuals-total>

    <app-widget-visuals-events #visuals
                               *ngSwitchCase="WidgetType.Events"
                               [widgetId]="widgetId"
                               [(widgetProps)]="widgetProps"
                               (widgetPropsChange)="widgetPropsChange.emit($event)"
                               (imageChanged)="imageChanged.emit($event)"
                               (audioChanged)="audioChanged.emit($event)">
    </app-widget-visuals-events>
</ng-container>
