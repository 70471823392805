import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../base.service";
import {merge, Observable, of, ReplaySubject, Subscription, zip} from "rxjs";
import {catchError, filter, first, map, shareReplay, switchMap, take} from "rxjs/operators";
import {User} from "../../../../../shared/src/lib/models/user";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {CookiePolyfillService} from "../../../../../shared/src/lib/cookie-polyfill.service";
import {AbstractControl, FormControl, ValidationErrors} from "@angular/forms";
import {PlatformInfoService} from "../../../../../shared/src/lib/platform-info.service";
import {UserExtraResponse, UserResponse} from "./user.api";

type UserExtra = {
    email: string,
    donationsCount: number,
    verified: boolean,
    oauthProvider: string,
};

export const USER_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;

@Injectable({
    providedIn: "root"
})
export class UserService extends BaseService {

    public readonly currentUser$: ReplaySubject<User> = new ReplaySubject(1);
    public readonly permissions$: Observable<boolean> = this.currentUser$.pipe(
        filter<User>(Boolean),
        switchMap(() => merge(
            this.getSecurity(),
            this.authService.authStatus$.pipe(filter(status => !status), map(() => false))
        )),
        shareReplay(1),
    );
    public readonly currentUserWithPermissions$ = zip(
        this.currentUser$.pipe(filter<User>(Boolean)),
        this.permissions$.pipe(filter<boolean>(Boolean)),
    ).pipe(map(([user]) => user), shareReplay(1));

    private readonly baseUri = this.environmentService.backendApiUri + "/users";

    constructor(http: HttpClient,
                authService: AuthService,
                private readonly platformInfoService: PlatformInfoService,
                private readonly cookieService: CookiePolyfillService,
                private readonly dataLayerService: DataLayerService,
                private readonly environmentService: EnvironmentService) {
        super(authService, http);
        this.serviceUrl = "users";
        authService.authStatus$.subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.fetchUser();
            } else {
                this.currentUser$.next(null);
            }
        });

        this.currentUserWithPermissions$
            .pipe(take(1))
            .subscribe(async user => {
                const userExtra = await this.getUserExtra().toPromise();
                this.initDataLayer(user, userExtra);
            });

        this.currentUser$
            .pipe(filter(user => !!user))
            .subscribe(user => this.userNameFormControl.setValue(user.name));
    }

    private getCurrentUserSubscription: Subscription;

    async uploadPicture(file: File): Promise<string> {
        return await this.uploadFile("users/me/picture", file);
    }

    async uploadCover(file: File): Promise<string> {
        return await this.uploadFile("users/me/cover", file);
    }

    private async isNameAvailable(name: string): Promise<boolean> {
        try {
            return !await this.getRequest(`find/${name}`, this.getCurrentUserSubscription);
        } catch (e) {
            if (e.status === 404) {
                return true;
            }

            throw e;
        }
    }

    async save(user: User): Promise<User> {
        await this.putRequest("me", user.cloneModel());
        this.currentUser$.next(user);
        return user;
    }

    private getMe() {
        return this.http.get<UserResponse>(`${this.baseUri}/me`, this.authService.makeTokenAuthHeaders())
            .pipe(map(userResponse => new User(this.environmentService, userResponse.response)));
    }

    public fetchUser() {
        this.getMe().subscribe(me => {
            this.currentUser$.next(me);
        });
    }

    private getSecurity(): Observable<boolean> {
        return this.http.get<SecurityInfoResponse>(
            `${this.baseUri}/me/security`, this.authService.makeTokenAuthHeaders()
        ).pipe(
            map(securityInfo => securityInfo.response.permissions.isNickUpdateAllowed),
            catchError(() => of(false)),
        );
    }

    private userNameDebounce: number;
    public userNameFormControl = new FormControl<string>("", (control: AbstractControl): ValidationErrors => {
            const errors: any = {};
            clearTimeout(this.userNameDebounce);
            if (!control.value) {
                errors.addressEmpty = true;
            } else if (!USER_NAME_REGEX.test(control.value)) {
                errors.addressInvalid = true;
            }
            if (Object.keys(errors).length) {
                return errors;
            }
            return null;
        },
        (() => {
            const timeout = 1000;
            return async (control: AbstractControl): Promise<ValidationErrors> => {
                const currentUser = await this.currentUser$.pipe(first()).toPromise();
                const isDifferentUsername = (!control.value || control.value !== currentUser.name);
                if (!isDifferentUsername) {
                    return null;
                }
                return new Promise(resolve => {
                    this.userNameDebounce = window.setTimeout(async () => {
                        const isNameAvailable = await this.isNameAvailable(control.value);
                        if (isNameAvailable) {
                            resolve(null);
                        }
                        resolve({
                            addressOccupied: true,
                        });
                    }, timeout);
                });
            };
        })(),
    );

    private getUserExtra(): Observable<any> {
        const url = `${this.baseUri}/me/info`;
        return this.http.get<UserExtraResponse>(url, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response));
    }

    private async initDataLayer(user: User, userExtra: UserExtra): Promise<void> {
        this.dataLayerService.emitRaw({
            userId: user.id,
            CarrotUserIdHash: user.carrotUserIdHash,
            displayName: user.displayName,
            donationPage: `donatty.com/${user.name}`,
            picture: user.avatarUrl,
            vk: user.vkUrl,
            youtube: user.youtubeUrl,
            twitch: user.twitchUrl,
            facebook: user.facebookUrl,
            rovo: user.trovoUrl,
            registratedAt: Math.round(new Date(user.registeredAt).getTime() / 1000),
            walletNumber: user.externalPayAccountId,
            email: userExtra?.email,
            donationsCount: userExtra?.donationsCount,
            verified: userExtra?.verified,
        });

        const isNewUser = !!this.cookieService.getCookie("new-user");
        if (isNewUser) {
            this.cookieService.deleteCookie("new-user");
        }

        this.dataLayerService.emit({
            eventAction: isNewUser ? EventAction.Registration : EventAction.Login,
            eventCategory: EventCategory.Panel,
            eventLabel: userExtra?.oauthProvider?.toLowerCase() || "unknown",
            eventValue: "success"
        });

        // @ts-ignore
        dataLayer.push({event: "application-load-success", eventValue: user.refId});
    }

}

interface SecurityInfoResponse {
    response: {
        permissions: {
            isNickUpdateAllowed: boolean
        }
    };
}
