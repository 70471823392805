import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {SpeechService} from "../../../../../../../../../shared/src/lib/services/speech/speech.service";
import {Voice} from "../../../../../../../../../shared/src/lib/services/speech/voice";
import {AlertWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";
import * as _ from "lodash";
import {ProfanityService} from "../../../../../../modules/profanity/profanity.servoce";
import {defaultAlertProps} from "../../../../../../default-widget-settings/defaultAlertProps";
import {TooltipPosition} from "../../../../../../../../../shared/src/lib/popup/popup.component";

type Sequence = { value: string, text: string };

@Component({
    selector: "app-widget-donations-tab-props",
    templateUrl: "./widget-donations-tab-props.component.html",
    styleUrls: ["./widget-donations-tab-props.component.scss"]
})
export class WidgetDonationsTabPropsComponent implements OnInit {

    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    public voices: Array<Voice> = [];

    public sequences: Array<Sequence> = [
        {value: "AFTER", text: "Речь после звука оповещения"},
        {value: "BEFORE", text: "Речь перед звуком оповещения"},
        {value: "SIMULTANEOUSLY", text: "Речь во время звука оповещения"},
    ];

    public caretPosition: number;

    public readonly TooltipPosition = TooltipPosition;
    private _selectedVoices: Array<Voice>;

    public constructor(private readonly speechService: SpeechService,
                       public readonly profanityService: ProfanityService) {
    }

    public async ngOnInit() {
        this.voices = await this.speechService.listVoices();
        let settingsVoices = this.widgetProps.data.speechSynthesis.voices;
        if (!settingsVoices.length) {
            settingsVoices = defaultAlertProps.data.speechSynthesis.voices;
        }
        // Temporary precaution
        settingsVoices = settingsVoices.map(voiceId => +voiceId);
        this._selectedVoices = this.voices.filter(voice => settingsVoices.includes(voice.id));
        this.caretPosition = this.widgetProps.data.speechSynthesis.template.length;
        this.profanityService.fetchProfanity();
    }

    public set selectedVoices(selectedVoices: Array<Voice>) {
        const oldValue = this.widgetProps.data.speechSynthesis.voices;
        if (_.isEqual(oldValue, selectedVoices)) {
            return;
        }
        this._selectedVoices = selectedVoices;
        _.set(this.widgetProps.data, "speechSynthesis.voices", selectedVoices.map(voice => voice.id));
        this.widgetPropsChange.emit(this.widgetProps);
    }

    public get selectedVoices(): Array<Voice> {
        return this._selectedVoices;
    }

    public get selectedSequence() {
        return this.sequences.find(
            sequence => sequence.value === this.widgetProps.data.speechSynthesis.sequence
        );
    }

    public set selectedSequence(item: Sequence) {
        const oldValue = this.widgetProps.data.speechSynthesis?.sequence ?? "";
        const newValue = item.value;

        _.set(this.widgetProps.data, "speechSynthesis.sequence", newValue);

        if (newValue !== oldValue) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get speechSynthesisSpeed(): number {
        return this.widgetProps.data.speechSynthesis?.speed ?? 0;
    }

    public set speechSynthesisSpeed(newValue: number) {
        const oldValue = this.speechSynthesisSpeed;
        _.set(this.widgetProps.data, "speechSynthesis.speed", newValue);

        if (newValue !== oldValue) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public onTagClicked(tag: string) {
        const tagTemplate = `{${tag}}`;
        this.widgetProps.data.speechSynthesis.template = [
            this.widgetProps.data.speechSynthesis.template.slice(0, this.caretPosition),
            tagTemplate,
            this.widgetProps.data.speechSynthesis.template.slice(this.caretPosition)
        ].join("");
        this.caretPosition += tagTemplate.length;
        this.widgetPropsChange.next(this.widgetProps);
    }

}
