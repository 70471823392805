<div class="we__tab-container">
    <div class="we__header">
        Продолжительность оповещения
    </div>

    <div class="we__row">
        <div class="we__label">
            Продолжительность оповещения на стриме
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100"
                               [(value)]="widgetProps.data.showDurationSeconds"
                               (valueChange)="widgetProps.data.showDurationSeconds = $event; widgetPropsChange.emit(widgetProps)"
                               unit="сек.">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Задержка появления текста
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="widgetProps.data.showDurationSeconds || .0001"
                               [value]="widgetProps.data.textDelaySeconds || 0"
                               (valueChange)="widgetProps.data.textDelaySeconds = $event; widgetPropsChange.emit(widgetProps)"
                               unit="сек.">
            </app-single-slider>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Фильтры
        <mat-icon class="help" svgIcon="question"
                  (mouseenter)="infoTooltip.show($event.target)"
                  (mouseleave)="infoTooltip.hide()"></mat-icon>
        <lib-tooltip #infoTooltip [position]="TooltipPosition.BottomLeft">
            Настроить фильтр по плохим словам<br>
            и слово-замену можно в настройках
        </lib-tooltip>
    </div>

    <div class="we__row" hidden>
        <div class="we__label">
            Отображать эмодзи
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="!widgetProps.data.filters.emoji"
                              (change)="widgetProps.data.filters.emoji = !$event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
            <span *ngIf="widgetProps.data.filters.emoji">Эмодзи будут отображаться</span>
            <span *ngIf="!widgetProps.data.filters.emoji">Эмодзи не будут отображаться</span>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать ссылки
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.data.filters.links"
                              (change)="widgetProps.data.filters.links = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
            <div class="we__controls">
                <span *ngIf="widgetProps.data.filters.links">Ссылки не будут отображаться</span>
                <span *ngIf="!widgetProps.data.filters.links">Ссылки будут отображаться</span>
            </div>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать мат
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings filterType="automatic"
                                           [widgetProps]="widgetProps"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать по&nbsp;плохим&nbsp;словам
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings filterType="custom"
                                           [widgetProps]="widgetProps"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Озвучивание голосом
    </div>

    <div class="we__row">
        <div class="we__label">
            Включить озвучивание
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.data.speechSynthesis.enable"
                              (change)="widgetProps.data.speechSynthesis.enable = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.data.speechSynthesis.enable">
        <div class="we__row">
            <div class="we__label">
                Последовательность
            </div>
            <div class="we__controls">
                <app-dropdown [(value)]="selectedSequence"
                              (valueChange)="widgetProps.data.speechSynthesis.sequence = $event.value; widgetPropsChange.emit(widgetProps)"
                              [items]="sequences"></app-dropdown>
            </div>
        </div>

        <div class="we__template-row">
            <div class="we__row">
                <div class="we__label">
                    Шаблон для озвучивания
                </div>
                <div class="we__controls">
                    <input [ngModel]="widgetProps.data.speechSynthesis.template"
                           (ngModelChange)="widgetProps.data.speechSynthesis.template = $event; widgetPropsChange.emit(widgetProps)"
                           #speechSynthesisTemplate
                           (keyup)="caretPosition = speechSynthesisTemplate.selectionStart"
                           (click)="caretPosition = speechSynthesisTemplate.selectionStart"
                           (focusin)="caretPosition = speechSynthesisTemplate.selectionStart"
                           type="text">
                </div>
            </div>
            <div class="we__row">
                <div class="we__label"></div>
                <div class="we__controls">
                    <app-widget-help-tags [widgetPropsData]="widgetProps.data"
                                          (tagClick)="onTagClicked($event)">
                    </app-widget-help-tags>
                </div>
            </div>
        </div>
        <div class="we__template-row">
            <div class="we__row">
                <div class="we__label">
                    Голос
                </div>
                <div class="we__controls">
                    <app-voice-selector [(selectedVoices)]="selectedVoices"
                                        [voices]="voices"></app-voice-selector>
                </div>
            </div>
            <div class="we__row">
                <div class="we__label"></div>
                <div class="we__controls"></div>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Скорость речи
            </div>
            <div class="we__controls">
                <app-single-slider [(value)]="speechSynthesisSpeed"
                               [min]="0.5" [max]="3" [step]="0.5" unit=""></app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Громкость речи
            </div>
            <div class="we__controls">
                <app-single-slider [(value)]="widgetProps.data.speechSynthesis.volume"
                               (valueChange)="widgetProps.data.speechSynthesis.volume = $event; widgetPropsChange.emit(widgetProps)"
                               [min]="0" [max]="100" unit="%"></app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Задержка речи
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="widgetProps.data.showDurationSeconds || .0001"
                                   [value]="widgetProps.data.speechDelaySeconds || 0"
                                   (valueChange)="widgetProps.data.speechDelaySeconds = $event; widgetPropsChange.emit(widgetProps)"
                                   unit="сек.">
                </app-single-slider>
            </div>
        </div>
    </ng-container>
</div>
