<div id="add-social-dialog"
     class="dlg__fade"
     [ngClass]="{'dlg__fade--visible': isShown, 'backdrop-workaround': useBackdropWorkaround}"
     (click)="onFadeClick($event)">
    <div class="dlg__body">
        <header>
            <h4>Доступные для подключения сервисы</h4>
            <div (click)="onClose($event)" class="close__icon">
                <mat-icon svgIcon="close"></mat-icon>
            </div>
        </header>
        <section>
            <button (click)="addSocial.emit(AccountType.Twitch)" class="twitch" mat-flat-button>
                <mat-icon svgIcon="logo-twitch"></mat-icon>
                Подключить Twitch
            </button>
            <button (click)="addSocial.emit(AccountType.Youtube)" class="youtube" mat-flat-button>
                <mat-icon svgIcon="logo-youtube"></mat-icon>
                Подключить YouTube
            </button>
            <button (click)="addSocial.emit(AccountType.Trovo)" class="trovo" mat-flat-button>
                <mat-icon svgIcon="logo-trovo"></mat-icon>
                Подключить Trovo
            </button>
        </section>
    </div>
</div>
