import {IGenericWidgetInfo} from "../../../../../../shared/src/lib/models/widget";

export enum WidgetGroupType {
    Common = "common",
    Ungrouped = "ungrouped",
    DonationPages = "donation-pages"
}

export enum WidgetGroupSequence {
    Serial = "SERIAL",
    Parallel = "PARALLEL",
}

export interface WidgetGroup {
    id: string;
    name: string;
    type: WidgetGroupType;
    isExpanded: boolean;
    widgets: Array<IGenericWidgetInfo>;
    token: string;
    sequence: WidgetGroupSequence;
    isAlertGroup: boolean;
    isHidden: boolean;
    weight: number;
}
