<div class="we__tab-container">
    <div class="we__header">
        Настройка списка
    </div>

    <div class="we__row">
        <div class="we__label">
            Количество строк
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listSettings.rowCount"
                        (input)="widgetProps.style.listSettings.rowCount = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="1" [max]="10" [step]="1"
                        thumbLabel></mat-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Расстояние между строк
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listSettings.lineSpacing"
                        (input)="widgetProps.style.listSettings.lineSpacing = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="100" [step]="1"
                        thumbLabel [displayWith]="formatLabel('%')">
            </mat-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Высота фона данных
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listSettings.backgroundHeight"
                        (input)="widgetProps.style.listSettings.backgroundHeight = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="100" [step]="1"
                        thumbLabel [displayWith]="formatLabel('%')"></mat-slider>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="we__header">
        Анимация списка
    </div>

    <div class="we__row">
        <div class="we__label">
            Анимация появления событий
        </div>
        <div class="we__controls">
            <mat-form-field appearance="outline">
                <mat-select [disableOptionCentering]="true"
                            [(value)]="widgetProps.style.listAnimation.appearance"
                            (selectionChange)="widgetPropsChange.emit(widgetProps)">
                    <mat-option *ngFor="let option of appearanceOptions" [value]="option.value">
                        {{option.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Анимация затухания событий
        </div>
        <div class="we__controls">
            <mat-form-field appearance="outline">
                <mat-select [disableOptionCentering]="true"
                            [(value)]="widgetProps.style.listAnimation.fading"
                            (selectionChange)="widgetPropsChange.emit(widgetProps)">
                    <mat-option *ngFor="let option of fadingOptions" [value]="option.value">
                        {{option.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Продолжительность анимации
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listAnimation.speed"
                        (input)="widgetProps.style.listAnimation.speed = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="3" [step]=".25"
                        thumbLabel [displayWith]="formatLabel('&nbsp;сек.')"></mat-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Скрытие виджета
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listAnimation.displayTime"
                        (input)="widgetProps.style.listAnimation.displayTime = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="60" [step]="1"
                        thumbLabel [displayWith]="formatLabel('&nbsp;сек.')"></mat-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label"></div>
        <div class="we__controls">
            <mat-hint class="hint">
                Выберете время после которого виджет будет ичезать. При&nbsp;значении 0 виджет всегда будет
                отображаться.
            </mat-hint>
        </div>
    </div>
    <div class="we__row">
        <div class="we__label">
            Затухание событий
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.listAnimation.fade"
                        (input)="widgetProps.style.listAnimation.fade = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="1" [step]=".01"></mat-slider>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="we__header">
        Скругление углов
    </div>

    <div class="we__row">
        <div class="we__label">
            Скругление фона данных
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.backgroundRounding"
                        (input)="widgetProps.style.backgroundRounding = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="0" [max]="100" [step]="1"
                        thumbLabel [displayWith]="formatLabel('%')"></mat-slider>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="we__header">
        Фильтры
    </div>

    <div class="we__row" hidden>
        <div class="we__label">
            Фильтровать эмодзи
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.data.filters.emoji"
                              (change)="widgetProps.data.filters.emoji = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
            <span *ngIf="!widgetProps.data.filters.emoji">Эмодзи будут отображаться</span>
            <span *ngIf="widgetProps.data.filters.emoji">Эмодзи не будут отображаться</span>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать ссылки
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.data.filters.links"
                              (change)="widgetProps.data.filters.links = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
            <div class="we__controls">
                <span *ngIf="!widgetProps.data.filters.links">Ссылки будут отображаться</span>
                <span *ngIf="widgetProps.data.filters.links">Ссылки не будут отображаться</span>
            </div>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать мат
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings filterType="automatic"
                                           [widgetProps]="widgetProps"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать по&nbsp;плохим&nbsp;словам
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings filterType="custom"
                                           [widgetProps]="widgetProps"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="we__header">
        Основные цвета
    </div>

    <div class="we__row">
        <div class="we__label">
            Цвет фона данных
        </div>
        <div class="we__controls">
            <app-color-select [(color)]="widgetProps.style.colors.backgroundColor"
                              (colorChange)="widgetPropsChange.emit(widgetProps)">
            </app-color-select>
        </div>
    </div>
</div>
