import {Component, EventEmitter, Inject, Input, Output, ViewChild} from "@angular/core";
import {PopupComponent} from "../../../../../../../shared/src/lib/popup/popup.component";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {
    disabledStreamerEvents, STREAMER_EVENT_TYPES_ALL, StreamerEventType
} from "../../../../services/events/streamer-events.api";
import {MatMenu} from "@angular/material/menu";

export interface IFilterSettings {
    enabledEvents: StreamerEventType[];
    showTargets: boolean;
    showMedia: boolean;
    version?: number;
}

const filterDisabled = e => !disabledStreamerEvents[e.type];

@Component({
    selector: "app-events-filter-popup",
    templateUrl: "./events-filter-popup.component.html",
    styleUrls: ["./events-filter-popup.component.scss"]
})
export class EventsFilterPopupComponent {
    @Input()
    public mobile = false;

    @Output()
    public readonly settingsChanged = new EventEmitter<IFilterSettings>();

    public readonly settings: IFilterSettings;

    private readonly TWITCH_ITEMS: readonly EventItem[] = [
        {type: StreamerEventType.TwitchBit, label: "Twitch – Bits"},
        {type: StreamerEventType.TwitchFollower, label: "Twitch – Followers"},
        {type: StreamerEventType.TwitchSubscriber, label: "Twitch – Subscribers"},
        {type: StreamerEventType.TwitchSubscriberUpgrade, label: "Twitch – Subscribers (Upgrade)"},
        {type: StreamerEventType.TwitchSubscriberGiftViewer, label: "Twitch – Gifts (Viewer)"},
        {type: StreamerEventType.TwitchSubscriberGiftChannel, label: "Twitch – Gifts (Channel)"},
        {type: StreamerEventType.TwitchSubscriberGiftUpgrade, label: "Twitch – Gifts (Upgrade)"},
        {type: StreamerEventType.TwitchChannelPoints, label: "Twitch – Points"},
        {type: StreamerEventType.TwitchHypeTrain, label: "Twitch – Hype Trains"},
        {type: StreamerEventType.TwitchHost, label: "Twitch – Hosts"},
        {type: StreamerEventType.TwitchRaid, label: "Twitch – Raids"},
    ].filter(filterDisabled);

    private readonly TROVO_ITEMS: readonly EventItem[] = [
        {type: StreamerEventType.TrovoSpellMana, label: "Trovo – Spell (Mana)"},
        {type: StreamerEventType.TrovoSpellElixir, label: "Trovo – Spell (Elixir)"},
        {type: StreamerEventType.TrovoFollower, label: "Trovo – Followers"},
        {type: StreamerEventType.TrovoSubscriber, label: "Trovo – Subscribers"},
        {type: StreamerEventType.TrovoSubscriberGiftViewer, label: "Trovo – Gifts (Viewer)"},
        {type: StreamerEventType.TrovoSubscriberGiftChannel, label: "Trovo – Gifts (Channel)"},
        {type: StreamerEventType.TrovoRaid, label: "Trovo – Raids"},
    ].filter(filterDisabled);

    private readonly YOUTUBE_ITEMS: readonly EventItem[] = [
        {type: StreamerEventType.YoutubeSuperchat, label: "Youtube – Superchats"},
        {type: StreamerEventType.YoutubeSponsor, label: "Youtube – Members"},
        {type: StreamerEventType.YoutubeFollower, label: "Youtube – Subscribers"},
    ].filter(filterDisabled);

    private readonly PAYPAL_ITEMS: readonly EventItem[] = [
        {type: StreamerEventType.PaypalDonation, label: "PayPal – Донаты"},
    ].filter(filterDisabled);

    public readonly sections = [
        this.TWITCH_ITEMS, this.TROVO_ITEMS, this.YOUTUBE_ITEMS, this.PAYPAL_ITEMS,
    ];

    constructor(@Inject(LOCAL_STORAGE)
                private readonly storageService: StorageService<IFilterSettings>) {
        this.settings = this.storageService.get("donatty/events-filter");
        if (!this.settings) {
            this.settings = {
                enabledEvents: Array.from(STREAMER_EVENT_TYPES_ALL),
                showMedia: true,
                showTargets: true,
                version: filterSettingsMigrations.length
            };
        } else {
            this.settings = migrate(this.settings, filterSettingsMigrations);
        }
        this.saveSettings();
    }

    public get showDonationTargets(): boolean {
        return this.settings.showTargets;
    }

    public set showDonationTargets(value: boolean) {
        if (this.settings.showTargets === value) {
            return;
        }

        this.settings.showTargets = value;
        this.saveSettings();

        this.settingsChanged.emit(this.settings);
    }

    public isEventEnabled(type: StreamerEventType): boolean {
        return this.settings.enabledEvents.includes(type);
    }

    public setEventEnabled(type: StreamerEventType, isEnabled: boolean): void {
        if (this.isEventEnabled(type) === isEnabled) {
            return;
        }

        this.settings.enabledEvents = this.settings.enabledEvents.filter(e => (e !== type));

        if (isEnabled) {
            this.settings.enabledEvents.push(type as StreamerEventType);
        }

        this.saveSettings();

        this.settingsChanged.emit(this.settings);
    }

    private saveSettings(): void {
        this.storageService.set("donatty/events-filter", this.settings);
    }

    @ViewChild("popup", {static: true})
    private popup: PopupComponent;

    @ViewChild("filterMenu")
    public readonly menu: MatMenu;

}

type EventItem = {
    type: StreamerEventType;
    label: string;
};

type MigrationData = { version?: number };
type Migration<T extends MigrationData> = (s: T) => T;

const filterSettingsMigrations: Array<Migration<IFilterSettings>> = [
    (s: IFilterSettings) => ({
        enabledEvents: [...s.enabledEvents, StreamerEventType.TwitchHost],
        showMedia: s.showMedia,
        showTargets: s.showTargets,
        version: 1,
    }),
    (s: IFilterSettings) => ({
        enabledEvents: [...s.enabledEvents,
            StreamerEventType.TwitchSubscriberUpgrade,
            StreamerEventType.TwitchSubscriberGiftUpgrade,
            StreamerEventType.TwitchSubscriberGiftViewer,
            StreamerEventType.TwitchSubscriberGiftChannel,
        ],
        showMedia: s.showMedia,
        showTargets: s.showTargets,
        version: 2,
    }),
    (s: IFilterSettings) => ({
        enabledEvents: [...s.enabledEvents,
            StreamerEventType.DonattyPayoutSuspicious
        ],
        showMedia: s.showMedia,
        showTargets: s.showTargets,
        version: 3,
    }),
    (s: IFilterSettings) => ({
        enabledEvents: [...s.enabledEvents,
            StreamerEventType.TrovoSubscriber,
            StreamerEventType.TrovoSubscriberGiftViewer,
            StreamerEventType.TrovoSubscriberGiftChannel,
            StreamerEventType.TrovoFollower,
            StreamerEventType.TrovoRaid,
            StreamerEventType.TrovoSpellElixir,
            StreamerEventType.TrovoSpellMana,
        ],
        showMedia: s.showMedia,
        showTargets: s.showTargets,
        version: 4,
    }),
];

function migrate<T extends MigrationData>(settings: T, migrations: Array<Migration<T>>) {
    const currentVersion = +settings.version || 0;
    migrations
        .filter((_, index) => index >= currentVersion)
        .forEach(migrateVersion => settings = migrateVersion(settings));
    return settings;
}
