<mat-tab-group ffScrollFix mat-align-tabs="start" dynamicHeight [disableRipple]="true">
    <mat-tab aria-label="style" label="Стиль">
        <app-widget-tab-style [widgetType]="WidgetType.Alert"
                              [(widgetProps)]="widgetProps"
                              (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-tab-style>
    </mat-tab>
    <mat-tab aria-label="properties" label="Свойства">
        <app-widget-donations-tab-props [(widgetProps)]="widgetProps"
                                        (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-donations-tab-props>
    </mat-tab>
    <mat-tab aria-label="image" label="Изображение">
        <app-widget-donations-tab-images [(widgetProps)]="widgetProps"
                                         (widgetPropsChange)="widgetPropsChange.emit($event)"
                                         (imageChanged)="imageChanged.emit($event)">
        </app-widget-donations-tab-images>
    </mat-tab>
    <mat-tab aria-label="sound" label="Звук">
        <app-widget-donations-tab-audio [(widgetProps)]="widgetProps"
                                        (widgetPropsChange)="widgetPropsChange.emit($event)"
                                        (audioChanged)="audioChanged.emit($event)">
        </app-widget-donations-tab-audio>
    </mat-tab>
    <mat-tab aria-label="header" label="Заголовок">
        <app-widget-donations-tab-header [(widgetProps)]="widgetProps"
                                         (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-donations-tab-header>
    </mat-tab>
    <mat-tab aria-label="text" label="Текст">
        <app-widget-donations-tab-text [(widgetProps)]="widgetProps"
                                       (widgetPropsChange)="widgetPropsChange.emit($event)">
        </app-widget-donations-tab-text>
    </mat-tab>
</mat-tab-group>
