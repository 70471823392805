import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {WidgetGoalTabHeaderComponent} from "./widget-goal-tab-header/widget-goal-tab-header.component";
import {GoalWidget} from "../../../../../../../../shared/src/lib/models/widget-props";
import {MatTabGroup} from "@angular/material/tabs";
import {WidgetVisuals} from "../widget-edit-visuals.component";
import { WidgetType } from "projects/shared/src/lib/models/widget";

@Component({
    selector: "app-widget-visuals-goal",
    templateUrl: "./widget-visuals-goal.component.html",
})
export class WidgetVisualsGoalComponent implements WidgetVisuals {

    @Input()
    public widgetProps: GoalWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter<GoalWidget["props"]>();

    @ViewChild("widgetGoalTabHeader")
    private widgetGoalTabHeader: WidgetGoalTabHeaderComponent;

    @ViewChild(MatTabGroup)
    public tabGroup: MatTabGroup;

    public get isValid(): boolean {
        return (!this.widgetGoalTabHeader) || this.widgetGoalTabHeader.isValid;
    }

    public readonly WidgetType = WidgetType;
}
