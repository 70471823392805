import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginTokenService} from "../../../services/login/login-token.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PlatformInfoService} from "../../../../../../shared/src/lib/platform-info.service";

@Component({
    selector: "app-mobile-card",
    templateUrl: "./mobile-card.component.html",
    styleUrls: ["./mobile-card.component.scss"]
})
export class MobileCardComponent implements OnInit, OnDestroy {
    public isQrCodeVisible = false;
    public isResettingQrCode = true;

    private readonly destroy$: Subject<void> = new Subject();

    public constructor(public readonly loginTokenService: LoginTokenService,
                       public readonly platformInfoService: PlatformInfoService) {
    }

    public ngOnInit() {
        this.loginTokenService.mobileLoginUrl$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.isResettingQrCode = false);
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }
}
