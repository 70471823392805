import {Injectable, Provider} from "@angular/core";
import {
    HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {delay, dematerialize, filter, materialize, mergeMap, switchMap} from "rxjs/operators";
import {EnvironmentService} from "../environment.service";
import {DevSettingsService, fakeId} from "./dev-settings.service";
import {Currency, PayoutMethodType} from "../common/FinancialOperation";

type RequestMock = {
    test: (request: HttpRequest<any>) => boolean;
    handle: (request: HttpRequest<any>, next: HttpHandler) => Observable<HttpResponse<any>>;
};

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    private payoutMethodSaved = false;

    public constructor(private readonly devSettingsService: DevSettingsService) {
    }

    private requestMocks: { [requestMethod: string]: Array<RequestMock> } = {
        DELETE: [
            {
                test: request =>
                    request.url.includes("/accounts/") &&
                    !!this.devSettingsService.fakePyout,
                handle: (request: HttpRequest<any>, next: HttpHandler): Observable<any> => {
                    this.devSettingsService.deleteFakePayoutMethod();
                    return of(new HttpResponse({
                        status: 200,
                        body: {},
                    }));
                },
            },
        ],
        POST: [
            {
                test: request =>
                    request.url.includes("/operations/withdrawal/") &&
                    !!this.devSettingsService.fakeWallet || this.devSettingsService.forcePayoutFailure,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<{ response: any }>> => {
                    const body: any = {};
                    if (this.devSettingsService.forcePayoutFailure) {
                        body.error = "Test error";
                    } else {
                        body.response = this.devSettingsService.buildFakeWithdrawalOperation(request.body);
                    }
                    return of(new HttpResponse({
                        status: 200,
                        body,
                    }));
                },
            },
            {
                test: request =>
                    request.url.includes("/payee") &&
                    !!this.devSettingsService.fakePyout,
                handle: (request: HttpRequest<any>, next: HttpHandler): Observable<any> => {
                    const body: any = {};
                    const refId = request.url.split("/")[5];
                    let status: number;
                    if (refId === this.devSettingsService.getFakePayoutMethod()?.refId) {
                        body.error = "CONFLICT";
                        status = 409;
                    } else {
                        this.devSettingsService.saveFakePayoutMethod({
                            refId,
                            type: PayoutMethodType.YANDEX,
                            currency: Currency.RUB,
                            owner: {
                                refId: "8940f76e-241f-4b24-a76f-666b7b4893af"
                            },
                            number: "22222222222"
                        });
                        body.response = {response: {}};
                        status = 200;
                        this.payoutMethodSaved = true;
                    }
                    return of(new HttpResponse({
                        status,
                        body,
                    }));
                },
            },
        ],
        GET: [
            {
                test: request =>
                    request.url.includes("users/store") &&
                    !!this.devSettingsService.isPaypalDonateEnabled,
                handle: (request: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<{ response: any }>> =>
                    next.handle(request).pipe(
                        filter(event => event instanceof HttpResponse),
                        switchMap((resp: HttpResponse<{ response: any }>) => {
                            resp.body.response.paymentMethods.paypal.isEnabled = !!this.devSettingsService.isPaypalDonateEnabled;
                            return of(resp);
                        })
                    )
            },
            {
                test: request =>
                    request.url.includes("/donations/statistics/donators") &&
                    !!this.devSettingsService.isFakeDonatorsEnabled,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<{ response: any }>> => {
                    const maxResult = +(new URLSearchParams((new URL(request.urlWithParams)).search)).get("maxResults");
                    return of(new HttpResponse({
                        status: 200,
                        body: {
                            response: {
                                nextPageToken: "0",
                                pollingIntervalMs: 500,
                                items: this.devSettingsService.fakeDonators
                                    .sort((d1, d2) => d2.amount - d1.amount)
                                    .slice(0, maxResult),
                            },
                        },
                    }));
                },
            },
            {
                test: request =>
                    request.url.includes("/finance/statistics?aggregateBy") &&
                    !!this.devSettingsService.isFakeStreamEventsFeedEnabled,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<{ response: any }>> => {
                    return of(new HttpResponse({
                        status: 200,
                        body: {
                            response: {
                                nextPageToken: "0",
                                pollingIntervalMs: 500,
                                items: this.devSettingsService.buildFakeStatistics(),
                            },
                        },
                    }));
                },
            },
            {
                test: request =>
                    request.url.includes(`/finance/operations/${fakeId}`) &&
                    !!this.devSettingsService.fakePyout,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<{ response: any }>> => {
                    return of(new HttpResponse({
                        status: 200,
                        body: {response: this.devSettingsService.buildFakeWithdrawalOperation()},
                    }));
                },
            },
            {
                test: request =>
                    request.url.includes("/finance/accounts?type=CARD&type=QIWI&type=YANDEX") &&
                    !!this.devSettingsService.fakePyout,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<any>> => {
                    const items = [];
                    const fakePayoutMethod = this.devSettingsService.getFakePayoutMethod();
                    if (fakePayoutMethod) {
                        items.push(fakePayoutMethod);
                    }
                    return of(new HttpResponse({
                        status: 200,
                        body: {response: {nextPageToken: 1, pollingIntervalMs: 500, items}},
                    }));
                },
            },
            {
                test: request =>
                    request.url.includes("/finance/accounts/wallets") &&
                    !!this.devSettingsService.fakeWallet,
                handle: (request: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> => {
                    return next.handle(request).pipe(
                        filter(event => event instanceof HttpResponse),
                        switchMap((resp: HttpResponse<any>) => {
                            const realWallet = resp.body.response.items[0] || {};
                            const fakeWallet = this.devSettingsService.fakeWallet;
                            return of(resp.clone({
                                body: {
                                    response: {
                                        items: [{...realWallet, ...fakeWallet}]
                                    }
                                }
                            }));
                        }));
                },
            },
            {
                test: request =>
                    request.url.includes("/events?") &&
                    !!this.devSettingsService.isFakeStreamEventsFeedEnabled,
                handle: (request: HttpRequest<any>): Observable<HttpResponse<any>> => {
                    const maxResult = 22;
                    return of(new HttpResponse({
                        status: 200,
                        body: {
                            response: {
                                items: this.devSettingsService.getFakeStreamerEvents(maxResult),
                                nextPageToken: null,
                            },
                        },
                    }));
                },
            },
        ],
    };

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return of(null)
            .pipe(mergeMap(() => {
                return this.requestMocks[request.method]
                    ?.find(mock => mock.test(request))
                    ?.handle(request, next) ?? next.handle(request);
            }))
            .pipe(materialize())
            .pipe(delay(300))
            .pipe(dematerialize());
    }

}

function error(message) {
    return throwError({error: {message}});
}

function unauthorized() {
    return throwError({status: 401, error: {message: "Unauthorised"}});
}

export class EmptyInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request);
    }
}

const factory = (environmentService: EnvironmentService, devSettingsService: DevSettingsService) => {
    return environmentService.isDevBuild ? new FakeBackendInterceptor(devSettingsService) : new EmptyInterceptor();
};

export const fakeBackendInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useFactory: factory,
    deps: [EnvironmentService, DevSettingsService],
    multi: true
};
