import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, merge, Observable, Subject} from "rxjs";
import {AccountType, AccountInfo, isKnownExternalAccountType, makeAccountInfo} from "./accounts";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AccountsSseService} from "./accounts-sse.service";
import {UserService} from "../user/user.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {User} from "../../../../../shared/src/lib/models/user";
import {ListResponse, SocialLoginResponse} from "./accounts.api";

@Injectable({providedIn: "root"})
export class AccountsService implements OnDestroy {
    public readonly disconnectedAccounts: Set<string> = new Set([]);
    public readonly accounts$ = new BehaviorSubject<Array<AccountInfo>>(null);
    public readonly accountConnected$ = new Subject<AccountInfo>();
    public readonly accountReconnected$ = new Subject<AccountInfo>();
    public readonly accountRemoved$ = new Subject<AccountInfo>();
    private readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly authService: AuthService,
                       private readonly dataLayerService: DataLayerService,
                       private readonly environmentService: EnvironmentService,
                       private readonly httpClient: HttpClient,
                       private readonly accountsSseService: AccountsSseService,
                       private readonly userService: UserService) {

        merge(
            this.userService.currentUserWithPermissions$,
            this.accountRemoved$.pipe(switchMap(() => this.userService.currentUserWithPermissions$))
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(user => this.fetchAccounts(user));

        this.accountsSseService.addSocial$.pipe(takeUntil(this.destroy$)).subscribe(
            async newAccount => {
                console.log("external account connected", newAccount);

                this.disconnectedAccounts.delete(newAccount.getId());

                if (this.accounts$.value && !this.accounts$.value.find(acc => acc.getId() === newAccount.getId())) {
                    this.accounts$.next(this.accounts$.value.concat(newAccount));
                }

                console.log("emitting new account event");
                this.accountConnected$.next(newAccount);

                setTimeout(() => {
                    // workaround for the immediate window closure
                    if (this.authWindow && !this.authWindow.closed) {
                        this.authWindow.close();
                    }
                }, 1000);

                this.dataLayerService.emit({
                    eventCategory: EventCategory.Panel,
                    eventAction: EventAction.Update,
                    eventLabel: "additional-account",
                    eventValue: newAccount.getType().toLowerCase(),
                });
            });

        this.accountsSseService.removeSocial$.pipe(takeUntil(this.destroy$)).subscribe(removedAccount => {
            console.log("external account removed", removedAccount);
            if (this.accounts$.value && this.accounts$.value.find(acc => acc.getId() === removedAccount.getId())) {
                this.accounts$.next(this.accounts$.value.filter(acc => acc.getId() !== removedAccount.getId()));
            }
            this.accountRemoved$.next(removedAccount);
        });

        this.accountsSseService.reconnectSocial$.pipe(takeUntil(this.destroy$)).subscribe(reconnectedAccount => {
            this.disconnectedAccounts.add(reconnectedAccount.getId());
            this.accountReconnected$.next(reconnectedAccount);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        if (this.authWindow && !this.authWindow.closed) {
            this.authWindow.close();
        }
    }

    public async addAccount(type: AccountType): Promise<void> {
        console.log("adding account of type", type);

        const socialLoginUri = await this.requestAddAccountUri(type);
        console.log("social auth uri retrieved", socialLoginUri);

        if (this.authWindow && !this.authWindow.closed) {
            this.authWindow.close();
        }

        const POPUP_WIDTH = 600;
        const POPUP_HEIGHT = 800;
        const [left, top] = AccountsService.calculateScreenCenter(POPUP_WIDTH, POPUP_HEIGHT);

        this.authWindow = window.open(
            socialLoginUri,
            "",
            `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},toolbar=0,menubar=0,location=0,left=${left},top=${top}`);

        if (!this.authWindow) {
            console.warn("browser refused to return auth window instance");
            return;
        }

        console.log("social authorization window opened");
        this.authWindow.focus();
    }

    private getAccounts(): Observable<Array<AccountInfo>> {
        return this.httpClient.get<ListResponse>(
            `${this.environmentService.backendApiUri}/users/socials`, this.authService.makeTokenAuthHeaders(),
        ).pipe(map(response => response.response.items
            .filter(item => isKnownExternalAccountType(item.type))
            .map(item => makeAccountInfo(item.id, item.type, item.name, item.main, item.new))
        ));
    }

    private async fetchAccounts(user: User) {
        const externalAccounts: Array<AccountInfo> = await this.getAccounts().toPromise();
        // TODO: Create main account on backend(?)
        externalAccounts.unshift(
            new AccountInfo("donatty", AccountType.Donatty, user.displayName, true, false)
        );
        this.accounts$.next(externalAccounts);
    }

    private deleteAccount(accountId: string) {
        const apiUri = this.environmentService.backendApiUri;
        return this.httpClient.delete(
            `${apiUri}/users/socials/${accountId}`,
            this.authService.makeTokenAuthHeaders(),
        );
    }

    public removeAccount(account: AccountInfo): void {
        this.deleteAccount(account.getId()).subscribe(() => this.accountRemoved$.next(account));
    }

    private static calculateScreenCenter(w: number, h: number): [number, number] {
        return [
            (window.top.outerWidth / 2) + window.top.screenX - (w / 2),
            (window.top.outerHeight / 2) + window.top.screenY - (h / 2)
        ];
    }

    private async requestAddAccountUri(type: AccountType): Promise<string> {
        let uri = "";

        const apiUri = this.environmentService.backendApiUri;
        switch (type) {
            case AccountType.Paypal:
                uri = `${apiUri}/users/socials/paypal`;
                break;

            case AccountType.Twitch:
                uri = `${apiUri}/users/socials/twitch`;
                break;

            case AccountType.Trovo:
                uri = `${apiUri}/users/socials/trovo`;
                break;

            case AccountType.Youtube:
                uri = `${apiUri}/users/socials/google`;
                break;
        }

        if (!uri) {
            throw new Error(`unknown account type ${type}`);
        }

        const response = await this.httpClient.post<SocialLoginResponse>(
            uri, {
                returnUrl: window.location.href
            },
            this.authService.makeTokenAuthHeaders()).toPromise();

        return response.response;
    }

    private authWindow: Window = null;
}
