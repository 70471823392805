import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from "@angular/core";
import {
    WidgetEventSourcesAccountEvents, WidgetEventSourcesAccountProp
} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {AccountEventType, disabledAccountEvents} from "../../../../../../services/accounts/accounts";
import {accountEventVisuals} from "../../../../../../services/events/accountEventVisuals";

export type EventSourceChip = {
    eventType: AccountEventType;
    prop: WidgetEventSourcesAccountProp;
};

@Component({
    selector: "app-event-source-chips",
    templateUrl: "./event-source-chips.component.html",
    styleUrls: ["./event-source-chips.component.scss"]
})
export class EventSourceChipsComponent implements OnInit, OnChanges {
    @Input()
    public accountEvents: Partial<WidgetEventSourcesAccountEvents>;

    @Output()
    public accountEventsChanged = new EventEmitter<Array<EventSourceChip>>();

    public items: EventSourceChip[] = [];

    public visibleItems: EventSourceChip[];

    public accountEventTypeVisuals: { [key in AccountEventType]: { label: string, order: number } } = accountEventVisuals;

    public ngOnChanges(changes: SimpleChanges): void {
        this.setItems();
    }

    public ngOnInit(): void {
        this.setItems();
    }

    public onClick(e: MouseEvent, item: EventSourceChip) {
        e.stopImmediatePropagation();
        item.prop.isEnabled = !item.prop.isEnabled;
        this.accountEventsChanged.emit(this.items);
    }

    private setItems() {
        this.items = Object
            .keys(this.accountEvents)
            .map((eventType: AccountEventType) => ({
                eventType,
                prop: {
                    isEnabled: this.accountEvents[eventType].isEnabled,
                    hidden: this.accountEvents[eventType].hidden || disabledAccountEvents.has(eventType),
                },
            }))
            .sort((l, r) =>
                accountEventVisuals[l.eventType].order - accountEventVisuals[r.eventType].order
            );
        this.visibleItems = this.items.filter(i => !i.prop.hidden);
    }
}
