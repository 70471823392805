<div class="we__edit-sources" [@collapse]="!isShown">
    <div class="we__row--header" (click)="toggleCollapse()">
        <div class="we__column--label">Источники данных</div>
        <div class="we__column--button">
            <button mat-icon-button aria-label="Expand event sources" disableRipple="true">
                <mat-icon [@rotate]="!isShown">expand_more</mat-icon>
            </button>
        </div>
    </div>

    <div class="we__collapsable" [@fadeOut]="!isShown">
        <div class="we__description">
            Выберите источники и события, о которых виджет должен оповещать.
            Если здесь нет нужного источника данных, то его можно подключить.
        </div>

        <app-widget-edit-event-sources
            [eventSources]="eventSources"
            (eventSourcesChange)="eventSourcesChange.emit(eventSources)"
            [disabled]="type !== WidgetType.Alert && type !== WidgetType.Events">
        </app-widget-edit-event-sources>

    </div>
</div>
