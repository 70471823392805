import {Injectable, NgZone, OnDestroy} from "@angular/core";
import {WidgetService} from "../widget.service";
import {AuthService} from "../../../../../../shared/src/lib/auth.service";
import {HttpClient} from "@angular/common/http";
import {WidgetGroup, WidgetGroupType} from "./widget-group";
import {EnvironmentService} from "../../../../../../shared/src/lib/environment.service";
import {
    ApiCreateWidgetGroupRequest, ApiListGroupsResponse, ApiPutWidgetIntoGroupRequest, ApiUpdateGroupRequest,
    ApiUpdateGroupsOrderRequest, ApiUpdateWidgetsOrderInGroupRequest, ApiWidgetGroupInfo
} from "./widget-groups-api";
import {IGenericWidgetInfo, WidgetType} from "../../../../../../shared/src/lib/models/widget";
import {CommandService} from "../../command.service";
import {ToastrService} from "ngx-toastr";
import {ApiWidgetInfo} from "../../../../../../shared/src/lib/models/api";
import {combineLatest, firstValueFrom, merge, Observable, ReplaySubject, Subject} from "rxjs";
import {
    distinctUntilChanged, filter, first, map, mergeMap, shareReplay, take, takeUntil, tap, withLatestFrom
} from "rxjs/operators";
import {WidgetAction} from "../widget.service";
import {AccountsService} from "../../accounts/accounts.service";
import {EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {SSEMessage} from "../../../../../../shared/src/lib/common";
import {WidgetEventType} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {SSEService} from "../../sse.service";

interface RemovedItem<T> {
    item: T;
    index: number;
}

const alertTypes = [WidgetType.Alert, WidgetType.Events];

const ungroup = groups => [].concat.apply([], groups.map(group => group.widgets));

interface WidgetGroupEvent extends ApiWidgetGroupInfo {
}

@Injectable({
    providedIn: "root"
})
export class WidgetGroupService implements OnDestroy {
    public readonly widgetGroups$ = new ReplaySubject<Array<WidgetGroup>>(1);
    public readonly widgets$ = this.widgetGroups$.pipe(map(ungroup), shareReplay(1));
    public readonly alerts$ = this.widgets$.pipe(
        map(widgets => widgets.filter(widget => alertTypes.includes(widget.getType()))),
        shareReplay(1),
    );
    private readonly groupAdded$: Observable<WidgetGroup> = this.sseService.message$.pipe(
        filter<SSEMessage<WidgetGroupEvent>>(m => m.type === WidgetEventType.GroupAdd),
        map(m => this.makeWidgetGroup(m.event)),
    );
    private readonly destroy$: Subject<void> = new Subject<void>();

    public readonly markedForRemoval: Set<string> = new Set();
    public readonly removedWidgets: Array<RemovedItem<IGenericWidgetInfo>> = [];
    private readonly baseUri = this.environmentService.backendApiUri + "/widget-groups";

    public constructor(private readonly authService: AuthService,
                       private readonly environmentService: EnvironmentService,
                       private readonly widgetService: WidgetService,
                       private readonly http: HttpClient,
                       private readonly commandService: CommandService,
                       private readonly toastr: ToastrService,
                       private readonly accountsService: AccountsService,
                       private readonly dataLayerService: DataLayerService,
                       private readonly sseService: SSEService,
                       private readonly ngZone: NgZone) {
        const accounts$ = this.accountsService.accounts$.pipe(filter(Boolean), take(1));
        const authStatus$ = this.authService.authStatus$.pipe(distinctUntilChanged(), filter(Boolean));

        merge(combineLatest([authStatus$, accounts$]))
            .subscribe(() => this.ngZone.run(() => this.fetchGroups()));

        this.widgetGroups$
            .pipe(first(groups => groups.length > 0))
            .subscribe(() => this.subscribeForWidgetChanges());

        this.groupAdded$
            .pipe(withLatestFrom(this.widgetGroups$), takeUntil(this.destroy$))
            .subscribe(([group, groups]) => {
                groups.splice(group.weight, 0, group);
                this.widgetGroups$.next(groups);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private fetchGroups() {
        this.http.get<ApiListGroupsResponse>(this.baseUri, this.authService.makeTokenAuthHeaders()).pipe(
            map(rawGroups => rawGroups.widgetGroups.map(group => this.makeWidgetGroup(group))),
            mergeMap(groups => this.validateGroupsFromBackend(groups)),
        ).subscribe(groups => this.widgetGroups$.next(groups));
    }

    public async updateGroupsOrder(widgetGroups: WidgetGroup[]): Promise<void> {
        const url = `${this.baseUri}/order`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        const order: ApiUpdateGroupsOrderRequest = {
            order: widgetGroups.map(group => ({id: group.id}))
        };
        await this.http.post(url, order, authHeaders).toPromise();
    }

    public async updateWidgetsOrder(widgetGroup: WidgetGroup): Promise<void> {
        const url = `${this.baseUri}/${widgetGroup.id}/order`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        const newOrder: ApiUpdateWidgetsOrderInGroupRequest = {
            order: widgetGroup.widgets.map(widget => ({id: widget.getId()}))
        };
        widgetGroup.widgets.forEach((widget, i) => widget.setWeight(i));
        await this.http.post(url, newOrder, authHeaders).toPromise();
    }

    public async createGroup(params: ApiCreateWidgetGroupRequest["widgetGroup"]): Promise<any> {
        const authHeaders = this.authService.makeTokenAuthHeaders();
        const requestBody = {widgetGroup: params};
        return this.http.post(this.baseUri, requestBody, authHeaders).pipe(
            tap(() => {
                this.dataLayerService.emit({
                    eventCategory: EventCategory.Widget,
                    eventAction: EventAction.CreateGroup,
                    eventLabel: EventAction.CreateGroup,
                });
            }),
        ).toPromise();
    }

    public async copyGroup(widgetGroup: WidgetGroup, name?: string): Promise<void> {
        const widgetPromises = widgetGroup.widgets
            .map(async widget => {
                const fullWidget = await this.widgetService.get(widget.getId());
                return {
                    name: widget.getName(),
                    type: widget.getType(),
                    props: fullWidget.getGenericProps(),
                };
            });

        this.dataLayerService.emit({
            eventCategory: EventCategory.Widget,
            eventAction: EventAction.CopyGroup,
            eventLabel: EventAction.CopyGroup,
        });
        await this.createGroup({
            name: name || widgetGroup.name,
            isExpanded: widgetGroup.isExpanded,
            widgets: await Promise.all(widgetPromises)
        });
    }

    public async updateGroup(widgetGroup: WidgetGroup): Promise<void> {
        const url = `${this.baseUri}/${widgetGroup.id}`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        const body: ApiUpdateGroupRequest = {
            widgetGroup: {
                name: widgetGroup.name,
                isExpanded: widgetGroup.isExpanded,
                sequence: widgetGroup.sequence,
            },
        };
        await this.http.put(url, body, authHeaders).toPromise();
    }

    public async deleteGroup(widgetGroup: WidgetGroup): Promise<void> {
        const url = `${this.baseUri}/${widgetGroup.id}`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        await this.http.delete(url, authHeaders).toPromise();

        const widgetIds = widgetGroup.widgets.map(widget => widget.getId());
        await this.widgetService.delete(widgetIds);
    }

    public async deleteGroupDeferred(widgetGroup: WidgetGroup, timeout: number) {
        const command = this.commandService.run({
            timeout,
            handler: () => {
                this.deleteGroup(widgetGroup);
            },
        });
        this.markedForRemoval.add(widgetGroup.id);
        widgetGroup.isHidden = this.isHidden(widgetGroup);
        this.toastr.show("Группа удалена", "", {
            timeOut: timeout,
            progressBar: true,
            tapToDismiss: false,
            extendedTimeOut: 0,
            disableTimeOut: false,
            // @ts-ignore
            action: "Восстановить",
        }).onAction.subscribe(() => {
            this.commandService.cancel(command);
            this.markedForRemoval.delete(widgetGroup.id);
            widgetGroup.isHidden = this.isHidden(widgetGroup);
        });
    }

    public async deleteWidgetDeferred(widgetGroup: WidgetGroup, widget: IGenericWidgetInfo, timeout: number) {
        const deletePromise = this.widgetService.deleteDeferred(widget, timeout);
        widgetGroup.isHidden = this.isHidden(widgetGroup);
        await deletePromise;
        widgetGroup.isHidden = this.isHidden(widgetGroup);
    }

    public async putWidgetIntoGroup(widget: IGenericWidgetInfo,
                                    toGroup: WidgetGroup,
                                    fromGroup: WidgetGroup): Promise<void> {
        const url = `${this.baseUri}/${toGroup.id}/widget`;
        const authHeaders = this.authService.makeTokenAuthHeaders();

        const body: ApiPutWidgetIntoGroupRequest = {
            widgetId: widget.getId(),
            order: toGroup.widgets.map(w => ({id: w.getId()}))
        };
        await firstValueFrom(this.http.put(url, body, authHeaders));

        toGroup.isAlertGroup = WidgetGroupService.isAlertGroup(toGroup);
        fromGroup.isAlertGroup = WidgetGroupService.isAlertGroup(fromGroup);
        fromGroup.isHidden = this.isHidden(fromGroup);

        widget.setGroupId(toGroup.id);
        this.widgetService.putWidgetToCache(widget);
    }

    public formatWidgetGroupLink(widgetGroup: WidgetGroup, isPreview: boolean): string {
        // TODO: trailing slash before '?' is mandatory, NGINX widgets host redirects
        //       from :8888 to :80 if the slash is omitted
        let href =
            `group/?ref=${widgetGroup.id}` +
            `&token=${widgetGroup.token}`;

        if (isPreview) {
            href += `&flags=preview,wysiwyg`;
        }

        if (!isPreview && !this.environmentService.isProdBuild) {
            const pongIntervalSec = (5 * 60);
            href += `&pongInterval=${pongIntervalSec}`;
        }

        if (!this.environmentService.isProdBuild) {
            href +=
                `&api=${this.environmentService.backendApiUri}` +
                `&logLevel=trace`;
        }

        return href;
    }

    public makeWidgetGroup(model: ApiWidgetGroupInfo): WidgetGroup {
        const group: WidgetGroup = {
            id: model.refId,
            name: model.name,
            type: model.type,
            isExpanded: model.isExpanded,
            widgets: (model.widgets || []).map((widgetModel: ApiWidgetInfo) => this.widgetService.makeWidgetInfo(widgetModel)),
            token: model.token,
            sequence: model.sequence,
            isAlertGroup: false,
            isHidden: false,
            weight: model.weight,
        };
        group.isAlertGroup = WidgetGroupService.isAlertGroup(group);
        group.isHidden = this.isHidden(group);
        return group;
    }

    public static isAlertGroup(widgetGroup: WidgetGroup): boolean {
        const alertsOnly = (widgets: Array<IGenericWidgetInfo>) =>
            !!widgets.length && !widgets.find(w => w.getType() !== WidgetType.Alert);
        return widgetGroup.type !== WidgetGroupType.Ungrouped && alertsOnly(widgetGroup.widgets);
    }

    private isHidden(widgetGroup: WidgetGroup): boolean {
        if (this.markedForRemoval.has(widgetGroup.id)) {
            return true;
        }
        if (widgetGroup.type === "ungrouped") {
            if (widgetGroup.widgets.length === 0) {
                return true;
            }
            const hasWidgetsNotMarkedForRemoval = widgetGroup.widgets.findIndex(widget =>
                !this.widgetService.markedForRemoval.has(widget.getId())) > -1;
            if (!hasWidgetsNotMarkedForRemoval) {
                return true;
            }
        }
        return false;
    }

    private readonly widgetHandlers: { [key in keyof typeof WidgetAction]: (widget: IGenericWidgetInfo, groups: Array<WidgetGroup>) => void } = {
        [WidgetAction.create]: (newWidget, groups) => {
            const group = groups.find(g => g.id === newWidget.getGroupId());
            group.widgets.splice(newWidget.getWeight(), 0, newWidget);
            group.isAlertGroup = WidgetGroupService.isAlertGroup(group);
            group.isHidden = false;
            this.widgetGroups$.next(groups);
        },
        [WidgetAction.change]: (changedWidget, groups) => {
            const group = groups.find(g => !!g.widgets.find(w => w.getId() === changedWidget.getId()));
            const widgetToUpdate = group.widgets?.find(widget => widget.getId() === changedWidget.getId());
            if (widgetToUpdate) {
                Object.assign(widgetToUpdate, changedWidget);
                this.widgetGroups$.next(groups);
            }
        },
        [WidgetAction.remove]: (removedWidget, groups) => {
            const group = groups.find(g => !!g.widgets.find(w => w.getId() === removedWidget.getId()));
            const widgetIndex = group.widgets.findIndex(widget => widget.getId() === removedWidget.getId());
            group.widgets.splice(widgetIndex, 1);
            this.removedWidgets.push({index: widgetIndex, item: removedWidget});
            group.isAlertGroup = WidgetGroupService.isAlertGroup(group);
            group.isHidden = this.isHidden(group);
            this.widgetGroups$.next(groups);
        },
    };

    private subscribeForWidgetChanges() {
        this.widgetService.widgetChanged$
            .pipe(withLatestFrom(this.widgetGroups$), takeUntil(this.destroy$))
            .subscribe(([event, groups]) =>
                this.widgetHandlers[event.action](event.widget, groups)
            );

        merge(this.accountsService.accountConnected$, this.accountsService.accountRemoved$)
            .pipe(withLatestFrom(this.widgetGroups$), takeUntil(this.destroy$))
            .subscribe(([, groups]) => {
                for (const widgetGroup of groups) {
                    for (const widget of widgetGroup.widgets) {
                        widget.setSources(this.widgetService.getSources(widget));
                        widget.setCondition(this.widgetService.getCondition(widget) || "Без условий");
                    }
                }
                this.widgetGroups$.next(groups);
            });
    }

    private async validateGroupsFromBackend(widgetGroups: Array<WidgetGroup>): Promise<Array<WidgetGroup>> {
        const requests: Array<Promise<any>> = [];

        // By design ungrouped widgets should be first, followed by donationPages and than the rest
        const orderIsCorrect = widgetGroups[0].type === WidgetGroupType.Ungrouped &&
            widgetGroups[1].type === WidgetGroupType.DonationPages;
        if (!orderIsCorrect) {
            const order = {
                [WidgetGroupType.Ungrouped]: 0,
                [WidgetGroupType.DonationPages]: 1,
                [WidgetGroupType.Common]: 2,
            };
            widgetGroups.sort((group1: WidgetGroup, group2: WidgetGroup) =>
                order[group1.type] - order[group2.type]
            );
            requests.push(this.updateGroupsOrder(widgetGroups));
        }

        await Promise.all(requests);
        return widgetGroups;
    }

}
