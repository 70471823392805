<div class="we__link">
  <div class="we__row--header">
    Ссылка для встраивания
  </div>
  <div class="we__row--link">
    <app-secure-input [text]="link"></app-secure-input>
  </div>
  <div class="we__row--warn">
    Никому не показывайте эту ссылку - ей могут воспользоваться злоумышленники.
  </div>
</div>
