<mat-card>
    <mat-card-title>Адрес страницы доната</mat-card-title>
    <mat-card-content>
        <section>
            Эту страницу пользователи увидят, когда перейдут
            по&nbsp;вашей ссылке.
        </section>
        <form [formGroup]="formGroup">
            <mat-form-field appearance="outline" (click)="copyLink()">
                <span matPrefix>https://donatty.com/</span>
                <input matInput formControlName="userName">
                <button mat-icon-button matSuffix>
                    <mat-icon>content_copy</mat-icon>
                </button>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button #dialogButton color="primary" mat-button
                *ngIf="userService.permissions$ | async"
                (click)="openDialog()">
            <mat-icon>mode</mat-icon>
            Редактировать
        </button>
    </mat-card-actions>
</mat-card>
