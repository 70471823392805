import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {FontFamilyLink} from "../widget-select-google-font/widget-select-google-font.component";
import {WidgetTextStyle} from "../../../../../../../../../shared/src/lib/models/widget-props";
import { TooltipPosition } from "projects/shared/src/lib/popup/popup.component";

@Component({
    selector: "app-widget-edit-font-basics",
    templateUrl: "widget-edit-font-basics.component.html",
    styleUrls: [
        "../../../widget-edit.component.scss",
        "../../widget-edit-settings.component.sass",
        "./widget-edit-font-basics.component.scss"
    ],
})
export class WidgetEditFontBasicsComponent implements OnChanges {
    @Input()
    public gradient = false;

    @Input()
    public id = "";

    @Input()
    public item: WidgetTextStyle;

    @Input()
    public offsetBounds = 200;

    @Input()
    public showAlign = true;

    @Input()
    public showWidth = false;

    @Input()
    public showFont = true;

    @Input()
    public showShadow = true;

    @Input()
    public showMaxWidth = false;

    @Input()
    public showPositioning = false;

    @Input()
    public text = "";

    @Output()
    public readonly itemChange = new EventEmitter();

    public readonly textAligns = ["left", "center", "right", "justify"];

    public readonly TooltipPosition = TooltipPosition;

    public get fontLink(): FontFamilyLink {
        return {
            family: this.item.font.family,
            uri: this.item.font.cssUri
        };
    }

    public set fontLink(value: FontFamilyLink) {
        const hasChanges = (
            (this.item.font.family !== value.family) ||
            (this.item.font.cssUri !== value.uri));
        if (!hasChanges) {
            return;
        }

        this.item.font.family = `'${value.family}'`;
        this.item.font.cssUri = value.uri;

        this.itemChange.emit(this.item);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.item) {
            if (undefined === this.item.geometry.maxWidth) {
                this.item.geometry.maxWidth = 0;
            }
        }
    }
}
