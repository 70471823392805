<div class="wsgf__dropdown"
     [ngClass]="{'wsgf__dropdown--opened': isOpened}"
     (click)="onClick()"
     (keydown)="onKeydown($event)"
     tabindex="1">
    <div class="wsgf__dropdown--label"
         [ngStyle]="{ 'font-family': value.family }"
         [innerText]="cleanFamilyName(value.family)">
    </div>

    <div class="wsgf__dropdown--icon">
        <img alt="V" src="/assets/images/select-google-font/arrow.svg">
    </div>
</div>

<div class="wsgf__form__wrapper"
     tabindex="1"
     (keydown)="onKeydown($event)">
    <div class="wsgf__form" [ngClass]="{'wsgf__form--visible': isOpened}">
        <div class="wsgf__form__search" (click)="searchInput.focus()">
            <img alt="Search" src="/assets/images/select-google-font/search.png">
            <input #searchInput placeholder="Поиск по Google Fonts"
                   (keyup)="searchString$.next(searchInput.value)"
                   (keydown)="onKeydown($event)">
        </div>
        <div class="wsgf__form__list__wrapper">
            <div class="wsgf__form__list">
                <div class="list__item"
                     *ngFor="let font of filteredFontsList$ | async"
                     (click)="onItemClick(font)"
                     [ngClass]="{ 'list__item--selected': isSelected(font) }">
                    <span [ngStyle]="{ fontFamily: escapedFamilyName(font.family) }">
                        {{ cleanFamilyName(font.family) }}
                    </span>
                    <img alt="x" src="/assets/images/select-google-font/check.svg">
                </div>
            </div>
        </div>
    </div>
</div>
