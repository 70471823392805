import {Injectable} from "@angular/core";
import {AccountsService} from "../accounts/accounts.service";
import {WidgetSettingsService} from "../settings/widget-settings.service";
import {AccountEventType, AccountInfo, AccountType, accountTypeEvents, eventTypesMap} from "../accounts/accounts";
import {
    IGenericWidgetInfo, IGoalWidgetInfo, WidgetCurrency, WidgetType
} from "../../../../../shared/src/lib/models/widget";
import {
    AlertWidget, EventsWidget, GoalWidget, TopWidget, WidgetProps
} from "../../../../../shared/src/lib/models/widget-props";
import {DonationData} from "../../../../../shared/src/lib/models/events";
import {WidgetGroupService} from "./widget_groups/widget-group.service";
import {BehaviorSubject, combineLatest, concat, from, interval, Observable, of} from "rxjs";
import {
    concatMap, debounceTime, delay, distinctUntilChanged, filter, map, pairwise, share, startWith, switchMap, take
} from "rxjs/operators";
import {StreamerEventType} from "../events/streamer-events.api";
import {PaymentMethodType} from "../../../../../shared/src/lib/models/finance.api";
import {Util} from "projects/shared/src/lib/util";
import {
    TrovoFollowerEventResponse, TrovoRaidEventResponse, TrovoSpellEventResponse, TrovoSubscriberEventResponse,
    TrovoSubscriberGiftChannelEventResponse, TrovoSubscriberGiftViewerEventResponse
} from "../events/streamer-events.trovo.api";

type TestDataOptions = {
    isAudioMuted: boolean;
    isVoiceMuted: boolean;
};
const defaultTestDataOptions: TestDataOptions = {
    isAudioMuted: false,
    isVoiceMuted: false,
};
export type WidgetTestGenerator<T extends WidgetProps> = (widgetProps: Observable<T>, options?: TestDataOptions) => Observable<object>;

@Injectable({
    providedIn: "root"
})
export class WidgetPreviewService {
    private readonly goalText$ = this.widgetGroupService.widgetGroups$.pipe(
        map(groups => [].concat.apply([], groups.map(group => group.widgets))),
        map(allWidgets => this.formatGoalText(allWidgets)),
        distinctUntilChanged(),
    );

    public constructor(private readonly accountsService: AccountsService,
                       private readonly widgetGroupService: WidgetGroupService,
                       private readonly widgetSettingsService: WidgetSettingsService) {
    }

    public readonly generateTestData: { [key in WidgetType]: WidgetTestGenerator<WidgetProps> } = {
        [WidgetType.Alert]: (alertProps: BehaviorSubject<AlertWidget["props"]>,
                             options: TestDataOptions = defaultTestDataOptions): Observable<DonationData> =>
            combineLatest([
                alertProps.pipe(filter(props => !!props), take(1)),
                this.accountsService.accounts$.pipe(filter(accounts => !!accounts)),
                this.goalText$,
            ]).pipe(
                debounceTime(100), // Prevent race condition in Alert widget when DATA and SETTINGS events occur simultaneously which causes settings being applied only after visibilityDurationMsecs
                map<[AlertWidget["props"], Array<AccountInfo>, string], [Array<AccountEventType>, string]>(([props, accounts, goalText]) =>
                    [this.widgetSettingsService.listEnabledEventTypes(accounts, props.data), goalText]
                ),
                map(([widgetEventTypes, goalText]) => {
                    const donationData: DonationData = {
                        subscriber: this.generateAlertsWidgetSubscriber(widgetEventTypes),
                        message: this.generateAlertsWidgetMessage(widgetEventTypes),
                        amount: this.generateAlertsWidgetAmount(widgetEventTypes),
                        currency: this.generateAlertsWidgetCurrency(widgetEventTypes),
                        goal: {
                            title: goalText
                        },
                        mute: {
                            audio: options.isAudioMuted,
                            voice: options.isVoiceMuted
                        },
                    };

                    const enabledTwitchEvents = widgetEventTypes.filter(value =>
                        accountTypeEvents[AccountType.Twitch].includes(value));
                    if (enabledTwitchEvents.length) {
                        donationData.twitch = this.getTwitchData(enabledTwitchEvents);
                    }

                    const enabledTrovoEvents = widgetEventTypes.filter(value =>
                        accountTypeEvents[AccountType.Trovo].includes(value));
                    if (enabledTrovoEvents.length) {
                        donationData.trovo = this.getTrovoData(enabledTrovoEvents);
                    }

                    return donationData;
                }),
            ),

        [WidgetType.Goal]: (goalProps: Observable<GoalWidget["props"]>): Observable<object> => goalProps.pipe(
            map(props => ({raised: props.data.goalFrom})),
        ),

        [WidgetType.Top]: (topProps: Observable<TopWidget["props"]>): Observable<object> => topProps.pipe(
            map(props => {
                const data = [...TOP_WIDGET_DATA];
                if (props.data.isListInverted) {
                    data.reverse();
                }
                return {
                    list: data.slice(0, props.data.rows)
                };
            })
        ),

        [WidgetType.Total]: (): Observable<object> => {
            this.totalWidgetAmount += Math.floor(1 + (Math.random() * 100000));
            return of({amount: this.totalWidgetAmount});
        },

        [WidgetType.Media]: (): Observable<object> => {
            throw new Error("Not implemented");
        },

        [WidgetType.Events]: (eventsWidgetProps$: Observable<EventsWidget["props"]>): Observable<object> => {
            const widgetEventTypes$ = combineLatest([
                eventsWidgetProps$.pipe(filter(props => !!props)),
                this.accountsService.accounts$.pipe(filter(accounts => accounts?.length > 0)),
            ]).pipe(
                map(([props, accounts]) => this.widgetSettingsService.listEnabledEventTypes(accounts, props.data)),
                map(accountEventTypes => accountEventTypes.map(aet => eventTypesMap[aet])),
                distinctUntilChanged((a, b) => a.length === b.length && -1 === a.findIndex((item, i) => item !== b[i])),
                startWith([]), pairwise(),
                map(([prev, cur]) => [cur, cur.filter(type => !prev.includes(type))]),
            );
            return widgetEventTypes$.pipe(
                switchMap(([eventTypes, addedEventTypes]) => concat(
                    from(addedEventTypes).pipe(
                        concatMap(event => of(makeTestEvent[event]()).pipe(delay(1357))),
                    ),
                    interval(3976).pipe(
                        filter(() => eventTypes.length > 0),
                        map(i => makeTestEvent[eventTypes[i % eventTypes.length]]())),
                )),
                share(),
            );
        },
    };

    private formatGoalText(widgets: Array<IGenericWidgetInfo>): string {
        const isGoal = (widget: IGenericWidgetInfo): widget is IGoalWidgetInfo => widget.isGoalWidget();
        const goalWidget = widgets.find<IGoalWidgetInfo>(isGoal);
        return goalWidget?.getProps().data.headerTemplate || "На улучшение канала";
    }

    private generateAlertsWidgetAmount(accountEventTypes: Array<AccountEventType>): (number | null) {
        const CURRENCY_AMOUNT = 500;
        const MONTHS_AMOUNT = 5;
        const TWITCH_REWARD_AMOUNT = 50;

        for (const eventType of accountEventTypes) {
            switch (eventType) {
                case AccountEventType.TrovoGiftSubscribersChannel:
                case AccountEventType.TwitchGiftSubscribersViewer:
                case AccountEventType.TwitchGiftSubscribersChannel:
                case AccountEventType.TwitchHypeTrain:
                case AccountEventType.TwitchSubscribers:
                case AccountEventType.YoutubeMembers:
                    return MONTHS_AMOUNT;

                case AccountEventType.DonattyDonate:
                case AccountEventType.PaypalDonate:
                case AccountEventType.TrovoRaids:
                case AccountEventType.TrovoSpellElixir:
                case AccountEventType.TrovoSpellMana:
                case AccountEventType.TwitchBits:
                case AccountEventType.TwitchRaids:
                case AccountEventType.YoutubeSuperchat:
                    return CURRENCY_AMOUNT;

                case AccountEventType.TwitchChannelPoints:
                    return TWITCH_REWARD_AMOUNT;
            }
        }

        return null;
    }

    private generateAlertsWidgetCurrency(accountEventTypes: Array<AccountEventType>): (WidgetCurrency | null) {
        for (const eventType of accountEventTypes) {
            switch (eventType) {
                case AccountEventType.DonattyDonate:
                case AccountEventType.YoutubeSuperchat:
                    return WidgetCurrency.Ruble;

                case AccountEventType.TwitchBits:
                    return WidgetCurrency.Bit;

                case AccountEventType.TwitchSubscribers:
                case AccountEventType.TwitchGiftSubscribersViewer:
                case AccountEventType.YoutubeMembers:
                    return WidgetCurrency.Month;

                case AccountEventType.TwitchGiftSubscribersChannel:
                case AccountEventType.TrovoGiftSubscribersChannel:
                    return WidgetCurrency.Subscription;

                case AccountEventType.TwitchHypeTrain:
                    return WidgetCurrency.Level;

                case AccountEventType.TwitchRaids:
                case AccountEventType.TrovoRaids:
                    return WidgetCurrency.Viewer;

                case AccountEventType.TwitchChannelPoints:
                    return WidgetCurrency.Points;

                case AccountEventType.TrovoSpellElixir:
                    return WidgetCurrency.TrovoElixir;

                case AccountEventType.TrovoSpellMana:
                    return WidgetCurrency.TrovoMana;
            }
        }

        return null;
    }

    public generateAlertsWidgetMessage(accountEventTypes: AccountEventType[]): string {
        for (const eventType of accountEventTypes) {
            switch (eventType) {
                case AccountEventType.DonattyDonate:
                case AccountEventType.PaypalDonate:
                case AccountEventType.TwitchBits:
                case AccountEventType.TwitchGiftSubscribersViewer:
                case AccountEventType.TwitchChannelPoints:
                case AccountEventType.YoutubeSuperchat:
                    return "В каждом сердце звучала музыка, а если это сердце было молодо, то песня рвалась с губ. " +
                        "Радость была на каждом лице, и весна - в походке каждого";
            }
        }

        return "";
    }

    private generateAlertsWidgetSubscriber(accountEventTypes: AccountEventType[]): string {
        for (const eventType of accountEventTypes) {
            switch (eventType) {
                case AccountEventType.DonattyDonate:
                case AccountEventType.PaypalDonate:
                case AccountEventType.TwitchBits:
                case AccountEventType.TwitchFollowers:
                case AccountEventType.TwitchSubscribers:
                case AccountEventType.TwitchGiftSubscribersChannel:
                case AccountEventType.TwitchGiftSubscribersUpgrade:
                case AccountEventType.TwitchSubscribersUpgrade:
                case AccountEventType.TwitchChannelPoints:
                case AccountEventType.TwitchHost:
                case AccountEventType.TwitchRaids:
                case AccountEventType.TrovoSubscribers:
                case AccountEventType.TrovoGiftSubscribersChannel:
                case AccountEventType.TrovoFollowers:
                case AccountEventType.TrovoRaids:
                case AccountEventType.TrovoSpellElixir:
                case AccountEventType.TrovoSpellMana:
                case AccountEventType.YoutubeMembers:
                case AccountEventType.YoutubeSubscribers:
                case AccountEventType.YoutubeSuperchat:
                    return "Username";

                case AccountEventType.TrovoGiftSubscribersViewer:
                    return "TrovoGiver";

                case AccountEventType.TwitchGiftSubscribersViewer:
                    return "TwitchGiver";
            }
        }

        return "";
    }

    private getTrovoData(accountEventTypes: Array<AccountEventType>): DonationData["trovo"] {
        if (!accountEventTypes.length) {
            throw new Error("accountEventTypes required");
        }

        const subscription = {
            giftedBy: "TrovoGiver",
            giftedTo: "Username",
            level: 5,
            tier: 2,
        };

        const spell = {
            name: "Единорог",
            value: 300,
        };

        return {
            spell,
            subscription
        };
    }

    private getTwitchData(accountEventTypes: Array<AccountEventType>): DonationData["twitch"] {
        if (!accountEventTypes.length) {
            throw new Error("accountEventTypes required");
        }

        const giftedBy = "TwitchGiver";
        const subscriber = "TwitchGiver";
        const giftedTo = "Username";
        const oldTier = 2;
        const tier = 3;

        const giftSubUpgrade = {
            oldTier,
            giftedBy
        };

        const hypeTrain = {
            level: 5,
        };

        const subscription = {
            streak: 3,
            total: 12,
            tier
        };

        const subUpgrade = {
            tier,
            oldTier
        };

        const subGiftViewer = {
            subscriber,
            giftedTo,
            tier
        };

        const rewards = {
            rewardId: "f6bd78c4-a486-43ec-afc4-18608e0876e2",
            rewardName: "Прыгнуть",
            subscriber: "TwitchSub",
            message: "user message",
            value: 1,
        };

        const twitchEventsData: { [key in AccountEventType]?: DonationData["twitch"] } = {
            [AccountEventType.TwitchFollowers]: {},
            [AccountEventType.TwitchSubscribers]: {subscription},
            [AccountEventType.TwitchGiftSubscribersViewer]: {subscription: subGiftViewer},
            [AccountEventType.TwitchGiftSubscribersChannel]: {subscription},
            [AccountEventType.TwitchGiftSubscribersUpgrade]: {subscription: giftSubUpgrade},
            [AccountEventType.TwitchSubscribersUpgrade]: {subscription: subUpgrade},
            [AccountEventType.TwitchChannelPoints]: {rewards},
            [AccountEventType.TwitchHypeTrain]: {hypeTrain},
            [AccountEventType.YoutubeMembers]: {subscription},
            [AccountEventType.YoutubeSubscribers]: {subscription},
            [AccountEventType.YoutubeSuperchat]: {subscription},
        };

        return accountEventTypes
            .map(eventType => twitchEventsData[eventType])
            .reduce((previous, current) => Object.assign(previous, current), {});
    }

    private totalWidgetAmount = 10000;
}

const makeTestEvent: { [key in StreamerEventType]?: () => object } = {
    [StreamerEventType.DonattyDonation]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.DonattyDonation,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
        method: PaymentMethodType.Card,
        amount: Math.ceil(1000 * Math.random() * Math.random()),
        commission: 1.37,
        currency: WidgetCurrency.Ruble,
        purpose: "На улучшение канала",
    }),
    [StreamerEventType.PaypalDonation]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.PaypalDonation,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
        amount: Math.ceil(1000 * Math.random() * Math.random()),
        currency: WidgetCurrency.Ruble,
        purpose: "На улучшение канала",
    }),
    [StreamerEventType.TwitchBit]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchBit,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
        amount: Math.ceil(1000 * Math.random() * Math.random()),
        currency: WidgetCurrency.Bit,
    }),
    [StreamerEventType.TwitchChannelPoints]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchChannelPoints,
        message: Util.randomMessage(),
        subscriber: Util.randomName(),
        rewardId: "daeeabd6-d80c-4d58-bb29-600bd1b98e89",
        title: "test reward title",
        description: "test reward description",
        amount: Math.ceil(100 * Math.random() * Math.random()),
    }),
    [StreamerEventType.TwitchFollower]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchFollower,
        displayName: Util.randomName(),
    }),
    [StreamerEventType.TwitchHost]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchHost,
        displayName: Util.randomName(),
    }),
    [StreamerEventType.TwitchHypeTrain]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchHypeTrain,
        level: Math.ceil(Math.random() * 5),
    }),
    [StreamerEventType.TwitchRaid]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchRaid,
        displayName: Util.randomName(),
        viewers: Math.floor(1000 * Math.random() * Math.random()),
    }),
    [StreamerEventType.TwitchSubscriber]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchSubscriber,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
        months: 2 + Math.ceil(Math.random() * 10),
        streak: 2,
        tier: Math.ceil(Math.random() * 3),
        total: Math.ceil(Math.random() * 100),
    }),
    [StreamerEventType.TwitchSubscriberGiftViewer]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchSubscriberGiftViewer,
        giftedBy: Util.randomName(),
        // giftedTo: Util.randomName(), // Backend never send this
        message: Util.randomMessage(),
        months: Math.ceil(Math.random() * 5),
        tier: Math.ceil(Math.random() * 3),
    }),
    [StreamerEventType.TwitchSubscriberGiftChannel]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchSubscriberGiftChannel,
        giftedBy: Util.randomName(),
        months: Math.ceil(Math.random() * 10),
        monthsTotal: 10 + Math.ceil(Math.random() * 100),
        tier: Math.ceil(Math.random() * 3),
    }),
    [StreamerEventType.TwitchSubscriberGiftUpgrade]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TwitchSubscriberGiftUpgrade,
        giftedBy: Util.randomName(),
        // giftedTo: Util.randomName(), // Backend never send this
    }),
    [StreamerEventType.TwitchSubscriberUpgrade]: () => {
        const tier = Math.ceil(Math.random() * 3);
        return {
            refId: "" + performance.now(),
            date: new Date().toISOString(),
            type: StreamerEventType.TwitchSubscriberUpgrade,
            displayName: Util.randomName(),
            oldTier: tier - 1,
            tier,
        };
    },
    [StreamerEventType.TrovoSubscriber]: (): Partial<TrovoSubscriberEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoSubscriber,
        subscriber: Util.randomName(),
        tier: Math.ceil(Math.random() * 3),
    }),
    [StreamerEventType.TrovoSubscriberGiftViewer]: (): Partial<TrovoSubscriberGiftViewerEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoSubscriberGiftViewer,
        giftedBy: Util.randomName(),
        giftedTo: Util.randomName(),
        tier: Math.ceil(Math.random() * 3),
    }),
    [StreamerEventType.TrovoSubscriberGiftChannel]: (): Partial<TrovoSubscriberGiftChannelEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoSubscriberGiftChannel,
        giftedBy: Util.randomName(),
        months: Math.ceil(Math.random() * 100),
        tier: Math.ceil(Math.random() * 3),
    }),
    [StreamerEventType.TrovoFollower]: (): Partial<TrovoFollowerEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoFollower,
        displayName: Util.randomName(),
    }),
    [StreamerEventType.TrovoRaid]: (): Partial<TrovoRaidEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoRaid,
        displayName: Util.randomName(),
        viewers: Math.ceil(Math.random() * 100),
    }),
    [StreamerEventType.TrovoSpellElixir]: (): Partial<TrovoSpellEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoSpellElixir,
        subscriber: Util.randomName(),
        title: "HYPE",
        amount: Math.ceil(Math.random() * 5),
        value: Math.ceil(Math.random() * 5) * 100,
    }),
    [StreamerEventType.TrovoSpellMana]: (): Partial<TrovoSpellEventResponse> => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.TrovoSpellMana,
        subscriber: Util.randomName(),
        title: "Stay Safe",
        amount: Math.ceil(Math.random() * 5),
        value: Math.ceil(Math.random() * 5) * 100,
    }),
    [StreamerEventType.YoutubeFollower]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.YoutubeFollower,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
    }),
    [StreamerEventType.YoutubeSponsor]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.YoutubeSponsor,
        displayName: Util.randomName(),
        months: Math.ceil(Math.random() * 10),
    }),
    [StreamerEventType.YoutubeSuperchat]: () => ({
        refId: "" + performance.now(),
        date: new Date().toISOString(),
        type: StreamerEventType.YoutubeSuperchat,
        displayName: Util.randomName(),
        message: Util.randomMessage(),
        amount: Math.ceil(1000 * Math.random() * Math.random()),
        currency: WidgetCurrency.Ruble,
    }),
};

const TOP_WIDGET_DATA = [
    {name: "Pythagoras Four", value: 661719},
    {name: "Jacob", value: 481516},
    {name: "Philip J. Fry", value: 430000},
    {name: "Finn the Human", value: 420000},
    {name: "Rick Sanchez", value: 380000},
    {name: "Jake the Dog", value: 320000},
    {name: "Morty Smith", value: 315137},
    {name: "William Jones", value: 314159},
    {name: "Princess Bubblegum", value: 300000},
    {name: "Summer Smith", value: 299999},
    {name: "Hans Sprungfeld", value: 290000},
    {name: "Jerry Smith", value: 280000},
    {name: "Marceline the Vampire Queen", value: 270000},
    {name: "Elizabeth Ormeli", value: 260789},
    {name: "Booker Comstock", value: 190474},
    {name: "👔-a-👔", value: 177147},
    {name: "Pierre de Fermat", value: 170801},
    {name: "🧊 👑", value: 100000},
    {name: "Ralph Wiggum", value: 99999},
    {name: "Turanga Leela", value: 80000},
    {name: "🦆 Huey", value: 69000},
    {name: "Rajesh Koothrappali", value: 58008},
    {name: "Robert Terwilliger", value: 24601},
    {name: "HAL", value: 9000},
    {name: "BMO", value: 4000},
    {name: "🦆 Dewey", value: 3100},
    {name: "🦆 Louie", value: 1800},
    {name: "Bernard de Bessy", value: 1729},
    {name: "Brown Riverside", value: 1640},
    {name: "Lumpy Space Princess", value: 1000},
    {name: "Vladimir The Honking", value: 666},
    {name: "🦆 Launchpad McQuack", value: 500},
    {name: "Plimpton", value: 322},
    {name: "Victor Frankenstein", value: 220},
    {name: "Lady Rainicorn", value: 150},
    {name: "Stanley Serum", value: 114},
    {name: "Sheldon Cooper", value: 73},
    {name: "Deep Thought", value: 42},
    {name: "Сarrie Notis", value: 30},
    {name: "Charles Burns", value: 1},
];
