import {AfterViewInit, Directive, ElementRef, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {MatTabGroup} from "@angular/material/tabs";
import {takeUntil} from "rxjs/operators";
import {PlatformInfoService} from "./platform-info.service";

/* @See https://github.com/angular/components/issues/9592#issuecomment-739962087 */
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: "mat-tab-group[ffScrollFix]"
})
export class MatTabGroupFfScrollFixDirective implements AfterViewInit, OnDestroy {

    private readonly matTabGroupEl: HTMLElement = this.el.nativeElement;
    private readonly destroy$: Subject<void> = new Subject();

    constructor(private readonly matTabGroup: MatTabGroup,
                private readonly el: ElementRef,
                private readonly platformInfoService: PlatformInfoService) {
    }

    public ngAfterViewInit() {
        if (!this.platformInfoService.isFirefox) {
            return;
        }
        const orig = this.matTabGroup._handleClick.bind(this.matTabGroup);
        this.matTabGroup._handleClick = (tab, tabHeader, index) => {
            if (!tab.disabled) {
                this.matTabGroupEl.style.minHeight = this.matTabGroupEl.clientHeight + "px";
            }
            return orig(tab, tabHeader, index);
        };
        this.matTabGroup.animationDone.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.matTabGroupEl.style.minHeight = "unset";
        });
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }
}
