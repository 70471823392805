import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
    AlertWidgetData, AlertWidgetStyle, WidgetProps
} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {WidgetType} from "../../../../../../../../../shared/src/lib/models/widget";

@Component({
    selector: "app-widget-tab-style",
    templateUrl: "./widget-tab-style.component.html",
    styleUrls: ["./widget-tab-style.component.scss"]
})
export class WidgetTabStyleComponent {

    @Input()
    public widgetType: WidgetType;

    @Input()
    public widgetProps: WidgetProps;

    @Output()
    public widgetPropsChange = new EventEmitter<WidgetProps>();

    public beforeChange() {
        if (this.layoutSettings[this.widgetType] && this.layoutSettings[this.widgetType][this.widgetProps.style.layout]) {
            this.layoutSettings[this.widgetType][this.widgetProps.style.layout](this.widgetProps);
        }
        this.widgetPropsChange.emit(this.widgetProps);
    }

    private layoutSettings: { [key in WidgetType]?: { [layout: number]: (widgetProps: WidgetProps) => void } } = {
        [WidgetType.Alert]: {
            0: (widgetProps: WidgetProps<AlertWidgetData, AlertWidgetStyle>) => {
                widgetProps.style.header.font.align = "center";
                widgetProps.style.message.font.align = "center";
            },
            1: (widgetProps: WidgetProps<AlertWidgetData, AlertWidgetStyle>) => {
                widgetProps.style.header.font.align = "center";
                widgetProps.style.message.font.align = "center";
            },
            2: (widgetProps: WidgetProps<AlertWidgetData, AlertWidgetStyle>) => {
                widgetProps.style.header.font.align = "center";
                widgetProps.style.message.font.align = "center";
            },
            3: (widgetProps: WidgetProps<AlertWidgetData, AlertWidgetStyle>) => {
                widgetProps.style.header.font.align = "left";
                widgetProps.style.message.font.align = "left";
            },
            4: (widgetProps: WidgetProps<AlertWidgetData, AlertWidgetStyle>) => {
                widgetProps.style.header.font.align = "right";
                widgetProps.style.message.font.align = "right";
            },
        },
    };
}
