import {
    ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren
} from "@angular/core";
import {WidgetService} from "../../services/widgets/widget.service";
import {UserService} from "../../services/user/user.service";
import {Router} from "@angular/router";
import {DonateThemeService} from "../../services/donate-theme.service";
import {filter, map, shareReplay, takeUntil} from "rxjs/operators";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {IGenericWidgetInfo, WidgetType} from "../../../../../shared/src/lib/models/widget";
import {WidgetGroupService} from "../../services/widgets/widget_groups/widget-group.service";
import {WidgetGroup, WidgetGroupSequence, WidgetGroupType} from "../../services/widgets/widget_groups/widget-group";
import {WidgetListItemAction, WidgetListItemEventPayload} from "./list-item/widgets-list-item.component";
import {CommandService} from "../../services/command.service";
import {ToastrService} from "ngx-toastr";
import {Options as SortableJsOptions} from "sortablejs";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {ClipboardService} from "ngx-clipboard";
import {SidebarService} from "../../../../../shared/src/lib/sidebar/sidebar.service";
import {WidgetsMenuComponent} from "./widgets-menu/widgets-menu.component";
import {Util} from "../../../../../shared/src/lib/util";
import {combineLatest, Subject} from "rxjs";
import {User} from "projects/shared/src/lib/models/user";
import {DonatePageTheme} from "../../../../../shared/src/lib/models/theme";

type WidgetActionHandlers = Map<WidgetListItemAction, (widget: IGenericWidgetInfo) => void>;

@Component({
    selector: "app-widgets",
    templateUrl: "./widgets.component.html",
    styleUrls: ["./widgets.component.sass"]
})
export class WidgetsComponent implements OnInit, OnDestroy {

    public loading = true;
    public widgetGroups: Array<WidgetGroup>;
    private readonly destroy$: Subject<void> = new Subject<void>();
    public readonly sortableWidgetsOptions: SortableJsOptions = {
        scrollSensitivity: 100,
        forceFallback: true,
        dataIdAttr: "data-widgetid",
        draggable: "app-widgets-list-item",
        scrollFn: (offsetX: number) => {
            if (offsetX > 0) {
                return;
            }
            return "continue";
        },
        ghostClass: "sortable-ghost-widget",
        dragClass: "sortable-drag-widget",
        group: {
            name: "widgets",
            put: (to, from, dragEl: HTMLElement) => {
                if (!from.el.dataset.groupid) {
                    return false;
                }
                const toGroup = this.findGroupById(to.el.dataset.groupid);
                if (toGroup.type === WidgetGroupType.DonationPages) {
                    return false;
                }
                if (toGroup.type === WidgetGroupType.Ungrouped) {
                    return true;
                }
                if (!toGroup.isAlertGroup) {
                    return true;
                }
                const widgetType = this.findGroupById(from.el.dataset.groupid)
                    .widgets.find(w => w.getId() === dragEl.dataset.widgetid).getType();
                return widgetType === WidgetType.Alert;
            },
        },
        onUpdate: async (evt) => {
            const groupToUpdate = this.widgetGroups.find(group => group.id === evt.to.dataset.groupid);
            await this.widgetGroupService.updateWidgetsOrder(groupToUpdate);
        },
        onAdd: async (event) => {
            const fromGroup = this.widgetGroups.find(group => group.id === event.from.dataset.groupid);
            const toGroup = this.widgetGroups.find(group => group.id === event.to.dataset.groupid);
            const widget = toGroup.widgets.find(w => w.getId() === event.item.dataset.widgetid);
            await this.widgetGroupService.putWidgetIntoGroup(widget, toGroup, fromGroup);
        },
    };

    public readonly sortableWidgetGroupsOptions: SortableJsOptions = {
        scrollSensitivity: 100,
        forceFallback: true,
        dataIdAttr: "data-groupid",
        handle: ".group-drag-handle",
        scrollFn: (offsetX: number) => {
            if (offsetX > 0) {
                return;
            }
            return "continue";
        },
        group: {
            name: "widget-groups",
            put: (to, from) => {
                if (!from.el.dataset.widgetId) {
                    return false;
                }
            },
        },
        onSort: () => {
            this.commandService.run({
                id: "sortGroups",
                timeout: 1500,
                handler: () => {
                    this.widgetGroupService.updateGroupsOrder(this.widgetGroups);
                },
            });
        },
        onMove: (evt) => {
            if (evt.related) {
                const relatedClassList = evt.related.classList;
                return !relatedClassList.contains("group-donation-pages") && !relatedClassList.contains("group-ungrouped");
            }
        },
    };

    @ViewChildren("groupName")
    private groupNames: QueryList<ElementRef<HTMLHeadElement>>;

    public readonly donationWidgetName$ = combineLatest([
        this.userService.currentUser$.pipe(filter(Boolean)),
        this.userStoreService.donatePageTheme$,
    ]).pipe(
        map(([user, theme]: [User, DonatePageTheme]) => {
            if (theme?.name?.length && theme.name !== "Страница доната" || !user) {
                return theme.name;
            } else {
                return user.name;
            }
        }),
        shareReplay(1),
        takeUntil(this.destroy$),
    );

    constructor(private readonly widgetService: WidgetService,
                private readonly widgetGroupService: WidgetGroupService,
                private readonly router: Router,
                private readonly userStoreService: DonateThemeService,
                public readonly userService: UserService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private readonly dataLayerService: DataLayerService,
                private readonly commandService: CommandService,
                private readonly toastr: ToastrService,
                private readonly environmentService: EnvironmentService,
                private readonly clipboardService: ClipboardService,
                private readonly sidebar: SidebarService) {
    }

    public async ngOnInit(): Promise<void> {
        this.widgetGroupService.widgetGroups$.subscribe(this.onGroupsFetched.bind(this));

        this.dataLayerService.emit({
            eventCategory: EventCategory.Panel,
            eventAction: EventAction.View,
            eventLabel: "widgets"
        });
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public openMenu(widgetGroup?: WidgetGroup) {
        this.sidebar.open(WidgetsMenuComponent, widgetGroup);
    }

    public async saveExpanded(group: WidgetGroup, isExpanded: boolean): Promise<void> {
        if (isExpanded === group.isExpanded) {
            return;
        }
        group.isExpanded = isExpanded;
        await this.widgetGroupService.updateGroup(group);
    }

    public async onDeleteWidgetGroupClick(widgetGroup: WidgetGroup) {
        await this.widgetGroupService.deleteGroupDeferred(widgetGroup, 10000);
    }

    public onCopyWidgetGroupClick(widgetGroup: WidgetGroup) {
        const copyCount = this.widgetGroups.filter((group => group.name.toLowerCase().startsWith("копия"))).length;
        let nextCopyNoStr: string;
        if (copyCount === 0) {
            nextCopyNoStr = `Копия`;
        } else {
            nextCopyNoStr = `Копия ${copyCount + 1}`;
        }

        const COPY_REGEX = /Копия\s+(\d+\s+)?/;
        const originalName = widgetGroup.name;
        let newName: string;
        if (COPY_REGEX.test(originalName)) {
            newName = originalName.replace(COPY_REGEX, `${nextCopyNoStr} `);
        } else {
            newName = `${nextCopyNoStr} ${originalName}`;
        }

        this.widgetGroupService.copyGroup(widgetGroup, newName);
    }

    private async copyWidget(widget: IGenericWidgetInfo) {
        const original = await this.widgetService.get(widget.getId());
        const originalName = original.getName();

        const widgetGroup = this.findGroupById(widget.getGroupId());
        const copiesCount = widgetGroup.widgets.filter(w => w.getName().toLowerCase().startsWith("копия")).length;

        const nextCopyNo = (copiesCount + 1);

        let nextCopyNoStr: string;
        if (nextCopyNo === 1) {
            nextCopyNoStr = `Копия`;
        } else {
            nextCopyNoStr = `Копия ${nextCopyNo}`;
        }

        let nextCopyName: string;

        const COPY_REGEX = /Копия\s+(\d+\s+)?/;
        if (COPY_REGEX.test(originalName)) {
            nextCopyName = originalName.replace(COPY_REGEX, `${nextCopyNoStr} `);
        } else {
            nextCopyName = `${nextCopyNoStr} ${originalName}`;
        }

        const copied = await this.widgetService.create({
            type: original.getType(),
            name: nextCopyName,
            props: original.getGenericProps(),
            groupId: original.getGroupId(),
        });
        this.dataLayerService.emit({
            eventCategory: EventCategory.Widget,
            eventAction: EventAction.Copy,
            eventLabel: original.getType().toLowerCase(),
            eventValue: "success"
        });

        await this.router.navigateByUrl(`/widgets/${copied.getId()}/edit`);
    }

    private async deleteWidget(widget: IGenericWidgetInfo) {
        const widgetGroup = this.findGroupById(widget.getGroupId());
        await this.widgetGroupService.deleteWidgetDeferred(widgetGroup, widget, 5000);
    }

    public async onRenameWidgetGroupClick(widgetGroup: WidgetGroup) {
        const widgetNameEl: HTMLHeadElement = this.groupNames.find(groupName => {
            return groupName.nativeElement.dataset.groupid === widgetGroup.id;
        }).nativeElement;
        setTimeout(() => {
            return Util.selectElementContents(widgetNameEl);
        }, 0);
    }

    public onCopyWidgetGroupLinkClick(event: Event, widgetGroup: WidgetGroup): void {
        const base = this.environmentService.widgetsUri;
        const link = this.widgetGroupService.formatWidgetGroupLink(widgetGroup, false);
        this.clipboardService.copyFromContent(`${base}/${link}`);
        this.toastr.success("Ссылка скопирована");
    }

    public onGroupNameInput(event: Event, widgetGroup: WidgetGroup) {
        const eventTarget = (event.target as HTMLHeadElement);
        const caretPosition = window.getSelection().focusOffset;
        widgetGroup.name = eventTarget.innerText;
        eventTarget.innerText = widgetGroup.name;
        if (eventTarget.innerText.length > 0) {
            // restore caret after widgetGroup.name changes
            requestAnimationFrame(() => Util.setCaret(eventTarget.firstChild, caretPosition));
        }
        this.commandService.run({
            id: `setGroupName-${widgetGroup.id}`,
            timeout: 1500,
            handler: async () => {
                await this.widgetGroupService.updateGroup(widgetGroup);
            },
        });
    }

    public trackWidgetsBy(i: number, widget: IGenericWidgetInfo) {
        return i + widget.getId();
    }

    public trackGroupsBy(i: number, widgetGroup: WidgetGroup) {
        return widgetGroup.id;
    }

    public async onAlertQueueToggle(isChecked: boolean, widgetGroup: WidgetGroup) {
        widgetGroup.sequence = isChecked ? WidgetGroupSequence.Serial : WidgetGroupSequence.Parallel;
        await this.widgetGroupService.updateGroup(widgetGroup);
    }

    private widgetActionHandlers: WidgetActionHandlers = new Map([
        [WidgetListItemAction.copy, widget => this.copyWidget(widget)],
        [WidgetListItemAction.delete, widget => this.deleteWidget(widget)],
    ]);

    public async widgetAction(payload: WidgetListItemEventPayload) {
        if (this.widgetActionHandlers.has(payload.action)) {
            this.widgetActionHandlers.get(payload.action)(payload.widget);
        }
    }

    private findGroupById(groupId: string): WidgetGroup {
        return this.widgetGroups.find(widgetGroup => widgetGroup.id === groupId);
    }

    private onGroupsFetched(groups: Array<WidgetGroup>): void {
        const isInitialFill = (!this.widgetGroups?.length && groups.length);

        this.widgetGroups = groups;

        if (isInitialFill) {
            this.loading = false;
        }
        this.changeDetectorRef.detectChanges();
    }
}
