<mat-menu #filterMenu
          class="filters-menu"
          [backdropClass]="'filter-backdrop'"
          [hasBackdrop]="mobile">
    <ng-container *ngFor="let section of sections">
        <ng-container *ngFor="let item of section"
                      [ngTemplateOutlet]="eventTypeItem"
                      [ngTemplateOutletContext]="item"></ng-container>
        <mat-divider></mat-divider>
    </ng-container>
    <div [ngClass]="{mobile: mobile}"
         mat-menu-item [disableRipple]="true"
         (click)="$event.stopPropagation()">
        <checkbox id="donatty-targets" type="eye"
                  [(value)]="showDonationTargets"
                  labelPadding="12px">
            Donatty – Цели
        </checkbox>
    </div>
</mat-menu>

<ng-template #eventTypeItem let-type="type" let-label="label">
    <div mat-menu-item [disableRipple]="true"
         [ngClass]="{mobile: mobile}"
         (click)="$event.stopPropagation()">
        <checkbox [id]="type" [value]="isEventEnabled(type)"
                  (valueChange)="setEventEnabled(type, $event)"
                  labelPadding="18px">
            {{label}}
        </checkbox>
    </div>
</ng-template>
