import {Component, EventEmitter, Input, Output} from "@angular/core";
import * as _ from "lodash";
import {Util} from "../../../../../shared/src/lib/util";

@Component({
    selector: "app-confirmation-code",
    templateUrl: "./confirmation-code.component.html",
    styleUrls: ["./confirmation-code.component.scss"]
})
export class ConfirmationCodeComponent {
    public confirmCode: string[] = [];

    @Input()
    public set code(c: string) {
        this.confirmCode = c.split("");
    }

    @Output()
    public changed = new EventEmitter<string>();

    public get isValid(): boolean {
        if (this.confirmCode.length < 6) {
            return false;
        }

        return _.every(this.confirmCode, ch => {
            return !isNaN(+ch);
        });
    }

    public keyDown(event: KeyboardEvent, num: number): void {
        let eventKey;
        if (event.key === "Unidentified") {
            eventKey = this.confirmCode[num];
        } else {
            eventKey = event.key.trim();
        }
        if (event.code === "Backspace") {
            event.preventDefault();
            if (!this.confirmCode[num] && num) {
                Util.selectElementContents(document.getElementById(`c-${--num}`));
            }
            this.confirmCode[num] = "";
            this.changed.emit(this.confirmCode.join(""));
            return;
        }

        if (event.code === "ArrowLeft" && num > 0) {
            event.preventDefault();
            document.getElementById(`c-${--num}`).focus();
            return;
        }

        if (event.code === "ArrowRight" && num < 5) {
            event.preventDefault();
            document.getElementById(`c-${++num}`).focus();
            return;
        }

        if (!eventKey || isNaN(+event.key)) {
            event.preventDefault();
            return;
        }
    }

    public keyUp(event: KeyboardEvent, num: number): void {
        let eventKey;
        if (event.key === "Unidentified") {
            eventKey = this.confirmCode[num];
        } else {
            eventKey = event.key.trim();
        }

        if (eventKey && !isNaN(+eventKey)) {
            this.confirmCode[num] = eventKey;
            this.changed.emit(this.confirmCode.join(""));

            if (num < 5) {
                document.getElementById(`c-${++num}`).focus();
            }

            return;
        }
    }
}
