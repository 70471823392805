import {Component, OnInit} from "@angular/core";
import {FinanceService} from "../../services/finance/finance.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";

@Component({
    selector: "app-finance",
    templateUrl: "./finance.component.html",
    styleUrls: ["./finance.component.scss"]
})
export class FinanceComponent implements OnInit {

    constructor(public readonly financeService: FinanceService,
                private readonly dataLayerService: DataLayerService) {
    }

    public ngOnInit(): void {
        this.dataLayerService.emit({
            eventCategory: EventCategory.Panel,
            eventAction: EventAction.View,
            eventLabel: "finances"
        });
    }

}
