import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {QuestService} from "./quest.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [],
    providers: [
        QuestService
    ]
})
export class QuestModule {
}
