import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {distinctUntilChanged, filter, takeUntil} from "rxjs/operators";

@Component({
    selector: "app-widget-edit-open-range",
    templateUrl: "./widget-edit-open-range.component.html",
    styleUrls: ["./widget-edit-open-range.component.scss"]
})
export class WidgetEditOpenRangeComponent implements OnInit, OnChanges {

    private readonly initialValue = 0;

    @Input() public readonly units: string;
    @Input() public min = this.initialValue;
    @Input() public max = Infinity;
    @Input() public from: number;
    @Input() public to: number;
    @Output() public readonly fromChange = new EventEmitter<number>();
    @Output() public readonly toChange = new EventEmitter<number>();

    public readonly fromControl = new FormControl<number>(0, Validators.compose([
        Validators.required,
        (control: AbstractControl): ValidationErrors => {
            const errors: { [key: string]: true | string } = {};
            if (isNaN(control.value)) {
                errors.isNaN = true;
            } else if (control.value < this.min) {
                errors.min = this.min.toString();
            } else if (control.value > this.max) {
                errors.max = this.max.toString();
            } else if (this.toControl?.value !== null && control.value > this.toControl?.value) {
                errors.max = this.toControl?.value.toString();
            }
            if (Object.keys(errors).length) {
                return errors;
            }
            return null;
        }
    ]));

    public readonly toControl = new FormControl<number>(0, Validators.compose([
        (control: AbstractControl): ValidationErrors => {
            if (control.value === null) {
                return null;
            }
            const errors: { [key: string]: true | string } = {};
            if (isNaN(control.value)) {
                errors.isNaN = true;
            } else if (control.value > this.max) {
                errors.max = this.max.toString();
            } else if (control.value < this.min) {
                errors.min = this.min.toString();
            } else if (this.fromControl.value > control.value) {
                errors.min = this.fromControl.value.toString();
            }
            if (Object.keys(errors).length) {
                return errors;
            }
            return null;
        }
    ]));

    public readonly formGroup = new FormGroup({from: this.fromControl, to: this.toControl});

    private readonly destroy$: Subject<void> = new Subject();

    public ngOnInit() {
        this.fromControl.setValue(this.from ? this.from : 0);
        this.toControl.setValue(this.to ? this.to : null);
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();

        this.fromControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.toControl.updateValueAndValidity({emitEvent: false}));
        this.toControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.fromControl.updateValueAndValidity({emitEvent: false}));

        this.formGroup.valueChanges.pipe(
            filter(() => this.formGroup.valid),
            distinctUntilChanged((a, b) => a.from - b.from + a.to - b.to === 0),
            takeUntil(this.destroy$),
        ).subscribe(range => {
            this.fromChange.emit(range.from);
            this.toChange.emit(range.to);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.from && !changes.from.firstChange) {
            const newFromValue = changes.from.currentValue;
            this.fromControl.setValue(newFromValue !== null ? newFromValue : 0);
        }
        if (changes.to && !changes.to.firstChange) {
            const newToValue = changes.to.currentValue;
            this.toControl.setValue(newToValue !== null ? newToValue : null);
        }
        if (this.fromControl.value < this.min) {
            this.fromControl.setValue(this.min);
        }
        if (this.toControl.value > this.max) {
            this.toControl.setValue(this.max);
        }
    }

}
