<header>
    <h2 mat-dialog-title>Редактировать адрес страницы доната</h2>
</header>
<div mat-dialog-content>
    <p>Выберите новый адрес страницы доната.</p>
    <form [formGroup]="formGroup">
        <mat-form-field appearance="outline">
            <span matPrefix>https://donatty.com/</span>
            <input formControlName="userName" matInput
                   [attr.cdkFocusInitial]="formGroup.controls.userName.enabled ? '' : null"/>
            <span matSuffix>
                <mat-progress-spinner *ngIf="formGroup.controls.userName.pending"
                                      color="primary"
                                      diameter="20"
                                      strokeWidth="2"
                                      mode="indeterminate">
                </mat-progress-spinner>
            </span>
            <mat-error *ngIf="formGroup.controls.userName.hasError('addressEmpty')">
                Адрес не может быть пустым
            </mat-error>
            <mat-error *ngIf="formGroup.controls.userName.hasError('addressInvalid')">
                Адрес страницы должен содержать только латинские буквы и цифры
            </mat-error>
            <mat-error *ngIf="formGroup.controls.userName.hasError('addressOccupied')">
                Адрес уже занят
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary"
            [disabled]="!hasChanges || formGroup.invalid || formGroup.pending || saveInProgress"
            (click)="saveTrigger$.next()">Сохранить
    </button>
    <button mat-button mat-raised-button class="cancel mat-elevation-z0"
            (click)="onCancelClick()">Отмена
    </button>
</div>
