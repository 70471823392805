import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {LoginResponse, PostLoginRequest, PostLoginResponse} from "./login.api";
import {map} from "rxjs/operators";

export enum LoginAccountType {
    TWITCH = "twitch",
    GOOGLE = "google",
    FACEBOOK = "facebook",
    VK = "vk",
    TROVO = "trovo",
}

@Injectable({
    providedIn: "root"
})
export class LoginService {

    constructor(private readonly environmentService: EnvironmentService,
                private readonly authService: AuthService,
                private readonly http: HttpClient) {
    }

    public login(socialNetwork: LoginAccountType, redirectUrl?: string) {
        const uri = `${this.environmentService.backendApiUri}/auth/${socialNetwork}`;
        const body: PostLoginRequest = {
            returnUrl: this.environmentService.streamerFrontUri + "/login/complete",
        };
        if (redirectUrl) {
            body.redirectUrl = redirectUrl;
        }
        return this.http
            .post<PostLoginResponse>(uri, body)
            .pipe(map(response => response.response));
    }

    public async loginByToken(token: string): Promise<void> {
        const uri = `${this.environmentService.backendApiUri}/auth/tokens/${token}`;

        console.log("loginByToken, logging in by token, uri=", uri);

        try {
            const response = (await this.http.get<LoginResponse>(uri).toPromise()).response;

            this.authService.setAuthenticated({
                expireAt: new Date(response.expireAt),
                accessToken: response.accessToken,
                refreshToken: response.refreshToken
            });

            console.log("loginByToken, succeed, jwt=", response.accessToken);
        } catch (e) {
            console.error("loginByToken, failed, e=", e);
            throw e;
        }
    }

}
