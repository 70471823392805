import {Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "app-payout-error",
    templateUrl: "./payout-error.component.html",
    styleUrls: ["./payout-error.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PayoutErrorComponent {
    enabled = true;

    async repeat() {
        this.enabled = false;
        parent.postMessage("payout-repeat", "*");
    }
}
