<mat-chip-list>
    <mat-chip *ngFor="let voice of selectedVoices"
              [ngClass]="{'is-playing': current.voiceId === voice.id}"
              (click)="togglePlay(voice)">
        <mat-icon [svgIcon]="voice.providerIcon"
                  class="provider-logo"></mat-icon>
        <ng-container [ngTemplateOutlet]="playButton" [ngTemplateOutletContext]="{voice:voice}"></ng-container>
        <mat-icon class="remove-button"
                  [matMenuTriggerFor]="menu"
                  [svgIcon]="selectedVoices.length === 1 ? 'find-replace' : 'close-outline'"
                  (click)="selectedVoices.length === 1 ? replace = true : removeVoice(voice); $event.stopImmediatePropagation();"></mat-icon>
    </mat-chip>
    <mat-chip color="primary" selected
              (click)="replace=false"
              [matMenuTriggerFor]="menu"
              [ngClass]="{ 'disabled' :addDisabled }">
        <mat-icon svgIcon="add"></mat-icon>
        <span>Добавить голос</span>
    </mat-chip>
</mat-chip-list>
<span id="description">{{description}}</span>

<mat-menu #menu="matMenu">
    <ng-container *ngFor="let voicesOfType of voicesMap | keyvalue">
        <h6 class="voice-section-label"
            (click)="$event.stopImmediatePropagation()"
            *ngIf="voicesOfType.value.length">
            {{voiceSystems.get(voicesOfType.key).displayName | uppercase}}
        </h6>
        <button mat-menu-item
                *ngFor="let voice of voicesOfType.value"
                (click)="addVoice(voice)">
            <ng-container [ngTemplateOutlet]="playButton" [ngTemplateOutletContext]="{voice:voice}"></ng-container>
            <mat-icon svgIcon="add"
                      class="add-button"
                      (click)="addVoice(voice); $event.stopImmediatePropagation()"></mat-icon>
        </button>
    </ng-container>
</mat-menu>

<ng-template #playButton let-voice='voice'>
    <mat-icon svgIcon="stop-circle"
              class="stop-button"
              *ngIf="current.voiceId === voice.id"
              (click)="stopPlaying(); $event.stopImmediatePropagation()"></mat-icon>
    <mat-icon svgIcon="play-circle"
              class="play-button"
              *ngIf="current.voiceId !== voice.id"
              (click)="playVoice(voice); $event.stopImmediatePropagation()"></mat-icon>
    <mat-progress-spinner color="primary"
                          diameter="22"
                          strokeWidth="3"
                          [mode]="current.progress ? 'determinate' : 'indeterminate'"
                          *ngIf="current.voiceId === voice.id"
                          [value]="current.progress">
    </mat-progress-spinner>
    <span>{{voice.localName}}</span>
</ng-template>
