import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren} from "@angular/core";
import {WidgetType} from "../../../../../../../shared/src/lib/models/widget";
import {Widget} from "../../../../../../../shared/src/lib/models/widget-props";
import {MatTabGroup} from "@angular/material/tabs";
import {Subject} from "rxjs";

export interface WidgetVisuals {
    tabGroup: MatTabGroup;
    isValid: boolean;
}

@Component({
    selector: "app-widget-edit-visuals",
    templateUrl: "./widget-edit-visuals.component.html",
})
export class WidgetEditVisualsComponent<T extends Widget = Widget> implements AfterViewInit, OnDestroy {

    @Input() widgetId: string;
    @Input() type: WidgetType;
    @Input() widgetProps: T["props"];

    @Output() readonly widgetPropsChange = new EventEmitter();
    @Output() readonly widgetPropsValidChange = new EventEmitter<boolean>();
    @Output() readonly imageChanged = new EventEmitter<File>();
    @Output() readonly audioChanged = new EventEmitter<File>();
    @Output() readonly tabChanged = new EventEmitter<string>();

    @ViewChildren("visuals")
    private visualsList: QueryList<WidgetVisuals>;

    private readonly destroy$ = new Subject<void>();

    public ngAfterViewInit(): void {
        this.visuals.tabGroup.selectedTabChange.subscribe(e =>
            this.tabChanged.next(e.tab.ariaLabel)
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private get visuals(): WidgetVisuals {
        return this.visualsList?.get(0);
    }

    public readonly WidgetType = WidgetType;

    public get isValid(): boolean {
        return !this.visuals || this.visuals?.isValid;
    }

    public selectTab(tabToSelect: string) {
        if (!tabToSelect) {
            return;
        }
        this.visuals?.tabGroup._tabs.find((tab, index) => {
            if (tab.ariaLabel === tabToSelect) {
                this.visuals.tabGroup.selectedIndex = index;
                return true;
            }
        });
    }

}
