import {AlertWidget} from "../../../../shared/src/lib/models/widget-props";
import {AccountEventType} from "../services/accounts/accounts";

export const defaultAlertProps: AlertWidget["props"] = {
    data: {
        imageUri: [],
        audioUri: [],
        sources: {
            accounts: {
                donatty: {
                    [AccountEventType.DonattyDonate]: {
                        isEnabled: true
                    }
                }
            }
        },
        audioVolume: 50,
        showDurationSeconds: 6,
        textDelaySeconds: 0,
        speechDelaySeconds: 0,
        switchDurationMin: 1,
        filters: {
            emoji: false,
            links: true,
            profanity: {
                automatic: true,
                custom: true,
            },
        },
        speechSynthesis: {
            enable: false,
            template: "{message}",
            sequence: "AFTER",
            volume: 100,
            speed: 1,
            voices: [7]
        }
    },
    style: {
        header: {
            font: {
                bold: true,
                size: 40,
                align: "center",
                color: "#FF9600",
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                italic: false,
                colorGradient: "",
            },
            shadow: {
                blur: 2,
                color: "rgba(0, 0, 0, 0.8)",
                enabled: true,
                offsetX: 0,
                offsetY: 2
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: true
            },
            template: "{amount} {unit} задонатил {username}!",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0
            },
        },
        layout: 0,
        message: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                bold: false,
                size: 20,
                align: "center",
                color: "#FFFFFF",
                family: "Roboto",
                italic: false,
                colorGradient: "",
            },
            shadow: {
                blur: 1,
                color: "rgba(0, 0, 0, 0.8)",
                enabled: true,
                offsetX: 0,
                offsetY: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "{message}",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0
            },
        },
        image: {
            scale: 100,
            offsetX: 0,
            offsetY: 0,
            transparency: 0
        }
    }
};
