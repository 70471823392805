import {Quest, QuestGoal, QuestGoalType} from "../../../modules/quest/Quest";

type Article = { title: string, url: string };

export interface ReadArticles extends QuestGoal<QuestGoalType.readArticle> {
    articles: Array<Article>;
    color: string;
}

export const onboardingQuests: Array<Quest<ReadArticles>> = [
    {
        id: "customize-donation-page",
        title: "Настроить свою&nbsp;страницу доната",
        goal: {
            type: QuestGoalType.readArticle,
            isReached: false,
            color: "#5e5ce6",
            articles: [
                {
                    title: "Как настроить страницу доната",
                    url: "//donatty.com/help/stranitsa-donata/kak-nastroit-stranitsu-donata",
                },
                {
                    title: "Как настроить текстовые блоки на странице",
                    url: "//donatty.com/help/stranitsa-donata/kak-nastroit-tekstovyye-bloki-na-stranitse-donata",
                },
            ],
        },
    },
    {
        id: "add-link-on-your-channel",
        title: "Разместить ссылку на страницу доната&nbsp;у&nbsp;себя на&nbsp;канале",
        goal: {
            type: QuestGoalType.readArticle,
            isReached: false,
            color: "#ff375f",
            articles: [
                {
                    title: "Как настроить ссылку для доната на Twitch",
                    url: "//donatty.com/help/stranitsa-donata/nastroit-ssylku-na-stranitsu-donata-v-opisanii-na-twitch",
                },
                {
                    title: "Как настроить ссылку для доната на Youtube",
                    url: "//donatty.com/help/stranitsa-donata/nastroit-ssylku-na-stranitsu-na-youtube",
                },
            ],
        },
    },
    {
        id: "setup-donation-alert",
        title: "Настроить алерт&nbsp;о&nbsp;донате и&nbsp;протестировать его работу",
        goal: {
            type: QuestGoalType.readArticle,
            isReached: false,
            color: "#ff9f0a",
            articles: [
                {
                    title: "Как настроить алерт о донате на стриме",
                    url: "//donatty.com/help/opoveshcheniya-i-alerti/kak-nastroit-alert-o-donate-na-strime",
                },
            ],
        },
    },
    {
        id: "identify-yourself",
        title: "Пройти упрощенную идентификацию",
        goal: {
            type: QuestGoalType.readArticle,
            isReached: false,
            color: "#c44de6",
            articles: [
                {
                    title: "Как пройти идентификацию",
                    url: "//donatty.com/help/vazhnaya-informatsiya/kak-proyti-protseduru-uproshchennoy-identifikatsii",
                },
            ],
        },
    },
    {
        id: "create-obs-panel",
        title: "Вставить в OBS док&#8209;панель от&nbsp;Donatty",
        goal: {
            type: QuestGoalType.readArticle,
            isReached: false,
            color: "#2787f5",
            articles: [
                {
                    title: "Как отслеживать донаты на стриме с&nbsp;мобильного или в OBS",
                    url: "//donatty.com/help/more/kak-otslezhivat-donaty-na-strime-s-mobilnogo-ili-v-obs",
                },
            ],
        },
    },
];
