import {Component, OnInit} from "@angular/core";
import {PageService} from "../../../services/page.service";

@Component({
    selector: "app-identity-thanks",
    templateUrl: "./identity.thanks.component.html",
    styleUrls: ["./identity.thanks.component.sass"]
})
export class IdentityThanksComponent implements OnInit {

    constructor(private readonly pageService: PageService) {
    }

    public ngOnInit() {
        this.pageService.setPageTitleDirectly("Спасибо за терпение - УПРИД | Donatty");
    }

}
