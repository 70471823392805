<mat-list>
    <mat-list-item class="moderation-item" *ngFor="let item of items" @moderationItemAnimation>
        <mat-card>
            <mat-card-title>
                <span>{{item.name}}</span>
                <span>+&nbsp;<span [innerHTML]="item.amount | currency:'RUB':'symbol':'1.0'"></span></span>
            </mat-card-title>
            <mat-card-subtitle>{{item.message}}</mat-card-subtitle>
            <mat-card-footer>
                <lib-progress-spinner [timeout]="item.timeoutSecs * 1000"
                                      (timeoutComplete)="ignore.emit(item)"></lib-progress-spinner>
                <mat-card-actions align="end">
                    <button mat-button (click)="moderate.emit(item)">{{moderationActionText[item.action]}}</button>
                    <button mat-button (click)="ignore.emit(item)">Закрыть</button>
                </mat-card-actions>
            </mat-card-footer>
        </mat-card>
    </mat-list-item>
</mat-list>
