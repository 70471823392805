<div class="we__tab-container">
    <div class="we__header">
        Все события
    </div>

    <div class="we__row">
        <div class="we__label">
            Размер шрифта
        </div>
        <div class="we__controls">
            <mat-slider [(value)]="widgetProps.style.events.font.size"
                        (input)="widgetProps.style.events.font.size = $event.value; widgetPropsChange.emit(widgetProps)"
                        [min]="16" [max]="56" [step]="1"
                        thumbLabel [displayWith]="formatSliderLabel('px')"></mat-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать тень
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.events.shadow.enabled"
                              (change)="widgetProps.style.events.shadow.enabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.events.shadow.enabled">
        <div class="we__row">
            <div class="we__label">
                Цвет тени
            </div>
            <div class="we__controls">
                <app-color-select [color]="widgetProps.style.events.shadow.color"
                                  (colorChange)="widgetProps.style.events.shadow.color = $event; widgetPropsChange.emit(widgetProps)"></app-color-select>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Размытость тени
            </div>
            <div class="we__controls">
                <mat-slider [(value)]="widgetProps.style.events.shadow.blur"
                            (input)="widgetProps.style.events.shadow.blur = $event.value; widgetPropsChange.emit(widgetProps)"
                            [min]="0" [max]="20" [step]="1"
                            thumbLabel [displayWith]="formatSliderLabel('px')"></mat-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по X
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.events.shadow.offsetX"
                               (valueChange)="widgetProps.style.events.shadow.offsetX = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по Y
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.events.shadow.offsetY"
                               (valueChange)="widgetProps.style.events.shadow.offsetY = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>
    </ng-container>

    <div class="we__row">
        <div class="we__label">
            Отображать обводку
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.events.stroke.enabled"
                              (change)="widgetProps.style.events.stroke.enabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.events.stroke.enabled">
        <div class="we__row">
            <div class="we__label">
                Цвет обводки
            </div>
            <div class="we__controls">
                <app-color-select [color]="widgetProps.style.events.stroke.color"
                                  (colorChange)="widgetProps.style.events.stroke.color = $event; widgetPropsChange.emit(widgetProps)"></app-color-select>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Толщина обводки
            </div>
            <div class="we__controls">
                <mat-slider [(value)]="widgetProps.style.events.stroke.width"
                            (input)="widgetProps.style.events.stroke.width = $event.value; widgetPropsChange.emit(widgetProps)"
                            [min]="0" [max]="20" [step]="1"
                            thumbLabel [displayWith]="formatSliderLabel('px')"></mat-slider>
            </div>
        </div>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="we__header">
        События
    </div>
    <drag-scroll snap-duration="200"
                 [scrollbar-hidden]="true"
                 [snap-disabled]="false"
                 [scroll-x-wheel-enabled]="true"
                 [drag-scroll-y-disabled]="true">
        <span drag-scroll-item *ngFor="let item of chips$ | async">
            <mat-chip disableRipple
                      (click)="selectedEvent$.next(item.type)"
                      [selected]="selectedEvent$.value === item.type">
                <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                {{item.label}}
            </mat-chip>
        </span>
    </drag-scroll>
    <!-- this is to use mat-chips as stand-alones https://github.com/angular/components/issues/9964#issuecomment-480287541 -->
    <mat-chip-list></mat-chip-list>

    <div class="we__row">
        <div class="we__label">
            Отображать иконку слева
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.events.eventTypes[selectedEvent$.value].displayIcon"
                              (change)="widgetProps.style.events.eventTypes[selectedEvent$.value].displayIcon = $event.checked;
                                        widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <div class="we__row icon">
        <div class="we__label">
            Иконка события
        </div>
        <div class="we__controls">
            <app-widget-edit-style-upload-button icon="ic-attach-image-white"
                                                 type="blue"
                                                 layout="vertical"
                                                 accept=".gif,.jpg,.jpeg,.png,.apng,.webp,.webm,.mp4"
                                                 help="Максимум 20 Мб. Допустимые форматы: {{allowedFormatsText}}."
                                                 [default]="widgetProps.style.events.eventTypes[selectedEvent$.value]?.iconUri"
                                                 (clear)="widgetProps.style.events.eventTypes[selectedEvent$.value].iconUri = [];
                                                          widgetPropsChange.emit(widgetProps)"
                                                 (change)="setEventImage($event, selectedEvent$.value)"
                                                 maxSizeMb="20">
                Загрузить иконку
            </app-widget-edit-style-upload-button>
        </div>
    </div>

    <div class="we__template-row">
        <div class="we__row">
            <div class="we__label">Шаблон</div>
            <div class="we__controls">
                <input type="text"
                       #messageTemplate
                       (ngModelChange)="widgetProps.style.events.eventTypes[selectedEvent$.value].template = $event; widgetPropsChange.emit(widgetProps)"
                       [ngModel]="widgetProps.style.events.eventTypes[selectedEvent$.value]?.template"
                       (keyup)="templateCaretPosition = messageTemplate.selectionStart"
                       (click)="templateCaretPosition = messageTemplate.selectionStart"
                       (focusin)="templateCaretPosition = messageTemplate.selectionStart">
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-help-tags #tags
                               [includePurpose]="true"
                               [customTags]="customTags"
                               [eventTypes]="[selectedEvent$.value]"
                               (tagClick)="onTagClicked($event)">
                </app-help-tags>
            </div>
        </div>
    </div>

    <div class="font">
        <div class="we__row">
            <div class="we__label">
                Шрифт шаблона
            </div>
            <div class="we__controls">
                <app-widget-select-google-font
                    [value]="widgetProps.style.events.eventTypes[selectedEvent$.value].font"
                    (valueChange)="widgetProps.style.events.eventTypes[selectedEvent$.value].font.uri = $event.uri;
                                   widgetProps.style.events.eventTypes[selectedEvent$.value].font.family = $event.family;
                                   widgetPropsChange.emit(widgetProps)"
                ></app-widget-select-google-font>
                <button mat-stroked-button
                        [class]="{selected: widgetProps.style.events.eventTypes[selectedEvent$.value].font.bold === true}"
                        (click)="widgetProps.style.events.eventTypes[selectedEvent$.value].font.bold = !widgetProps.style.events.eventTypes[selectedEvent$.value].font.bold; widgetPropsChange.emit(widgetProps)">
                    <mat-icon svgIcon="bold"></mat-icon>
                </button>
                <button mat-stroked-button
                        [class]="{selected: widgetProps.style.events.eventTypes[selectedEvent$.value].font.italic === true}"
                        (click)="widgetProps.style.events.eventTypes[selectedEvent$.value].font.italic = !widgetProps.style.events.eventTypes[selectedEvent$.value].font.italic; widgetPropsChange.emit(widgetProps)">
                    <mat-icon svgIcon="italic"></mat-icon>
                </button>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-color-select
                    [color]="widgetProps.style.events.eventTypes[selectedEvent$.value].font.color"
                    (colorChange)="widgetProps.style.events.eventTypes[selectedEvent$.value].font.color = $event; widgetPropsChange.emit(widgetProps)"></app-color-select>
            </div>
        </div>
    </div>

    <div class="font" *ngFor="let tag of tags.availableTags$ | async">
        <ng-container *ngIf="tag.tag !== 'icon'">
            <div class="we__row">
                <div class="we__label">
                    Шрифт тега {{'{' + tag.tag + '}'}}
                </div>
                <div class="we__controls">
                    <app-widget-select-google-font
                        [value]="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag]"
                        (valueChange)="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].uri = $event.uri;
                                       widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].family = $event.family;
                                       widgetPropsChange.emit(widgetProps)"
                    ></app-widget-select-google-font>
                    <button mat-stroked-button
                            [class]="{selected: widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].bold === true}"
                            (click)="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].bold = !widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].bold; widgetPropsChange.emit(widgetProps)">
                        <mat-icon svgIcon="bold"></mat-icon>
                    </button>
                    <button mat-stroked-button
                            [class]="{selected: widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].italic === true}"
                            (click)="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].italic = !widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].italic; widgetPropsChange.emit(widgetProps)">
                        <mat-icon svgIcon="italic"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="we__row">
                <div class="we__label"></div>
                <div class="we__controls">
                    <app-color-select
                        [color]="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].color"
                        (colorChange)="widgetProps.style.events.eventTypes[selectedEvent$.value].tagFonts[tag.tag].color = $event; widgetPropsChange.emit(widgetProps)"></app-color-select>
                </div>
            </div>
        </ng-container>
    </div>
</div>
