<div class="we__dropdown" [ngClass]="{'we__dropdown--label': label}">
    <div class="we__collapsed" (click)="click()" [ngClass]="{'visible': !opened}">
        <div class="we__label-row" *ngIf="label">{{label}}</div>
        <div class="we__value-row">
            <div class="we__value" *ngIf="value">
                {{value.text}}
            </div>
            <div class="we__icon">
                <img src="/assets/icons/arrow-drop-down-white.svg">
            </div>
        </div>
    </div>
    <div class="we__opened-base-layer" [ngClass]="{'visible': opened}">
        <div class="we__opened">
            <div class="we__option"
                 (click)="select(item)"
                 [ngClass]="{selected: (value === item)}"
                 *ngFor="let item of items">
                <div class="we__value">
                    {{item.text}}
                </div>
                <div class="we__selected-icon">
                    <img src="/assets/icons/ic-done-24-px.svg"/>
                </div>
            </div>
        </div>
    </div>
</div>
