import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "app-transaction-avatar[picture]",
    templateUrl: "./transaction-avatar.component.html",
    styleUrls: ["./transaction-avatar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionAvatarComponent {
    @Input()
    public picture: string = null;
}
