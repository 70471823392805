import {Injectable, NgZone, OnDestroy} from "@angular/core";
import {AccountsService} from "../accounts/accounts.service";
import {Subscription} from "rxjs";
import {AccountEventType, AccountType, AccountInfo} from "../accounts/accounts";
import {WidgetService} from "./widget.service";
import {WidgetSettingsService} from "../settings/widget-settings.service";
import * as _ from "lodash";
import {UserService} from "../user/user.service";
import {WidgetType} from "../../../../../shared/src/lib/models/widget";
import {filter} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class DefaultWidgetsCreationService implements OnDestroy {
    public constructor(private readonly accountsService: AccountsService,
                       private readonly userService: UserService,
                       private readonly widgetService: WidgetService,
                       private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly ngZone: NgZone) {
        this.newAccountSubscription = accountsService.accountConnected$
            .pipe(filter(account => account.isNew()))
            .subscribe(account => ngZone.run(() => this.onNewAccount(account)));
    }

    public ngOnDestroy(): void {
        this.newAccountSubscription?.unsubscribe();
    }

    private onNewAccount(account: AccountInfo): void {
        console.log("new account event received", account);

        switch (account.getType()) {
            case AccountType.Twitch:
                break;

            case AccountType.Trovo:
                break;

            case AccountType.Youtube:
                break;

            case AccountType.Paypal:
                this.onNewPaypalAccount(account);
                break;

            default:
                console.warn(`unexpected account type '${account.getType()}'`);
        }
    }

    private onNewPaypalAccount(account: AccountInfo): void {
        const eventTypes = [AccountEventType.PaypalDonate];
        this.createDefaultWidgets(account, eventTypes);
    }

    private async createDefaultWidgets(account: AccountInfo, eventTypes: AccountEventType[]): Promise<void> {
        console.group("createDefaultWidgets");

        console.log("started, eventTypes=", eventTypes);

        for (const eventType of eventTypes) {
            console.log("creating props, et=", eventType);

            const props = this.widgetSettingsService.generateDefaultAlertPropsByEventType(eventType);

            console.log("creating eventSources, et=", eventType, "props=", props);

            const eventSourcesCfg = this.widgetSettingsService.getDefaultAccountEvents(account.getType());

            // enable event type filter for the eventType
            for (const eventTypeKey of Object.keys(eventSourcesCfg)) {
                const isOurEventTypeProps = (eventTypeKey === eventType);
                if (isOurEventTypeProps) {
                    eventSourcesCfg[eventType].isEnabled = true;
                }
            }

            const accountsSettings = props.data.sources.accounts;

            // disable Donatty account events
            _.set(accountsSettings.donatty, "donate.isEnabled", false);

            // enable account event for the account/eventType pair
            _.set(accountsSettings, account.getId(), eventSourcesCfg);

            console.log("creating name, et=", eventType, "props=", props, "eventSourcesCfg=", eventSourcesCfg);

            const widgetName = this.widgetSettingsService
                .generateDefaultWidgetNameByEventType(WidgetType.Alert, eventType);

            console.log(
                "creating widget, et=", eventType,
                "props=", props, "eventSourcesCfg=", eventSourcesCfg,
                "name=", widgetName);

            await this.widgetService.create({
                name: widgetName,
                type: WidgetType.Alert,
                props
            });
        }

        console.groupEnd();
    }

    private readonly newAccountSubscription: Subscription;
}
