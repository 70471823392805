import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output
} from "@angular/core";
import {WidgetSettingsService} from "../../../../../services/settings/widget-settings.service";
import {AccountsService} from "../../../../../services/accounts/accounts.service";
import {WidgetPropsData} from "../../../../../../../../shared/src/lib/models/widget-props";
import {merge} from "rxjs";
import {filter, map, shareReplay, switchMap} from "rxjs/operators";

@Component({
    selector: "app-widget-help-tags",
    templateUrl: "./widget-help-tags.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetHelpTagsComponent implements AfterViewInit {
    @Input()
    public widgetPropsData: WidgetPropsData;

    @Input()
    public includePurpose = true;

    @Output()
    public readonly tagClick = new EventEmitter<string>();

    public constructor(private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly changeDetectorRef: ChangeDetectorRef,
                       private readonly accountsService: AccountsService) {
    }

    public ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    private readonly accounts$ = this.accountsService.accounts$.pipe(
        filter(accounts => accounts?.length > 0),
        shareReplay(1),
    );

    public readonly enabledEventTypes$ = merge(
        this.accounts$,
        this.widgetSettingsService.eventSourcePropsChanged$.pipe(switchMap(() => this.accounts$)),
    ).pipe(
        map(accounts => this.widgetSettingsService.listEnabledEventTypes(accounts, this.widgetPropsData)),
        shareReplay(1),
    );

}
