import {Injectable, OnDestroy} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NavigationHistoryService implements OnDestroy {
    public constructor(
            private readonly router: Router) {
        this.routerSubscription = router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(e => this.onNavigationEnd(e as NavigationEnd));
    }

    public ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
    }

    public async goBack(): Promise<void> {
        await this.router.navigateByUrl(this.previousUrl);
    }

    private onNavigationEnd(e: NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = e.urlAfterRedirects;
    }

    private readonly routerSubscription: Subscription;

    private currentUrl = "";
    private previousUrl = "";
}
