import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UserService} from "../../services/user/user.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {filter, takeUntil} from "rxjs/operators";
import {User} from "../../../../../shared/src/lib/models/user";
import {DonateThemeService} from "../../services/donate-theme.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AvatarService} from "../../../../../shared/src/lib/avatar.service";
import {combineLatest, Subject} from "rxjs";

@Component({
    selector: "app-avatar",
    templateUrl: "./avatar.component.html",
    styleUrls: ["./avatar.component.scss"],
    animations: [
        trigger("hideName", [
            state("show", style({
                maxWidth: "137px",
                opacity: "1",
            })),
            state("hide", style({
                maxWidth: "0px",
                opacity: "0",
            })),
            transition("show => hide", [
                animate("1s ease", style({
                    maxWidth: "0px",
                    opacity: "0",
                })),
            ]),
            transition("hide => show", [
                animate("1s ease", style({
                    maxWidth: "137px",
                    opacity: "1",
                })),
            ]),
        ]),
    ],
})
export class AvatarComponent implements OnInit, OnDestroy {

    public name = "";
    public avatar = "";
    public avatarLoaded: boolean;
    public opened = false;

    @Input()
    public short = true;
    public emptyAvatar: string;
    public backgroundColor: string;

    private readonly destroy$: Subject<void> = new Subject<void>();

    constructor(private readonly authService: AuthService,
                private readonly dataLayerService: DataLayerService,
                private readonly userService: UserService,
                private readonly userStoreService: DonateThemeService) {
    }

    public ngOnInit() {
        combineLatest([
            this.userService.currentUser$.pipe(filter<User>(Boolean)), this.userStoreService.donatePageTheme$,
        ]).pipe(takeUntil(this.destroy$)).subscribe(([user, theme]) => {
            if (user.avatarUrl) {
                this.avatar = `${user.avatarUrl}?w=72&h=72`;
                setTimeout(() => {
                    if (!this.avatarLoaded) {
                        this.avatarLoaded = false;
                    }
                }, 500);
            } else {
                this.avatarLoaded = false;
            }
            this.name = theme.title ?? user.name ?? "";
            const emptyAvatar = AvatarService.buildEmptyAvatar(this.name);
            this.emptyAvatar = emptyAvatar.label;
            this.backgroundColor = emptyAvatar.color;
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public async logout(): Promise<void> {
        await this.authService.logout();

        this.dataLayerService.emit({
            eventAction: EventAction.Logout,
            eventCategory: EventCategory.Panel,
            eventLabel: "logout",
            eventValue: "success"
        });
    }

    public openKnowledgeBase() {
        window.open("//donatty.com/help", "_blank");
    }

}
