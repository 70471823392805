import {Component, Input, ViewChild} from "@angular/core";
import {PopupComponent, TooltipPosition} from "../popup/popup.component";

@Component({
    selector: "lib-tooltip",
    templateUrl: "./tooltip.component.html",
    styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent {
    @Input()
    public position: TooltipPosition = TooltipPosition.Bottom;

    @Input()
    public width = 0;

    public hide(): void {
        this.popup.hide();
    }

    public show(target: EventTarget): void {
        this.popup.show(target);
    }

    public toggle(target: EventTarget): void {
        this.popup.toggle(target);
    }

    @ViewChild("popup", { static: true })
    private popup: PopupComponent;
}


