import {ChangeDetectionStrategy, Component} from "@angular/core";
import {filter, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {FinanceSSEService} from "../../../services/finance/finance-sse.service";
import {Currency} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {AccountLimits, LimitsId} from "../../../services/finance/account-limits";

interface Item {
    title: string;
    subtitle: string;
    amount: number;
}

@Component({
    selector: "app-identity-info",
    templateUrl: "./identity-info.component.html",
    styleUrls: ["./identity-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityInfoComponent {
    public readonly items$: Observable<Array<Item>> = this.financeSSEService.limits$.pipe(
        filter<AccountLimits>(Boolean),
        map(IdentityInfoComponent.formatLimitItems));

    public readonly currency = Currency.RUB;

    constructor(public readonly financeSSEService: FinanceSSEService) {
    }

    private static formatLimitItems(limits: AccountLimits): Array<Item> {
        const { credit, balance } = limits.findById(LimitsId.Standard);

        return [
            {
                title: "Общая сумма донатов",
                subtitle: "в месяц",
                amount: credit.month,
            },
            {
                title: "Макс. доступный остаток",
                subtitle: "на счёте",
                amount: balance.max,
            },
            {
                title: "Макс. размер доната",
                subtitle: "единовременно",
                amount: credit.max,
            }
        ];
    }
}
