import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    ElementRef
} from "@angular/core";

export class DropdownItem<T> {
    public constructor(
        public readonly text: string,
        public readonly value: T) {
    }
}

@Component({
    selector: "app-dropdown",
    templateUrl: "./dropdown.component.html",
    styleUrls: ["./dropdown.component.scss"]
})
export class DropdownComponent {
    @Input()
    public items: Array<DropdownItem<any>> = [];

    @Input()
    public label = "";

    @Output()
    public valueChange = new EventEmitter();

    @Input()
    public set value(v: any) {
        this.valueInternal = v && v.text
            ? v : this.items.find(it => it.value === v);
    }

    public get value(): any {
        return this.valueInternal;
    }

    public opened = false;

    public constructor(
            private readonly thisRef: ElementRef) {
    }

    public select(item) {
        this.value = item;
        this.opened = false;
        this.valueChange.emit(this.value);
    }

    public click() {
        this.opened = !this.opened;
    }

    @HostListener("document:click", ["$event"])
    documentClick(event: MouseEvent) {
        if (!this.thisRef.nativeElement.contains(event.target)) {
            this.opened = false;
        }
    }

    private valueInternal;
}
