<div class="tl__item" [ngClass]="itemClasses">
    <div class="tl__body">
        <div class="tl__icon">
            <mat-icon svgIcon="{{icon}}"></mat-icon>
        </div>
        <div class="tl__text">
            <div class="tl__header">
                Списание
                <div class="tl__timestamp">
                    в {{item.date.format("HH:mm:ss")}}
                </div>
            </div>
            <div class="tl__comment">Перевод на
                {{item.paymentMethod === 'CARD' ? 'карту' : 'кошелёк'}}
                *{{item.cardNumber?.slice(-4) ?? ''}}</div>
        </div>
        <div class="tl__sum">
            <div class="tl__amount">
                -&nbsp;<span [innerHTML]="item.amount | currency:item.currency:'symbol':'1.0'"></span>
            </div>
            <div class="tl__commission">
                Комиссия: <span [innerHTML]="item.commission | currency:item.currency:'symbol':'1.0'"></span>
            </div>
        </div>
    </div>
</div>
