import {Title} from "@angular/platform-browser";
import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";

const pageTitles: Array<{ location: string, title: string }> = [
    {
        location: "/widgets/donate",
        title: "Настройка страницы доната | Donatty"
    },
    {
        location: "/widgets/",
        title: "Настройка виджета %name% | Donatty"
    },
    {
        location: "/widgets",
        title: "Виджеты | Donatty"
    },
    {
        location: "/login",
        title: "Вход в личный кабинет | Donatty"
    },
    {
        location: "/identity",
        title: "Упрощенная идентификация | Donatty"
    },
    {
        location: "",
        title: "Финансы | Donatty"
    }
];

@Injectable({
    providedIn: "root"
})
export class PageService {

    private readonly destroy$: Subject<void> = new Subject();

    constructor(private readonly titleService: Title,
                private readonly router: Router) {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd), takeUntil(this.destroy$))
            .subscribe(() => this.setPageTitle());
    }

    setPageTitleDirectly(title: string) {
        this.titleService.setTitle(title);
        // this.meta.updateTag({ name: "description", content: description });
    }

    setPageTitle(name = "") {
        const pageTitle = pageTitles.find(x => document.location.href.toLocaleLowerCase().includes(x.location.toLocaleLowerCase()));
        this.titleService.setTitle(pageTitle ? pageTitle.title.replace("%name%", name) : "Donatty");
    }
}
