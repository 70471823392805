<div class="tl__item" [ngClass]="itemClasses" (touchstart)="selected.emit(item)">
    <app-transaction-avatar [picture]="item.picture"></app-transaction-avatar>
    <div class="tl__right-block">
        <div class="tl__top-row">
            <div class="tl__nick">{{item.displayName || "Anonymous"}}</div>
            <div class="tl__moderated" *ngIf="item.moderation === ModerationState.Blocked">
                <mat-icon svgIcon="notifications-off"></mat-icon>
            </div>
            <div class="tl__timestamp">в {{item.date.format("HH:mm:ss")}}</div>
            <div class="tl__retry" (click)="retry.emit(item)"
                 matTooltip="Повторить на стриме">
                <mat-icon svgIcon="retry"></mat-icon>
            </div>
        </div>
        <div class="tl__digest-row">
            <mat-icon class="digest__icon digest-{{item.type}}"
                      svgIcon="digest-{{item.type}}"></mat-icon>
            <span class="digest__text digest-{{item.type}}">
                <b>
                    <ng-container *ngIf="item.total > item.months else firstTime">Повторно подписывается</ng-container>
                    <ng-template #firstTime>Подписывается</ng-template>
                </b>
                (Ур. {{item.tier}})
                <b>на&nbsp;{{item.months}}&nbsp;мес.</b>
                <ng-container *ngIf="item.total > item.months">
                     (всего {{item.total}}&nbsp;мес.)
                </ng-container>
            </span>
        </div>
        <div class="tl__bottom-row">
            <div class="tl__comment" [innerText]="item.message || '–'"></div>
        </div>
    </div>
</div>
