import {Injectable} from "@angular/core";

interface Command {
    timeout: number;
    handler: () => void;
    id?: string;
}

@Injectable({
    providedIn: "root"
})
export class CommandService {

    private timeouts: { [key: string]: number } = {};

    public run(command: Command): Command {
        if (!command.id) {
            command.id = Math.random().toString(36).substr(2, 9);
        }
        if (this.timeouts[command.id]) {
            this.cancel(command);
        }
        this.timeouts[command.id] = window.setTimeout(command.handler, command.timeout);
        return command;
    }

    public cancel(command: Command) {
        clearTimeout(this.timeouts[command.id]);
    }

}
