<h1>Выберите тип</h1>
<h5>Виджеты</h5>
<mat-nav-list>
    <a mat-list-item
       (click)="createWidget(WidgetType.Alert)">
        <mat-icon svgIcon="widget-alert"></mat-icon>
        <section>
            <h6>Оповещение</h6>
            <p>Уведомления на стриме о донате или новом подписчике.</p>
        </section>
    </a>
    <a mat-list-item
       [disabled]="sidenavWidgetGroup?.isAlertGroup"
       (click)="createWidget(WidgetType.Goal)">
        <mat-icon svgIcon="widget-goal"></mat-icon>
        <section>
            <h6>Сбор средств</h6>
            <p>Полоска сбора средств. Можно собрать себе на новую видеокарту, проц или доширак.</p>
        </section>
    </a>
    <a mat-list-item
       [disabled]="sidenavWidgetGroup?.isAlertGroup"
       (click)="createWidget(WidgetType.Top)">
        <mat-icon svgIcon="widget-top"></mat-icon>
        <section>
            <h6>ТОП</h6>
            <p>ТОП донатеров, или ТОП лучших донатов отобразится на стриме.</p>
        </section>
    </a>
    <a mat-list-item
       [disabled]="sidenavWidgetGroup?.isAlertGroup"
       (click)="createWidget(WidgetType.Events)">
        <mat-icon svgIcon="widget-events"></mat-icon>
        <section>
            <h6>Список событий</h6>
            <p>Транслирует все события на стриме: донаты, медиа, события с Twitch, YouTube и Trovo.</p>
        </section>
    </a>
    <a *ngIf="false" mat-list-item
       [disabled]="sidenavWidgetGroup?.isAlertGroup"
       (click)="createWidget(WidgetType.Media)">
        <mat-icon svgIcon="widget-media"></mat-icon>
        <section>
            <h6>Медиа</h6>
            <p>Проигрывание видео на стриме за определённую сумму денег.</p>
        </section>
    </a>
    <a mat-list-item
       [disabled]="sidenavWidgetGroup?.isAlertGroup"
       (click)="createWidget(WidgetType.Total)">
        <mat-icon svgIcon="widget-total"></mat-icon>
        <section>
            <h6>Сумма</h6>
            <p>Транслирует общую сумму денег за указанное время.</p>
        </section>
    </a>
</mat-nav-list>
<mat-divider></mat-divider>
<h5>Контейнеры</h5>
<mat-nav-list>
    <a mat-list-item
       [disabled]="!!sidenavWidgetGroup"
       (click)="createWidgetGroup()">
        <img alt="Оповещение" src="/assets/icons/widget-new-group.svg">
        <section>
            <h6>Группа виджетов</h6>
            <p>Позволяет сгруппировать виджеты, чтобы вставить в OBS одной ссылкой.</p>
        </section>
    </a>
</mat-nav-list>
