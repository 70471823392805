import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {
    AccountEventType, AccountInfo, AccountType, accountTypeEvents,
    disabledAccountEvents, eventParamLabels, eventParamUnits,
} from "../accounts/accounts";
import {BehaviorSubject, Subject} from "rxjs";
import {defaultAlertProps} from "../../default-widget-settings/defaultAlertProps";
import {defaultGoalProps} from "../../default-widget-settings/defaultGoalProps";
import {defaultTotalProps} from "../../default-widget-settings/defaultTotalProps";
import {defaultTopProps} from "../../default-widget-settings/defaultTopProps";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {
    AlertWidget, EventSourceParamLimits, EventSourceParamOption, EventSourceParamOptions, EventsWidget, GoalWidget,
    TopWidget, TotalWidget, WidgetEventSources, WidgetEventSourcesAccountEvents, WidgetEventSourcesAccountProp,
    WidgetProps, WidgetPropsData,
} from "../../../../../shared/src/lib/models/widget-props";
import {WidgetType} from "../../../../../shared/src/lib/models/widget";
import * as moment from "moment";
import {ApiCreateWidgetRequest} from "../../../../../shared/src/lib/models/api";
import {UserService} from "../user/user.service";
import {filter, map, takeUntil} from "rxjs/operators";
import {User} from "@sentry/angular";
import {TwitchReward} from "../../../../../shared/src/lib/models/user";
import {accountEventVisuals} from "../events/accountEventVisuals";
import {AccountsService} from "../accounts/accounts.service";
import {defaultEventsProps} from "../../default-widget-settings/defaultEventsProps";

export interface EventSourceParam<T = any> {
    items$: BehaviorSubject<Array<EventSourceParamOption>>;
    account: AccountInfo;
    accountEventType: AccountEventType;
    range: EventSourceParamLimits;
    type: EventParamType;
    value: T;
    label: string;
    unit: string;
}

export const editableEventTypes = [
    AccountEventType.DonattyDonate,
    AccountEventType.PaypalDonate,
    AccountEventType.TwitchSubscribers,
    AccountEventType.TwitchBits,
    AccountEventType.TwitchChannelPoints,
    AccountEventType.YoutubeMembers,
    AccountEventType.YoutubeSuperchat
];

// TODO: Add "string"
export type EventParamType = "list" | "range";

const formatTwitchRewardText = (rawReward: TwitchReward) => {
    const result = [];
    if (rawReward.title) {
        result.push(`${rawReward.title}`);
    }

    result.push(`(${rawReward.value} points)`);
    return result.join(" ");
};

@Injectable({providedIn: "root"})
export class WidgetSettingsService {

    public readonly eventSourcePropsChanged$ = new Subject<WidgetProps>();
    public readonly eventSourceParamItems$: { [key in AccountEventType]?: BehaviorSubject<EventSourceParamOptions> } = {
        [AccountEventType.TwitchChannelPoints]: new BehaviorSubject<EventSourceParamOptions<string>>([]),
    };
    private readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly environmentService: EnvironmentService,
                       private readonly userService: UserService,
                       private readonly accountsService: AccountsService) {
        this.subscribeOnAccountSettings();
    }

    private subscribeOnAccountSettings() {
        this.userService.currentUser$
            .pipe(
                filter(user => user?.accountParams.twitch?.rewards.length > 0),
                map<User, Array<TwitchReward>>(user => user.accountParams.twitch.rewards),
                map<Array<TwitchReward>, EventSourceParamOptions>(rawRewards =>
                    rawRewards.map(rawReward => ({
                        value: rawReward.id,
                        text: formatTwitchRewardText(rawReward),
                    }))
                ),
                takeUntil(this.destroy$))
            .subscribe(rewardsOptions =>
                this.eventSourceParamItems$[AccountEventType.TwitchChannelPoints]
                    .next(rewardsOptions)
            );
    }

    public generateDefaultWidgetNameByEventType(type: WidgetType, eventType: AccountEventType): string {
        if (type !== WidgetType.Alert) {
            throw new Error(`unexpected widget type ${type}`);
        }

        const widgetName: { [key in AccountEventType]?: string } = {
            [AccountEventType.PaypalDonate]: "Новый донат PayPal",
            [AccountEventType.TwitchBits]: "Новый донат Twitch",
            [AccountEventType.TwitchFollowers]: "Новый фолловер Twitch",
            [AccountEventType.TwitchHost]: "Новый хост Twitch",
            [AccountEventType.TwitchRaids]: "Новый рейд Twitch",
            [AccountEventType.TwitchSubscribers]: "Новый подписчик Twitch",
            [AccountEventType.YoutubeMembers]: "Новый спонсор YouTube",
            [AccountEventType.YoutubeSubscribers]: "Новый подписчик YouTube",
            [AccountEventType.YoutubeSuperchat]: "Новый донат YouTube",
            [AccountEventType.TwitchHypeTrain]: "Twitch Hype Train",
            [AccountEventType.TwitchChannelPoints]: "Twitch Channel Points",
        };

        if (!(eventType in widgetName)) {
            throw new Error(`unexpected event type ${eventType}`);
        }

        return widgetName[eventType];
    }

    private widgetProps: { [key in WidgetType]: () => ApiCreateWidgetRequest } = {
        [WidgetType.Alert]: () => {
            const props: AlertWidget["props"] = defaultAlertProps;
            this.accountsService.accounts$.value
                .filter(account => account.getType() !== AccountType.Donatty)
                .forEach(account => props.data.sources.accounts[account.getId()] = this.getDefaultAccountEvents(account.getType()));
            return {
                name: "Новый донат",
                props,
                type: WidgetType.Alert,
            };
        },
        [WidgetType.Goal]: () => {
            return {
                name: "Сбор средств",
                props: WidgetSettingsService.fmtGoalWidgetDefaultProps(),
                type: WidgetType.Goal,
            };
        },
        [WidgetType.Total]: () => {
            const props: TotalWidget["props"] = defaultTotalProps;
            return {
                name: "Общая сумма донатов",
                props,
                type: WidgetType.Total,
            };
        },
        [WidgetType.Top]: () => {
            const props: TopWidget["props"] = defaultTopProps;
            return {
                name: "Топ донатеров",
                props,
                type: WidgetType.Top,
            };
        },
        [WidgetType.Media]: () => {
            return {
                name: "Медиа",
                props: {},
                type: WidgetType.Media,
            };
        },
        [WidgetType.Events]: () => {
            const props: EventsWidget["props"] = defaultEventsProps;
            this.accountsService.accounts$.value
                .filter(account => account.getType() !== AccountType.Donatty)
                .forEach(account => {
                    props.data.sources.accounts[account.getId()] = this.getDefaultAccountEvents(account.getType());
                    Object.keys(props.data.sources.accounts[account.getId()])
                        .filter(accountEventType => !disabledAccountEvents.has(accountEventType as AccountEventType))
                        .forEach(accountEventType => props.data.sources.accounts[account.getId()][accountEventType].isEnabled = true);
                });
            return {
                name: "События на стриме",
                props,
                type: WidgetType.Events,
            };
        },
    };

    public generateDefaultWidgetProps(type: WidgetType): ApiCreateWidgetRequest {
        if (!this.widgetProps[type]) {
            throw new Error(`unknown widget type '${type}'`);
        }
        return this.widgetProps[type]();
    }

    public generateDefaultAlertPropsByEventType(eventType: AccountEventType): WidgetProps {
        const baseUri = `${this.environmentService.streamerFrontUri}/assets/images/default-alert-gifs/`;
        const imageUri = WidgetSettingsService.getDefaultEventTypeAlertAnimationUri(eventType);

        const settings: AlertWidget["props"] = _.cloneDeep(defaultAlertProps);

        if (!_.isEmpty(imageUri)) {
            settings.data.imageUri = [baseUri + imageUri];
        }

        const headers = {
            [AccountEventType.PaypalDonate]: "{amount} {unit} задонатил {username}!",
            [AccountEventType.TrovoFollowers]: "{username} отслеживает канал.",
            [AccountEventType.TrovoGiftSubscribersChannel]: "{username} дарит {amount} {unit} ({unit-tier} {tier}) случайным зрителям.",
            [AccountEventType.TrovoGiftSubscribersViewer]: "{username} дарит {receiver} подписку ({unit-tier} {tier}).",
            [AccountEventType.TrovoRaids]: "Рейд с канала {username}",
            [AccountEventType.TrovoSpellElixir]: "{username} кастует {spell-name} за {amount} {unit}.",
            [AccountEventType.TrovoSpellMana]: "{username} кастует {spell-name} за {amount} {unit}.",
            [AccountEventType.TrovoSubscribers]: "{username} оформляет подписку ({unit-tier} {tier}).",
            [AccountEventType.TwitchBits]: "{amount} {unit} задонатил {username}!",
            [AccountEventType.TwitchFollowers]: "Ура! Новый фолловер {username}!",
            [AccountEventType.TwitchHost]: "Ура! Новый хост от {username}!",
            [AccountEventType.TwitchRaids]: "Рейд с канала {username}",
            [AccountEventType.TwitchSubscribers]: "Ура! Новый подписчик {username}!",
            [AccountEventType.TwitchHypeTrain]: "Ура! Hype Train {level} уровня!",
            [AccountEventType.TwitchChannelPoints]: "{amount} {unit} задонатил {username}!",
            [AccountEventType.YoutubeMembers]: "Ура! Новый спонсор {username}!",
            [AccountEventType.YoutubeSubscribers]: "Ура! Новый подписчик {username}!",
            [AccountEventType.YoutubeSuperchat]: "{amount} {unit} задонатил {username}!"
        };

        if (!(eventType in headers)) {
            throw new Error(`unexpected event type ${eventType}`);
        }

        settings.style.header.template = headers[eventType];

        const messages = {
            [AccountEventType.TrovoRaids]: "{amount} {unit}!",
            [AccountEventType.TwitchFollowers]: "",
            [AccountEventType.TwitchHost]: "",
            [AccountEventType.TwitchRaids]: "{amount} {unit}!",
            [AccountEventType.TwitchSubscribers]: "Ты с нами уже {total} {unit-total}!",
            [AccountEventType.TwitchHypeTrain]: "",
            [AccountEventType.TwitchChannelPoints]: "Великий {username} выбрал награду «{reward}» за {amount} {unit} и сказал: «{message}»",
            [AccountEventType.YoutubeMembers]: "Ты с нами уже {amount} {unit}!",
            [AccountEventType.YoutubeSubscribers]: ""
        };

        if (eventType in messages) {
            settings.style.message.template = messages[eventType];
        }

        return settings;
    }

    public hasEditableDataParams(accounts: WidgetEventSources["accounts"]): boolean {
        if (!accounts) {
            return false;
        }

        for (const accountId of Object.keys(accounts)) {
            const accountEvents: Partial<WidgetEventSourcesAccountEvents> = accounts[accountId];

            for (const eventType of Object.keys(accountEvents)) {
                if (!editableEventTypes.includes(eventType as AccountEventType)) {
                    continue;
                }

                const prop: WidgetEventSourcesAccountProp = accountEvents[eventType];
                if (prop.isEnabled) {
                    return true;
                }
            }
        }

        return false;
    }

    public listEnabledEventTypes(accounts: AccountInfo[], widgetPropsData: WidgetPropsData): AccountEventType[] {
        const eventTypes: AccountEventType[] = [];

        WidgetSettingsService.visitAccountsEventSourceParams(
            accounts,
            widgetPropsData?.sources?.accounts ?? {},
            (eventSource, eventType, paramValue) => {
                if (!paramValue.isEnabled || eventTypes.includes(eventType)) {
                    return;
                }

                eventTypes.push(eventType);
            });

        return eventTypes
            .filter(accountEventType => !disabledAccountEvents.has(accountEventType as AccountEventType))
            .sort((l, r) => accountEventVisuals[l].order - accountEventVisuals[r].order);
    }

    public listEventSourceParams(accounts: AccountInfo[], eventSourcesAccounts: WidgetEventSources["accounts"]): EventSourceParam[] {
        const params: EventSourceParam[] = [];

        WidgetSettingsService.visitAccountsEventSourceParams(
                accounts,
                eventSourcesAccounts,
                (eventSource, eventType, paramValue) => {
                    if (!paramValue.isEnabled) {
                        return;
                    }

                    const settings: { [key in AccountEventType]?: EventParamType } = {
                        [AccountEventType.DonattyDonate]: "range",
                        [AccountEventType.PaypalDonate]: "range",
                        [AccountEventType.TwitchBits]: "range",
                        [AccountEventType.TwitchSubscribers]: "range",
                        [AccountEventType.TwitchChannelPoints]: "list",
                        [AccountEventType.YoutubeMembers]: "range",
                        [AccountEventType.YoutubeSuperchat]: "range",
                    };

                    if (!settings[eventType]) {
                        return;
                    }

                    const paramLabel = eventParamLabels[eventType];
                    if (!paramLabel) {
                        console.warn(`listEventSourceParams, no label, et='${eventType}'`);
                    }

                    params.push({
                        account: eventSource,
                        accountEventType: eventType,
                        label: paramLabel ?? "",
                        value: paramValue,
                        unit: eventParamUnits[eventType] ?? "",
                        // TODO: Split interfaces in range and list
                        type: settings[eventType],
                        range: this.getDefaultPropValues(eventType),
                        items$: this.eventSourceParamItems$[eventType]
                    });
                });

        return params;
    }

    public listEventSources(eventSourcesCfg: WidgetEventSources["accounts"],
                            accounts: AccountInfo[]): AccountType[] {
        const sources: AccountType[] = [];

        if (!eventSourcesCfg) {
            // oldstyle widget settings?
            return [];
        }

        for (const accountId of Object.keys(eventSourcesCfg)) {
            const accountCfg = eventSourcesCfg[accountId];
            if (!WidgetSettingsService.hasAnyFiltersEnabled(accountCfg)) {
                continue;
            }

            const isDonattyEventSource = (accountId === "donatty");
            if (isDonattyEventSource) {
                sources.push(AccountType.Donatty);
                continue;
            }

            const account = accounts.find(acc => (acc.getId() === accountId));
            if (!account) {
                continue;
            }

            sources.push(account.getType());
        }

        return _.uniq(sources);
    }

    private static fmtGoalWidgetDefaultProps(): GoalWidget["props"] {
        const props = defaultGoalProps;

        props.data.goalDate = moment()
            .add(1, "month")
            .toISOString();

        return props;
    }

    private static hasAnyFiltersEnabled(accountCfg: Partial<WidgetEventSourcesAccountEvents>): boolean {
        for (const filterKey of Object.keys(accountCfg)) {
            if (accountCfg[filterKey].isEnabled) {
                return true;
            }
        }

        return false;
    }

    private static visitAccountsEventSourceParams(eventSources: AccountInfo[],
                                                  eventSourcesCfg: WidgetEventSources["accounts"],
                                                  callback: EventSourcesVisitor): void {
        for (const eventSource of (eventSources ?? [])) {
            const eventSourceCfg: Partial<WidgetEventSourcesAccountEvents> = eventSourcesCfg[eventSource.getId()];
            if (!eventSourceCfg) {
                continue;
            }

            for (const paramKey of Object.keys(eventSourceCfg)) {
                callback(
                    eventSource,
                    paramKey as AccountEventType,
                    eventSourceCfg[paramKey]);
            }
        }
    }

    private static getDefaultEventTypeAlertAnimationUri(eventType: AccountEventType): string[] {
        const isDefaultAnimation =
            (eventType === AccountEventType.PaypalDonate) ||
            (eventType === AccountEventType.DonattyDonate);
        if (isDefaultAnimation) {
            return [];
        }

        const filenames = {
            [AccountEventType.TrovoFollowers]: "trovo_followers.gif",
            [AccountEventType.TrovoGiftSubscribersChannel]: "trovo_subscription_gift.gif",
            [AccountEventType.TrovoGiftSubscribersViewer]: "trovo_subscription_gift.gif",
            [AccountEventType.TrovoRaids]: "trovo_raids.gif",
            [AccountEventType.TrovoSpellElixir]: "trovo_spell.gif",
            [AccountEventType.TrovoSpellMana]: "trovo_spell.gif",
            [AccountEventType.TrovoSubscribers]: "trovo_subscripbers.gif",
            [AccountEventType.TwitchBits]: "twitch_bits.gif",
            [AccountEventType.TwitchFollowers]: "twitch_followers.gif",
            [AccountEventType.TwitchHost]: "twitch_raids.gif",
            [AccountEventType.TwitchRaids]: "twitch_raids.gif",
            [AccountEventType.TwitchSubscribers]: "twitch_subscribers.gif",
            [AccountEventType.TwitchHypeTrain]: "twitch_subscribers.gif",
            [AccountEventType.YoutubeMembers]: "youtube_members.gif",
            [AccountEventType.YoutubeSubscribers]: "youtube_subscribers.gif",
            [AccountEventType.YoutubeSuperchat]: "youtube_superchat.gif",
        };

        if (!(eventType in filenames)) {
            throw new Error(`unexpected event type '${eventType}'`);
        }

        return filenames[eventType];
    }

    public getDefaultAccountEvents(accountType: AccountType): Partial<WidgetEventSourcesAccountEvents> {
        const result: Partial<WidgetEventSourcesAccountEvents> = {};

        const eventTypes = accountTypeEvents[accountType];
        for (const eventType of eventTypes) {
            const defaultEventSettings = this.getDefaultPropValues(eventType);
            if (defaultEventSettings === undefined) {
                console.warn(`getDefaultProps, no props, et='${eventType}'`);
            }

            result[eventType] = {
                isEnabled: false,
                ...defaultEventSettings,
            };

            if (disabledAccountEvents.has(eventType)) {
                result[eventType].hidden = true;
            }
        }

        return result;
    }

    private getDefaultPropValues(eventId: AccountEventType) {
        return {
            [AccountEventType.DonattyDonate]: {
                from: 1,
            },
            [AccountEventType.PaypalDonate]: {
                from: 1,
            },
            [AccountEventType.YoutubeMembers]: {
                from: 1,
            },
            [AccountEventType.YoutubeSuperchat]: {
                from: 1,
            },
            [AccountEventType.TwitchBits]: {
                from: 1,
            },
            [AccountEventType.TwitchHypeTrain]: {
                from: 1,
            },
            [AccountEventType.TwitchChannelPoints]: {
                selectedItems: this.eventSourceParamItems$[AccountEventType.TwitchChannelPoints].value.map(item => item.value),
            },
            [AccountEventType.TwitchSubscribers]: {
                from: 1,
            },
        }[eventId];
    }
}

type EventSourcesVisitor = (eventSource: AccountInfo, eventType: AccountEventType, paramValue: WidgetEventSourcesAccountProp) => void;
