import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DadataService {
    public constructor(
            private readonly http: HttpClient) {
    }

    public getNameSuggestions(query: string): Observable<string[]> {
        return new Observable<string[]>(subscriber => {
            this.http
                .post<DadataSuggestionsResponse>(
                    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
                    { query },
                    { headers: this.getHttpHeaders() })
                .subscribe(
                    response => subscriber.next(
                            this.nameSuggestions(response.suggestions)));
        });
    }

    private getHttpHeaders(): HttpHeaders {
        return new HttpHeaders({
            Accept: "application/json",
            Authorization: `Token ${this.API_KEY}`,
            "Content-Type": "application/json",
        });
    }

    private nameSuggestions(suggestions: DadataSuggestionResponse[]): string[] {
        return suggestions.map(s => s.value);
    }

    private readonly API_KEY = "520b03311bd65f769c870acc4333e2dbc0a49076";
}

export type DadataSuggestionResponse = {
    value: string
};

export type DadataSuggestionsResponse = {
    suggestions: DadataSuggestionResponse[]
};
