<div class="we__tab-container" *ngIf="widgetProps.style?.progressBar">
    <div class="we__header">
        Цель
    </div>

    <div class="we__template-row">
        <div class="we__row">
            <div class="we__label">Текст цели</div>
            <div class="we__controls">
                <input type="text"
                       (ngModelChange)="widgetProps.style.progressBar.template = $event; widgetPropsChange.emit(widgetProps)"
                       [ngModel]="widgetProps.style.progressBar.template"
                       #progressBarTemplate
                       (keyup)="caretPosition = progressBarTemplate.selectionStart"
                       (click)="caretPosition = progressBarTemplate.selectionStart"
                       (focusin)="caretPosition = progressBarTemplate.selectionStart">
            </div>
        </div>
        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-tags-selector [items]="AVAILABLE_TAGS"
                                   (tagClick)="onTagClicked($event)"></app-tags-selector>
            </div>
        </div>
    </div>

    <app-widget-edit-font-basics [(item)]="widgetProps.style.progressBar"
                                 text="цели" offsetBounds="200"
                                 (itemChange)="widgetProps.style.progressBar = $event; widgetPropsChange.emit(widgetProps)">
    </app-widget-edit-font-basics>
</div>
