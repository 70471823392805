import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild} from "@angular/core";
import {FinanceService} from "../../../services/finance/finance.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {PageService} from "../../../services/page.service";
import * as _ from "lodash";
import {ConfirmationCodeComponent} from "../../confirmation-code/confirmation-code.component";
import {
    IdentityRequest, VerificationState, Wallet
} from "../../../../../../shared/src/lib/models/finance.api";
import {DadataService} from "../../../../../../shared/src/lib/dadata.service";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";
import {JsonErrorCode, JsonErrorObject} from "../../../../../../shared/src/lib/models/json-error-object";
import {HttpErrorResponse} from "@angular/common/http";

enum WizardStep {
    Id = "id",
    AdditionalId = "additional-id",
    Confirmation = "confirm"
}

@Component({
    selector: "app-identity-v2",
    templateUrl: "./identity-v2.component.html",
    styleUrls: ["./identity-v2.component.scss"]
})
export class IdentityV2Component implements AfterViewInit {

    constructor(
            private readonly changeDetectorRef: ChangeDetectorRef,
            private readonly dadataService: DadataService,
            private readonly dataLayerService: DataLayerService,
            private readonly financeService: FinanceService,
            private readonly router: Router,
            private readonly toastr: ToastrService,
            private readonly pageService: PageService) {
        this.pageService.setPageTitleDirectly("Имя и дата рождения УПРИД | Donatty");
    }

    public ngAfterViewInit() {
        this.notifyStepChanged();
    }

    public confirmationCode = "";
    public isConfirmationCodeSent = false;
    public isIdentitySent = false;
    public isProceedingToConfirmation = false;
    public isInnHelpBubbleVisible = false;
    public isNameLoaderVisible = false;
    public isNameSuggestionVisible = false;
    public isSnilsHelpBubbleVisible = false;
    public isWaitingForCode = false;
    public nameSuggestions: string[] = [];
    public step = WizardStep.Id;
    public suggestionSelectionIndex: number = null;
    public timeout = 60;

    public get birthDateInputText(): string {
        return this.birthDateInput.nativeElement.value;
    }

    public get isAdditionalIdStep(): boolean {
        return (this.step === WizardStep.AdditionalId);
    }

    public get isAdditionalIdStepPassed(): boolean {
        return (
            (this.step === WizardStep.Confirmation) ||
            (this.step === WizardStep.Id)
        ) && this.isAdditionalIdStepValid;
    }

    public get isAdditionalIdStepValid(): boolean {
        return this.isPassportValid;
    }

    public get isConfirmationEnabled(): boolean {
        return (
            this.confirmationCodeEl.isValid &&
            !this.isConfirmationCodeSent
        );
    }

    public get isConfirmationStep(): boolean {
        return (this.step === WizardStep.Confirmation);
    }

    public get isDateError(): boolean {
        return this.isDateFocused && !this.isBirthDateValid;
    }

    public get isDateFilled(): boolean {
        return (this.birthDateInputText.length > 0);
    }

    public get isBirthDateValid(): boolean {
        return (this.birthDateInputText.length === 10);
    }

    public get isIdStep(): boolean {
        return (this.step === WizardStep.Id);
    }

    public get isIdStepPassed(): boolean {
        return (
            (this.step === WizardStep.AdditionalId) ||
            (this.step === WizardStep.Confirmation)
        );
    }

    public get isIdStepValid(): boolean {
        return this.isNameValid && this.isBirthDateValid;
    }

    public get isNameError(): boolean {
        return this.isNameFocused && !this.isNameValid;
    }

    public get isNameFilled(): boolean {
        return (this.nameInputText.length > 0);
    }

    public get isNameValid(): boolean {
        const namePartsCount = this.nameInputText.split(" ").length;
        return ((namePartsCount >= 2) && (namePartsCount <= 6));
    }

    public get isPassportError(): boolean {
        return this.isPassportFocused && !this.isPassportValid;
    }

    public get isPassportFilled(): boolean {
        return (this.passportInputText.length > 0);
    }

    public get isPassportValid(): boolean {
        return (
            (this.passportInputText.length === 10) ||
            (this.passportInputText.length === 9));
    }

    public get isPhoneError(): boolean {
        return this.isPhoneFocused && !this.isPhoneValid;
    }

    public get isPhoneFilled(): boolean {
        return (this.phoneInputText.length > 0);
    }

    public get isPhoneValid(): boolean {
        return (this.phoneInputText.length === 11);
    }

    public get isPhoneMaskVisible(): boolean {
        const isPhoneFocused = (this.phoneInput.nativeElement === document.activeElement);
        return isPhoneFocused || this.isPhoneFilled;
    }

    public get isSendSmsEnabled(): boolean {
        if (this.isIdentitySent) {
            return false;
        }

        if (!this.isPhoneValid) {
            return false;
        }

        if (!(this.phoneInputText in this.sentNumbers)) {
            return true;
        }

        return (this.timeout === 0);
    }

    public get isTimeoutTicking(): boolean {
        return (this.smsTimeoutTimerId !== 0);
    }

    public get nameInputRawText(): string {
        return this.nameInput.nativeElement.value;
    }

    public get nameInputText(): string {
        return this.nameInputRawText.trim();
    }

    public get passportInputText(): string {
        return this.passportInput.nativeElement.value
            .replace(/\s/g, "");
    }

    public get phoneInputText(): string {
        const rawText = this.phoneInput.nativeElement.value;
        return rawText.replace(/[\s()\-_+]/g, "");
    }

    public fmtSuggestionTyped(name: string) {
        return name.substring(0, this.nameInputRawText.length);
    }

    public fmtSuggestionTail(name: string) {
        return name.substring(this.nameInputRawText.length);
    }

    public onAdditionalIdStepClick(): void {
        if (!this.isAdditionalIdStepPassed) {
            return;
        }

        this.step = WizardStep.AdditionalId;

        this.setAdditionalIdFocus();

        this.notifyStepChanged();
    }

    public onChangeClick(event: MouseEvent) {
        event.preventDefault();

        this.isWaitingForCode = false;
        this.phoneInput.nativeElement.focus();
    }

    public onCloseClick(): void {
        this.router.navigate(["/finance"]);
    }

    public async onConfirmCodeClicked(): Promise<void> {
        const currentIdentityRequest = this.buildIdentityRequest();

        try {
            this.isConfirmationCodeSent = true;
            this.changeDetectorRef.detectChanges();

            if (!_.isEqual(this.sentIdentityRequest, currentIdentityRequest)) {
                await this.sendIdentity();
            }

            await this.financeService.confirmPhone(this.confirmationCode);

            await this.handlePurseState();
        } catch (e) {
            const toastText = _.get(e, "error.detail", e.message);
            this.toastr.error(toastText);
        }
        finally {
            this.isConfirmationCodeSent = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    public onContinueToAdditionalId(): void {
        this.step = WizardStep.AdditionalId;

        this.setAdditionalIdFocus();

        this.notifyStepChanged();
    }

    public async onContinueToConfirmation(): Promise<void> {
        console.log("onContinueToConfirmation, started");

        if (this.isProceedingToConfirmation) {
            console.log("onContinueToConfirmation, SKIP, request in progress");
            return;
        }

        this.isProceedingToConfirmation = true;

        try {
            const purseState = await this.getPurseState();
            console.log(
                "onContinueToConfirmation, got purse state, s=", purseState,
                "ipc=", purseState.isPhoneConfirmed);

            if (purseState.isPhoneConfirmed) {
                console.log("onContinueToConfirmation, phone confirmed, processing state");

                // complete check without the phone

                try {
                    await this.sendIdentity();
                } catch (e) {
                    const toastText = _.get(e, "error.detail", e.message);
                    this.toastr.error(toastText);
                    return;
                }

                await this.handlePurseState();
                return;
            }
        } finally {
            this.isProceedingToConfirmation = false;
        }

        console.log("onContinueToConfirmation, switching to phone confirmation");

        this.step = WizardStep.Confirmation;
        setTimeout(() => this.phoneInput.nativeElement.focus());

        this.notifyStepChanged();
    }

    public onDateBlur(): void {
        this.isDateFocused = true;
    }

    public onDateInputChange(): void {
        const inputEl: HTMLInputElement = this.birthDateInput.nativeElement;
        const inputVal = inputEl.value;

        let filteredVal = "";

        let firstDayDigit = 0;
        let secondDayDigit = 0;
        let firstMonthDigit = 0;
        let secondMonthDigit = 0;
        let firstYearDigit = 0;
        let secondYearDigit = 0;
        let thirdYearDigit = 0;
        let fourthYearDigit = 0;

        for (let index = 0; index < inputVal.length; ++index) {
            const ch = inputVal[index];

            if (index === 0) {
                firstDayDigit = +ch;

                if (firstDayDigit > 3) {
                    break;
                }
            }

            if (index === 1) {
                secondDayDigit = +ch;

                if (firstDayDigit === 0) {
                    if (secondDayDigit === 0) {
                        break;
                    }
                }

                if (firstDayDigit === 3) {
                    if (secondDayDigit > 1) {
                        break;
                    }
                }
            }

            if (index === 3) {
                firstMonthDigit = +ch;

                if (firstMonthDigit > 1) {
                    break;
                }
            }

            if (index === 4) {
                secondMonthDigit = +ch;

                if (firstMonthDigit === 0) {
                    if (secondMonthDigit === 0) {
                        break;
                    }
                }

                if (firstMonthDigit === 1) {
                    if (secondMonthDigit > 2) {
                        break;
                    }
                }
            }

            if (index === 6) {
                firstYearDigit = +ch;

                if ((firstYearDigit !== 1) && (firstYearDigit !== 2)) {
                    break;
                }
            }

            if (index === 7) {
                secondYearDigit = +ch;

                if (firstYearDigit === 1) {
                    if (secondYearDigit !== 9) {
                        break;
                    }
                }

                if (firstYearDigit === 2) {
                    if (secondYearDigit !== 0) {
                        break;
                    }
                }
            }

            if (index === 8) {
                thirdYearDigit = +ch;

                if (firstYearDigit === 2) {
                    if (thirdYearDigit > 1) {
                        break;
                    }
                }
            }

            if (index === 9) {
                fourthYearDigit = +ch;

                if (firstYearDigit === 2) {
                    if (thirdYearDigit === 1) {
                        if (fourthYearDigit > 5) {
                            break;
                        }
                    }
                }
            }

            filteredVal += ch;
        }

        inputEl.value = filteredVal;
    }

    public onDateInputKeydown(event: KeyboardEvent): void {
        if (event.key !== "Tab") {
            return;
        }

        event.preventDefault();

        this.nameInput.nativeElement.focus();
    }

    @HostListener("document:click", [])
    public onDocumentClick(): void {
        this.isInnHelpBubbleVisible = false;
        this.isSnilsHelpBubbleVisible = false;
    }

    public onIdStepClick(): void {
        if (!this.isIdStepPassed) {
            return;
        }

        this.step = WizardStep.Id;

        // workaround for the focus caused by the click
        setTimeout(() => {
            this.nameInput.nativeElement.focus();
        });

        this.notifyStepChanged();
    }

    public onInnBlur(): void {
        this.isInnFocused = true;
    }

    public onNameInputBlur(): void {
        this.isNameFocused = true;
        this.hideNameSuggestion();
    }

    public onNameInputChange(): void {
        const inputEl = this.nameInput.nativeElement as HTMLInputElement;
        const inputValue: string = inputEl.value;

        const CONSEC_SPACE = /\s{2,}/g;
        let filteredValue = inputValue.replace(CONSEC_SPACE, " ");

        filteredValue = _.filter(filteredValue, ch => {
            return (
                (ch === " ") || (ch === "-") || (ch === "ё") ||
                ((ch >= "a") && (ch <= "z")) ||
                ((ch >= "A") && (ch <= "Z")) ||
                ((ch >= "а") && (ch <= "я")) ||
                ((ch >= "А") && (ch <= "Я"))
            );
        }).join("");

        const hasErrors = (inputValue !== filteredValue);
        if (hasErrors) {
            inputEl.value = filteredValue;
            return;
        }

        this.hideNameSuggestion();

        if (filteredValue) {
            this.requestNameSuggestion(filteredValue);
        }
    }

    public onNameInputFocus(): void {
        this.showNameSuggestionsIfPossible();
    }

    public onNameInputKeydown(event: KeyboardEvent): void {
        if (event.key === "Tab") {
            event.preventDefault();

            this.birthDateInput.nativeElement.focus();

            return;
        }

        if (event.key === "Escape") {
            this.hideNameSuggestion();
            return;
        }

        if (event.key === "ArrowUp") {
            event.preventDefault();
            this.shiftSuggestionSelection(-1);
            return;
        }

        if (event.key === "ArrowDown") {
            event.preventDefault();
            this.shiftSuggestionSelection(1);
            return;
        }

        if (event.key === "Enter") {
            const suggestedSelection = this.getSuggestedSelection();
            if (!suggestedSelection) {
                return;
            }

            event.preventDefault();
            this.nameInput.nativeElement.value = suggestedSelection;
            this.hideNameSuggestion();

            return;
        }
    }

    public onNameSuggestionClick(e: MouseEvent, suggestion: string): void {
        e.stopImmediatePropagation();
        e.preventDefault();

        this.nameInput.nativeElement.value = suggestion;

        this.hideNameSuggestion();
    }

    public onPassportBlur(): void {
        this.isPassportFocused = true;
    }

    public onPassportInputKeydown(event: KeyboardEvent) {
        if (event.key !== "Tab") {
            return;
        }

        event.preventDefault();

        this.nameInput.nativeElement.focus();
    }

    public onPhoneBlur(): void {
        this.isPhoneFocused = true;
    }

    public async onRetrySmsCodeClick(): Promise<void> {
        await this.onSendSms();
    }

    public async onSendSms(): Promise<void> {
        if (this.isIdentitySent) {
            return;
        }

        this.isIdentitySent = true;

        try {
            await this.sendIdentity();

            const isStateHandled = await this.handlePurseState();
            if (isStateHandled) {
                return;
            }
        } catch (e) {
            const toastText = _.get(e, "error.detail", e.message);
            this.toastr.error(toastText);

            const isProfileUpdateFailed =
                (e instanceof HttpErrorResponse) &&
                (e.error.code === JsonErrorCode.EntityUpdateFailure);
            if (isProfileUpdateFailed) {
                await this.router.navigateByUrl("/finance");
            }

            return;
        } finally {
            this.isIdentitySent = false;
        }

        this.confirmationCode = "";
        this.isWaitingForCode = true;

        this.timeout = 60;
        this.sentNumbers[this.phoneInputText] = true;

        this.stopSmsTimeout();
        this.smsTimeoutTimerId = setInterval(() => {
            this.timeout = Math.max(0, this.timeout - 1);

            if (this.timeout === 0) {
                this.stopSmsTimeout();
                this.smsTimeoutTimerId = 0;
                this.sentNumbers = {};
                this.changeDetectorRef.detectChanges();
            }
        }, 1000) as any;
    }

    private buildIdentityRequest(): IdentityRequest {
        const birthDate = this.birthDateInputText;
        const familyName = this.nameInputText.split(" ")[0];
        const givenName = this.nameInputText.replace(`${familyName} `, "");
        const passportSeries = this.passportInputText.substring(0, 4);
        const passportId = this.passportInputText.substring(4);
        const phone = this.phoneInputText;

        return {
            birthDate,
            familyName,
            givenName,
            passport: {
                series: passportSeries,
                id: passportId
            },
            phone
        };
    }

    private async getPurseState(): Promise<PurseState> {
        const purse = await this.financeService.fetchWallet() as Wallet | null;

        const isPhoneConfirmed = _.get(purse, "phoneConfirmed", false);
        const verificationState = _.get(purse, "verification.state", VerificationState.UNVERIFIED) as VerificationState;

        if (verificationState === VerificationState.FAILURE) {
            const errorText = _.get(
                purse,
                "verification.errors[0]",
                "Неизвестная ошибка");

            return {
                errorText,
                isPhoneConfirmed,
                verificationState
            };
        }

        return {
            errorText: null,
            isPhoneConfirmed,
            verificationState
        };
    }

    private getSuggestedSelection(): string {
        if (this.suggestionSelectionIndex === null) {
            return;
        }

        return _.get(
            this.nameSuggestions,
            `[${this.suggestionSelectionIndex}]`,
            null);
    }

    private async handlePurseState(): Promise<boolean> {
        try {
            const purseState = await this.getPurseState();
            console.log(
                "handlePurseState, got purse state, s=", purseState,
                "vs=", purseState.verificationState);

            switch (purseState.verificationState) {
                case VerificationState.VERIFYING:
                case VerificationState.VERIFIED:
                case VerificationState.LEGACY_CHECKING: {
                    this.dataLayerService.emit({
                        eventCategory: EventCategory.Identification,
                        eventAction: EventAction.View,
                        eventLabel: "sent"
                    });

                    await this.router.navigateByUrl("/identity/thanks");
                    return true;
                }

                case VerificationState.PHONE: {
                    this.step = WizardStep.Confirmation;
                    this.notifyStepChanged();
                    this.showSmsInputForm();
                    return true;
                }

                case VerificationState.FAILURE: {
                    this.toastr.error(purseState.errorText);
                    return true;
                }

                default:
                    return false;
            }
        } catch (e) {
            console.log("handlePurseState, FAILED, e=", e);

            const toastText = _.get(e, "error.error", e.message);
            this.toastr.error(toastText);
            return true;
        }
    }

    private hideNameSuggestion(): void {
        this.isNameSuggestionVisible = false;
    }

    private notifyStepChanged() {
        let index = 0;

        switch (this.step) {
            case WizardStep.Id:
                index = 1;
                this.pageService.setPageTitleDirectly("Имя и дата рождения УПРИД | Donatty");
                break;

            case WizardStep.AdditionalId:
                index = 2;
                this.pageService.setPageTitleDirectly("Паспортные данные УПРИД | Donatty");
                break;

            case WizardStep.Confirmation:
                index = 3;
                this.pageService.setPageTitleDirectly("Подтверждение номера телефона - УПРИД | Donatty");
                break;
        }

        this.dataLayerService.emit({
            eventCategory: EventCategory.Identification,
            eventAction: EventAction.View,
            eventLabel: `step${index}`
        });
    }

    private onNameSuggestions(suggestions: string[]): void {
        this.isNameLoaderVisible = false;

        const currentName = this.nameInputText;

        const isNameChangedDuringRequest = (this.submittedSuggestionQuery !== currentName);
        if (isNameChangedDuringRequest) {
            this.nameSuggestions = [];
            return;
        }

        this.nameSuggestions = suggestions;
        this.showNameSuggestionsIfPossible();
    }

    private requestNameSuggestion(query: string): void {
        const DEBOUNCE_INTERVAL = 500;

        if (this.suggestionTimeoutId) {
            clearTimeout(this.suggestionTimeoutId);
        }

        this.suggestionTimeoutId = setTimeout(() => {
            this.isNameLoaderVisible = true;
            this.submittedSuggestionQuery = query;

            this.dadataService
                .getNameSuggestions(query)
                .subscribe(suggestions => this.onNameSuggestions(suggestions));
        }, DEBOUNCE_INTERVAL) as any;
    }

    private async sendIdentity(): Promise<void> {
        const req = this.buildIdentityRequest();
        console.log("sendIdentity, started, r=", req);

        await this.financeService.sendIdentity(req);

        console.log("sendIdentity, succeed");
        this.sentIdentityRequest = req;
    }

    public setAdditionalIdFocus(): void {
        // workaround for the focus caused by the click
        setTimeout(() =>
            this.birthDateInput.nativeElement.focus());
    }

    private shiftSuggestionSelection(delta: number): void {
        const hasSelection = (this.suggestionSelectionIndex !== null);
        if (!hasSelection) {
            if (delta > 0) {
                this.suggestionSelectionIndex = 0;
            } else {
                this.suggestionSelectionIndex = (this.nameSuggestions.length - 1);
            }

            return;
        }

        this.suggestionSelectionIndex += delta;

        if (this.suggestionSelectionIndex < 0) {
            this.suggestionSelectionIndex = (this.nameSuggestions.length - 1);
            return;
        }

        if (this.suggestionSelectionIndex >= this.nameSuggestions.length) {
            this.suggestionSelectionIndex = 0;
        }
    }

    private showNameSuggestionsIfPossible(): boolean {
        if (_.isEmpty(this.nameSuggestions)) {
            return false;
        }

        const isNameInputInFocus = (document.activeElement === this.nameInput.nativeElement);
        if (!isNameInputInFocus) {
            return false;
        }

        this.suggestionSelectionIndex = null;
        this.isNameSuggestionVisible = true;
        return true;
    }

    private showSmsInputForm(): void {
        this.confirmationCode = "";
        this.isWaitingForCode = true;

        this.timeout = 60;
        this.sentNumbers[this.phoneInputText] = true;

        this.stopSmsTimeout();
        this.smsTimeoutTimerId = setInterval(() => {
            this.timeout = Math.max(0, this.timeout - 1);

            if (this.timeout === 0) {
                this.stopSmsTimeout();
                this.smsTimeoutTimerId = 0;
                this.sentNumbers = {};
                this.changeDetectorRef.detectChanges();
            }
        }, 1000) as any;
    }

    private stopSmsTimeout(): void {
        if (!this.smsTimeoutTimerId) {
            return;
        }

        clearInterval(this.smsTimeoutTimerId);
        this.smsTimeoutTimerId = null;
    }

    @ViewChild("code", {static: true})
    private confirmationCodeEl: ConfirmationCodeComponent;

    @ViewChild("birthDateInput", {static: true})
    private birthDateInput: ElementRef;

    @ViewChild("nameInput", {static: true})
    private nameInput: ElementRef;

    @ViewChild("passportInput", {static: true})
    private passportInput: ElementRef;

    @ViewChild("phoneInput", {static: true})
    private phoneInput: ElementRef;

    private isInnFocused = false;
    private isNameFocused = false;
    private isDateFocused = false;
    private isPassportFocused = false;
    private isPhoneFocused = false;
    private smsTimeoutTimerId: number;
    private sentNumbers = {};
    private sentIdentityRequest: IdentityRequest = null;
    private suggestionTimeoutId: number = null;
    private submittedSuggestionQuery = "";
}

type PurseState = {
    isPhoneConfirmed: boolean;
    verificationState: VerificationState;
    errorText: string;
};
