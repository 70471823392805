import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
    Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild,
} from "@angular/core";
import {UserService} from "../../../../../services/user/user.service";
import {AccountInfo} from "../../../../../services/accounts/accounts";
import {merge, Observable, Subject} from "rxjs";
import {AccountsService} from "../../../../../services/accounts/accounts.service";
import {
    WidgetEventSources, WidgetEventSourcesAccountEvents,
} from "../../../../../../../../shared/src/lib/models/widget-props";
import {EventSourceChip} from "./chips/event-source-chips.component";
import {filter, map, shareReplay, switchMap, takeUntil} from "rxjs/operators";
import {WidgetSettingsService} from "../../../../../services/settings/widget-settings.service";
import {ConnectSocialDialogComponent} from "../../../../connect-social-dialog/connect-social-dialog.component";

@Component({
    selector: "app-widget-edit-event-sources",
    templateUrl: "./widget-edit-event-sources.html",
    styleUrls: ["./widget-edit-event-sources.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetEditEventSourcesComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public disabled = true;

    @Input()
    public eventSources: WidgetEventSources;

    @Output()
    public readonly eventSourcesChange = new EventEmitter<WidgetEventSources>();

    private readonly changes$: Subject<void> = new Subject();
    private readonly destroy$: Subject<void> = new Subject();

    public constructor(private readonly accountsService: AccountsService,
                       private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly changeDetectorRef: ChangeDetectorRef,
                       public readonly externalAccountsService: AccountsService,
                       public readonly userService: UserService) {
    }

    public readonly accounts$ = this.accountsService.accounts$.pipe(
        filter(accounts => accounts?.length > 0), shareReplay(1),
    );

    public readonly accountEvents$: Observable<Partial<WidgetEventSourcesAccountEvents>> =
        merge(this.accounts$, this.changes$.pipe(switchMap(() => this.accounts$))).pipe(
            map(accounts => {
                const events = {};
                accounts.forEach(account => events[account.getId()] = this.getAccountEvents(account));
                return events;
            }),
            shareReplay(1),
        );

    public ngOnInit(): void {
        this.accountsService.accountConnected$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.changeDetectorRef.detectChanges());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.eventSources) {
            this.changes$.next();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private getAccountEvents(account: AccountInfo): Partial<WidgetEventSourcesAccountEvents> {
        let accountEvents: Partial<WidgetEventSourcesAccountEvents> = this.eventSources.accounts[account.getId()];

        if (!accountEvents || Object.keys(accountEvents).length === 0) {
            accountEvents = this.widgetSettingsService.getDefaultAccountEvents(account.getType());
            this.eventSources.accounts[account.getId()] = accountEvents;
        }

        return accountEvents;
    }

    public onEventSourcesChanged(id: string, eventSourceChips: Array<EventSourceChip>) {
        for (const item of eventSourceChips) {
            const prop = this.eventSources.accounts[id][item.eventType];
            prop.isEnabled = item.prop.isEnabled;
        }
        this.eventSourcesChange.emit(this.eventSources);
    }

    @ViewChild("dialog", {static: true})
    public connectDialog: ConnectSocialDialogComponent;
}
