<div class="row" *ngFor="let item of direction == 'vertical' ? items : []; let i=index">
  <div class="pt-2 col">
    <input type="radio" id='{{group}}-{{i}}' [(ngModel)]="selectedValue" (change)="onChange(selectedValue)"
      [value]="item.value" [name]="group">
    <label for="{{group}}-{{i}}">{{item.name}}</label>
    <div *ngIf="selectedValue == item.value">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <div class="col-auto cursor-pointer mt-3" *ngIf="item.delete" (click)="delete.emit(item)">
    <img src="/assets/icons/delete-card.svg">
  </div>
</div>
<div class="row no-margin horizontal pt-3" *ngIf="direction == 'horizontal'">
  <div class="radio-item col-auto" *ngFor="let item of items; let i=index">
    <input type="radio" id='{{group}}-{{i}}' [(ngModel)]="selectedValue" (change)="changed.emit(selectedValue)"
      [value]="item.value" [name]="group">
    <label for="{{group}}-{{i}}">{{item.name}}</label>
  </div>
</div>
<ng-template #content><ng-content></ng-content></ng-template>
