import {BrowserModule} from "@angular/platform-browser";
import {
    NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER, LOCALE_ID
} from "@angular/core";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {ComponentsModule} from "./components/components.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FinanceComponent} from "./components/finance/finance.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {registerLocaleData} from "@angular/common";
import localeRU from "@angular/common/locales/ru";
import {SharedModule} from "../../../shared/src/lib/shared.module";
import {TestDonationComponent} from "./components/finance/test-donation/test-donation.component";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import * as Sentry from "@sentry/angular";
import {Router, RouteReuseStrategy} from "@angular/router";
import {SortablejsModule} from "ngx-sortablejs";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CustomReusingStrategy} from "./CustomRouteReusingStrategy";
import {ErrorStateMatcher} from "@angular/material/core";
import {ShowWhenTouchedErrorStateMatcher} from "../../../shared/src/lib/ShowAlwaysErrorStateMatcher";
import {AuthInterceptor} from "./http-interceptors/auth.interceptor";
import {RetryInterceptor} from "./http-interceptors/retry.interceptor";
import {MatChipsModule} from "@angular/material/chips";
import {MatTabsModule} from "@angular/material/tabs";
import {DragScrollModule} from "ngx-drag-scroll";
import {NotificationModule} from "./modules/notification/notification.module";
import {QuestModule} from "./modules/quest/quest.module";
import {DevModule} from "../../../shared/src/lib/dev/dev.module";
import {environment} from "../environments/environment";
import {SidebarModule} from "../../../shared/src/lib/sidebar/sidebar.module";
import {SidebarService} from "../../../shared/src/lib/sidebar/sidebar.service";
import {MatSidenavModule} from "@angular/material/sidenav";
import {PortalModule} from "@angular/cdk/portal";
import {ENVIRONMENT} from "../../../shared/src/lib/environment.service";
import {ModerationModule} from "./modules/moderation/moderation.module";
import {ServiceWorkerModule} from "@angular/service-worker";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";

registerLocaleData(localeRU, "ru");

@NgModule({
    declarations: [
        AppComponent,
        FinanceComponent,
        TestDonationComponent,
    ],
    imports: [
        DevModule.forRoot(environment),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ComponentsModule,
        SharedModule,
        HttpClientModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        ReactiveFormsModule,
        MatIconModule,
        SortablejsModule.forRoot({
            animation: 150,
        }),
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTabsModule,
        DragScrollModule,
        NotificationModule,
        QuestModule,
        SidebarModule,
        MatSidenavModule,
        PortalModule,
        ModerationModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        ServiceWorkerModule.register("firebase-messaging-sw.js"),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.serviceWorkerEnabled,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000"
        }),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({}),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: ENVIRONMENT,
            useFactory: () => environment
        },
        {provide: RouteReuseStrategy, useClass: CustomReusingStrategy},
        {provide: ErrorStateMatcher, useClass: ShowWhenTouchedErrorStateMatcher},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: "ru-RU"},
        SidebarService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
