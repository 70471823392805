import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {TotalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-total-tab-sum",
    templateUrl: "./widget-total-tab-sum.component.html",
    styleUrls: ["./widget-total-tab-sum.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetTotalTabSumComponent {

    @Input()
    public widgetProps: TotalWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();
}
