import {animate, animateChild, AUTO_STYLE, group, query, state, style, transition, trigger} from "@angular/animations";

export default [
    trigger("collapse", [
        state("false", style({height: AUTO_STYLE, overflow: AUTO_STYLE})),
        state("true", style({height: "64px", overflow: "hidden"})),
        transition(`false => true`, group([
            query("@*", animateChild()),
            query(":self", [style({ overflow: "hidden" })]),
            animate("300ms ease", style({height: "64px"})),
        ])),
        transition(`true => false`, group([
            query("@*", animateChild()),
            animate("300ms ease", style({height: AUTO_STYLE}))
        ])),
    ]),
    trigger("fadeOut", [
        state("false", style({opacity: 1})),
        state("true", style({opacity: 0})),
        transition(`* => *`, [animate("300ms ease")]),
    ]),
    trigger("rotate", [
        state("false", style({transform: "rotate(0deg)"})),
        state("true", style({transform: "rotate(-90deg)"})),
        transition(`* => *`, [animate("300ms ease")]),
    ]),
];
