import {
    ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output,
} from "@angular/core";
import {
    ExternalFontsService
} from "../../../../../../../../../shared/src/lib/services/external-fonts/external-fonts.service";
import {BehaviorSubject, combineLatest, Subject} from "rxjs";
import * as _ from "lodash";
import {map, switchMap, take} from "rxjs/operators";

export interface FontFamilyLink {
    family: string;
    uri: string;
}

@Component({
    selector: "app-widget-select-google-font",
    templateUrl: "./widget-select-google-font.component.html",
    styleUrls: ["./widget-select-google-font.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetSelectGoogleFontComponent {
    @Input()
    public value: FontFamilyLink = null;

    @Output()
    public readonly valueChange = new EventEmitter<FontFamilyLink>();

    private readonly init$ = new Subject<void>();
    public readonly searchString$ = new BehaviorSubject<string>("");
    public readonly filteredFontsList$ = this.init$.pipe(
        take(1),
        switchMap(() => combineLatest([this.googleFontsService.fonts$, this.searchString$])),
        map(([fontsList, searchString]) => filterFontsList(fontsList, searchString))
    );

    public isOpened = false;

    public constructor(private readonly googleFontsService: ExternalFontsService,
                       private readonly elementRef: ElementRef) {
    }

    private adjust() {
        const rect = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect();
        const parentRect = this.elementRef.nativeElement.offsetParent.getBoundingClientRect();
        this.reverse = rect.top > parentRect.bottom - rect.bottom;
    }

    @HostBinding("class.reverse")
    public reverse = false;

    @HostBinding("--font-list-height")
    public listHeight = "280px";

    @HostListener("document:mousedown", ["$event"])
    public documentMousedown(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isOpened = false;
        }
    }

    public cleanFamilyName(family: string): string {
        return _.trim(family, "'\"");
    }

    public escapedFamilyName(family: string): string {
        return `'${this.cleanFamilyName(family)}'`;
    }

    public isSelected(item: FontFamilyLink): boolean {
        return (this.value?.family === item.family);
    }

    public onClick(): void {
        this.adjust();
        this.isOpened = !this.isOpened;
        this.init$.next();
    }

    public onItemClick(item: FontFamilyLink): void {
        this.value = item;
        this.valueChange.emit(item);
        this.isOpened = false;
    }

    public onKeydown(event: KeyboardEvent): void {
        if (event.code === "Escape") {
            this.isOpened = false;
        }
    }

}

function filterFontsList(list: FontFamilyLink[], filterStr: string): FontFamilyLink[] {
    const filterLc = filterStr.toLocaleLowerCase();
    return list.filter(item => item.family.toLocaleLowerCase().includes(filterLc));
}
