export class Util {
    /** @deprecated Use textGrammarService.declentNumeric */
    public static declineNumeral(n: number, titles: Array<string>): string {
        return titles[n % 100 > 4 && n % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][n % 10 < 5 ? n % 10 : 5]];
    }

    public static formatDate(d: Date) {
        const ye = new Intl.DateTimeFormat("en", {year: "numeric"}).format(d);
        const mo = new Intl.DateTimeFormat("en", {month: "2-digit"}).format(d);
        const da = new Intl.DateTimeFormat("en", {day: "2-digit"}).format(d);
        return `${ye}-${mo}-${da}`;
    }

    public static selectElementContents(el: HTMLElement) {
        el.focus();
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    public static setCaret(el: Node, start: number) {
        const range = document.createRange();
        try {
            range.setStart(el, start);
        } catch (e) {
            range.setStart(el, start - 1);
        }
        range.collapse(true);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    public static randomItem(arr: Array<any>) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    public static randomName() {
        return `${Util.randomItem(NAMES)} ${Util.randomItem(FAMILY_NAMES)}`;
    }

    public static randomMessage() {
        return MESSAGES[Math.floor(Math.random() * MESSAGES.length)];
    }

    public static deepDiffMap<T>(obj1: object, obj2: object) {
        const VALUE_CREATED = "created";
        const VALUE_UPDATED = "updated";
        const VALUE_DELETED = "deleted";
        const VALUE_UNCHANGED = "unchanged";
        const isFunction = x => Object.prototype.toString.call(x) === "[object Function]";
        const isArray = x => Object.prototype.toString.call(x) === "[object Array]";
        const isDate = x => Object.prototype.toString.call(x) === "[object Date]";
        const isObject = x => Object.prototype.toString.call(x) === "[object Object]";
        const isValue = x => !isObject(x) && !isArray(x);

        const compareValues = (a: any, b: any) => {
            if (a === b || isDate(a) && isDate(b) && a.getTime() === b.getTime()) {
                return VALUE_UNCHANGED;
            }
            if (a === undefined) {
                return VALUE_CREATED;
            }
            if (b === undefined) {
                return VALUE_DELETED;
            }
            return VALUE_UPDATED;
        };

        if (isFunction(obj1) || isFunction(obj2)) {
            throw new Error("Invalid argument. Function given, object expected.");
        }

        if (isValue(obj1) || isValue(obj2)) {
            return {
                type: compareValues(obj1, obj2),
                data: obj1 === undefined ? obj2 : obj1
            };
        }

        const diff = {};
        for (const key in obj1) {
            if (isFunction(obj1[key])) {
                continue;
            }

            let value2;
            if (obj2[key] !== undefined) {
                value2 = obj2[key];
            }

            diff[key] = this.deepDiffMap(obj1[key], value2);
        }
        for (const key in obj2) {
            if (isFunction(obj2[key]) || diff[key] !== undefined) {
                continue;
            }

            diff[key] = this.deepDiffMap(undefined, obj2[key]);
        }

        for (const key in diff) {
            if (Object.keys(diff[key]).length === 0 || diff[key]?.type === VALUE_UNCHANGED) {
                delete diff[key];
            }
        }

        return diff;
    }
}

const MESSAGES: Array<string> = [
    "",
    "Hello",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ✈ aliquet purus eu mi bibendum, id hendrerit magna rutrum.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium, turpis eget pharetra luctus, felis est sagittis odio, nec fermentum libero nisi nec libero. Sed metus eros, porttitor eu nibh ac, pulvinar porta eros. Aenean id enim finibus, tempor lorem eu, suscipit nibh. Cras facilisis eu."
];
const NAMES = ["Kenjo", "Gypsy", "Cao"];
const FAMILY_NAMES: string[] = ["Yu", "Hardinge", "Assou"];
