<div class="error__page">
    <div class="error__logo">
        <img class="error__image error__image--blur" src="/assets/icons/error-image.png" />
        <img class="error__image error__image--normal" src="/assets/icons/error-image.png" />
    </div>
    <div class="error__title">Ошибочка</div>
    <div class="error__subtitle">
        Проверьте правильность введенных данных<br/>
        и повторите попытку снова.
    </div>

    <app-new-button [disabled]="!enabled" type="blue centered" (click)="repeat()">
        Повторить попытку
    </app-new-button>
</div>
