import {NgModule} from "@angular/core";
import {NewButtonComponent} from "./new-button/new-button.component";
import {CommonModule} from "@angular/common";
import {ConfirmationModalComponent} from "./confirmation-modal/confirmation-modal.component";
import {TooltipComponent} from "./tooltip/tooltip.component";
import {PopupComponent} from "./popup/popup.component";
import {FocusDirective} from "./focus.directive";
import {CheckboxComponent} from "./checkbox/checkbox.component";
import {PastePlaneTextDirective} from "./paste-plane-text.directive";
import {DeclentNumericPipe} from "./declent-numeric.pipe";
import {CustomCurrencyPipe} from "./custom-currency.pipe";
import {NiceDatePipe} from "./nice-date.pipe";
import {MatTabGroupFfScrollFixDirective} from "./mat-tab-group-ff-scroll-fix.directive";
import {ProgressSpinnerComponent} from "./progress-spinner/progress-spinner.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {OverlayModule} from "@angular/cdk/overlay";

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        OverlayModule
    ],
    declarations: [
        NewButtonComponent,
        ConfirmationModalComponent,
        TooltipComponent,
        PopupComponent,
        FocusDirective,
        CheckboxComponent,
        PastePlaneTextDirective,
        DeclentNumericPipe,
        CustomCurrencyPipe,
        NiceDatePipe,
        MatTabGroupFfScrollFixDirective,
        ProgressSpinnerComponent,
    ],
    exports: [
        NewButtonComponent,
        ConfirmationModalComponent,
        TooltipComponent,
        PopupComponent,
        FocusDirective,
        CheckboxComponent,
        PastePlaneTextDirective,
        DeclentNumericPipe,
        CustomCurrencyPipe,
        NiceDatePipe,
        MatTabGroupFfScrollFixDirective,
        ProgressSpinnerComponent,
    ]
})
export class SharedModule {
}
