import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener,
    NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren
} from "@angular/core";
import {
    AccountEventType, AccountInfo, AccountType, disabledAccountEvents, eventTypesMap
} from "../../../services/accounts/accounts";
import {AuthService} from "../../../../../../shared/src/lib/auth.service";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../../../../shared/src/lib/environment.service";
import {AccountsService} from "../../../services/accounts/accounts.service";
import {IAlertWidgetInfo, WidgetCurrency} from "../../../../../../shared/src/lib/models/widget";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WidgetSettingsService} from "../../../services/settings/widget-settings.service";
import {MatSelect} from "@angular/material/select";
import {BehaviorSubject, combineLatest, Subject} from "rxjs";
import {filter, map, startWith, take, takeUntil} from "rxjs/operators";
import {DragScrollComponent} from "ngx-drag-scroll";
import {WidgetGroupService} from "../../../services/widgets/widget_groups/widget-group.service";
import {MatTabGroup} from "@angular/material/tabs";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: "app-test-donation",
    templateUrl: "./test-donation.component.html",
    styleUrls: ["./test-donation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestDonationComponent implements OnInit, AfterViewInit, OnDestroy {
    public isSendInProgress = false;
    public readonly AccountType = AccountType;
    public readonly AccountEventType = AccountEventType;
    public readonly selectedTab$ = new BehaviorSubject(AccountType.Donatty);
    public readonly selectedItems$: { [key in AccountType]: BehaviorSubject<AccountEventType> } = {
        [AccountType.Donatty]: new BehaviorSubject(AccountEventType.DonattyDonate),
        [AccountType.Twitch]: new BehaviorSubject(AccountEventType.TwitchBits),
        [AccountType.Youtube]: new BehaviorSubject(AccountEventType.YoutubeSuperchat),
        [AccountType.Trovo]: new BehaviorSubject(AccountEventType.TrovoSpellMana),
        [AccountType.Paypal]: new BehaviorSubject(AccountEventType.PaypalDonate),
    };
    public chipsSnapDuration = 200;
    private readonly destroy$: Subject<void> = new Subject();
    private readonly accounts$ = this.accountsService.accounts$.pipe(filter<Array<AccountInfo>>(Boolean));
    public readonly isLoading$ = this.accounts$.pipe(take(1), map(() => false), startWith(true));
    private readonly accountsAlerts$ = combineLatest([this.accounts$, this.widgetGroupService.alerts$])
        .pipe(takeUntil(this.destroy$));

    public constructor(
        public readonly widgetSettingsService: WidgetSettingsService,
        private readonly accountsService: AccountsService,
        private readonly authService: AuthService,
        private readonly environmentService: EnvironmentService,
        private readonly http: HttpClient,
        private readonly widgetGroupService: WidgetGroupService,
        private readonly formBuilder: FormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        private readonly router: Router,
    ) {
    }

    @HostListener("wheel", ["$event"])
    public onWheel(event: WheelEvent) {
        if (!event.target) {
            return;
        }
        const target = event.target as HTMLElement;
        if (target.tagName !== "TEXTAREA") {
            return;
        }
        const textarea = target as HTMLTextAreaElement;
        if (textarea.scrollHeight <= textarea.clientHeight) {
            return;
        }
        target.scrollBy({top: event.deltaY});
        event.preventDefault();
    }


    @ViewChild(MatTabGroup)
    private tabs: MatTabGroup;

    public ngOnInit(): void {
        this.watchEnabledAccountEvents();
    }

    public onTabClick(e) {
        const order = new Map<string, AccountType>([
            ["Donatty", AccountType.Donatty],
            ["Twitch", AccountType.Twitch],
            ["YouTube", AccountType.Youtube],
            ["Trovo", AccountType.Trovo],
            ["PayPal", AccountType.Paypal]
        ]);

        this.selectedTab$.next(order.get(e.tab.textLabel));
    }

    public ngAfterViewInit(): void {
        this.autoScrollToSelectedOption();
        this.tabs.selectedTabChange.subscribe(() => this.autoScrollToSelectedChip());
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            takeUntil(this.destroy$),
        ).subscribe(() => this.tabs.realignInkBar());
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private watchEnabledAccountEvents() {
        const accountTypes = [AccountType.Twitch, AccountType.Youtube, AccountType.Trovo, AccountType.Paypal];
        this.accountsAlerts$.subscribe(([accounts, alerts]) => this.ngZone.run(() => {
            for (const accountType of accountTypes) {
                this.forms[accountType].isEnabled$.next(this.hasEnabledAccountEvents(accounts, alerts, accountType));
            }
            if (!this.forms[this.selectedTab$.value].isEnabled$.value) {
                this.selectedTab$.next(AccountType.Donatty);
            }
            this.tabs?.realignInkBar();
        }));
    }

    @ViewChildren(MatSelect)
    private selectList: QueryList<MatSelect>;

    private autoScrollToSelectedOption() {
        this.selectList.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.selectList.forEach((select: MatSelect) => select.openedChange
                .pipe(filter(Boolean), filter(() => !!select.value))
                .subscribe(() => {
                    const selector = `[data-value='${select.value}']`;
                    select.panel.nativeElement.querySelector(selector).scrollIntoView();
                })
            );
        });
    }

    @ViewChildren("chips")
    private dragScrollComponents: QueryList<DragScrollComponent>;

    private autoScrollToSelectedChip() {
        this.dragScrollComponents.forEach((dragScroll: DragScrollComponent) => {
            const chipIndex = this.forms[this.selectedTab$.value].items
                .findIndex(item => item.accountEventType === this.selectedItems$[this.selectedTab$.value].value);
            if (chipIndex < 3) {
                return;
            }
            dragScroll.snapDuration = 40;
            dragScroll.snapAnimationFinished.subscribe(() => dragScroll.snapDuration = 500);
            dragScroll.moveTo(0);
            dragScroll.moveTo(chipIndex);
        });
    }

    private hasEnabledAccountEvents(accounts: Array<AccountInfo>, alerts: Array<IAlertWidgetInfo>, accountType: AccountType): boolean {
        const accountsOfType = accounts.filter(account => account.getType() === accountType);
        for (const widget of alerts) {
            const widgetAccounts = widget.getEventSources().accounts;
            for (const accountId of Object.keys(widgetAccounts)) {
                const isAccountConnected = accountsOfType.find(account => account.getId() === accountId);
                if (!isAccountConnected) {
                    continue;
                }

                const accountEvents = widgetAccounts[accountId];
                for (const eventId of Object.keys(accountEvents)) {
                    const event = accountEvents[eventId];
                    if (event.isEnabled) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private buildPayload(type: AccountType, eventType: AccountEventType): {} {
        console.log("buildPayload, started, t=", type, "e=", eventType);

        const action = this.forms[type].eventTypes[eventType];
        const payload = action.formatPayload(action.formGroup);

        console.log("buildPayload, payload ready, p=", payload);

        return {
            sources: {
                accounts: {
                    [type]: {
                        [eventType]: {isEnabled: true}
                    }
                }
            },
            payload,
            type: eventTypesMap[eventType]
        };
    }

    public async sendPayload(type: AccountType, eventType: AccountEventType): Promise<void> {
        this.isSendInProgress = true;
        try {
            const url = `${this.environmentService.backendApiUri}/widgets/alert/test`;
            const payload = this.buildPayload(type, eventType);
            const authHeaders = this.authService.makeTokenAuthHeaders();
            await this.http.post(url, payload, authHeaders).toPromise();
        } finally {
            this.isSendInProgress = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    public forms: Forms = {
        [AccountType.Donatty]: {
            isEnabled$: new BehaviorSubject(true),
            items: [
                {name: "Донат", accountEventType: AccountEventType.DonattyDonate},
            ],
            eventTypes: {
                [AccountEventType.DonattyDonate]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            message: form.get("message").value,
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Ruble
                        };
                    },
                },
            }
        },
        [AccountType.Twitch]: {
            isEnabled$: new BehaviorSubject(false),
            items: [
                {name: "Bits", accountEventType: AccountEventType.TwitchBits},
                {name: "Follower", accountEventType: AccountEventType.TwitchFollowers},
                {name: "Subscriber", accountEventType: AccountEventType.TwitchSubscribers},
                {name: "Subscriber (Upgrade)", accountEventType: AccountEventType.TwitchSubscribersUpgrade},
                {name: "Gift (Viewer)", accountEventType: AccountEventType.TwitchGiftSubscribersViewer},
                {name: "Gift (Channel)", accountEventType: AccountEventType.TwitchGiftSubscribersChannel},
                {name: "Gift (Upgrade)", accountEventType: AccountEventType.TwitchGiftSubscribersUpgrade},
                {name: "Points", accountEventType: AccountEventType.TwitchChannelPoints},
                {name: "Hype Train", accountEventType: AccountEventType.TwitchHypeTrain},
                {name: "Host", accountEventType: AccountEventType.TwitchHost},
                {name: "Raid", accountEventType: AccountEventType.TwitchRaids},
            ].filter(item => !disabledAccountEvents.has(item.accountEventType)),
            eventTypes: {
                [AccountEventType.TwitchBits]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            message: form.get("message").value,
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Bit
                        };
                    }
                },
                [AccountEventType.TwitchSubscribers]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
                        total: [3, Validators.compose([Validators.min(1), Validators.max(9999)])],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Month,
                            twitch: {
                                subscription: {
                                    streak: 2,
                                    total: Number(form.get("total").value),
                                    tier: Number(form.get("tier").value),
                                },
                            },
                        };
                    },
                },
                [AccountEventType.TwitchSubscribersUpgrade]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        tier: [2, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                        oldTier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous"),
                            twitch: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                    oldTier: Number(form.get("oldTier").value),
                                },
                            },
                        };
                    },
                },
                [AccountEventType.TwitchGiftSubscribersViewer]: {
                    formGroup: this.formBuilder.group({
                        giftedBy: [""],
                        giftedTo: ["Username"],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("giftedBy").value || "Anonymous"),
                            amount: Number(form.get("amount").value),
                            message: form.get("message").value,
                            twitch: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                },
                            },
                            currency: WidgetCurrency.Month,
                        };
                    },
                },
                [AccountEventType.TwitchGiftSubscribersChannel]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous"),
                            amount: Number(form.get("amount").value),
                            currency: WidgetCurrency.Subscription,
                            twitch: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                },
                            },
                        };
                    },
                },
                [AccountEventType.TwitchGiftSubscribersUpgrade]: {
                    formGroup: this.formBuilder.group({
                        name: ["", Validators.compose([Validators.required])],
                        giver: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous"),
                            twitch: {
                                subscription: {
                                    giftedBy: (form.get("giver").value || "Anonymous")
                                },
                            },
                        };
                    }
                },
                [AccountEventType.TwitchFollowers]: {
                    formGroup: this.formBuilder.group({
                        name: ["", Validators.compose([Validators.required])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous")
                        };
                    }
                },
                [AccountEventType.TwitchHost]: {
                    formGroup: this.formBuilder.group({
                        name: ["", Validators.compose([Validators.required])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous")
                        };
                    }
                },
                [AccountEventType.TwitchRaids]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Viewer
                        };
                    }
                },
                [AccountEventType.TwitchHypeTrain]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([
                            Validators.required,
                            Validators.min(1),
                            Validators.max(5),
                        ])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        const level = Number(form.get("amount").value);
                        return {
                            amount: level,
                            currency: WidgetCurrency.Level,
                            twitch: {
                                hypeTrain: {
                                    level,
                                },
                            },
                        };
                    },
                },
                [AccountEventType.TwitchChannelPoints]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        reward: ["", Validators.required],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            currency: WidgetCurrency.Points,
                            twitch: {
                                rewards: {
                                    subscriber: (form.get("name").value || "Anonymous"),
                                    rewardId: form.get("reward").value,
                                    message: form.get("message").value,
                                },
                            },
                        };
                    },
                },
            },
        },
        [AccountType.Youtube]: {
            isEnabled$: new BehaviorSubject(false),
            items: [
                {name: "Superchat", accountEventType: AccountEventType.YoutubeSuperchat},
                {name: "Subscriber", accountEventType: AccountEventType.YoutubeSubscribers},
                {name: "Member", accountEventType: AccountEventType.YoutubeMembers},
            ],
            eventTypes: {
                [AccountEventType.YoutubeSuperchat]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            message: form.get("message").value,
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Ruble
                        };
                    }
                },
                [AccountEventType.YoutubeSubscribers]: {
                    formGroup: this.formBuilder.group({
                        name: ["", Validators.compose([Validators.required, Validators.min(1)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous")
                        };
                    }
                },
                [AccountEventType.YoutubeMembers]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Month
                        };
                    }
                },
            },
        },

        // Trovo
        [AccountType.Trovo]: {
            isEnabled$: new BehaviorSubject(false),
            items: [
                {name: "Spell (Mana)", accountEventType: AccountEventType.TrovoSpellMana},
                {name: "Spell (Elixir)", accountEventType: AccountEventType.TrovoSpellElixir},
                {name: "Follower", accountEventType: AccountEventType.TrovoFollowers},
                {name: "Subscriber", accountEventType: AccountEventType.TrovoSubscribers},
                {name: "Gift (Viewer)", accountEventType: AccountEventType.TrovoGiftSubscribersViewer},
                {name: "Gift (Channel)", accountEventType: AccountEventType.TrovoGiftSubscribersChannel},
                {name: "Raid", accountEventType: AccountEventType.TrovoRaids},
            ].filter(item => !disabledAccountEvents.has(item.accountEventType)),
            eventTypes: {
                [AccountEventType.TrovoSpellMana]: {
                    formGroup: this.formBuilder.group({
                        spellName: ["", Validators.compose([Validators.required])],
                        casterName: [""],
                        amount: [3, Validators.compose([Validators.required, Validators.min(1), Validators.max(999)])],
                        value: [300, Validators.compose([Validators.required, Validators.min(1)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            currency: WidgetCurrency.TrovoMana,
                            subscriber: (form.get("casterName").value || "Anonymous"),
                            amount: form.get("amount").value,
                            trovo: {
                                spell: {
                                    name: form.get("spellName").value,
                                    value: form.get("value").value,
                                },
                            },
                        };
                    },
                },

                [AccountEventType.TrovoSpellElixir]: {
                    formGroup: this.formBuilder.group({
                        spellName: ["", Validators.compose([Validators.required])],
                        casterName: [""],
                        amount: [3, Validators.compose([Validators.required, Validators.min(1), Validators.max(999)])],
                        value: [100, Validators.compose([Validators.required, Validators.min(1)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            currency: WidgetCurrency.TrovoElixir,
                            subscriber: (form.get("casterName").value || "Anonymous"),
                            amount: form.get("amount").value,
                            trovo: {
                                spell: {
                                    name: form.get("spellName").value,
                                    value: form.get("value").value,
                                },
                            },
                        };
                    },
                },

                [AccountEventType.TrovoSubscribers]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous"),
                            trovo: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                },
                            },
                        };
                    },
                },

                [AccountEventType.TrovoGiftSubscribersViewer]: {
                    formGroup: this.formBuilder.group({
                        giftedBy: [""],
                        giftedTo: ["", Validators.compose([Validators.required])],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: form.get("giftedBy").value || "Anonymous",
                            trovo: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                    giftedTo: form.get("giftedTo").value || "Username",
                                },
                            },
                            currency: WidgetCurrency.Month,
                        };
                    },
                },
                [AccountEventType.TrovoGiftSubscribersChannel]: {
                    formGroup: this.formBuilder.group({
                        giftedBy: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
                        tier: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("giftedBy").value || "Anonymous"),
                            amount: Number(form.get("amount").value),
                            trovo: {
                                subscription: {
                                    tier: Number(form.get("tier").value),
                                },
                            },
                            currency: WidgetCurrency.Subscription,
                        };
                    },
                },
                [AccountEventType.TrovoFollowers]: {
                    formGroup: this.formBuilder.group({
                        name: ["", Validators.compose([Validators.required])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            subscriber: (form.get("name").value || "Anonymous")
                        };
                    }
                },
                [AccountEventType.TrovoRaids]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Viewer,
                        };
                    }
                },
            }
        },
        [AccountType.Paypal]: {
            isEnabled$: new BehaviorSubject(false),
            items: [
                {name: "Donate", accountEventType: AccountEventType.PaypalDonate},
            ],
            eventTypes: {
                [AccountEventType.PaypalDonate]: {
                    formGroup: this.formBuilder.group({
                        name: [""],
                        amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
                        message: [""],
                    }),
                    formatPayload: (form: FormGroup) => {
                        return {
                            amount: Number(form.get("amount").value),
                            message: form.get("message").value,
                            subscriber: (form.get("name").value || "Anonymous"),
                            currency: WidgetCurrency.Ruble
                        };
                    }
                }
            }
        }
    };
}

type Forms = {
    [key in AccountType]: {
        isEnabled$: BehaviorSubject<boolean>;
        items: Array<{ name: string, accountEventType: AccountEventType }>;
        eventTypes: {
            [tab in AccountEventType]?: {
                formGroup: FormGroup;
                formatPayload: (form: FormGroup) => {};
            };
        };
    };
};
