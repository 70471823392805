import {Pipe, PipeTransform} from "@angular/core";
import {StreamerEventCurrency} from "../../../streamer/src/app/services/events/streamer-events.api";
import {Currency} from "./common/FinancialOperation";

@Pipe({name: "customCurrency"})
export class CustomCurrencyPipe implements PipeTransform {
    private fmt = new Intl.NumberFormat("ru-RU", {maximumFractionDigits: 0});
    private symbols = {
        [StreamerEventCurrency.Bits]: "&nbsp;Bits",
        [StreamerEventCurrency.Points]: "&nbsp;points",
        [StreamerEventCurrency.Ruble]: "&nbsp;₽",
    };

    public transform(amount: number, currency?: Currency | StreamerEventCurrency): string {
        if (this.symbols[currency]) {
            return this.fmt.format(amount) + (this.symbols[currency] ?? "");
        } else {
            return "$&nbsp;" + this.fmt.format(amount);
        }
    }
}
