import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginTokenService} from "../../../services/login/login-token.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "app-obs-card",
    templateUrl: "./obs-card.component.html",
    styleUrls: ["./obs-card.component.scss"]
})
export class ObsCardComponent implements OnInit, OnDestroy {
    public isResettingLoginToken = false;

    private readonly destroy$: Subject<void> = new Subject();

    public constructor(public readonly loginTokenService: LoginTokenService) {
    }

    public ngOnInit() {
        this.loginTokenService.obsLoginUrl$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.isResettingLoginToken = false);
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }
}
