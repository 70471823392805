<mat-tab-group ffScrollFix mat-align-tabs="start" dynamicHeight [disableRipple]="true">
    <mat-tab aria-label="style" label="Стиль">
        <app-widget-tab-style [widgetType]="WidgetType.Goal"
                              [(widgetProps)]="widgetProps"
                              (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-tab-style>
    </mat-tab>
    <mat-tab aria-label="properties" label="Свойства">
        <app-widget-goal-tab-props [(widgetProps)]="widgetProps"
                                   (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-goal-tab-props>
    </mat-tab>
    <mat-tab aria-label="header" label="Заголовок">
        <app-widget-goal-tab-header #widgetGoalTabHeader
                                    [(widgetProps)]="widgetProps"
                                    (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-goal-tab-header>
    </mat-tab>
    <mat-tab aria-label="goal" label="Цель">
        <app-widget-goal-tab-target [(widgetProps)]="widgetProps"
                                    (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-goal-tab-target>
    </mat-tab>
    <mat-tab aria-label="countdown" label="Счётчик дней">
        <app-widget-goal-tab-countdown [(widgetProps)]="widgetProps"
                                       (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-goal-tab-countdown>
    </mat-tab>
</mat-tab-group>
