import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {ToastrService} from "ngx-toastr";
import {PlatformInfoService} from "../../../../../../../../shared/src/lib/platform-info.service";
import {TwitchSubscriberGiftUpgradeEvent} from "../../../../../services/events/streamer-event";
import {ModerationState} from "../../../../../services/events/streamer-events.api";
import {ItemClasses} from "../item-donate-card/item-donate-card.component";

@Component({
    selector: "app-item-twitch-subscriber-gift-upgrade-card",
    templateUrl: "./item-twitch-subscriber-gift-upgrade-card.component.html",
    styleUrls: ["../item-donate-card/item-donate-card.component.scss"],
})
export class ItemTwitchSubscriberGiftUpgradeComponent implements OnChanges {
    @Input()
    public item: TwitchSubscriberGiftUpgradeEvent;

    @Input()
    public active = false;

    @Output()
    public selected = new EventEmitter<TwitchSubscriberGiftUpgradeEvent>();

    @Output()
    public readonly retry = new EventEmitter<TwitchSubscriberGiftUpgradeEvent>();
    public itemClasses: ItemClasses;
    public readonly ModerationState = ModerationState;

    constructor(private readonly clipboardService: ClipboardService,
                private readonly toastr: ToastrService,
                private readonly platformInfoService: PlatformInfoService) {
    }

    public ngOnChanges(): void {
        this.itemClasses = {
            "tl__item--desktop": this.platformInfoService.isDesktop,
            "tl__item--live": this.item.isLive,
            "tl__item--mobile": this.platformInfoService.isMobile,
            "tl__item--selected": this.active,
            "tl__item--obs": this.platformInfoService.isObs,
        };
    }

}
