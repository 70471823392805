<div class="we__tab-container">
    <div class="we__header">
        Стиль виджета
    </div>

    <app-settings-edit-layout [type]="widgetType"
                              [(value)]="widgetProps.style.layout"
                              (valueChange)="widgetProps.style.layout = $event; beforeChange()">
    </app-settings-edit-layout>

    <div class="we__style-notif">
        При смене стиля некоторые настройки могут потеряться. Будьте внимательны.
    </div>
</div>
