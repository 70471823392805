<div class="we__tab-container">
    <div class="we__header">
        Настройка списка
    </div>

    <div class="we__row">
        <div class="we__label">
            Количество данных
        </div>
        <div class="we__controls">
            <app-single-slider [min]="1" [max]="1000" [(value)]="widgetProps.data.rows" logScale="true"
                           (valueChange)="widgetProps.data.rows = $event; widgetPropsChange.emit(widgetProps)" unit="">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Количество строк
        </div>
        <div class="we__controls">
            <app-single-slider [min]="1" [max]="40" [(value)]="visibleRows" unit="">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Расстояние между строк
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="widgetProps.style.rowsMargin"
                           (valueChange)="widgetProps.style.rowsMargin = $event; widgetPropsChange.emit(widgetProps)" unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Высота фона данных
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="widgetProps.style.rowsPadding"
                           (valueChange)="widgetProps.style.rowsPadding = $event; widgetPropsChange.emit(widgetProps)" unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Инвертировать порядок
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.data.isListInverted"
                              (change)="widgetProps.data.isListInverted = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Скорость прокрутки
    </div>

    <div class="we__row">
        <div class="we__label">
            Скорость прокрутки списка
        </div>
        <div class="we__controls">
            <app-single-slider [min]="1" [max]="100" [(value)]="widgetProps.style.updateSpeed"
                           (valueChange)="widgetProps.style.updateSpeed = $event; widgetPropsChange.emit(widgetProps)" unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Нумерация списка
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать нумерацию списка
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.isIndexEnabled"
                              (change)="widgetProps.style.isIndexEnabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Скругление углов виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Скругление виджета
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="mainRounding" unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Скругление фона данных
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="itemsRounding" unit="%">
            </app-single-slider>
        </div>
    </div>


    <div class="we__hor-line"></div>

    <div class="we__header">
        Основные цвета виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Цвет виджета
        </div>
        <div class="we__controls we__controls--hor">
            <div class="we__control">
                <mat-slide-toggle [disableRipple]="true"
                                  [checked]="widgetProps.style.widgetBackgroundColorEnabled"
                                  (change)="widgetProps.style.widgetBackgroundColorEnabled = $event.checked; widgetPropsChange.emit(widgetProps)">
                </mat-slide-toggle>
            </div>
            <div class="we__control">
                <app-color-select [color]="widgetProps.style.widgetBackgroundColor"
                                  (colorChange)="widgetProps.style.widgetBackgroundColor = $event; widgetPropsChange.emit(widgetProps)"
                                  [disabled]="!widgetProps.style.widgetBackgroundColorEnabled"
                                  [short]="true">
                </app-color-select>
            </div>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Цвет фона данных
        </div>
        <div class="we__controls we__controls--hor">
            <div class="we__control">
                <mat-slide-toggle [disableRipple]="true"
                                  [checked]="widgetProps.style.widgetBackgroundUnderSumColorEnabled"
                                  (change)="widgetProps.style.widgetBackgroundUnderSumColorEnabled = $event.checked; widgetPropsChange.emit(widgetProps)">
                </mat-slide-toggle>
            </div>
            <div class="we__control">
                <app-color-select [color]="widgetProps.style.widgetBackgroundUnderSumColor"
                                  (colorChange)="widgetProps.style.widgetBackgroundUnderSumColor = $event; widgetPropsChange.emit(widgetProps)"
                                  [disabled]="!widgetProps.style.widgetBackgroundUnderSumColorEnabled"
                                  [short]="true">
                </app-color-select>
            </div>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Обводка виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать обводку
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.stroke.enabled"
                              (change)="widgetProps.style.stroke.enabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.stroke.enabled">
        <div class="we__gradient-row">
            <div class="we__row">
                <div class="we__label">Градиент обводки</div>
                <div class="we__controls">
                    <app-color-select [color]="widgetProps.style.stroke.color0"
                                      [showHints]="false"
                                      (colorChange)="widgetProps.style.stroke.color0 = $event; widgetPropsChange.emit(widgetProps)">
                    </app-color-select>
                </div>
            </div>

            <div class="we__row we__row--second-color">
                <div class="we__label"></div>
                <div class="we__controls">
                    <app-color-select [color]="widgetProps.style.stroke.color1"
                                      (colorChange)="widgetProps.style.stroke.color1 = $event; widgetPropsChange.emit(widgetProps)">
                    </app-color-select>
                </div>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Толщина обводки
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="20" [(value)]="widgetProps.style.stroke.width"
                               (valueChange)="widgetProps.style.stroke.width = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px">
                </app-single-slider>
            </div>
        </div>
    </ng-container>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Тень виджета
    </div>

    <div class="we__row">
        <div class="we__label">
            Отображать тень
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="widgetProps.style.shadow.enabled"
                              (change)="widgetProps.style.shadow.enabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
        </div>
    </div>

    <ng-container *ngIf="widgetProps.style.shadow.enabled">
        <div class="we__row">
            <div class="we__label">
                Цвет тени
            </div>
            <div class="we__controls">
                <app-color-select [color]="widgetProps.style.shadow.color"
                                  (colorChange)="widgetProps.style.shadow.color = $event; widgetPropsChange.emit(widgetProps)">
                </app-color-select>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Размытость тени
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="20" [(value)]="widgetProps.style.shadow.blur"
                               (valueChange)="widgetProps.style.shadow.blur = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по X
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.shadow.offsetX"
                               (valueChange)="widgetProps.style.shadow.offsetX = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по Y
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="widgetProps.style.shadow.offsetY"
                               (valueChange)="widgetProps.style.shadow.offsetY = $event; widgetPropsChange.emit(widgetProps)"
                               unit="px"
                               [zeroAdjust]="0">
                </app-single-slider>
            </div>
        </div>
    </ng-container>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Фильтры
        <mat-icon class="help" svgIcon="question"
                  (mouseenter)="infoTooltip.show($event.target)"
                  (mouseleave)="infoTooltip.hide()"></mat-icon>
        <lib-tooltip #infoTooltip [position]="'bottom-left'">
            Настроить фильтр по плохим словам<br>
            и слово-замену можно в настройках
        </lib-tooltip>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать ссылки
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="isLinksFilterEnabled"
                              (change)="isLinksFilterEnabled = $event.checked; widgetPropsChange.emit(widgetProps)">
            </mat-slide-toggle>
            <div class="we__controls">
                <span *ngIf="isLinksFilterEnabled">Ссылки не будут отображаться</span>
                <span *ngIf="!isLinksFilterEnabled">Ссылки будут отображаться</span>
            </div>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать мат
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings [widgetProps]="widgetProps"
                                           filterType="automatic"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Фильтровать по&nbsp;плохим&nbsp;словам
        </div>
        <div class="we__controls">
            <app-profanity-widget-settings [widgetProps]="widgetProps"
                                           filterType="custom"
                                           (widgetPropsChange)="widgetPropsChange.emit($event)"></app-profanity-widget-settings>
        </div>
    </div>
</div>
