import {
    ChangeDetectorRef, Component, HostBinding, NgZone, OnDestroy, OnInit, ViewChild
} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from "@angular/router";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {filter, map, startWith, take, takeUntil} from "rxjs/operators";
import {WidgetGroupService} from "./services/widgets/widget_groups/widget-group.service";
import {DefaultWidgetsCreationService} from "./services/widgets/default-widgets-creation.service";
import {BehaviorSubject, combineLatest, merge, Subject} from "rxjs";
import {trigger} from "@angular/animations";
import appIcons from "./app.icons";
import {routeAnimations} from "./app.animations";
import {BreakpointObserver} from "@angular/cdk/layout";
import {SidebarManagerService} from "../../../shared/src/lib/sidebar/sidebar-manager.service";
import {MatSidenav} from "@angular/material/sidenav";
import {PlatformInfoService} from "../../../shared/src/lib/platform-info.service";
import {PageService} from "./services/page.service";
import {NotificationService} from "./modules/notification/notification.service";
import {WidgetSSEService} from "./services/widgets/widget.sse.service";
import {SwUpdate} from "@angular/service-worker";
import {PushNotificationService} from "./modules/notification/push-notification.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    animations: [trigger("routeAnimations", routeAnimations)],
})
export class AppComponent implements OnInit, OnDestroy {

    public readonly useBackdropWorkaround = window.navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

    private readonly simpleLayoutRoutes = ["/login", "/mobile", "/payout/error", "/payout/confirm"];
    private readonly routesWithoutHeader = ["/identity", "/widgets/", "/payout/confirm", "/profile", "/login", "/login/complete"];
    private readonly routesWithTransactionsList = ["/finance"];
    private readonly destroy$: Subject<void> = new Subject();

    public readonly isSimpleView$ = new BehaviorSubject(!this.platformInfoService.isDesktop || this.simpleLayoutRoutes.includes(this.currentPath));
    public readonly showHeader$ = new BehaviorSubject(false);
    public readonly showTransactionsList$ = new BehaviorSubject(false);

    private readonly navigationEnd$ = this.router.events.pipe(
        filter<RouterEvent>(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
    );

    private readonly avatarBreakpoints = new Map([
        ["/finance", "(max-width: 1140px)"],
        ["/widgets", "(max-width: 730px)"],
    ]);

    public readonly shrinkAvatar$ = combineLatest([
        this.breakpointObserver.observe(Array.from(this.avatarBreakpoints.values())),
        this.navigationEnd$.pipe(startWith(() => {
            const breakpoint = this.avatarBreakpoints.get(this.currentPath);
            return breakpoint && this.breakpointObserver.isMatched(this.avatarBreakpoints.get(this.currentPath));
        }))
    ]).pipe(map(([result]) => {
        const breakpoint = this.avatarBreakpoints.get(this.currentPath);
        return breakpoint && result.breakpoints[breakpoint];
    }));

    @ViewChild("payoutSidebar")
    public payoutSidebar: MatSidenav;

    @HostBinding("style.--notification-shift")
    public notificationShift = "0";

    constructor(private readonly router: Router,
                private readonly route: ActivatedRoute,
                private readonly matIconRegistry: MatIconRegistry,
                private readonly domSanitizer: DomSanitizer,
                private readonly platformInfoService: PlatformInfoService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                public readonly sidebarManager: SidebarManagerService,
                private readonly breakpointObserver: BreakpointObserver,
                private readonly notificationService: NotificationService,
                private readonly ngZone: NgZone,
                private readonly updates: SwUpdate,
                // create default widgets
                private readonly defaultWidgetsCreationService: DefaultWidgetsCreationService,
                // preload widget groups
                private readonly widgetGroupService: WidgetGroupService,
                // watch for sse
                private readonly widgetSSEService: WidgetSSEService,
                // set page title
                private readonly pageService: PageService,
                // send firebase token to backend
                private readonly pushNotificationService: PushNotificationService) {
        appIcons.forEach(icon => this.matIconRegistry.addSvgIcon(
            icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
        ));
        updates.versionUpdates.subscribe(evt => {
            // tslint:disable:no-console
            switch (evt.type) {
                case "VERSION_DETECTED":
                    console.info(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case "VERSION_READY":
                    console.info(`Current app version: ${evt.currentVersion.hash}`);
                    console.info(`New app version ready for use: ${evt.latestVersion.hash}`);
                    break;
                case "VERSION_INSTALLATION_FAILED":
                    console.info(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
    }

    public ngOnInit() {
        this.sidebarManager.openChanges$.pipe(takeUntil(this.destroy$)).subscribe(open => {
            if (this.payoutSidebar) {
                if (open) {
                    this.payoutSidebar.open();
                } else {
                    this.payoutSidebar.close();
                }
            }
        });

        this.navigationEnd$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            const checkUrl = url => this.currentPath.includes(url);
            this.showHeader$.next(!this.routesWithoutHeader.find(checkUrl));
            this.showTransactionsList$.next(!!this.routesWithTransactionsList.find(checkUrl));
            this.isSimpleView$.next(!this.platformInfoService.isDesktop || !!this.simpleLayoutRoutes.find(checkUrl));
            this.scrollToHash();
        });
        this.scrollToHash();

        merge(this.notificationService.alertAdded$, this.notificationService.alertRemoved$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.updateOffset());
        this.updateOffset();
    }

    private updateOffset() {
        this.notificationShift = this.notificationService.alerts.size * 40 + "px";
    }

    private scrollToHash() {
        if (!document.location.hash) {
            return;
        }
        const target = document.querySelector(document.location.hash) as HTMLElement;
        if (!target) {
            return;
        }
        this.ngZone.onStable
            .pipe(take(1))
            .subscribe(() => target.scrollIntoView({block: "center", inline: "center"}));
        history.replaceState("", document.title, window.location.pathname + window.location.search);
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private get currentPath() {
        return window.location.pathname.toLowerCase();
    }
}
