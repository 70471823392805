import {Component} from "@angular/core";
import {SidebarManagerService} from "../sidebar-manager.service";

@Component({
    styleUrls: ["./sidebar-entry.component.scss"],
    template: `
        <div>
            <button mat-mini-fab aria-label="Close sidebar"
                    (click)="sidebarManager.close()">
                <mat-icon svgIcon="close"></mat-icon>
            </button>
            <ng-template [cdkPortalOutlet]="sidebarManager.innerPortal"></ng-template>
        </div>
    `,
})
export class SidebarEntryComponent {

    constructor(public sidebarManager: SidebarManagerService) {
    }
}
