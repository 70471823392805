import _ from "lodash";
import {EnvironmentService} from "../environment.service";
import {AccountType} from "../../../../streamer/src/app/services/accounts/accounts";

export interface UserImageMedia {
    source: string;
    attributes?: any;
}

interface UserStatus {
    isBlocked: false;
}

export interface UserModel {
    carrotUserIdHash?: string;
    cover?: UserImageMedia;
    displayName?: string;
    email?: string;
    externalPayAccountId?: string;
    name?: string;
    picture?: UserImageMedia;
    registrationDate?: string;
    refId: string;
    registeredAt?: number;

    favicon?: UserImageMedia;
    pasteboard?: UserImageMedia;

    youtubeUrl?: string;
    facebookUrl?: string;
    trovoUrl?: string;
    twitch?: { url?: string };
    vkUrl?: string;

    features?: UserFeature[];
    status?: UserStatus;
}

export enum UserFeatureId {
    applePay = "apple-pay",
    googlePay = "google-pay",
    mediaDonation = "media-donation",
    widgetsLogging = "widgets-logging",
    youtubeAccounts = "youtube-accounts",
}

interface UserFeature {
    id: UserFeatureId;
    isEnabled: boolean;
}

export interface TwitchReward {
    id: string;
    bgColor: string;
    description: string;
    title: string;
    value: number;
}

interface TwitchAccountParams {
    url: string;
    rewards: Array<TwitchReward>;
}

interface AccountParams {
    twitch?: TwitchAccountParams;
}

export class User {
    public accountParams: AccountParams = {};

    public constructor(private readonly environmentService: EnvironmentService,
                       private readonly model: UserModel) {
        if (model) {
            Object.values(AccountType).forEach(accountType => {
                this.accountParams[accountType] = model[accountType];
            });
        }
    }

    public get avatarFilename(): string {
        return _.get(this.model, "picture.attributes.originalName", "");
    }

    public get avatarUrl(): string {
        return _.get(this.model, "picture.source", null);
    }

    public get carrotUserIdHash(): string {
        return _.get(this.model, "carrotUserIdHash", "");
    }

    public get coverFilename(): string {
        return _.get(this.model, "cover.attributes.originalName", "");
    }

    public get coverUrl(): string {
        return _.get(this.model, "cover.source", "");
    }

    public get displayName(): string {
        return this.model.displayName;
    }

    public get externalPayAccountId(): string {
        return this.model.externalPayAccountId ?? "";
    }

    public get id(): string {
        return this.model.refId;
    }

    public get name(): string {
        return this.model.name;
    }

    public set name(value: string) {
        this.model.name = value;
    }

    public get pageUrl(): string {
        const frontUrl = this.environmentService.donationFrontUri;
        return `${frontUrl}/${this.name}`;
    }

    public get registrationDate(): Date {
        return new Date(this.model.registrationDate);
    }

    public get registeredAt(): number {
        return this.model.registeredAt;
    }

    public get thanksPageUrl(): string {
        return `${this.pageUrl}/thankyou`;
    }

    public get faviconUrl(): string {
        return this.model.favicon?.source || "/favicon.svg";
    }

    public get ogImageUrl(): string {
        return this.model.pasteboard?.source || "";
    }

    public get twitchUrl(): string {
        return this.model.twitch?.url ?? "";
    }

    public get vkUrl(): string {
        return this.model.vkUrl || "";
    }

    public get youtubeUrl(): string {
        return this.model.youtubeUrl || "";
    }

    public get facebookUrl(): string {
        return this.model.facebookUrl || "";
    }

    public get trovoUrl(): string {
        return this.model.trovoUrl || "";
    }

    public get status(): UserStatus {
        return this.model.status || { isBlocked: false };
    }

    public clone(): User {
        return new User(this.environmentService, _.cloneDeep(this.model));
    }

    public cloneModel(): UserModel {
        return _.cloneDeep(this.model);
    }

    public isAvatarEqual(that: User): boolean {
        return (
            _.get(this.model, "picture.source") ===
            _.get(that.model, "picture.source")
        );
    }

    public isCoverEqual(that: User): boolean {
        return (
            _.get(this.model, "cover.source") ===
            _.get(that.model, "cover.source")
        );
    }

    public isEqual(that: User): boolean {
        return _.isEqual(this.model, that.model);
    }

    public isFeatureEnabled(id: UserFeatureId): boolean {
        const feature = this.model.features.find(f => (f.id === id));
        if (!feature) {
            return false;
        }

        return feature.isEnabled;
    }

    public removeAvatar(): void {
        delete this.model.picture;
    }

    public removeCover(): void {
        delete this.model.cover;
    }

    public setAvatar(image: UserImageMedia): void {
        if (!image) {
            throw new Error("avatar must be provided");
        }

        this.model.picture = image;
    }

    public setAvatarImage(data: string): void {
        const attributes = _.get(this.model, "picture.attributes", {});
        this.model.picture = {
            source: data,
            attributes
        };
    }

    public setCover(image: UserImageMedia): void {
        if (!image) {
            throw new Error("cover must be provided");
        }

        this.model.cover = image;
    }

    public setCoverImage(data: string): void {
        const attributes = _.get(this.model, "cover.attributes", {});
        this.model.cover = {
            source: data,
            attributes
        };
    }
}
