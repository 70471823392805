import {ApplicationRef, enableProdMode} from "@angular/core";
import {enableDebugTools} from "@angular/platform-browser";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import {Integrations} from "@sentry/tracing";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";

Sentry.init({
    dsn: "https://c80e54fd4d68497cade1da8a66d24ecc@o469899.ingest.sentry.io/5499958",
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [
                "localhost:4200",
                "localhost:8080",
                /streamer\.(develop\.|staging\.)?donatty\.com/,
                /api\.(develop\.|staging\.)?donatty\.com/],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

if (environment.build === "production") {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {
        if (environment.build !== "production") {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];
            enableDebugTools(componentRef);
        }
    })
    .catch(err => console.error(err));
