import {Directive, HostListener} from "@angular/core";

@Directive({
    selector: "[libPastePlaneText]"
})
export class PastePlaneTextDirective {

    @HostListener("paste", ["$event"])
    public onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const text = event.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }

}
