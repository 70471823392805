import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TotalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-total-tab-header",
    templateUrl: "./widget-total-tab-header.component.html",
    styleUrls: ["./widget-total-tab-header.component.scss"]
})
export class WidgetTotalTabHeaderComponent {

    @Input()
    public widgetProps: TotalWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();
}
