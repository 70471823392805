<ng-container *ngFor="let event of events.filteredEvents; trackBy: trackEventsBy"
              [ngSwitch]="eventTemplates[event.type] || 'default'"
              class="{{event.type}}">
    <app-item-donate-card #item
                          [showGoal]="showTargets"
                          *ngSwitchCase="'donation'"
                          [item]="event"
                          [active]="isItemSelected(event)"
                          (selected)="selectItem($event)"
                          (retry)="retry.next($event)"></app-item-donate-card>
    <app-item-social-donate-card #item
                                 [showGoal]="showTargets"
                                 *ngSwitchCase="'social-donate'"
                                 [item]="event"
                                 [active]="isItemSelected(event)"
                                 (selected)="selectItem($event)"
                                 (retry)="retry.next($event)"></app-item-social-donate-card>
    <app-item-youtube-superchat-card #item
                                     *ngSwitchCase="'youtube-superchat'"
                                     [item]="event"
                                     [active]="isItemSelected(event)"
                                     (selected)="selectItem($event)"
                                     (retry)="retry.next($event)"></app-item-youtube-superchat-card>
    <app-item-withdrawal #item
                         *ngSwitchCase="'withdrawal'"
                         [item]="event"></app-item-withdrawal>
    <app-item-twitch-hype-train-card #item
                                     *ngSwitchCase="'twitch-hype-train'"
                                     [item]="event"
                                     [active]="isItemSelected(event)"
                                     (selected)="selectItem($event)"
                                     (retry)="retry.next($event)"></app-item-twitch-hype-train-card>
    <app-item-twitch-channel-points-card #item
                                         *ngSwitchCase="'channel-points'"
                                         [item]="event"
                                         [active]="isItemSelected(event)"
                                         (selected)="selectItem($event)"
                                         (retry)="retry.next($event)"></app-item-twitch-channel-points-card>
    <app-item-twitch-subscriber-card #item
                                     *ngSwitchCase="'twitch-subscriber'"
                                     [item]="event"
                                     [active]="isItemSelected(event)"
                                     (selected)="selectItem($event)"
                                     (retry)="retry.next($event)"></app-item-twitch-subscriber-card>
    <app-item-twitch-subscriber-gift-viewer-card #item
                                                 *ngSwitchCase="'twitch-subscriber-gift-viewer'"
                                                 [item]="event"
                                                 [active]="isItemSelected(event)"
                                                 (selected)="selectItem($event)"
                                                 (retry)="retry.next($event)"></app-item-twitch-subscriber-gift-viewer-card>
    <app-item-twitch-subscriber-gift-channel-card #item
                                                  *ngSwitchCase="'twitch-subscriber-gift-channel'"
                                                  [item]="event"
                                                  [active]="isItemSelected(event)"
                                                  (selected)="selectItem($event)"
                                                  (retry)="retry.next($event)"></app-item-twitch-subscriber-gift-channel-card>
    <app-item-twitch-subscriber-gift-upgrade-card #item
                                                  *ngSwitchCase="'twitch-subscriber-gift-upgrade'"
                                                  [item]="event"
                                                  [active]="isItemSelected(event)"
                                                  (selected)="selectItem($event)"
                                                  (retry)="retry.next($event)"></app-item-twitch-subscriber-gift-upgrade-card>
    <app-item-twitch-subscriber-upgrade-card #item
                                             *ngSwitchCase="'twitch-subscriber-upgrade'"
                                             [item]="event"
                                             [active]="isItemSelected(event)"
                                             (selected)="selectItem($event)"
                                             (retry)="retry.next($event)"></app-item-twitch-subscriber-upgrade-card>
    <app-item-twitch-host-card #item
                               *ngSwitchCase="'twitch-host'"
                               [item]="event"
                               [active]="isItemSelected(event)"
                               (selected)="selectItem($event)"
                               (retry)="retry.next($event)"></app-item-twitch-host-card>
    <app-item-trovo-subscriber-card #item
                                    *ngSwitchCase="'trovo-subscriber'"
                                    [item]="event"
                                    [active]="isItemSelected(event)"
                                    (selected)="selectItem($event)"
                                    (retry)="retry.next($event)"></app-item-trovo-subscriber-card>
    <app-item-trovo-subscriber-gift-viewer-card #item
                                                *ngSwitchCase="'trovo-subscriber-gift-viewer'"
                                                [item]="event"
                                                [active]="isItemSelected(event)"
                                                (selected)="selectItem($event)"
                                                (retry)="retry.next($event)"></app-item-trovo-subscriber-gift-viewer-card>
    <app-item-trovo-subscriber-gift-channel-card #item
                                                 *ngSwitchCase="'trovo-subscriber-gift-channel'"
                                                 [item]="event"
                                                 [active]="isItemSelected(event)"
                                                 (selected)="selectItem($event)"
                                                 (retry)="retry.next($event)"></app-item-trovo-subscriber-gift-channel-card>
    <app-item-trovo-spell-elixir-card #item
                                      *ngSwitchCase="'trovo-spell-elixir'"
                                      [item]="event"
                                      [active]="isItemSelected(event)"
                                      (selected)="selectItem($event)"
                                      (retry)="retry.next($event)"></app-item-trovo-spell-elixir-card>
    <app-item-trovo-spell-mana-card #item
                                    *ngSwitchCase="'trovo-spell-mana'"
                                    [item]="event"
                                    [active]="isItemSelected(event)"
                                    (selected)="selectItem($event)"
                                    (retry)="retry.next($event)"></app-item-trovo-spell-mana-card>
    <app-item-follower-card #item
                            *ngSwitchCase="'follower'"
                            [item]="event"
                            [active]="isItemSelected(event)"
                            (selected)="selectItem($event)"
                            (retry)="retry.next($event)"></app-item-follower-card>
    <app-item-raid-card #item
                        *ngSwitchCase="'raid'"
                        [item]="event"
                        [active]="isItemSelected(event)"
                        (selected)="selectItem($event)"
                        (retry)="retry.next($event)"></app-item-raid-card>
    <app-item-youtube-sponsor-card #item
                                   *ngSwitchCase="'youtube-sponsor'"
                                   [item]="event"
                                   [active]="isItemSelected(event)"
                                   (selected)="selectItem($event)"
                                   (retry)="retry.next($event)"></app-item-youtube-sponsor-card>
    <app-item-youtube-follower-card #item
                                    *ngSwitchCase="'youtube-follower'"
                                    [item]="event"
                                    [active]="isItemSelected(event)"
                                    (selected)="selectItem($event)"
                                    (retry)="retry.next($event)"></app-item-youtube-follower-card>
    <h3 *ngSwitchDefault>No card for event of type {{event.type}}</h3>
</ng-container>
