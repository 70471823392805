import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {merge, Observable, Subject} from "rxjs";
import {User} from "../../../../shared/src/lib/models/user";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {createDonatePageTheme, DonatePageTheme} from "../../../../shared/src/lib/models/theme";
import {UserService} from "./user/user.service";
import {filter, map, shareReplay, switchMap, tap} from "rxjs/operators";
import {EnvironmentService} from "../../../../shared/src/lib/environment.service";
import {ApiResponse} from "../../../../shared/src/lib/common";

@Injectable({
    providedIn: "root"
})
export class DonateThemeService {

    private readonly donatePageThemeSaved$ = new Subject<DonatePageTheme>();

    public readonly donatePageTheme$ = this.userService.currentUser$.pipe(
        filter<User>(Boolean),
        switchMap(user => merge(this.get(user.name), this.donatePageThemeSaved$).pipe(
            map(theme => ({...createDonatePageTheme(user), ...theme}))),
        ),
        shareReplay(1),
    );

    private readonly baseUrl = `${this.environmentService.backendApiUri}/users/store`;

    constructor(private readonly http: HttpClient,
                private readonly authService: AuthService,
                private readonly userService: UserService,
                private readonly environmentService: EnvironmentService) {
    }

    public save(theme: DonatePageTheme) {
        const url = `${this.baseUrl}/donate-theme`;
        return this.http.post(url, theme, this.authService.makeTokenAuthHeaders())
            .pipe(tap(() => this.donatePageThemeSaved$.next(theme)));
    }

    private get(username: string): Observable<DonatePageTheme> {
        const url = `${this.baseUrl}/${username}/donate-theme`;
        return this.http.get<ApiResponse<DonatePageTheme>>(url, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response));
    }

}
