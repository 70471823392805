import {Component} from "@angular/core";

enum Tab {
    Transactions = "donate",
    Balance = "balance",
}

export interface TabInfo {
    type: Tab;
    icon: string;
    text: string;
}

@Component({
    selector: "app-mobile-header",
    templateUrl: "./mobile-header.component.html",
    styleUrls: ["./mobile-header.component.scss"]
})
export class MobileHeaderComponent {

    public currentTab: Tab = Tab.Transactions;

    public readonly TABS: TabInfo[] = [
        {
            type: Tab.Transactions,
            icon: "bx-message",
            text: "Донаты"
        },
        {
            type: Tab.Balance,
            icon: "bx-card",
            text: "Баланс"
        }
    ];

    public onTabClick(type: Tab): void {
        this.currentTab = type;
    }

}
