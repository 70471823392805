import {ModuleWithProviders, NgModule} from "@angular/core";
import {fakeBackendInterceptorProvider} from "./FakeBackendInterceptor";

@NgModule({
    providers: [
        fakeBackendInterceptorProvider,
    ],
})
export class DevModule {
    private static isDevMode = false;

    public constructor() {
        if (!DevModule.isDevMode) {
            throw new Error("DevModule can only be loaded in develop environment");
        }
    }

    static forRoot(environment): ModuleWithProviders<DevModule> {
        DevModule.isDevMode = environment?.build === "develop";
        if (!DevModule.isDevMode) {
            return null;
        }
        return {ngModule: DevModule};
    }
}
