<div class="payment" *ngIf="method.isNew else continueButton">
    <iframe #iFrame
            id="paymentFormIframe"
            *ngIf="paymentUrl$ | async as paymentUrl"
            [src]="paymentUrl"
            (load)="paymentFormLoaded$.next($event.target)"></iframe>
</div>
<ng-template #continueButton>
    <button mat-flat-button color="primary" (click)="initWithdrawal$.next()">
        Продолжить
    </button>
</ng-template>
