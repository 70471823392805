<div class="we__tab-contents">
    <div class="we__row we__row--alignment" *ngIf="showAlign">
        <div class="we__label">
            Выравнивание
        </div>
        <div class="we__controls">
            <div *ngFor="let align of textAligns" [ngClass]="{'selected': item.font.align == align}"
                 (click)="item.font.align = align; itemChange.emit(item)"
                 class="we__item">
                <div class="we__icon">
                    <img [alt]="align" src="/assets/icons/format-align-{{align}}.svg">
                </div>
            </div>
        </div>
    </div>

    <div class="we__row--font">
        <div class="we__row" *ngIf="showFont">
            <div class="we__label">Шрифт</div>
            <div class="we__controls">
                <div class="we__dropdown">
                    <app-widget-select-google-font [(value)]="fontLink"></app-widget-select-google-font>
                </div>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <div class="we__cb-control">
                    <checkbox id="{{id}}-bold" [(value)]="item.font.bold" (valueChange)="itemChange.emit(item)">
                    </checkbox>
                </div>
                <div class="we__cb-label"
                     (click)="item.font.bold = !item.font.bold; itemChange.emit(item)">
                    Жирный текст
                </div>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <div class="we__cb-control">
                    <checkbox id="{{id}}-italic" [(value)]="item.font.italic" (valueChange)="itemChange.emit(item)">
                    </checkbox>
                </div>
                <div class="we__cb-label"
                     (click)="item.font.italic = !item.font.italic; itemChange.emit(item)">
                    Курсивный текст
                </div>
            </div>
        </div>
    </div>

    <div class="we__row" *ngIf="showFont">
        <div class="we__label">
            Размер шрифта
        </div>
        <div class="we__controls">
            <app-single-slider [min]="18" [max]="56" [(value)]="item.font.size"
                               (valueChange)="item.font.size = $event; itemChange.emit(item)" unit="px">
            </app-single-slider>
        </div>
    </div>

    <div class="we__gradient-row" *ngIf="showFont">
        <div class="we__row">
            <div class="we__label">Цвет шрифта</div>
            <div class="we__controls">
                <app-color-select [color]="item.font.color"
                                  (colorChange)="item.font.color = $event; itemChange.emit(item)"
                                  [showHints]="!gradient">
                </app-color-select>
            </div>
        </div>

        <div class="we__row we__row--second-color" *ngIf="gradient">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-color-select [color]="item.font.colorGradient"
                                  (colorChange)="item.font.colorGradient = $event; itemChange.emit(item)">
                </app-color-select>
            </div>
        </div>

    </div>

    <div class="we__row" *ngIf="showWidth">
        <div class="we__label">
            Ширина поля
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="item.geometry.width"
                               (valueChange)="item.geometry.width = $event; itemChange.emit(item)"
                               unit="%">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Смещение по X
        </div>
        <div class="we__controls">
            <app-single-slider [min]="-offsetBounds" [max]="offsetBounds" [(value)]="item.geometry.offsetX"
                               (valueChange)="item.geometry.offsetX = $event; itemChange.emit(item)" [zeroAdjust]="0"
                               unit="px">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row">
        <div class="we__label">
            Смещение по Y
        </div>
        <div class="we__controls">
            <app-single-slider [min]="-offsetBounds" [max]="offsetBounds" [(value)]="item.geometry.offsetY"
                               (valueChange)="item.geometry.offsetY = $event; itemChange.emit(item)" [zeroAdjust]="0"
                               unit="px">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row" *ngIf="showMaxWidth">
        <div class="we__label">
            Максимальная ширина
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="offsetBounds * 2" [(value)]="item.geometry.maxWidth"
                               (valueChange)="item.geometry.maxWidth = $event; itemChange.emit(item)" unit="px">
            </app-single-slider>
        </div>
    </div>

    <div class="we__row" *ngIf="showPositioning">
        <div class="we__label">
            Абсолютное позиционирование
        </div>
        <div class="we__controls">
            <mat-slide-toggle [disableRipple]="true"
                              [checked]="item.position === 'absolute'"
                              (change)="item.position = $event.checked ? 'absolute' : ''; itemChange.emit(item)">
            </mat-slide-toggle>
            <div class="we__controls">
                <span *ngIf="item.position === 'absolute'">Элемент не влияет на другие элементы</span>
            </div>
        </div>
    </div>

    <div class="we__hor-line"></div>

    <div class="we__header">
        Тень {{text}}
    </div>

    <div class="we__row" *ngIf="showShadow">
        <div class="we__label">
            Отображать тень
        </div>
        <div class="we__controls">
            <switcher [(value)]="item.shadow.enabled"
                      (valueChange)="item.shadow.enabled = $event; itemChange.emit(item)">
            </switcher>
        </div>
    </div>

    <ng-container *ngIf="showShadow && item.shadow.enabled">
        <div class="we__row">
            <div class="we__label">
                Цвет тени
            </div>
            <div class="we__controls">
                <app-color-select [color]="item.shadow.color"
                                  (colorChange)="item.shadow.color = $event; itemChange.emit(item)">
                </app-color-select>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Размытость тени
            </div>
            <div class="we__controls">
                <app-single-slider [min]="0" [max]="20" [(value)]="item.shadow.blur"
                                   (valueChange)="item.shadow.blur = $event; itemChange.emit(item)" unit="px">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по X
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="item.shadow.offsetX"
                                   (valueChange)="item.shadow.offsetX = $event; itemChange.emit(item)" [zeroAdjust]="0"
                                   unit="px">
                </app-single-slider>
            </div>
        </div>

        <div class="we__row">
            <div class="we__label">
                Смещение по Y
            </div>
            <div class="we__controls">
                <app-single-slider [min]="-20" [max]="20" [(value)]="item.shadow.offsetY"
                                   (valueChange)="item.shadow.offsetY = $event; itemChange.emit(item)" [zeroAdjust]="0"
                                   unit="px">
                </app-single-slider>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="item.stroke !== undefined">
        <div class="we__hor-line"></div>

        <div class="we__header">
            Обводка {{text}}
        </div>

        <div class="we__row">
            <div class="we__label">
                Отображать обводку
            </div>
            <div class="we__controls">
                <switcher [(value)]="item.stroke.enabled"
                          (valueChange)="item.stroke.enabled = $event; itemChange.emit(item)"></switcher>
            </div>
        </div>

        <ng-container *ngIf="item.stroke.enabled">
            <div class="we__row">
                <div class="we__label">
                    Цвет обводки
                </div>
                <div class="we__controls">
                    <app-color-select [color]="item.stroke.color"
                                      (colorChange)="item.stroke.color = $event; itemChange.emit(item)"></app-color-select>
                </div>
            </div>

            <div class="we__row">
                <div class="we__label">
                    Толщина обводки
                </div>
                <div class="we__controls">
                    <app-single-slider [min]="0" [max]="20" [(value)]="item.stroke.width"
                                       (valueChange)="item.stroke.width = $event; itemChange.emit(item)" unit="px">
                    </app-single-slider>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
