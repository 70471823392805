<section [ngClass]="{'animating': isAnimating}">
    <div *ngFor="let item of
        [item1, item0, item1, item1, item0, item1, item0, item0, item1, item0, item1, item0, item0, item1, item1, item1, item0, item0, item1, item0, item1, item0]">
        <ng-container *ngTemplateOutlet="item"></ng-container>
    </div>
</section>

<ng-template #item0>
    <div class="avatar"></div>
    <div class="message">
        <div class="nick"></div>
        <div class="text"></div>
    </div>
    <div class="total"></div>
</ng-template>

<ng-template #item1>
    <div class="avatar"></div>
    <div class="message">
        <div class="nick"></div>
        <div class="text"></div>
        <div class="text"></div>
    </div>
    <div class="total"></div>
</ng-template>
