import {Component, Inject} from "@angular/core";
import {WidgetType} from "../../../../../../shared/src/lib/models/widget";
import {SIDEBAR_DATA} from "../../../../../../shared/src/lib/sidebar/sidebar.service";
import {EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {WidgetService} from "../../../services/widgets/widget.service";
import {WidgetGroupService} from "../../../services/widgets/widget_groups/widget-group.service";
import {Router} from "@angular/router";
import {SidebarManagerService} from "../../../../../../shared/src/lib/sidebar/sidebar-manager.service";
import {WidgetGroup} from "../../../services/widgets/widget_groups/widget-group";

@Component({
    templateUrl: "./widgets-menu.component.html",
    styleUrls: ["./widgets-menu.component.scss"]
})
export class WidgetsMenuComponent {

    public readonly WidgetType = WidgetType;
    public constructor(private readonly dataLayerService: DataLayerService,
                       private readonly widgetService: WidgetService,
                       private readonly widgetGroupService: WidgetGroupService,
                       private readonly router: Router,
                       private readonly sidebarManager: SidebarManagerService,
                       @Inject(SIDEBAR_DATA) public sidenavWidgetGroup: WidgetGroup) {
    }

    public async createWidget(type: WidgetType): Promise<void> {
        this.sidebarManager.close();
        const widget = await this.widgetService.createWithDefaultProps(type, this.sidenavWidgetGroup);
        this.dataLayerService.emit({
            eventCategory: EventCategory.Widget,
            eventAction: EventAction.Create,
            eventLabel: type.toLowerCase(),
            eventValue: "success",
        });
        await this.router.navigateByUrl(`/widgets/${widget.getId()}/edit`);
    }

    public async createWidgetGroup() {
        this.sidebarManager.close();
        const groupParams = {
            name: "Новая группа",
            isExpanded: true,
        };
        this.widgetGroupService.createGroup(groupParams);
    }
}
