import {Injectable, OnDestroy} from "@angular/core";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {HttpClient} from "@angular/common/http";
import {StreamerEvent, streamerEventBuilders} from "./streamer-event";
import {
    donationEvents, STREAMER_EVENT_TYPES_ALL, StreamerEventResponse, StreamerEventsList, StreamerEventsResponse
} from "./streamer-events.api";
import {Observable, Subject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {StreamEventType} from "../../../../../shared/src/lib/common/FinancialOperation";
import {SSEService} from "../sse.service";

@Injectable({providedIn: "root"})
export class StreamerEventsService implements OnDestroy {
    private readonly baseUrl = `${this.environmentService.backendApiUri}/events`;
    private readonly eventTypesParams = STREAMER_EVENT_TYPES_ALL
        .map(eventType => `type=${eventType}`).join("&");
    private readonly destroy$: Subject<void> = new Subject<void>();

    public constructor(private readonly authService: AuthService,
                       private readonly httpClient: HttpClient,
                       private readonly environmentService: EnvironmentService,
                       private readonly sseService: SSEService) {
    }

    public readonly streamerEvents$: Observable<StreamerEvent> = this.sseService.message$.pipe(
        filter(m => m.type === StreamEventType.StreamEvent),
        map(m => m.event as StreamerEventResponse),
        map(rawEvent => streamerEventBuilders[rawEvent.type](rawEvent, true)),
        takeUntil(this.destroy$),
    );

    public readonly donationEvents$ = this.streamerEvents$.pipe(
        filter(streamerEvent => donationEvents.includes(streamerEvent.type)));

    public async getEvents(nextPageToken: string, maxResults: number): Promise<[StreamerEvent[], string]> {
        const maxResultsArg = maxResults ? `maxResults=${maxResults}` : "";
        const tokenArg = nextPageToken ? `&pageToken=${nextPageToken}` : "";
        const url = `${this.baseUrl}?${maxResultsArg}${tokenArg}&${this.eventTypesParams}`;
        return this.httpClient
            .get<StreamerEventsResponse>(url, this.authService.makeTokenAuthHeaders())
            .pipe(
                map(response => response.response),
                map<StreamerEventsList, [StreamerEvent[], string]>(streamerEventsList => [
                    streamerEventsList.items.map(e => streamerEventBuilders[e.type](e, false)),
                    streamerEventsList.nextPageToken,
                ]),
            ).toPromise();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }
}
