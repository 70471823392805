import {EventsWidget} from "../../../../shared/src/lib/models/widget-props";
import {AccountEventType} from "../services/accounts/accounts";

export const defaultEventsProps: EventsWidget["props"] = {
    data: {
        switchDurationMin: 1,
        switchDurationMax: null,
        sources: {
            accounts: {
                donatty: {
                    [AccountEventType.DonattyDonate]: {
                        isEnabled: true
                    }
                }
            }
        },
        filters: {
            emoji: false,
            links: true,
            profanity: {
                automatic: true,
                custom: true,
            },
        },
    },
    style: {
        layout: 0,
        backgroundRounding: 50,
        listSettings: {
            rowCount: 5,
            lineSpacing: 4,
            backgroundHeight: 12,
        },
        listAnimation: {
            appearance: "fadeOnRight",
            fading: "fadeInLeft",
            speed: 1,
            displayTime: 0,
            fade: 0.75,
        },
        colors: {
            backgroundColor: "#202124ff",
        },
        events: {
            font: {
                size: 16,
            },
            shadow: {
                enabled: false,
                blur: 1,
                color: "rgba(0, 0, 0, 0.8)",
                offsetX: 0,
                offsetY: 1
            },
            stroke: {
                enabled: false,
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
            },
            eventTypes: {
                [AccountEventType.DonattyDonate]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} задонатил {amount} {unit}! И сказал: {message}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        },
                        purpose: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    },
                },
                [AccountEventType.PaypalDonate]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} задонатил {amount} {unit}! И сказал: {message}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#64D2FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        },
                        purpose: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    },
                },
                [AccountEventType.TwitchBits]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} подарил {amount} {unit}{icon}! И сказал: {message}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        }
                    }
                },
                [AccountEventType.TwitchFollowers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} отслеживает канал",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TwitchSubscribers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} подписывается на {amount} {unit} ({unit-tier}{tier})",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        streak: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        total: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-total": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-streak": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TwitchGiftSubscribersViewer]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} подарил {receiver} подписку на {amount} {unit} ({unit-tier}{tier})",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        receiver: {
                            bold: true,
                            size: 20,
                            color: "#00fff6",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        }
                    }
                },
                [AccountEventType.TwitchGiftSubscribersChannel]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} дарит каналу {amount} {unit} ({unit-tier}{tier})",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TwitchGiftSubscribersUpgrade]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} улучшает подаренную подписку",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    },
                },
                [AccountEventType.TwitchSubscribersUpgrade]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} улучшает подписку",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    },
                },
                [AccountEventType.TwitchChannelPoints]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} получает награду: {reward} ({amount} {unit}) {message}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#ff6905",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#ff6905",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        },
                        reward: {
                            bold: true,
                            size: 20,
                            color: "#ff6905",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        }
                    }
                },
                [AccountEventType.TwitchHypeTrain]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "Запущен хайповоз ({unit} {level})",
                    font: {
                        bold: true,
                        size: 20,
                        color: "#E258FF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        level: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TwitchHost]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} хостит стрим",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TwitchRaids]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} проводит рейд в компании {amount} {unit}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#E258FF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoSubscribers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} оформляет подписку ({unit-tier}{tier})",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        level: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-level": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoGiftSubscribersViewer]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} дарит {receiver} подписку ({unit-tier} {tier}).",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        receiver: {
                            bold: true,
                            size: 20,
                            color: "#00fff6",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        level: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-level": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    },
                },
                [AccountEventType.TrovoGiftSubscribersChannel]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} дарит {amount} {unit} ({unit-tier}{tier}) случайным зрителям",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        level: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-level": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        tier: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "unit-tier": {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoFollowers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} отслеживает канал",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoRaids]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} проводит рейд в компании {amount} {unit}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoSpellMana]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} кастует {spell-name} за {unit} {spell-value} x {amount}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "spell-name": {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: false,
                            size: 20,
                            color: "#68DFC4",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "spell-value": {
                            bold: true,
                            size: 20,
                            color: "#68DFC4",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#68DFC4",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.TrovoSpellElixir]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} кастует {spell-name} за {unit} {spell-value} x {amount}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#30be7a",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "spell-name": {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: false,
                            size: 20,
                            color: "#FF375F",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        "spell-value": {
                            bold: true,
                            size: 20,
                            color: "#FF375F",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF375F",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.YoutubeMembers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} становится спонсором на {amount} {unit}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#eb4e45",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#eb4e45",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#eb4e45",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.YoutubeSubscribers]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} отслеживает канал",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#eb4e45",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                    }
                },
                [AccountEventType.YoutubeSuperchat]: {
                    displayIcon: true,
                    iconUri: [],
                    template: "{username} подарил {amount} {unit}! И написал в Супер Чат: {message}",
                    font: {
                        bold: false,
                        size: 20,
                        color: "#FFFFFF",
                        uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                        family: "Roboto",
                        italic: false,
                    },
                    tagFonts: {
                        username: {
                            bold: true,
                            size: 20,
                            color: "#eb4e45",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        amount: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        unit: {
                            bold: true,
                            size: 20,
                            color: "#FF9600",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: false,
                        },
                        message: {
                            bold: false,
                            size: 20,
                            color: "#FFFFFF99",
                            uri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                            family: "Roboto",
                            italic: true,
                        },
                    }
                },
            },
        }
    }
};
