import {Directive, HostListener, Input} from "@angular/core";
import {MatSelect} from "@angular/material/select";

type ElementId = string;

@Directive({
    selector: "[libFocus]"
})
export class FocusDirective {
    @Input()
    public focusItems: Array<HTMLElement | ElementId | MatSelect>;

    @HostListener("keydown", ["$event"])
    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Tab") {
            event.preventDefault();
            this.focusNextItem(event.shiftKey ? -1 : 1);
        }
    }

    private get currentIndex() {
        return this.focusItems.findIndex(el => {
            if (typeof el === "string") {
                return document.getElementById(el) === document.activeElement;
            } else {
                return el === document.activeElement;
            }
        });
    }

    private focusNextItem(step: number): void {
        const len = this.focusItems.length;
        let item = this.focusItems[(len + this.currentIndex + step) % len];
        if (typeof item === "string") {
            item = document.getElementById(item);
            if (!item) {
                return this.focusNextItem(step + Math.sign(step));
            }
        }
        item.focus();
    }
}
