<div class="we__tab-container">
    <div class="we__header">
        Звук
    </div>

    <div class="we__block">
        <app-widget-edit-style-upload-button icon="ic-attach-audio"
                                             accept=".mp3,.wav"
                                             help="Максимум 20 Мб. Допустимые типы файлов: {{allowedFormatsText}}."
                                             [default]="widgetProps.data.audioUri"
                                             (clear)="clearAudio()"
                                             (change)="setAudio($event)"
                                             maxSizeMb="20">
            Загрузить аудиофайл
        </app-widget-edit-style-upload-button>
    </div>

    <div class="we__row">
        <div class="we__label">
            Громкость звука
        </div>
        <div class="we__controls">
            <app-single-slider [min]="0" [max]="100" [(value)]="widgetProps.data.audioVolume"
                           (valueChange)="widgetProps.data.audioVolume = $event; widgetPropsChange.emit(widgetProps)"
                           unit="%">
            </app-single-slider>
        </div>
    </div>
</div>
