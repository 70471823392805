export enum EventType {
    SelectTab = "editor.tab.select",
    Drag = "editor.drag",
    WidgetLayoutChanged = "widget.layout-changed",
    WidgetLoaded = "widget.loaded",
    WidgetSettingsApplied = "widget.settings-applied",
    AlertCompleted = "widget.alert-completed",
}

export interface WidgetEvent<T = any> {
    type: EventType;
    message: T;
}

export type WidgetEventSelectElement = {
    tab: string,
};

export type WidgetEventDragElement = {
    tab: string,
    event: {
        movementX: number,
        movementY: number,
    },
};

export type WidgetEventRect = {
    left: number,
    top: number,
    right: number,
    bottom: number
};

export type WidgetEventAlertCompleted = {
    widgetId: string,
};
