import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, UrlTree} from "@angular/router";
import {NavigationService} from "../services/navigation.service";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {LoginService} from "../services/login/login.service";
import {CookiePolyfillService} from "../../../../shared/src/lib/cookie-polyfill.service";

@Injectable()
export class GuestGuard implements CanActivate {
    public constructor(private readonly navigationService: NavigationService,
                       private readonly loginService: LoginService,
                       private readonly authService: AuthService,
                       private readonly cookieService: CookiePolyfillService) {
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        if (route.queryParams["new-user"]) {
            console.log("user registration detected");
            const exp = new Date(new Date().getTime() + 5 * 60000);
            this.cookieService.setCookie("new-user", "1", exp);
        }

        if (route.queryParams.token) {
            await this.loginService.loginByToken(route.queryParams.token);
        }

        if (this.authService.authStatus$.value) {
            return this.navigationService.mainPageUrlTree;
        }
        return true;
    }
}
