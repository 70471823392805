import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, UrlTree} from "@angular/router";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {NavigationService} from "../services/navigation.service";
import {LoginService} from "../services/login/login.service";

@Injectable()
export class AuthGuard implements CanActivate {
    public constructor(private readonly authService: AuthService,
                       private readonly loginService: LoginService,
                       private readonly navigationService: NavigationService) {
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        const loginByUrl = route.queryParams["login-token"];
        if (loginByUrl) {
            try {
                this.authService.removeAccessToken();
                await this.loginService.loginByToken(route.queryParams["login-token"]);
                if (route.url[0]?.path === "mobile") {
                    return this.navigationService.mobilePageUrlTree;
                } else {
                    return this.navigationService.mainPageUrlTree;
                }
            } catch {
                this.authService.removeAccessToken();
            }
        }

        if (!this.authService.authStatus$.value) {
            return this.navigationService.loginUrlTree;
        }

        return true;
    }
}
