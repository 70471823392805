import {Component, EventEmitter, Input, Output} from "@angular/core";
import _ from "lodash";
import {GoalWidget, WidgetRoundingStyle} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-goal-tab-props",
    templateUrl: "./widget-goal-tab-props.component.html",
    styleUrls: ["./widget-goal-tab-props.component.scss"]
})
export class WidgetGoalTabPropsComponent {

    @Input()
    public widgetProps: GoalWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    private readonly progressHeightDefault = 15;
    // We want default at 100% rather than at 15%
    private readonly progressHeightMap = (value: number) => (100 / this.progressHeightDefault) * value;
    private readonly progressHeightRemap = (value: number) => (this.progressHeightDefault / 100) * value;

    public get mainBorderRadius(): number {
        return this.getRoundingValue(this.widgetProps.style.goal);
    }

    public set mainBorderRadius(value: number) {
        this.setRoundingValue(this.widgetProps.style.goal, value);
    }

    public get progressBorderRadius(): number {
        return this.getRoundingValue(this.widgetProps.style.progressBar);
    }

    public set progressBorderRadius(value: number) {
        this.setRoundingValue(this.widgetProps.style.progressBar, value);
    }

    public get progressHeight(): number {
        const value = _.get(this.widgetProps.style.progressBar, "heightRate", this.progressHeightDefault);
        return this.progressHeightMap(value);
    }

    public set progressHeight(value: number) {
        const remappedValue = this.progressHeightRemap(value);
        const oldValue = this.widgetProps.style.progressBar.heightRate;
        this.widgetProps.style.progressBar.heightRate = remappedValue;
        if (remappedValue !== oldValue) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get indicatorRounding(): number {
        return this.getRoundingValue(this.widgetProps.style.countdown);
    }

    public set indicatorRounding(value: number) {
        this.setRoundingValue(this.widgetProps.style.countdown, value);
    }

    private getRoundingValue(style: WidgetRoundingStyle): number {
        return _.get(style, "rounding", 100);
    }

    private setRoundingValue(style: WidgetRoundingStyle, value: number): void {
        const oldValue = _.get(style, "rounding", 100);

        _.set(style, "rounding", value);

        if (value !== oldValue) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }
}
