<mat-slide-toggle [disableRipple]="true"
                  [checked]="widgetProps.data.filters?.profanity[filterType]"
                  (change)="onChange($event.checked)">
</mat-slide-toggle>
<span>
    <ng-container [ngSwitch]="filterType">
        <ng-container *ngSwitchCase="'automatic'">
            Мат
            <ng-container *ngIf="!widgetProps.data.filters?.profanity[filterType]"> не</ng-container>
            будет
            <ng-container *ngTemplateOutlet="replacement"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'custom'">
            Слова из <a routerLink="/profile" fragment="profanity-settings">списка плохих слов</a>
            <ng-container *ngIf="!widgetProps.data.filters?.profanity[filterType]"> не</ng-container>
            будут
            <ng-container *ngTemplateOutlet="replacement"></ng-container>
        </ng-container>
    </ng-container>
    <ng-template #replacement>
        <ng-container *ngIf="(profanityService.profanity$ | async)?.replacement as replacement else hasNoReplacement">
            заменяться на {{replacement}}
        </ng-container>
        <ng-template #hasNoReplacement>
            удаляться
        </ng-template>
    </ng-template>
</span>
