export const environment = {
    build: "develop",

    donationAPI: "https://api.staging.donatty.com",
    donationWeb: "https://staging.donatty.com",
    streamerWeb: "https://streamer.staging.donatty.com",
    widgetsWeb: "https://widgets.staging.donatty.com",

    serviceWorkerEnabled: true,
    firebase: {
        apiKey: 'AIzaSyC2_YArL25kpjC5WB-RZUNRkuPXtp2Hdu4',
        authDomain: 'donatty-push-develop.firebaseapp.com',
        projectId: 'donatty-push-develop',
        storageBucket: 'donatty-push-develop.appspot.com',
        messagingSenderId: '953615400965',
        appId: '1:953615400965:web:5b414466baeb3c4b315c6c',
        measurementId: 'G-6GT8KQ600R'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error";  // Included with Angular CLI.
