import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModerationControlButtonComponent} from "./moderation-control-button/moderation-control-button.component";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatSliderModule} from "@angular/material/slider";
import {MatCardModule} from "@angular/material/card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ModerationNotificationsComponent} from "./moderation-alerts/moderation-notifications.component";
import {MatListModule} from "@angular/material/list";
import {SharedModule} from "../../../../../shared/src/lib/shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatSliderModule,
        MatCardModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatListModule,
    ],
    declarations: [
        ModerationControlButtonComponent,
        ModerationNotificationsComponent,
    ],
    exports: [
        ModerationControlButtonComponent,
    ],
})
export class ModerationModule {
    public constructor(private readonly matIconRegistry: MatIconRegistry,
                       private readonly domSanitizer: DomSanitizer) {
        [
            {name: "moderate-judge", url: "assets/icons/moderate-judge.svg"},
        ].forEach(icon => this.matIconRegistry.addSvgIcon(
            icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
        ));
    }

}
