<header>
    <figure class="logo-icon">
        <img alt="Logo" src="/assets/icons/logo-mark.svg">
    </figure>
    <figure class="logo-name">
        <img alt="Background" src="/assets/icons/br-blur.svg" class="background">
        <img alt="Donatty" src="/assets/icons/company-name.svg">
    </figure>
</header>
<section>
    <button mat-raised-button class="twitch"
            [disabled]="submitted"
            (click)="login(accountType.TWITCH)">
        <mat-icon svgIcon="logo-twitch"></mat-icon>
        Войти с Twitch
    </button>
    <button mat-raised-button class="youtube"
            [disabled]="submitted"
            (click)="login(accountType.GOOGLE)">
        <mat-icon svgIcon="logo-youtube"></mat-icon>
        Войти с YouTube
    </button>
    <button mat-raised-button class="trovo"
            [disabled]="submitted"
            (click)="login(accountType.TROVO)">
        <mat-icon svgIcon="logo-trovo"></mat-icon>
        Войти с Trovo
    </button>
    <button mat-raised-button class="vk"
            [disabled]="submitted"
            (click)="login(accountType.VK)">
        <mat-icon svgIcon="logo-vk"></mat-icon>
        Войти с Vkontakte
    </button>
    <button mat-raised-button class="facebook"
            [hidden]="true"
            [disabled]="submitted"
            (click)="login(accountType.FACEBOOK)">
        <mat-icon svgIcon="logo-facebook"></mat-icon>
        Войти с Facebook
    </button>
</section>
<footer class="small blur-text">
    Авторизуясь, вы подтверждаете, что вы прочитали и&nbsp;соглашаетесь
    с&nbsp;<a target="_blank"
       href="https://donatty.com/pages/agreement">Условиями&nbsp;сервиса</a>
    и&nbsp;<a target="_blank"
       href="https://donatty.com/pages/privacy">Политикой&nbsp;конфиденциальности</a>.
</footer>
