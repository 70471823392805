import {animate, style, transition} from "@angular/animations";

const fadeIn = [
    style({ opacity: 0 }),
    animate("800ms", style({ opacity: 1 }))
];

// State names are in the AppRoutingModule
export const routeAnimations = [
    transition(
        "* => finance, * => widgets, * => profile, * => identity, * => identity-thanks",
        fadeIn,
    ),
];
