<ng-container *ngIf="!loading else pageLoader">
    <div [sortablejs]="widgetGroups"
         [sortablejsOptions]="sortableWidgetGroupsOptions">
        <mat-accordion multi
                       *ngFor="let widgetGroup of widgetGroups; trackBy: trackGroupsBy"
                       [style.display]="widgetGroup.isHidden ? 'none' : 'block'"
                       [attr.data-groupid]="widgetGroup.id"
                       [ngClass]="'group-' + widgetGroup.type">
            <mat-expansion-panel [expanded]="widgetGroup.isExpanded"
                                 [ngClass]="{overflowable: widgetGroup.isExpanded}"
                                 (afterExpand)="saveExpanded(widgetGroup, true)"
                                 (expandedChange)="!$event && saveExpanded(widgetGroup, false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <button mat-icon-button
                                class="group-drag-handle"
                                [disableRipple]="true"
                                (click)="$event.stopImmediatePropagation()">
                            <mat-icon>drag_handle</mat-icon>
                        </button>
                        <h5 #groupName
                            class="group-name"
                            [attr.contenteditable]="widgetGroup.type !== 'ungrouped' && widgetGroup.type !== 'donation-pages'"
                            libPastePlaneText
                            [attr.data-groupid]="widgetGroup.id"
                            (click)="$event.stopImmediatePropagation()"
                            (keydown)="$event.stopImmediatePropagation();"
                            (input)="onGroupNameInput($event, widgetGroup)">{{widgetGroup.name}}</h5>
                        <span class="markers">
                            <span class="marker marker-donate"></span>
                            <span *ngFor="let widget of widgetGroup.widgets; trackBy: trackWidgetsBy"
                                  class="marker marker-{{widget.getType() | lowercase}}"></span>
                        </span>
                        <button mat-icon-button
                                class="group-link"
                                *ngIf="widgetGroup.isAlertGroup"
                                [disableRipple]="true"
                                (click)="onCopyWidgetGroupLinkClick($event, widgetGroup); $event.stopImmediatePropagation();">
                            <mat-icon svgIcon="bx-link"></mat-icon>
                        </button>
                        <button mat-icon-button
                                class="group-menu-button"
                                [matMenuTriggerRestoreFocus]="false"
                                [disableRipple]="true"
                                [matMenuTriggerFor]="widgetGroupMenu"
                                [matMenuTriggerData]="{widgetGroup: widgetGroup}"
                                (click)="$event.stopImmediatePropagation()">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <section *ngIf="widgetGroup.type !== 'donation-pages' else donationPage"
                         [sortablejs]="widgetGroup.widgets"
                         [sortablejsOptions]="sortableWidgetsOptions"
                         [attr.data-groupid]="widgetGroup.id"
                         [attr.data-grouptype]="widgetGroup.type">
                    <ng-container *ngFor="let widget of widgetGroup.widgets; trackBy: trackWidgetsBy">
                        <app-widgets-list-item [attr.data-widgetid]="widget.getId()"
                                               (action)="widgetAction($event)"
                                               (mousedown)="$event.preventDefault()"
                                               [widget]="widget">
                        </app-widgets-list-item>
                    </ng-container>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <ng-template #donationPage>
        <section>
            <mat-card id="donation-card" routerLink="/widgets/donate" matRipple>
                <div>
                    <mat-card-subtitle>
                        <span>СТРАНИЦА ДОНАТА</span>
                    </mat-card-subtitle>
                    <mat-card-title>{{donationWidgetName$ | async}}</mat-card-title>
                    <mat-card-content>
                        <div class="conditions">
                            https://donatty.com/{{(userService.currentUser$ | async).name | lowercase}}
                        </div>
                    </mat-card-content>
                </div>
            </mat-card>
        </section>
    </ng-template>
    <mat-menu class="widgets-menu" #widgetGroupMenu="matMenu">
        <ng-template matMenuContent let-widgetGroup="widgetGroup">
            <button mat-menu-item (click)="openMenu(widgetGroup)">Создать виджет в группе</button>
            <button mat-menu-item (click)="onCopyWidgetGroupClick(widgetGroup)">Создать копию группы</button>
            <button mat-menu-item (click)="onRenameWidgetGroupClick(widgetGroup)">Переименовать группу</button>
            <button mat-menu-item (click)="onDeleteWidgetGroupClick(widgetGroup)">Удалить группу</button>
            <mat-divider></mat-divider>
            <div [matTooltipDisabled]="widgetGroup.isAlertGroup"
                 matTooltip="Эта функция пока что работает только с группами оповещений"
                 matTooltipPosition="below">
                <button mat-menu-item
                        [disableRipple]="true"
                        [disabled]="!widgetGroup.isAlertGroup"
                        (click)="$event.stopPropagation();">Ссылка для встраивания
                    <button mat-flat-button color="primary" class="small"
                            [disabled]="!widgetGroup.isAlertGroup"
                            (click)="onCopyWidgetGroupLinkClick($event, widgetGroup)">Копировать
                    </button>
                </button>
            </div>
            <button mat-menu-item
                    *ngIf="widgetGroup.isAlertGroup"
                    [disableRipple]="true"
                    (click)="$event.stopPropagation();">Очередь оповещений
                <switcher [value]="widgetGroup.sequence === 'SERIAL'"
                          (valueChange)="onAlertQueueToggle($event, widgetGroup)"></switcher>
            </button>
        </ng-template>
    </mat-menu>
    <div class="btn-create">
        <button mat-flat-button color="primary" (click)="openMenu()">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Создать</span>
        </button>
    </div>
</ng-container>
<ng-template #pageLoader>
    <div class="loading-content">
        <ng-container *ngTemplateOutlet="loadingGroup1"></ng-container>
        <ng-container *ngTemplateOutlet="loadingGroup2"></ng-container>
        <ng-container *ngTemplateOutlet="loadingGroup3"></ng-container>
        <ng-container *ngTemplateOutlet="loadingGroup3"></ng-container>
    </div>
    <ng-template #loadingGroup1>
        <div class="group">
            <div class="header">
                <span class="drag-handle"></span>
                <span class="name"></span>
            </div>
            <div class="widgets">
                <ng-container *ngTemplateOutlet="loadingWidget1"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #loadingGroup2>
        <div class="group">
            <div class="header">
                <span class="drag-handle animated"></span>
                <span class="name animated"></span>
            </div>
            <div class="widgets">
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #loadingGroup3>
        <div class="group">
            <div class="header">
                <span class="drag-handle animated"></span>
                <span class="name animated"></span>
            </div>
            <div class="widgets">
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
                <ng-container *ngTemplateOutlet="loadingWidget2"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #loadingWidget1>
        <div class="widget">
            <div class="type animated"></div>
            <div class="name animated"></div>
            <div class="sources">
                <span class="source animated"></span>
            </div>
            <div class="conditions animated"></div>
        </div>
    </ng-template>
    <ng-template #loadingWidget2>
        <div class="widget">
            <div class="type animated"></div>
            <div class="name animated"></div>
            <div class="sources">
                <span class="source animated"></span>
                <span class="source animated"></span>
            </div>
            <div class="conditions animated"></div>
        </div>
    </ng-template>
</ng-template>
