<main>
    <section>
        <app-balance></app-balance>
        <app-identity-info *ngIf="financeService.promoteVerification$ | async"></app-identity-info>
        <app-onboarding-quest></app-onboarding-quest>
    </section>
    <h2 id="statistics-header">Статистика</h2>
    <section id="statistics">
        <app-statistics></app-statistics>
        <app-top-donators-list></app-top-donators-list>
    </section>
    <h2 id="instruments-header">Инструменты</h2>
    <section>
        <app-test-donation></app-test-donation>
        <section>
            <app-mobile-card></app-mobile-card>
            <app-obs-card></app-obs-card>
        </section>
    </section>
</main>
