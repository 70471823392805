import {NgModule} from "@angular/core";
import {SidebarManagerService} from "./sidebar-manager.service";
import {SidebarEntryComponent} from "./sidebar-entry/sidebar-entry.component";
import {PortalModule} from "@angular/cdk/portal";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        PortalModule,
        MatIconModule,
        MatButtonModule,
        CommonModule
    ],
    declarations: [
        SidebarEntryComponent
    ],
    providers: [SidebarManagerService]
})
export class SidebarModule {
}
