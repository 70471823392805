import {Component, EventEmitter, Input, Output} from "@angular/core";
import _ from "lodash";
import {TotalWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-total-tab-props",
    templateUrl: "./widget-total-tab-props.component.html",
    styleUrls: ["./widget-total-tab-props.component.scss"]
})
export class WidgetTotalTabPropsComponent {

    @Input()
    public widgetProps: TotalWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    public get sumRounding(): number {
        return _.get(this.widgetProps.style, "sumRounding", 50);
    }

    public set sumRounding(value: number) {
        const oldValue = _.get(this.widgetProps.style, "sumRounding", 50);

        _.set(this.widgetProps.style, "sumRounding", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

    public get mainRounding(): number {
        return _.get(this.widgetProps.style, "mainRounding", 50);
    }

    public set mainRounding(value: number) {
        const oldValue = _.get(this.widgetProps.style, "mainRounding", 50);

        _.set(this.widgetProps.style, "mainRounding", value);

        if (oldValue !== value) {
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }
}
