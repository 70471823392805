<div class="we__layout_blocks">
  <div *ngFor="let item of styleTexts[type]; let i=index" (click)="value = i; valueChange.emit(value)"
       [ngClass]="{'selected': value == i}"
       class="we__layout_block we__layout_block--{{type}}">
    <div class="we__radio">
      <app-radiobutton [items]="[{ name: '', value: i }]" [selectedValue]="value"></app-radiobutton>
    </div>
    <div class="we__image we__image--{{i}}">
      <img alt="" src="/assets/icons/widget-style/style-{{type}}-{{i}}.svg">
    </div>
    <div class="we__description_block">
      <div class="we__description we__description--{{i}}">
        {{item}}
      </div>
    </div>
  </div>
</div>
