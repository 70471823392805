import {GoalWidget} from "../../../../shared/src/lib/models/widget-props";

export const defaultGoalProps: GoalWidget["props"] =  {
    data: {
        refId: "",
        sources: {
            accounts: {
                donatty: {
                    donate: {
                        isEnabled: true
                    }
                }
            }
        },
        goal: 1000000,
        goalFrom: 200000,
        goalDate: "2020-12-12T12:00:00.000Z",
        headerTemplate: "На улучшение канала"
    },
    style: {
        layout: 0,
        widgetBackgroundColor: "#2F3033",
        widgetBackgroundColorEnabled: true,
        widgetIndicatorColor: "#FF9600",
        widgetIndicatorColorEnabled: true,
        widgetDateBackground: "#292929",
        widgetDateBackgroundEnabled: true,
        stroke: {
            color0: "#1484FF",
            color1: "#D731B6",
            width: 2,
            enabled: true
        },
        shadow: {
            enabled: true,
            color: "rgba(0, 0, 0, 0.8)",
            offsetX: 0,
            offsetY: 4,
            blur: 4
        },
        goal: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FFFFFF",
                align: "left",
                size: 20,
                bold: true,
                italic: false,
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: true,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            template: "",
            rounding: 100,
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            }
        },
        progressBar: {
            heightRate: 15,
            rounding: 100,
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FFFFFF",
                size: 20,
                align: "center",
                bold: true,
                italic: false,
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.14)",
                enabled: true,
                offsetX: 0,
                offsetY: 2,
                blur: 2
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            },
            template: "Собрано {amount} из {goal}₽ !"
        },
        countdown: {
            font: {
                cssUri: "https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i",
                family: "Roboto",
                color: "#FF9600",
                size: 16,
                bold: false,
                italic: false,
                align: "left",
                colorGradient: "",
            },
            shadow: {
                color: "rgba(0, 0, 0, 0.8)",
                enabled: false,
                offsetX: 0,
                offsetY: 1,
                blur: 1
            },
            stroke: {
                color: "rgba(255, 255, 255, 0.8)",
                width: 1,
                enabled: false
            },
            rounding: 100,
            template: "",
            position: "",
            geometry: {
                width: 100,
                offsetX: 0,
                offsetY: 0,
                maxWidth: 0,
            }
        }
    }
};
