<div class="et__wrapper">
    <div class="et__header">Экспорт донатов</div>
    <div class="et__descr">
        Список донатов за выбранный период времени в формате Excel.
    </div>
    <form>
        <label>Период</label>
        <mat-form-field appearance="outline">
            <mat-select panelClass="select-panel"
                        [formControl]="formControl"
                        [disableOptionCentering]="true">
                <mat-option
                    *ngFor="let paramItem of durationItems"
                    [value]="paramItem.value"
                    [attr.data-value]="paramItem.value">
                    {{paramItem.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div class="actions">
        <button mat-flat-button color="primary" (click)="onDownloadClick()">
            Скачать список донатов
        </button>
    </div>
</div>
