import {User} from "./user";

export enum Social {
    Twitch = "twitch",
    Youtube = "youtube",
    Trovo = "trovo",
    Vk = "vk",
    Facebook = "facebook",
    Discord = "discord",
    Zen = "zen",
    Instagram = "instagram",
    Twitter = "twitter",
    Telegram = "telegram",
    Tiktok = "tiktok",
}

export type Socials = {
    [key in Social]: string
};

export interface Thankyou {
    title: string;
    description: string;
}

interface PaymentMethods {
    qiwi: {
        isEnabled: boolean;
    };

    paypal: {
        isEnabled: boolean;
    };

    yandexMoney: {
        isEnabled: boolean;
    };
}


export type MediaDonationRestrictions = {
    viewsMin: number;
    likesRate: number;
    maxMediaDuration: number;
};

export type MediaContentFlags = {
    hasLicensedContent: boolean;
    isAdult: boolean;
    isEmbeddable: boolean;
};

export type MediaDonationSettings = {
    amountMin: number;
    isEnabled: boolean;
    restrictions: MediaDonationRestrictions;
};

export interface DonatePageTheme {
    defaultDonateAmount: number;
    description: string;
    donateButtonText: string;
    maxMessageLength: number;
    mediaDonation: MediaDonationSettings;
    minDonateAmount: number;
    maxDonateAmount: number;
    descriptionBlocks: {
        top: MarkdownBlockTheme;
        right: MarkdownBlockTheme;
    };
    name: string;
    paymentMethods: PaymentMethods;
    socials: Socials;
    thankyou: Thankyou;
    title: string;
}

export interface MarkdownBlockTheme {
    isEnabled: boolean;
    text: string;
}

export function createDonatePageTheme(user?: User): DonatePageTheme {
    return {
        defaultDonateAmount: 100,
        description: `Поддержать ${user ? user.displayName : ""}`,
        descriptionBlocks: {
            top: {
                isEnabled: false,
                text: ""
            },
            right: {
                isEnabled: false,
                text: ""
            },
        },
        donateButtonText: "Поддержать",
        maxMessageLength: 150,
        mediaDonation: {
            amountMin: 50,
            isEnabled: false,
            restrictions: {
                likesRate: .65,
                viewsMin: 5000,
                maxMediaDuration: 600,
            }
        },
        minDonateAmount: 1,
        maxDonateAmount: 60000,
        name: user?.name || "",
        paymentMethods: {
            paypal: {
                isEnabled: false
            },

            qiwi: {
                isEnabled: false
            },

            yandexMoney: {
                isEnabled: false
            }
        },
        socials: {
            twitch: user?.twitchUrl || "",
            youtube: user?.youtubeUrl || "",
            discord: "",
            twitter: "",
            vk: user?.vkUrl || "",
            trovo: user?.trovoUrl ?? "",
            facebook: "",
            instagram: "",
            telegram: "",
            tiktok: "",
            zen: "",
        },
        thankyou: {
            title: "Спасибо за пожертвование!",
            description: "Благодарим тебя за то, что помогаешь развивать сообщество и являешься его частью."
        },
        title: user?.name || ""
    };
}
