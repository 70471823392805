<div class="td__body" [ngClass]="{loading: isLoading$ | async}">
    <header>
        <div class="td__label">
            <span>Тест на стриме</span>
            <app-donation-help>
                Тестовые донаты не будут учитываться в виджетах
                Статистики и Сбора средств и не отображаются в списке донатов.
            </app-donation-help>
        </div>
    </header>
    <mat-tab-group class="test-donation-tabs" mat-align-tabs="start" dynamicHeight
                   [disablePagination]="false"
                   [disableRipple]="true" (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Donatty" *ngIf="forms.donatty.isEnabled$ | async">
            <drag-scroll #chips
                         [scrollbar-hidden]="true"
                         [snap-disabled]="false"
                         [snap-duration]="chipsSnapDuration"
                         [scroll-x-wheel-enabled]="true"
                         [drag-scroll-y-disabled]="true">
            <span drag-scroll-item *ngFor="let tabItem of forms.donatty.items">
                <mat-chip disableRipple
                          (click)="selectedItems$[AccountType.Donatty].next(tabItem.accountEventType)"
                          [selected]="selectedItems$[AccountType.Donatty].value === tabItem.accountEventType">
                    {{tabItem.name}}
                </mat-chip>
            </span>
            </drag-scroll>
            <!-- Donate -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, messageInput]"
                  [formGroup]="forms.donatty.eventTypes.donate.formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Donatty].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник донатера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Сумма</mat-label>
                            <input #amountInput required
                                   matInput type="number" formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">₽</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение</mat-label>
                            <textarea #messageInput matInput formControlName="message"
                                      maxlength="300" rows="3"></textarea>
                            <mat-hint align="end">{{messageInput.maxLength - messageInput.value?.length}}</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button [disabled]="isSendInProgress || forms.donatty.eventTypes.donate.formGroup.invalid"
                                mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-tab>
        <mat-tab label="Twitch" *ngIf="forms.twitch.isEnabled$ | async">
            <drag-scroll #chips
                         [scrollbar-hidden]="true"
                         [snap-duration]="chipsSnapDuration"
                         [scroll-x-wheel-enabled]="true"
                         [drag-scroll-y-disabled]="true">
            <span drag-scroll-item *ngFor="let tabItem of forms.twitch.items">
                <mat-chip disableRipple
                          (click)="selectedItems$[AccountType.Twitch].next(tabItem.accountEventType)"
                          [selected]="selectedItems$[AccountType.Twitch].value === tabItem.accountEventType">
                    {{tabItem.name}}
                </mat-chip>
            </span>
            </drag-scroll>
            <!-- Bits -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, messageInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchBits"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchBits].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник донатера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Сумма</mat-label>
                            <input #amountInput matInput required type="number" formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">Bits</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение</mat-label>
                            <textarea #messageInput matInput formControlName="message"
                                      maxlength="300" rows="3"></textarea>
                            <mat-hint align="end">{{messageInput.maxLength - messageInput.value?.length}}</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchBits].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Subscriber -->
            <form dense novalidate libFocus [focusItems]="[nameInput, tierInput, amountInput, totalInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchSubscribers"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchSubscribers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество месяцев подписки</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">мес.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Всего месяцев подписки</mat-label>
                            <input #totalInput matInput type="number" formControlName="total">
                            <span matSuffix *ngIf="totalInput.matches(':focus') || totalInput.value">мес.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchSubscribers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Subscriber (Upgrade) -->
            <form dense novalidate libFocus [focusItems]="[nameInput, tierInput, oldTierInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchSubscribersUpgrade"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchSubscribersUpgrade].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Новый уровень подписки (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Старый уровень подписки (Tier)</mat-label>
                            <input #oldTierInput matInput required type="number" min="1" max="3"
                                   formControlName="oldTier">
                            <span matSuffix *ngIf="oldTierInput.matches(':focus') || oldTierInput.value">ур.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchSubscribersUpgrade].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Gift subscriber (Viewer) -->
            <form dense novalidate libFocus [focusItems]="[giftedByInput, tierInput, amountInput, messageInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchGiftSubscribersViewer"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersViewer].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник дарителя</mat-label>
                            <input #giftedByInput matInput formControlName="giftedBy">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки в подарке (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество месяцев в подарке</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">мес.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение</mat-label>
                            <input #messageInput matInput formControlName="message"
                                   maxlength="300">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersViewer].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Gift subscriber (Channel) -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, tierInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchGiftSubscribersChannel"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersChannel].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник дарителя</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество подписок в подарке</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">шт.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки в подарке (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersChannel].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Gift subscriber (Upgrade) -->
            <form dense novalidate libFocus [focusItems]="[nameInput, giverInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchGiftSubscribersUpgrade"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersUpgrade].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник получателя</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник дарителя</mat-label>
                            <input #giverInput matInput formControlName="giver">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchGiftSubscribersUpgrade].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Follower -->
            <form dense novalidate libFocus [focusItems]="[nameInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchFollowers"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchFollowers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник фолловера</mat-label>
                            <input #nameInput matInput required formControlName="name">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchFollowers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Host -->
            <form dense novalidate libFocus [focusItems]="[nameInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchHost"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchHost].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник хоста</mat-label>
                            <input #nameInput matInput required formControlName="name">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchHost].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Raid -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchRaids"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchRaids].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник рейдера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество зрителей в рейде</mat-label>
                            <input #amountInput matInput required type="number"
                                   formControlName="amount">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchRaids].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Hype train -->
            <form dense novalidate libFocus [focusItems]="[amountInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchHypeTrain"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchHypeTrain].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень</mat-label>
                            <input #amountInput matInput required type="number"
                                   min="1" max="15"
                                   formControlName="amount">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchHypeTrain].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Channel points -->
            <form dense novalidate libFocus [focusItems]="[nameInput, rewardInput, messageInput]"
                  *ngIf="selectedItems$[AccountType.Twitch].value === AccountEventType.TwitchChannelPoints"
                  [formGroup]="forms.twitch.eventTypes[AccountEventType.TwitchChannelPoints].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Twitch].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Награда за Points</mat-label>
                            <mat-select #rewardInput
                                        formControlName="reward"
                                        panelClass="reward-select-panel"
                                        [disableOptionCentering]="true">
                                <mat-option
                                    *ngFor="let paramItem of widgetSettingsService.eventSourceParamItems$['twitch-channel-points'] | async"
                                    [value]="paramItem.value"
                                    [attr.data-value]="paramItem.value">
                                    {{paramItem.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение</mat-label>
                            <textarea #messageInput matInput formControlName="message"
                                      maxlength="300" rows="3"></textarea>
                            <mat-hint align="end">{{messageInput.maxLength - messageInput.value?.length}}</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.twitch.eventTypes[AccountEventType.TwitchChannelPoints].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-tab>
        <mat-tab label="YouTube" *ngIf="forms.youtube.isEnabled$ | async">
            <drag-scroll #chips
                         [scrollbar-hidden]="true"
                         [snap-disabled]="false"
                         [snap-duration]="chipsSnapDuration"
                         [scroll-x-wheel-enabled]="true"
                         [drag-scroll-y-disabled]="true">
            <span drag-scroll-item *ngFor="let tabItem of forms.youtube.items">
                <mat-chip disableRipple
                          (click)="selectedItems$[AccountType.Youtube].next(tabItem.accountEventType)"
                          [selected]="selectedItems$[AccountType.Youtube].value === tabItem.accountEventType">
                    {{tabItem.name}}
                </mat-chip>
            </span>
            </drag-scroll>
            <!-- Superchat -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, messageInput]"
                  *ngIf="selectedItems$[AccountType.Youtube].value === AccountEventType.YoutubeSuperchat"
                  [formGroup]="forms.youtube.eventTypes[AccountEventType.YoutubeSuperchat].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Youtube].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник донатера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Сумма</mat-label>
                            <input #amountInput matInput required type="number" formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">₽</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение суперчата</mat-label>
                            <textarea #messageInput matInput formControlName="message"
                                      maxlength="300" rows="3"></textarea>
                            <mat-hint align="end">{{messageInput.maxLength - messageInput.value?.length}}</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.youtube.eventTypes[AccountEventType.YoutubeSuperchat].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Subscriber -->
            <form dense novalidate libFocus [focusItems]="[nameInput]"
                  *ngIf="selectedItems$[AccountType.Youtube].value === AccountEventType.YoutubeSubscribers"
                  [formGroup]="forms.youtube.eventTypes[AccountEventType.YoutubeSubscribers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Youtube].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput required formControlName="name">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.youtube.eventTypes[AccountEventType.YoutubeSubscribers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- Member -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput]"
                  *ngIf="selectedItems$[AccountType.Youtube].value === AccountEventType.YoutubeMembers"
                  [formGroup]="forms.youtube.eventTypes[AccountEventType.YoutubeMembers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Youtube].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество месяцев подписки</mat-label>
                            <input #amountInput matInput required type="number" formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">мес.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.youtube.eventTypes[AccountEventType.YoutubeMembers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-tab>
        <mat-tab label="Trovo" *ngIf="forms.trovo.isEnabled$ | async">
            <drag-scroll #chips
                         [scrollbar-hidden]="true"
                         [snap-duration]="chipsSnapDuration"
                         [scroll-x-wheel-enabled]="true"
                         [drag-scroll-y-disabled]="true">
            <span drag-scroll-item *ngFor="let tabItem of forms.trovo.items">
                <mat-chip disableRipple
                          (click)="selectedItems$[AccountType.Trovo].next(tabItem.accountEventType)"
                          [selected]="selectedItems$[AccountType.Trovo].value === tabItem.accountEventType">
                    {{tabItem.name}}
                </mat-chip>
            </span>
            </drag-scroll>

            <!-- Spell (Mana) -->
            <form dense novalidate libFocus [focusItems]="[spellNameInput, casterNameInput, valueInput, amountInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoSpellMana"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoSpellMana].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Заклинание</mat-label>
                            <input #spellNameInput matInput formControlName="spellName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник кастера</mat-label>
                            <input #casterNameInput matInput formControlName="casterName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Стоимость</mat-label>
                            <input #valueInput matInput required type="number" min="1"
                                   formControlName="value">
                            <span matSuffix *ngIf="valueInput.matches(':focus') || valueInput.value">Mana</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">шт.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoSpellMana].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Spell (Elixir) -->
            <form dense novalidate libFocus [focusItems]="[spellNameInput, casterNameInput, valueInput, amountInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoSpellElixir"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoSpellElixir].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Заклинание</mat-label>
                            <input #spellNameInput matInput formControlName="spellName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник кастера</mat-label>
                            <input #casterNameInput matInput formControlName="casterName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Стоимость</mat-label>
                            <input #valueInput matInput required type="number" min="1"
                                   formControlName="value">
                            <span matSuffix *ngIf="valueInput.matches(':focus') || valueInput.value">Elixir</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">шт.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoSpellElixir].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Subscriber -->
            <form dense novalidate libFocus [focusItems]="[nameInput, tierInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoSubscribers"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoSubscribers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник подписчика</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                    formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoSubscribers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Gift subscriber (Viewer) -->
            <form dense novalidate libFocus [focusItems]="[giftedByInput, giftedToInput, tierInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoGiftSubscribersViewer"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoGiftSubscribersViewer].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник дарителя</mat-label>
                            <input #giftedByInput matInput formControlName="giftedBy">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник получателя</mat-label>
                            <input #giftedToInput matInput formControlName="giftedTo">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки в подарке (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoGiftSubscribersViewer].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Gift subscriber (Channel) -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, tierInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoGiftSubscribersChannel"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoGiftSubscribersChannel].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник дарителя</mat-label>
                            <input #nameInput matInput formControlName="giftedBy">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество подписок в подарке</mat-label>
                            <input #amountInput matInput required type="number" min="1"
                                   formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">шт.</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Уровень подписки в подарке (Tier)</mat-label>
                            <input #tierInput matInput required type="number" min="1" max="3"
                                   formControlName="tier">
                            <span matSuffix *ngIf="tierInput.matches(':focus') || tierInput.value">ур.</span>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoGiftSubscribersChannel].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Follower -->
            <form dense novalidate libFocus [focusItems]="[nameInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoFollowers"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoFollowers].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Ник фолловера</mat-label>
                            <input #nameInput matInput required formControlName="name">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoFollowers].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>

            <!-- Raid -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput]"
                  *ngIf="selectedItems$[AccountType.Trovo].value === AccountEventType.TrovoRaids"
                  [formGroup]="forms.trovo.eventTypes[AccountEventType.TrovoRaids].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Trovo].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник рейдера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Количество зрителей в рейде</mat-label>
                            <input #amountInput matInput required type="number"
                                   formControlName="amount">
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.trovo.eventTypes[AccountEventType.TrovoRaids].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-tab>
        <mat-tab label="PayPal" *ngIf="forms.paypal.isEnabled$ | async">
            <drag-scroll #chips
                         [scrollbar-hidden]="true"
                         [snap-disabled]="false"
                         [snap-duration]="chipsSnapDuration"
                         [scroll-x-wheel-enabled]="true"
                         [drag-scroll-y-disabled]="true">
            <span drag-scroll-item *ngFor="let tabItem of forms.paypal.items">
                <mat-chip disableRipple
                          (click)="selectedItems$[AccountType.Paypal].next(tabItem.accountEventType)"
                          [selected]="selectedItems$[AccountType.Paypal].value === tabItem.accountEventType">
                    {{tabItem.name}}
                </mat-chip>
            </span>
            </drag-scroll>
            <!-- Donate -->
            <form dense novalidate libFocus [focusItems]="[nameInput, amountInput, messageInput]"
                  *ngIf="selectedItems$[AccountType.Paypal].value === AccountEventType.PaypalDonate"
                  [formGroup]="forms.paypal.eventTypes[AccountEventType.PaypalDonate].formGroup"
                  (ngSubmit)="sendPayload(selectedTab$.value, selectedItems$[AccountType.Paypal].value)">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Ник донатера</mat-label>
                            <input #nameInput matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                            <mat-label>Сумма</mat-label>
                            <input #amountInput matInput required type="number" formControlName="amount">
                            <span matSuffix *ngIf="amountInput.matches(':focus') || amountInput.value">₽</span>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Сообщение</mat-label>
                            <textarea #messageInput matInput formControlName="message"
                                      maxlength="300" rows="3"></textarea>
                            <mat-hint align="end">{{messageInput.maxLength - messageInput.value?.length}}</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            [disabled]="isSendInProgress || forms.paypal.eventTypes[AccountEventType.PaypalDonate].formGroup.invalid"
                            mat-raised-button color="primary" type="submit">Отправить
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>

<!-- this is to use mat-chips as stand-alones https://github.com/angular/components/issues/9964#issuecomment-480287541 -->
<mat-chip-list></mat-chip-list>
