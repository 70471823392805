import {
    AlertWidget, EventsWidget, GoalWidget, MediaWidget, TopWidget, TotalWidget,
    WidgetEventSources, WidgetProps, WidgetPropsStyle
} from "./widget-props";
import {ApiWidgetInfo} from "./api";
import _ from "lodash";
import * as moment from "moment";
import {Moment} from "moment";
import {AccountType} from "../../../../streamer/src/app/services/accounts/accounts";

// ----------------------------------------------------------------
// enums

export enum WidgetType {
    Alert = "ALERT",
    Goal = "GOAL",
    Top = "TOP",
    Total = "TOTAL",
    Media = "MEDIA",
    Events = "EVENTS",
}

export enum WidgetCurrency {
    Bit = "TWITCH_BIT",
    Points = "TWITCH_POINT",
    Level = "LEVEL",
    Month = "MONTH",
    Ruble = "RUB",
    Subscription = "SUBSCRIPTION",
    Viewer = "VIEWER",
    TrovoMana = "TROVO_MANA",
    TrovoElixir = "TROVO_ELIXIR",
}

// ----------------------------------------------------------------
// interfaces

// TODO: generic ops are cancerous but are needed for the back compatibility
export interface IGenericWidgetInfo<Props extends WidgetProps = WidgetProps> {
    isActive(): boolean;
    setActive(isActive: boolean): this;
    getCreatedAt(): Moment;
    getProps(): Props;
    getGenericProps(): Props;
    getGenericData(): Props["data"];
    getGenericStyle(): Props["style"];
    getGenericDataProperty<T>(id: string): T;
    getId(): string;
    getGroupId(): string;
    getName(): string;
    getToken(): string;
    getType(): WidgetType;
    getUpdatedAt(): Date;
    getHeader(): string;
    getCondition(): string;
    getSources(): Array<AccountType>;
    getWeight(): number;
    hasName(): boolean;
    hasProps(): boolean;
    isAlertWidget(): this is IAlertWidgetInfo;
    isGoalWidget(): this is IGoalWidgetInfo;
    isTopWidget(): this is ITopWidgetInfo;
    isTotalWidget(): this is ITotalWidgetInfo;
    isEventsWidget(): this is IEventsWidgetInfo;
    setGenericData(data: Props["data"]): IGenericWidgetInfo<Props>;
    setGenericDataProperty(id: string, value: any): IGenericWidgetInfo<Props>;
    setGenericProps(props: Props): IGenericWidgetInfo<Props>;
    setGenericStyle(style: Props["style"]): IGenericWidgetInfo<Props>;
    setName(name: string): IGenericWidgetInfo<Props>;
    setGroupId(groupId: string): IGenericWidgetInfo<Props>;
    setCondition(condition: string): IGenericWidgetInfo<Props>;
    setSources(sources: Array<AccountType>): IGenericWidgetInfo<Props>;
    setWeight(weight: number);
    serialize(): ApiWidgetInfo;
}

export interface IAlertWidgetInfo extends IGenericWidgetInfo<AlertWidget["props"]> {
    getEventSources(): WidgetEventSources;
}

export interface IGoalWidgetInfo extends IGenericWidgetInfo<GoalWidget["props"]> {
}

export interface ITopWidgetInfo extends IGenericWidgetInfo<TopWidget["props"]> {
}

export interface ITotalWidgetInfo extends IGenericWidgetInfo<TotalWidget["props"]> {
}

type IEventsWidgetInfo = IAlertWidgetInfo;

// ----------------------------------------------------------------
// procedures

export function jsonType2WidgetPathName(jsonType: WidgetType) {
    if (!WIDGET_TYPE_2_PATH_NAME.has(jsonType)) {
        throw new Error(`unknown widget type in JSON: '${jsonType}'`);
    }

    return WIDGET_TYPE_2_PATH_NAME.get(jsonType);
}

const WIDGET_TYPE_2_PATH_NAME = new Map<WidgetType, string>([
    [WidgetType.Top, "stats"],
    [WidgetType.Total, "total"],
    [WidgetType.Goal, "goal"],
    [WidgetType.Alert, "donations"],
    [WidgetType.Media, "media"],
    [WidgetType.Events, "events"],
]);

// ----------------------------------------------------------------
// private implementation details

export abstract class ConcreteWidgetInfo<Props extends WidgetProps = WidgetProps> implements IGenericWidgetInfo<Props> {
    protected header: string;
    protected condition: string;
    protected sources: Array<AccountType>;

    public constructor(private readonly model: ApiWidgetInfo) {
    }

    public getCreatedAt(): Moment {
        return moment(this.model.createdAt);
    }

    public getGenericData(): Props["data"] {
        return _.cloneDeep(this.model.props.data);
    }

    public getGenericDataProperty<U>(id: string): U {
        return this.model.props.data[id];
    }

    public getGenericProps(): Props {
        const props = this.model.props;
        if (!props) {
            console.error("getGenericProps, model=", this.model);
            throw new Error("widget model must have properties");
        }

        return _.cloneDeep(props);
    }

    public getGenericStyle(): Props["style"] {
        return this.getGenericProps().style;
    }

    public getId(): string {
        return this.model.refId;
    }

    public getGroupId(): string {
        return this.model.groupId;
    }

    public getName(): string {
        return this.model.name;
    }

    public getProps(): Props {
        return _.cloneDeep(this.model.props) as Props;
    }

    public getToken(): string {
        return this.model.token;
    }

    public getType(): WidgetType {
        return this.model.type;
    }

    public getUpdatedAt(): Date {
        if (!this.model.updatedAt) {
            return null;
        }

        return new Date(this.model.updatedAt);
    }

    public getHeader() {
        return this.header;
    }

    public getCondition() {
        return this.condition;
    }

    public getSources() {
        return this.sources;
    }

    public getWeight() {
        return this.model.weight;
    }

    public hasName(): boolean {
        return !_.isEmpty(this.model.name);
    }

    public hasProps(): boolean {
        return !_.isNil(this.model.props);
    }

    public isActive(): boolean {
        return this.model.active;
    }

    public setActive(isActive: boolean) {
        this.model.active = isActive;
        return this;
    }

    public isAlertWidget(): this is IAlertWidgetInfo {
        return (this.getType() === WidgetType.Alert);
    }

    public isGoalWidget(): this is IGoalWidgetInfo {
        return (this.getType() === WidgetType.Goal);
    }

    public isTopWidget(): this is ITopWidgetInfo {
        return (this.getType() === WidgetType.Top);
    }

    public isTotalWidget(): this is ITotalWidgetInfo {
        return (this.getType() === WidgetType.Total);
    }

    public isEventsWidget(): this is IEventsWidgetInfo {
        return (this.getType() === WidgetType.Events);
    }

    public setGenericData(data: Props["data"]): IGenericWidgetInfo<Props> {
        this.model.props.data = data;
        return this;
    }

    public setGenericDataProperty(id: string, value: any): IGenericWidgetInfo<Props> {
        this.model.props.data[id] = value;
        return this;
    }

    public setGenericProps(props: WidgetProps): IGenericWidgetInfo<Props> {
        this.model.props = props;
        return this;
    }

    public setGenericStyle(style: WidgetPropsStyle): IGenericWidgetInfo<Props> {
        this.model.props.style = style;
        return this;
    }

    public setName(name: string): IGenericWidgetInfo<Props> {
        this.model.name = name;
        return this;
    }

    public setGroupId(groupId: string) {
        this.model.groupId = groupId;
        return this;
    }

    public setCondition(condition: string) {
        this.condition = condition;
        return this;
    }

    public setSources(sources: Array<AccountType>) {
        this.sources = sources;
        return this;
    }

    public setWeight(weight: number) {
        this.model.weight = weight;
        return this;
    }

    public serialize(): ApiWidgetInfo {
        return _.cloneDeep(this.model);
    }
}

export class AlertWidgetInfo extends ConcreteWidgetInfo<AlertWidget["props"]> implements IAlertWidgetInfo {
    protected header = "Оповещение";

    public getEventSources(): WidgetEventSources {
        const props = this.getProps();
        if (!props || !props.data) {
            return {accounts: {}};
        }

        return props.data.sources;
    }
}

export class GoalWidgetInfo extends ConcreteWidgetInfo<GoalWidget["props"]> {
    protected header = "Сбор средств";
}

export class TopWidgetInfo extends ConcreteWidgetInfo<TopWidget["props"]> {
    protected header = "Топ";
}

export class TotalWidgetInfo extends ConcreteWidgetInfo<TotalWidget["props"]> {
    protected header = "Сумма";
}

export class MediaWidgetInfo extends ConcreteWidgetInfo<MediaWidget["props"]> {
    protected header = "Медиа";
}

export class EventsWidgetInfo extends ConcreteWidgetInfo<EventsWidget["props"]> {
    protected header = "Список событий";

    public getEventSources(): WidgetEventSources {
        const props = this.getProps();
        if (!props || !props.data) {
            return {accounts: {}};
        }

        return props.data.sources;
    }
}
