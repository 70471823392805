import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ProfanityService} from "../../profanity.servoce";
import {
    AlertWidget, ProfanityFilterSettings, TopWidget
} from "../../../../../../../shared/src/lib/models/widget-props";
import {get, set} from "lodash";

@Component({
    selector: "app-profanity-widget-settings",
    templateUrl: "./profanity-widget-settings.component.html",
    styleUrls: ["./profanity-widget-settings.component.scss"],
})
export class ProfanityWidgetSettingsComponent<WidgetPropsWithProfanitySettings extends AlertWidget["props"] | TopWidget["props"]> {
    @Input()
    public widgetProps: WidgetPropsWithProfanitySettings;

    @Input()
    public filterType: keyof ProfanityFilterSettings;

    @Output()
    public readonly widgetPropsChange = new EventEmitter<WidgetPropsWithProfanitySettings>();

    public constructor(public readonly profanityService: ProfanityService) {
    }

    public onChange(checked: boolean) {
        const currentValue = get(this.widgetProps.data, `filters.profanity.${this.filterType}`);
        if (checked === currentValue) {
            return;
        }
        set(this.widgetProps.data, `filters.profanity.${this.filterType}`, checked);
        this.widgetPropsChange.emit(this.widgetProps);
    }

}
