<div class="alert {{alert.class}}" *ngFor="let alert of notificationService.alerts">
    <mat-icon class="icon" [svgIcon]="alert.icon"></mat-icon>
    <span>{{alert.message}}</span>
    <button *ngIf="alert.action"
            (click)="alert.action.handler(alert)"
            mat-button>{{alert.action.text}}</button>
    <div class="close"
         *ngIf="alert.isClosable"
         (click)="onCloseClick(alert)">
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>
