import {Injectable} from "@angular/core";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {debounceTime, filter, map, shareReplay, switchMap} from "rxjs/operators";
import {merge, Observable, Subject} from "rxjs";
import {GetLoginTokenResponse} from "./login.api";
import {UserService} from "../user/user.service";

export enum LoginTokenType {
    Mobile = "mobile",
    Obs = "obs"
}

@Injectable({
    providedIn: "root"
})
export class LoginTokenService {

    public constructor(private readonly authService: AuthService,
                       private readonly userService: UserService,
                       private readonly environmentService: EnvironmentService,
                       private readonly http: HttpClient) {
    }

    private readonly permissions$ = this.userService.permissions$.pipe(filter(Boolean), shareReplay(1));
    private readonly mapUrl = map(token =>
        `${this.environmentService.streamerFrontUri}/mobile?login-token=${token}`);

    public readonly resetMobileLoginUrl$: Subject<void> = new Subject();
    public readonly mobileLoginUrl$ = merge(
        this.permissions$.pipe(switchMap(() =>
            this.getLoginToken(LoginTokenType.Mobile).pipe(this.mapUrl))),
        this.resetMobileLoginUrl$.pipe(debounceTime(500), switchMap(() =>
            this.resetLoginToken(LoginTokenType.Mobile).pipe(this.mapUrl))),
    ).pipe(shareReplay(1));

    public readonly resetOBSLoginUrl$: Subject<void> = new Subject();
    public readonly obsLoginUrl$ = merge(
        this.permissions$.pipe(switchMap(() =>
            this.getLoginToken(LoginTokenType.Obs).pipe(this.mapUrl))),
        this.resetOBSLoginUrl$.pipe(debounceTime(500), switchMap(() =>
            this.resetLoginToken(LoginTokenType.Obs).pipe(this.mapUrl))),
    ).pipe(shareReplay(1));

    private readonly baseUrl = `${this.environmentService.backendApiUri}/users/tokens`;

    private getLoginToken(type: LoginTokenType) {
        const uri = `${this.baseUrl}/${type}`;
        return this.http.get<GetLoginTokenResponse>(uri, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response.token));
    }

    private resetLoginToken(type: LoginTokenType): Observable<string> {
        const uri = `${this.baseUrl}/${type}`;
        return this.http.post<GetLoginTokenResponse>(uri, null, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response.token));
    }

}
