import {distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {combineLatestWith, Observable, Subject} from "rxjs";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {UserService} from "../../services/user/user.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {PlatformInfoService} from "../../../../../shared/src/lib/platform-info.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import firebase from "firebase/compat";

@Injectable({
    providedIn: "root"
})
export class PushNotificationService {

    private readonly baseUrl = `${environment.donationAPI}/users`;

    private readonly destroy$ = new Subject<void>();

    public constructor(private readonly authService: AuthService,
                       private readonly userService: UserService,
                       private readonly afMessaging: AngularFireMessaging,
                       private readonly platformInfoService: PlatformInfoService,
                       private readonly http: HttpClient) {
        this.updatePushToken$.subscribe();
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public readonly pushMessage$: Observable<firebase.messaging.MessagePayload> = this.afMessaging.messages.pipe(
        tap(q => console.log("pushMessage$", q)),
        takeUntil(this.destroy$),
    );

    private readonly tokenChanges$: Observable<string> = this.afMessaging.tokenChanges.pipe(
        startWith(localStorage.getItem("push-token")),
        distinctUntilChanged(),
    );

    private readonly updatePushToken$ = this.authService.authStatus$.pipe(
        filter(Boolean),
        combineLatestWith(this.tokenChanges$.pipe(filter(Boolean))),
        map(([, token]) => token),
        combineLatestWith(this.userService.currentUser$.pipe(filter(Boolean))),
        switchMap(([token, user]) => {
            const url = `${this.baseUrl}/${user.id}/push-notifications/devices`;
            const params = {
                name: this.platformInfoService.deviceName,
                media: "FIREBASE",
                data: token,
            };
            console.info("Push token set", token);
            return this.http.put(url, params, this.authService.makeTokenAuthHeaders()).pipe(
                tap(() => localStorage.setItem("push-token", token)),
            );
        }),
        takeUntil(this.destroy$),
    );

}
