<div class="tst__underlay">
    <div class="tst__body">
        <div class="tst__icon tst__icon--success">
            <img alt="Done" src="/assets/icons/ic-toast-done.svg"/>
        </div>
        <div class="tst__icon tst__icon--error">
            <img alt="Error" src="/assets/icons/ic-toast-error.svg"/>
        </div>
        <div class="tst__icon tst__icon--warning">
            <img alt="Warning" src="/assets/icons/ic-toast-warning.svg"/>
        </div>
        <div class="tst__progressbar" *ngIf="options.progressBar">
            <lib-progress-spinner [timeout]="options.timeOut" (timeoutComplete)="close()"></lib-progress-spinner>
        </div>
        <span class="tst__message">{{message}}</span>
        <button mat-button
                color="primary"
                class="cancel-button"
                *ngIf="options.action"
                (click)="action()">{{options.action}}
        </button>
    </div>
</div>
