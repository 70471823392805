<app-notification-bar></app-notification-bar>
<mat-sidenav-container [ngClass]="{'backdrop-workaround': useBackdropWorkaround}">
    <mat-sidenav position="end" #payoutSidebar>
        <ng-template [cdkPortalOutlet]="sidebarManager.outerPortal"></ng-template>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-container *ngIf="(isSimpleView$ | async) === false">
            <div class="main-wrapper" [ngClass]="{'transactions-list-opened': (showTransactionsList$ | async)}">
                <div id="left-menu">
                    <app-transaction-list></app-transaction-list>
                </div>
                <main id="main-content">
                    <header *ngIf="showHeader$ | async">
                        <nav role="navigation" aria-label="Main">
                            <ul>
                                <li routerLink="/finance" routerLinkActive="active">
                                    <h1>Финансы</h1>
                                </li>
                                <li routerLink="/widgets" routerLinkActive="active">
                                    <h1>Виджеты</h1>
                                </li>
                            </ul>
                        </nav>
                        <app-avatar [short]="shrinkAvatar$ | async"></app-avatar>
                    </header>
                    <ng-container [ngTemplateOutlet]="outletTemplate"></ng-container>
                </main>
            </div>
        </ng-container>
        <ng-container *ngIf="(isSimpleView$ | async) === true">
            <div class="main-wrapper">
                <ng-container [ngTemplateOutlet]="outletTemplate"></ng-container>
            </div>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #outletTemplate>
    <div [@routeAnimations]="outlet?.activatedRouteData?.animation">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</ng-template>
