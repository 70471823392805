import {Injectable} from "@angular/core";
import {ComponentPortal} from "@angular/cdk/portal";
import {BehaviorSubject} from "rxjs";


@Injectable()
export class SidebarManagerService {
    public outerPortal: ComponentPortal<any>;
    public innerPortal: ComponentPortal<any>;
    public openChanges$ = new BehaviorSubject<boolean>(false);

    public open(outerPortal: ComponentPortal<any>, innerPortal: ComponentPortal<any>): void {
        this.outerPortal = outerPortal;
        this.innerPortal = innerPortal;
        this.openChanges$.next(true);
    }

    public close(): void {
        this.openChanges$.next(false);
    }
}
