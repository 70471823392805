import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
    EventsListAppearanceAnimation, EventsListFadingAnimation, EventsWidget
} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {ProfanityService} from "../../../../../../modules/profanity/profanity.servoce";

type AnimationItem<T> = {
    value: T,
    viewValue: string
};

type AnimationList<T> = Array<AnimationItem<T>>;

@Component({
    selector: "app-widget-events-tab-props",
    templateUrl: "./widget-events-tab-props.component.html",
    styleUrls: ["./widget-events-tab-props.component.scss"]
})
export class WidgetEventsTabPropsComponent {

    @Input()
    public widgetProps: EventsWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    public readonly appearanceOptions: AnimationList<EventsListAppearanceAnimation> = [
        {value: "fadeOnTop", viewValue: "Сверху"},
        {value: "fadeOnBottom", viewValue: "Снизу"},
        {value: "fadeOnLeft", viewValue: "Слева"},
        {value: "fadeOnRight", viewValue: "Справа"},
    ];
    public readonly fadingOptions: AnimationList<EventsListFadingAnimation> = [
        {value: "fadeInTop", viewValue: "Вверх"},
        {value: "fadeInBottom", viewValue: "Вниз"},
        {value: "fadeInLeft", viewValue: "Влево"},
        {value: "fadeInRight", viewValue: "Вправо"},
    ];

    public formatLabel(unit: string) {
        return value => value + unit;
    }

    public constructor(public readonly profanityService: ProfanityService) {
        this.profanityService.fetchProfanity();
    }
}
