<mat-form-field *ngIf="method.isNew" appearance="outline" [hideRequiredMarker]="true">
    <mat-label>Кошелёк ЮMoney</mat-label>
    <input #numberInput matInput [formControl]="formControl" required>
    <mat-icon svgIcon="logo-you-money" matSuffix [inline]="true"></mat-icon>
    <mat-error *ngIf="formControl.hasError('required')">Заполните поле</mat-error>
</mat-form-field>
<button mat-button mat-flat-button color="primary"
        [disabled]="method.isNew && formControl.invalid"
        (click)="initWithdrawal$.next(formControl.value)">
    Продолжить
</button>
