import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {LoginAccountType, LoginService} from "../../services/login/login.service";
import {Meta, Title} from "@angular/platform-browser";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {ActivatedRoute} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {filter, finalize, map, switchMap, takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {REDIRECT_KEY} from "../../guards/redirect.guard";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    public submitted = false;
    public readonly accountType = LoginAccountType;
    private readonly destroy$: Subject<void> = new Subject();

    constructor(private readonly authService: AuthService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private readonly dataLayerService: DataLayerService,
                private readonly loginService: LoginService,
                private readonly activatedRoute: ActivatedRoute,
                private readonly toastr: ToastrService,
                private readonly title: Title,
                private readonly meta: Meta) {
    }

    private readonly externalLoginError$: Observable<string> = this.activatedRoute.queryParams.pipe(
        filter(queryParams => !!queryParams.error),
        map(queryParams => queryParams.error),
        takeUntil(this.destroy$),
    );

    private readonly redirectUri$ = this.activatedRoute.queryParams.pipe(
        map(queryParams => queryParams[REDIRECT_KEY]),
        takeUntil(this.destroy$),
    );

    public ngOnInit() {
        this.setMeta();
        this.registerLoginPageView();
        this.externalLoginError$.subscribe(errorMessage => this.toastr.error(errorMessage));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public login(socialNetwork: LoginAccountType): void {
        console.log("logging in by", socialNetwork);

        if (this.submitted) {
            console.log("login already in progress, cancelled");
            return;
        }

        this.submitted = true;

        console.log("removing access token before login");
        this.authService.removeAccessToken();

        this.redirectUri$.pipe(
            switchMap(redirectUri => this.loginService.login(socialNetwork, redirectUri).pipe(
                finalize(() => {
                    this.submitted = false;
                    this.changeDetectorRef.detectChanges();
                }),
            )),
        ).subscribe(url => document.location.href = url);
    }

    private setMeta() {
        const ogTitle = "Вход в личный кабинет | Donatty";
        const description = "Страница входа в личный кабинет стримера в сервисе Donatty";
        this.title.setTitle(ogTitle);
        this.meta.updateTag({name: "og:title", content: ogTitle});
        this.meta.updateTag({name: "description", content: description});
        this.meta.updateTag({name: "og:description", content: description});
        this.meta.updateTag({
            name: "og:image",
            content: "https://storage.yandexcloud.net/donatty/og_image_donatty_home.png"
        });
    }

    private registerLoginPageView() {
        this.dataLayerService.emitRaw({event: "login-page-view"});
    }
}
