import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ProfanityService} from "../../profanity.servoce";
import {combineLatest, Subject} from "rxjs";
import {distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";

@Component({
    selector: "app-profanity-settings",
    templateUrl: "./profanity-settings.component.html",
    styleUrls: ["./profanity-settings.component.scss"],
})
export class ProfanitySettingsComponent implements OnInit, OnDestroy {
    public readonly formGroup = new FormGroup({
        blacklist: new FormControl<string>("", {nonNullable: true}),
        replacement: new FormControl<string>("", {nonNullable: true}),
    });

    public readonly submitDisabled$ = combineLatest(
        [this.formGroup.valueChanges, this.profanityService.profanity$]
    ).pipe(map(([, savedData]) =>
        !this.formGroup.valid || this.formGroup.pending || !this.hasChanges(savedData)
    ));

    private readonly destroy$: Subject<void> = new Subject();

    public constructor(public readonly profanityService: ProfanityService) {
    }

    public ngOnInit(): void {
        this.profanityService.profanity$
            .pipe(filter(Boolean), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(profanity => {
                this.formGroup.reset(profanity);
            });
        this.profanityService.fetchProfanity();
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private hasChanges(profanity) {
        return profanity && !!Object.keys(profanity).find(key => profanity[key] !== this.formGroup.value[key]);
    }

}
