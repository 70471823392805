import {Inject, Injectable, InjectionToken} from "@angular/core";

export const ENVIRONMENT = new InjectionToken<object>("app.environment.token");

@Injectable({providedIn: "root"})
export class EnvironmentService {

    public constructor(@Inject(ENVIRONMENT) private readonly environment) {
    }

    public get donationFrontUri(): string {
        return this.environment.donationWeb;
    }

    public get streamerFrontUri(): string {
        return this.environment.streamerWeb;
    }

    public get backendApiUri(): string {
        return this.environment.donationAPI;
    }

    public get widgetsUri(): string {
        return this.environment.widgetsWeb;
    }

    public get isDevBuild(): boolean {
        return this.environment.build === "develop";
    }

    public get isProdBuild(): boolean {
        return this.environment.build === "production";
    }

}
