import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {AlertWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-donations-tab-header",
    templateUrl: "./widget-donations-tab-header.component.html",
    styleUrls: ["./widget-donations-tab-header.component.scss"]
})
export class WidgetDonationsTabHeaderComponent implements OnInit {
    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    public caretPosition: number;

    public async ngOnInit() {
        this.caretPosition = this.widgetProps.style.header.template.length;
    }

    @ViewChild("headerTemplate")
    public headerTemplate: ElementRef<HTMLInputElement>;

    public onTagClicked(tag: string) {
        const tagTemplate = `{${tag}}`;
        this.widgetProps.style.header.template = [
            this.widgetProps.style.header.template.slice(0, this.caretPosition),
            tagTemplate,
            this.widgetProps.style.header.template.slice(this.caretPosition)
        ].join("");
        this.caretPosition += tagTemplate.length;
        this.widgetPropsChange.emit(this.widgetProps);
        requestAnimationFrame(() => {
            this.headerTemplate.nativeElement.setSelectionRange(this.caretPosition, this.caretPosition);
            this.headerTemplate.nativeElement.focus();
        });
    }
}
