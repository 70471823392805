<app-mobile-header #header></app-mobile-header>

<div class="mbf__page" [ngClass]="{'mbf__page--visible': header.currentTab === 'donate'}">
    <app-transaction-list (listScroll)="onScrolled($event[0], $event[1])"></app-transaction-list>
</div>

<div class="mbf__page mbf__page--balance" [ngClass]="{'mbf__page--visible': header.currentTab === 'balance'}">
    <app-balance></app-balance>
    <app-user-name></app-user-name>
    <app-identity-info *ngIf="financeService.promoteVerification$ | async"></app-identity-info>
    <app-statistics></app-statistics>
</div>
