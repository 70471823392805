import {AbstractControl, ValidationErrors} from "@angular/forms";

export class PhoneNumberService {

    public static formValidator = (control: AbstractControl): ValidationErrors | null => {
        const isValid = PhoneNumberService.isValid(control.value);
        return isValid ? null : {phoneNumberInvalid: {value: control.value}};
    }

    public static isValid(rawNumber: string) {
        const phone = this.sanitize(rawNumber);
        if (phone.length === 10) {
            return phone[0] !== "7";
        }

        return (phone.length > 10);
    }

    public static getFullNumber(rawNumber: string): string {
        const phone = this.sanitize(rawNumber);
        if (!phone) {
            return "";
        }

        // short form (ABC)DEF-GH-IJ => +7(ABC)DEF-GH-IJ
        if ((phone.length === 10) && (phone[0] !== "7")) {
            return `+7${phone}`;
        }

        if ((phone.length > 10) && (phone[0] === "8")) {
            return `+7${phone.substring(1)}`;
        }

        return `+${phone}`;
    }

    public static sanitize(rawNumber: string): string {
        let result = "";
        let index = 0;
        for (const ch of rawNumber) {
            const isSpaceLike = ((ch === " ") || (ch === "(") || (ch === ")") || (ch === "-"));
            if (isSpaceLike) {
                ++index;
                continue;
            }

            const isPlus = (ch === "+");
            if (isPlus) {
                if (index > 0) {
                    return "";
                }

                ++index;
                continue;
            }

            const isNumber = ((ch >= "0") && (ch <= "9"));
            if (!isNumber) {
                return "";
            }

            result += ch;
            ++index;
        }


        return result;
    }
}
