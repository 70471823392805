import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {ModerationAction, moderationActionText, ModerationEvent, ModerationSettings} from "../moderation.service";
import {animate, AUTO_STYLE, style, transition, trigger} from "@angular/animations";

@Component({
    templateUrl: "./moderation-notifications.component.html",
    styleUrls: ["./moderation-notifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger("moderationItemAnimation", [
        transition(":enter", [
            style({opacity: 0, height: "0", marginTop: "-1rem", overflow: "hidden"}),
            animate("300ms ease-in-out", style({opacity: 1, height: AUTO_STYLE, marginTop: AUTO_STYLE})),
        ]),
        transition(":leave", [
            style({overflow: "hidden"}),
            animate("100ms ease-in-out", style({opacity: 0, height: "0", marginTop: "-1rem"})),
        ])
    ])]
})
export class ModerationNotificationsComponent {
    @Input()
    public items: Array<ModerationEvent> = [];

    @Input()
    public settings: ModerationSettings;

    @Output()
    public moderate = new EventEmitter<ModerationEvent>();

    @Output()
    public ignore = new EventEmitter<ModerationEvent>();

    public readonly ModerationAction = ModerationAction;
    public readonly moderationActionText = moderationActionText;
}
