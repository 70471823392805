import {Component, Output, EventEmitter, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
    selector: "switcher",
    templateUrl: "./switcher.component.html",
    styleUrls: ["./switcher.component.scss"]
})
export class SwitcherComponent {
    @Input()
    public value = false;

    @Input()
    public disabled = false;

    @Input()
    public offText = "";

    @Input()
    public onText = "";

    @Output()
    public valueChange = new EventEmitter();

    public get hasText(): boolean {
        return (
            (this.onText.length > 0) ||
            (this.offText.length > 0)
        );
    }

    public get label(): string {
        return this.value ? this.onText : this.offText;
    }

    public onClick(): void {
        if (this.disabled) {
            return;
        }

        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
