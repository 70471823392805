import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TopWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-top-tab-sum",
    templateUrl: "./widget-top-tab-sum.component.html",
    styleUrls: ["./widget-top-tab-sum.component.scss"]
})
export class WidgetTopTabSumComponent {

    @Input()
    public widgetProps: TopWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();
}
