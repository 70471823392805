import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";

export interface QuestGroupState {
    completedIds: Array<string>;
    isClosed: boolean;
}

interface QuestGroupResponse {
    response: QuestGroupState;
}

@Injectable({
    providedIn: "root"
})
export class QuestService {

    private baseUri = this.environmentService.backendApiUri + "/users/store/";

    public constructor(private readonly http: HttpClient,
                       private readonly authService: AuthService,
                       private readonly environmentService: EnvironmentService) {
    }

    public getInfo(group: string): Observable<QuestGroupState> {
        const url = this.baseUri + group;
        return this.http.get<QuestGroupResponse>(url, this.authService.makeTokenAuthHeaders())
            .pipe(map(response => response.response));
    }

    public saveInfo(group: string, data: QuestGroupState) {
        const url = this.baseUri + group;
        return this.http.post(url, data, this.authService.makeTokenAuthHeaders());
    }

}
