import {VoiceInfo} from "./speech-api";

export interface Voice {
    id: number;
    provider: string;
    providerIcon: string;
    name: string;
    localName: string;
    locale: string;
    isMale: boolean;
    mood: string;
}

export const buildVoice = (voiceInfo: VoiceInfo): Voice => {
    return {
        id: +voiceInfo.id,
        provider: voiceInfo.provider,
        providerIcon: voiceInfo.provider,
        name: voiceInfo.name,
        localName: voiceInfo.translation[voiceInfo.locale],
        locale: voiceInfo.locale,
        isMale: voiceInfo.isMale,
        mood: voiceInfo.mood,
    };
};
