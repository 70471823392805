import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Output, ViewChild} from "@angular/core";
import {
    CreateWithdrawalRequest, OrderStateEvent, PayoutMethod, WithdrawalState
} from "../../../../../../../../shared/src/lib/common/FinancialOperation";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {FormControl, Validators} from "@angular/forms";
import {map, shareReplay, switchMap} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {FinanceService} from "../../../../../services/finance/finance.service";

@Component({
    selector: "app-payout-yandex",
    templateUrl: "./payout-yandex.component.html",
    styleUrls: ["../methods.scss"],
})
export class PayoutYandexComponent implements OnInit, AfterViewInit {
    public readonly initWithdrawal$ = new Subject<string>();

    @Input() public readonly amount: number;
    @Input() public readonly method: PayoutMethod;
    @Output() public readonly order$: Observable<OrderStateEvent<WithdrawalState>> = this.initWithdrawal$.pipe(
        map(account => {
            const params: CreateWithdrawalRequest["args"]["withdrawal"] = {
                amount: this.amount,
                method: {id: this.method.id}
            };
            if (account) {
                params.method.args = {account};
            }
            return params;
        }),
        switchMap(params => this.financeService.createWithdrawal(params)),
        shareReplay(1));

    public readonly formControl = new FormControl<string>("", Validators.compose([
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(20),
    ]));

    public constructor(private readonly financeService: FinanceService,
                       @Inject(LOCAL_STORAGE)
                       private readonly storageService: StorageService) {
        this.formControl.valueChanges.subscribe(inputVal => {
            inputVal = inputVal.trim();
            let filteredValue = "";
            for (const ch of inputVal.substring(0, 20)) {
                if (isNaN(+ch)) {
                    continue;
                }
                filteredValue += ch;
            }
            this.formControl.patchValue(filteredValue, {emitEvent: false});
            this.storageService.set(ACCOUNT_ID_KEY, filteredValue);
        });
    }

    @ViewChild("numberInput")
    private numberInput: ElementRef<HTMLInputElement>;

    public ngOnInit() {
        const storedAccount = this.storageService.get(ACCOUNT_ID_KEY);
        if (storedAccount) {
            this.formControl.setValue(storedAccount);
        }
    }

    public ngAfterViewInit() {
        requestAnimationFrame(() => this.numberInput?.nativeElement.focus());
    }

}

const ACCOUNT_ID_KEY = "payout-yandex-account-id";
