import {Injectable} from "@angular/core";
import {CookiePolyfillService} from "../../cookie-polyfill.service";
import * as moment from "moment";

@Injectable({
    providedIn: "root"
})
export class CookiesBackedLocalStorageService {
    public constructor(private readonly cookieService: CookiePolyfillService) {
        this.isLocalStorageSupported = ((localStorage !== null) && (localStorage !== undefined));
    }

    public getItem(key: string): string | null {
        if (this.isLocalStorageSupported) {
            return localStorage.getItem(key);
        }

        return this.cookieService.getCookie(fmtCookieId(key));
    }

    public removeItem(key: string): void {
        if (this.isLocalStorageSupported) {
            localStorage.removeItem(key);
            return;
        }

        this.cookieService.deleteCookie(fmtCookieId(key));
    }

    public setItem(key: string, value: string): void {
        if (this.isLocalStorageSupported) {
            localStorage.setItem(key, value);
            return;
        }

        const expiry = moment().add(42, "years").toDate();
        this.cookieService.setCookie(fmtCookieId(key), value, expiry);
    }

    private readonly isLocalStorageSupported: boolean;
}

function fmtCookieId(key: string): string {
    return `dntcbls-${key}`;
}
