import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnInit {
    @Input() icon = "";
    @Input("icon-right") iconRight = "";
    @Input() disabled = false;
    @Input("button-class") buttonClass = "";
    @Input("id") buttonId = "";

    public constructor() {
    }

    public ngOnInit() {
    }

    public click(event: Event): void {
        if (!this.disabled) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
    }

    public get iconTuningClass(): string {
        if (this.isLeftIconPresent) {
            return `icon-tune-${this.icon}`;
        }

        if (this.isRightIconPresent) {
            return `icon-tune-${this.iconRight}`;
        }

        return "";
    }

    public get isLeftIconPresent(): boolean {
        const isPresent = (this.icon && (this.icon.length > 0));
        return isPresent;
    }

    public get isRightIconPresent(): boolean {
        const isPresent = (this.iconRight && (this.iconRight.length > 0));
        return isPresent;
    }

    public get textClass(): string {
        if (this.isLeftIconPresent) {
            return "text text-right";
        }

        if (this.isRightIconPresent) {
            return "text text-left";
        }

        return "text text-center";
    }
}
