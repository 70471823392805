export interface Avatar {
    label: string;
    color: string;
}

export class AvatarService {
    public static buildEmptyAvatar(name: string): Avatar {
        const label = this.buildLabel(name);
        const hash = AvatarService.hashCode(label);
        const color = `linear-gradient(
            135deg,
            hsl(${hash % 360}, 100%, 50%),
            hsl(${(hash + 296) % 360}, 100%, 50%)
        )`;
        return {label, color};
    }

    public static buildLabel(name: string): string {
        name = name?.replace(/[`'"]+/g, "").trim();
        if (!name?.length) {
            return "";
        }

        const ucFilter = x => x?.length > 0 && x[0] === x[0].toUpperCase();
        let parts = name.split(" ");

        const checkUc = () => {
            const partsUc = parts.filter(ucFilter);
            if (partsUc.length) {
                parts = partsUc;
            }
        };
        checkUc();
        if (!parts.length) {
            parts = name.split(" ").map(s => s.trim());
            checkUc();
        }

        if (!parts.length) {
            parts = name.split("").map(s => s.trim());
            checkUc();
        }

        if (!parts.length) {
            parts = [name[0].toUpperCase()];
        }

        if (parts.length === 1) {
            return parts[0].substring(0, 1);
        }
        return `${parts[0][0]}${parts[1][0]}`;
    }

    public static hashCode(str) {
        let hash = 0;
        if (str) {
            for (let i = 0; i < str.length; i++) {
                // tslint:disable-next-line:no-bitwise
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
        }
        return hash;
    }

    private static buildEmptyAvatarTest() {
        const variants = [
            "qwe",
            "Qwe",
            "qWe",
            "qwe asd",
            "Qwe Asd",
            "qwe Asd",
            "qWe aSd",
            "qwe aSD",
            "qwe Asd Zxc",
        ];

        for (const variant of variants) {
            const avatar = AvatarService.buildEmptyAvatar(variant[0]);
            console.log(variant, "=>", avatar.label);
        }
    }
}
