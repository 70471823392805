import {
    Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {WidgetDimensions} from "../widget-edit.component";
import {fromEvent, merge, Observable, Subject} from "rxjs";
import {filter, map, shareReplay, startWith, takeUntil} from "rxjs/operators";
import {MatRipple} from "@angular/material/core";

@Component({
    selector: "app-widget-size-control",
    templateUrl: "./widget-size-control.component.html",
    styleUrls: ["./widget-size-control.component.scss"],
    providers: [MatRipple]
})
export class WidgetSizeControlComponent implements OnInit, OnDestroy {
    public readonly min = 1;
    public readonly max = 4096;

    @Input()
    public defaults: WidgetDimensions = {
        width: 800,
        height: 600,
        autoDetect: true,
    };

    public readonly formGroup = new FormGroup({
        width: new FormControl<number>(this.defaults.width, {
            validators: [Validators.required, Validators.min(this.min), Validators.max(this.max)],
            updateOn: "change",
            nonNullable: true,
        }),
        height: new FormControl<number>(this.defaults.height, {
            validators: [Validators.required, Validators.min(this.min), Validators.max(this.max)],
            updateOn: "change",
            nonNullable: true,
        }),
        autoDetect: new FormControl<boolean>(true, {nonNullable: true}),
    });

    @Input()
    public set value(newValue: WidgetDimensions) {
        if (!newValue) {
            return;
        }
        if (this.formGroup.pristine) {
            this.defaults = newValue;
            this.formGroup.reset(this.defaults);
        }
    }

    @Input()
    @HostBinding("class.active")
    public active = false;
    private activeAuto = false;

    @Output()
    public readonly valueChange: EventEmitter<WidgetDimensions> = new EventEmitter<WidgetDimensions>();

    @Output()
    public readonly previewChange: Observable<WidgetDimensions> = this.formGroup.valueChanges.pipe(
        map(() => this.formGroup.getRawValue()),
    );

    @Output()
    public readonly activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding("style.--width-width")
    public widthWidth = 1;

    @HostBinding("style.--height-width")
    public heightWidth = 1;

    public mouseOver = false;

    public constructor(private readonly el: ElementRef,
                       private readonly ripple: MatRipple) {
    }

    private readonly destroy$: Subject<void> = new Subject();

    private readonly rippleTrigger$ = fromEvent<MouseEvent>(this.el.nativeElement, "mousedown", {passive: true}).pipe(
        takeUntil(this.destroy$),
    );
    public readonly mouseOver$: Observable<boolean> = merge(
        fromEvent<MouseEvent>(this.el.nativeElement, "mouseenter", {passive: true}),
        fromEvent<MouseEvent>(this.el.nativeElement, "mouseleave", {passive: true}),
    ).pipe(
        filter(e => e.target === this.el.nativeElement),
        map(e => e.type === "mouseenter"),
        shareReplay(1),
        takeUntil(this.destroy$),
    );
    private readonly hasChanges$: Observable<boolean> = this.formGroup.valueChanges.pipe(
        map(value => !!Object.keys(this.defaults).find(k => this.defaults[k] !== value[k])),
        shareReplay(1),
        takeUntil(this.destroy$),
    );

    public ngOnInit(): void {
        this.mouseOver$.subscribe(mouseOver => this.mouseOver = mouseOver);
        this.hasChanges$.subscribe(hasChanges => {
            if (!hasChanges) {
                this.formGroup.reset(this.defaults, {emitEvent: false});
            }
            if ((!this.active || this.activeAuto) && this.active !== hasChanges) {
                this.active = hasChanges;
                this.activeAuto = this.active;
                this.activeChange.next(this.active);
            }
        });
        this.rippleTrigger$.subscribe(event => this.ripple.launch(event.x, event.y, {
            radius: 350,
            animation: {
                enterDuration: 300,
                exitDuration: 300,
            },
        }));

        this.sanitize(this.formGroup.controls.width);
        this.sanitize(this.formGroup.controls.height);

        this.formGroup.valueChanges.pipe(
            startWith(this.formGroup.value),
            takeUntil(this.destroy$),
        ).subscribe(value => {
            this.widthWidth = ("" + value.width).length ?? 1;
            this.heightWidth = ("" + value.height).length ?? 1;
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public save(autodetect: boolean) {
        this.formGroup.controls.autoDetect.setValue(autodetect, {emitEvent: false});
        this.defaults = this.formGroup.getRawValue();
        this.valueChange.next(this.defaults);
        this.formGroup.reset(this.defaults);
        if (this.activeAuto) {
            this.active = false;
            this.activeAuto = false;
            this.activeChange.next(this.active);
        }
    }

    private sanitize(control: FormControl<number>) {
        control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value > this.max) {
                control.setValue(this.max, {emitEvent: false});
            }
            if (value < 0) {
                control.setValue(0, {emitEvent: false});
            }
        });
    }
}
