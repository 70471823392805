import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AlertWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-donations-tab-images",
    templateUrl: "./widget-donations-tab-images.component.html",
    styleUrls: ["./widget-donations-tab-images.component.scss"]
})
export class WidgetDonationsTabImagesComponent {

    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    @Output()
    public imageChanged = new EventEmitter<File>();

    public imageFormats: string[] =
        [".gif", ".jpg", ".jpeg", ".png", ".apng", ".webp", ".webm", ".mp4"];

    public allowedFormatsText: string;

    constructor(private toastr: ToastrService) {
        this.allowedFormatsText = this.imageFormats.map(s => s.substring(1)).join(", ");
    }

    public clearImage(): void {
        this.widgetProps.data.imageUri = [];
        this.widgetPropsChange.emit(this.widgetProps);
        this.imageChanged.emit(null);
    }

    public setImage(event: Event): void {
        const file = (event.target as HTMLInputElement).files[0];
        if (this.checkFileFormat(file.name, this.imageFormats)) {
            this.imageChanged.emit(file);
        }
    }

    private checkFileFormat(name, formats) {
        let result = false;
        formats.map(x => {
            if (name.toLowerCase().endsWith(x)) {
                result = true;
                return;
            }
        });
        if (!result) {
            this.toastr.warning(`Допустимые форматы: ${formats.join(", ")}`, "Неправильный формат файла");
        }
        return result;
    }

}
