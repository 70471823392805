<div class="sl__container">
    <div class="sl__text" [ngClass]="{'visible': isTextShown}">
        <input [value]="text" readonly>
    </div>
    <div class="sl__column--buttons">
        <div class="sl__button sl__button--show">
            <mat-icon (click)="isTextShown = !isTextShown" [svgIcon]="isTextShown ? 'bx-hide': 'bx-show'"></mat-icon>
        </div>
        <div class="sl__button sl__button--copy">
            <mat-icon (click)="copyText()" svgIcon="content-copy"></mat-icon>
        </div>
    </div>
</div>

<div class="sl__curtain" [ngClass]="{'visible': isTextShown}"></div>
