import {
    DonationAttachments,
    StreamerEventGoalResponse,
    StreamerEventPictureResponse
} from "../../../../streamer/src/app/services/events/streamer-events.api";
import * as moment from "moment";
import _ from "lodash";
import {PaymentMethodType, VerificationState, Wallet} from "../models/finance.api";
import {fakeId} from "./dev-settings.service";
import {Currency} from "../common/FinancialOperation";

const sequence = <T>(items: Array<T>) => {
    const mod = items.length;
    let i = 0;
    return () => items[i++ % mod];
};

export const fake = {
    wallet: (): Wallet => ({
        balance: 0,
        currency: Currency.RUB,
        phoneConfirmed: true,
        verification: {
            state: VerificationState.VERIFIED
        },
        usage: {
            fee: 0,
            withdrawal: 0,
        },
        tariff: {
            name: "ANONYMOUS",
            balanceLimit: 15000,
            in: {
                min: 1,
                max: 15000,
                monthLimit: 40000,
                rates: {
                    CARD: {0: {percent: 3.5}},
                    QIWI: {0: {percent: 7}},
                    PAYPAL: {0: {percent: 0}},
                    YANDEX: {0: {percent: 6}}
                }
            },
            out: {
                min: 1,
                max: 40000,
                monthLimit: 40000,
                rates: {}
            }
        },
        refId: fakeId
    }),
    accountNumber: () => {
        const num = Math.round(Math.random() * 10000);
        return `*${num}`;
    },

    date: () => {
        const UNITS: string[] = ["days", "hours", "minutes"];
        const AMOUNTS: number[] = [1, 2, 3, 4, 5];
        fake.dateCount = fake.dateCount.subtract(_.sample(AMOUNTS), _.sample(UNITS));
        return fake.dateCount.toISOString();
    },
    donationAmount: () => (10 + Math.round(Math.random() * 3000)),
    amount: () => Math.ceil(Math.random() * [10, 1000, 60000][Math.floor(Math.random() * 3)]),
    paymentMethod: sequence([
        PaymentMethodType.Card,
        PaymentMethodType.Qiwi,
        PaymentMethodType.Yandex,
        PaymentMethodType.Google,
        PaymentMethodType.Apple,
    ]),
    withdrawalMethod: sequence([
        PaymentMethodType.Card,
        PaymentMethodType.Qiwi,
        PaymentMethodType.Yandex,
    ]),
    goal: (): StreamerEventGoalResponse => {
        return Math.random() < 0.5 ? undefined : {
            name: "Na edu i lechenie korovi"
        };
    },
    attachments: (): DonationAttachments => {
        return Math.random() < 0.6 ? undefined : {
            media: {
                url: "https://www.youtube.com/watch?v=sexdpCeSaDY&t=592s",
                info: {
                    name: "ТОП 12 ХУДШИХ АВТО 21 ВЕКА.",
                    likes: 38773,
                    views: 1161079,
                    isAdult: false,
                    dislikes: 3102,
                    provider: "YOUTUBE",
                    isEmbeddable: true,
                    durationSeconds: 2457,
                    hasLicensedContent: true,
                },
            },
        };
    },
    picture: (): StreamerEventPictureResponse => {
        const URLS: string[] = [
            "logo/donatty-color.svg", "payment-success.png", "phone.png", "snils-demo.png", null
        ];

        const url = _.sample(URLS);
        if (url) {
            return {source: `/assets/icons/${url}`};
        }

        return null;
    },

    refId: () => (++fake.refIdCount).toString(),

    subscriptionMoths: () => (1 + Math.round(Math.random() * 12)),

    viewersAmount: () => (1 + Math.round(Math.random() * 100)),

    dateCount: moment(),
    refIdCount: 0,
};
