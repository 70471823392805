import {Injectable} from "@angular/core";
import {buildVoice, Voice} from "./voice";
import {EnvironmentService} from "../../environment.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {ApiListVoicesResponse} from "./speech-api";

@Injectable({
    providedIn: "root"
})
export class SpeechService {

    constructor(private readonly environmentService: EnvironmentService,
                private readonly authService: AuthService,
                private readonly http: HttpClient) {
    }

    public async listVoices(): Promise<Array<Voice>> {
        const url = `${this.environmentService.backendApiUri}/tts/voices`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        const response = await this.http.get<ApiListVoicesResponse>(url, authHeaders).toPromise();
        return response.voices.map(buildVoice);
    }

    public buildVoiceAudio(voiceId: number, options: { text: string, speed: number }): HTMLAudioElement {
        const params = {
            voice: "" + voiceId,
            text: options.text,
            speed: "" + options.speed,
            jwt: this.authService.getToken(),
        };
        const url = `${this.environmentService.backendApiUri}/tts`;
        return new Audio(`${url}?${new URLSearchParams(params).toString()}`);
    }

}
