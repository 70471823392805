import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {CookiePolyfillService} from "./cookie-polyfill.service";

export interface AccessToken {
    accessToken: string;
    refreshToken: string;
    expireAt: Date;
}

export interface TokenAuthHeaders {
    headers: HttpHeaders;
}

@Injectable()
export class AuthService {
    public readonly authStatus$ = new BehaviorSubject<boolean>(this.getToken() !== null);

    constructor(private readonly cookieService: CookiePolyfillService,
                private readonly router: Router) {
    }

    public getToken(): string {
        return this.cookieService.getCookie("access-token");
    }

    public makeTokenAuthHeaders(): TokenAuthHeaders {
        let headers = new HttpHeaders({"Content-Type": "application/json"});

        const accessToken = this.getToken();
        if (accessToken) {
            headers = headers.append("Authorization", `Bearer ${accessToken}`);
        }

        return {headers};
    }

    public setAuthenticated(token: AccessToken): void {
        const isNewToken = this.getToken() !== token.accessToken;
        if (!this.setAccessToken(token)) {
            console.warn("Auth token not set");
            return;
        }
        if (isNewToken) {
            this.authStatus$.next(true);
        }
    }

    public async logout(): Promise<void> {
        this.removeAccessToken();
        await this.router.navigateByUrl("/login");
    }

    public removeAccessToken(): void {
        console.log("removing access token");

        this.cookieService.deleteCookie("access-token");
        this.authStatus$.next(false);
    }

    private setAccessToken(token: AccessToken): boolean {
        return this.cookieService.setCookie("access-token", token.accessToken, token.expireAt);
    }
}
