import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AlertWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-donations-tab-text",
    templateUrl: "./widget-donations-tab-text.component.html",
    styleUrls: ["./widget-donations-tab-text.component.scss"]
})
export class WidgetDonationsTabTextComponent implements OnInit {
    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public widgetPropsChange = new EventEmitter();

    public caretPosition: number;

    public async ngOnInit() {
        this.caretPosition = this.widgetProps.style.message.template.length;
    }

    public onTagClicked(tag: string) {
        const tagTemplate = `{${tag}}`;
        this.widgetProps.style.message.template = [
            this.widgetProps.style.message.template.slice(0, this.caretPosition),
            tagTemplate,
            this.widgetProps.style.message.template.slice(this.caretPosition)
        ].join("");
        this.caretPosition += tagTemplate.length;
        this.widgetPropsChange.emit(this.widgetProps);
    }
}
