import {
    DonationAttachments, DonattyDonationEventResponse, PayoutEventResponse, PaypalDonationEventResponse,
    StreamerEventCurrency, ModerationState, StreamerEventResponse, StreamerEventType
} from "./streamer-events.api";
import * as moment from "moment";
import {PaymentMethodType} from "../../../../../shared/src/lib/models/finance.api";
import {
    TwitchBitEventResponse, TwitchChannelPointsEventResponse, TwitchFollowerEventResponse, TwitchHostEventResponse,
    TwitchHypeTrainEventResponse, TwitchRaidEventResponse, TwitchSubscriberEventResponse,
    TwitchSubscriberGiftChannelEventResponse, TwitchSubscriberGiftUpgradeEventResponse,
    TwitchSubscriberGiftViewerEventResponse, TwitchSubscriberUpgradeEventResponse,
} from "./streamer-events.twitch.api";
import {
    TrovoFollowerEventResponse, TrovoRaidEventResponse, TrovoSpellEventResponse, TrovoSubscriberEventResponse,
    TrovoSubscriberGiftChannelEventResponse, TrovoSubscriberGiftViewerEventResponse
} from "./streamer-events.trovo.api";
import {
    YoutubeDonationEventResponse, YoutubeFollowerEventResponse, YoutubeSponsorEventResponse
} from "./streamer-events.youtube.api";

interface HasDisplayName {
    displayName: string;
}

interface HasUserInfo extends HasDisplayName {
    picture?: string;
}

interface HasModeration {
    moderation: ModerationState;
}

interface HasMessage extends HasUserInfo {
    message: string;
}

export interface StreamerEvent extends HasModeration {
    id: string;
    date: moment.Moment;
    type: StreamerEventType;
    isLive: boolean;
}

export interface FinanceEvent extends StreamerEvent {
    amount: number;
    currency: StreamerEventCurrency;
    commission: number;
    paymentMethod: PaymentMethodType;
}

export interface DonationEvent extends FinanceEvent, HasMessage {
    goal: string;
    attachments: DonationAttachments;
    nickname: string;
}

export interface WithdrawalEvent extends FinanceEvent {
    cardNumber: string;
    orderId?: number;
}

export type FollowerEvent = StreamerEvent & HasUserInfo;

export type HostEvent = StreamerEvent & HasUserInfo;

export interface RaidEvent extends StreamerEvent, HasUserInfo {
    usersAmount: number;
}

export interface TwitchSubscriberEvent extends StreamerEvent, HasMessage {
    months: number;
    streak: number;
    tier: number;
    total: number;
}

export interface TwitchSubscriberGiftViewerEvent extends StreamerEvent, HasMessage {
    giftedTo: string;
    months: number;
    tier: number;
}

export interface TwitchSubscriberGiftChannelEvent extends StreamerEvent, HasUserInfo {
    tier: number;
    subscriptionsAmount: number;
    subscriptionsAmountTotal: number;
}

export interface TwitchSubscriberGiftUpgradeEvent extends StreamerEvent, HasUserInfo {
    giftedBy?: string;
}

export interface TwitchSubscriberUpgradeEvent extends StreamerEvent, HasUserInfo {
    tier: number;
    oldTier: number;
}

export interface TwitchHypeTrainEvent extends StreamerEvent {
    level: number;
}

export interface TwitchChannelPointsEvent extends StreamerEvent, HasMessage {
    reward: string;
    amount: number;
    color: string;
}

export interface TrovoEvent extends StreamerEvent {
    picture?: string;
}

export interface TrovoFollowerEvent extends TrovoEvent, HasDisplayName {
}

export interface TrovoSubscriberEvent extends TrovoEvent, HasDisplayName {
    tier: number;
}

export interface TrovoSubscriberGiftChannelEvent extends TrovoEvent {
    giftedBy: string;
    count: number;
    tier: number;
}

export interface TrovoSubscriberGiftViewerEvent extends TrovoEvent {
    giftedBy: string;
    giftedTo: string;
    tier: number;
}

export interface TrovoRaidEvent extends TrovoEvent, HasDisplayName {
    usersAmount: number;
}

export interface TrovoSpellManaEvent extends TrovoEvent, HasDisplayName {
    spell: string;
    amount: number;
    value: number;
    currency: StreamerEventCurrency;
}

export interface TrovoSpellElixirEvent extends TrovoEvent, HasDisplayName {
    spell: string;
    amount: number;
    value: number;
    currency: StreamerEventCurrency;
}

export const streamerEventBuilders: { [key in StreamerEventType]?: (event: StreamerEventResponse, isLive: boolean) => StreamerEvent } = {
    [StreamerEventType.DonattyDonation]: (event: DonattyDonationEventResponse, isLive: boolean): DonationEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: event.type,
        amount: event.amount,
        commission: event.commission,
        currency: event.currency,
        displayName: event.displayName,
        goal: (event.goal ? event.goal.name : ""),
        attachments: event.attachments,
        message: event.message,
        nickname: event.displayName,
        paymentMethod: event.method,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.DonattyPayout]: payoutEventBuilder,
    [StreamerEventType.DonattyPayoutSuspicious]: payoutEventBuilder,
    [StreamerEventType.TwitchBit]: (event: TwitchBitEventResponse, isLive: boolean): DonationEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchBit,
        amount: event.amount,
        currency: StreamerEventCurrency.Bits,
        displayName: event.displayName,
        goal: null,
        attachments: null,
        message: event.message,
        nickname: event.displayName,
        commission: 0,
        paymentMethod: null,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchFollower]: (event: TwitchFollowerEventResponse, isLive: boolean): FollowerEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchFollower,
        displayName: event.displayName,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchHost]: (event: TwitchHostEventResponse, isLive: boolean): HostEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchHost,
        displayName: event.displayName,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchRaid]: (event: TwitchRaidEventResponse, isLive: boolean): RaidEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchRaid,
        displayName: event.displayName,
        usersAmount: event.viewers,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchSubscriber]: (event: TwitchSubscriberEventResponse, isLive: boolean): TwitchSubscriberEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchSubscriber,
        message: event.message,
        months: event.months,
        streak: event.streak,
        total: (event.total ?? event.streak),
        displayName: event.displayName,
        tier: event.tier,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchSubscriberGiftViewer]: (event: TwitchSubscriberGiftViewerEventResponse, isLive: boolean): TwitchSubscriberGiftViewerEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchSubscriberGiftViewer,
        message: event.message,
        months: event.months,
        displayName: event.giftedBy,
        giftedTo: event.giftedTo,
        tier: event.tier,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchSubscriberGiftChannel]: (event: TwitchSubscriberGiftChannelEventResponse, isLive: boolean): TwitchSubscriberGiftChannelEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchSubscriberGiftChannel,
        subscriptionsAmount: event.months,
        subscriptionsAmountTotal: event.monthsTotal,
        displayName: event.giftedBy,
        tier: event.tier,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchSubscriberGiftUpgrade]: (event: TwitchSubscriberGiftUpgradeEventResponse, isLive: boolean): TwitchSubscriberGiftUpgradeEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchSubscriberGiftUpgrade,
        displayName: event.giftedTo,
        giftedBy: event.giftedBy,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchSubscriberUpgrade]: (event: TwitchSubscriberUpgradeEventResponse, isLive: boolean): TwitchSubscriberUpgradeEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchSubscriberUpgrade,
        displayName: event.displayName,
        oldTier: event.oldTier,
        tier: event.tier,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchHypeTrain]: (event: TwitchHypeTrainEventResponse, isLive: boolean): TwitchHypeTrainEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchHypeTrain,
        level: event.level,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TwitchChannelPoints]: (event: TwitchChannelPointsEventResponse, isLive: boolean): TwitchChannelPointsEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TwitchChannelPoints,
        amount: event.amount,
        reward: `${event.title}`,
        color: event.bgColor,
        picture: event.picture?.source,
        displayName: event.subscriber ?? "Anonymous",
        message: event.message,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoSubscriber]: (event: TrovoSubscriberEventResponse, isLive: boolean): TrovoSubscriberEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoSubscriber,
        displayName: event.subscriber,
        tier: event.tier,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoSubscriberGiftViewer]: (event: TrovoSubscriberGiftViewerEventResponse, isLive: boolean): TrovoSubscriberGiftViewerEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoSubscriberGiftViewer,
        giftedBy: event.giftedBy,
        giftedTo: event.giftedTo,
        tier: event.tier,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoSubscriberGiftChannel]: (event: TrovoSubscriberGiftChannelEventResponse, isLive: boolean): TrovoSubscriberGiftChannelEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoSubscriberGiftChannel,
        giftedBy: event.giftedBy,
        count: event.months,
        tier: event.tier,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoFollower]: (event: TrovoFollowerEventResponse, isLive: boolean): TrovoFollowerEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoFollower,
        displayName: event.displayName,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoRaid]: (event: TrovoRaidEventResponse, isLive: boolean): TrovoRaidEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoRaid,
        displayName: event.displayName,
        usersAmount: event.viewers,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoSpellElixir]: (event: TrovoSpellEventResponse, isLive: boolean): TrovoSpellElixirEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoSpellElixir,
        displayName: event.subscriber,
        spell: event.title,
        amount: event.amount,
        value: event.value,
        currency: StreamerEventCurrency.Elixir,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.TrovoSpellMana]: (event: TrovoSpellEventResponse, isLive: boolean): TrovoSpellManaEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.TrovoSpellMana,
        displayName: event.subscriber,
        spell: event.title,
        amount: event.amount,
        value: event.value,
        currency: StreamerEventCurrency.Mana,
        picture: event.picture?.source,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.YoutubeFollower]: (event: YoutubeFollowerEventResponse, isLive: boolean): FollowerEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        picture: event.picture?.source,
        displayName: event.displayName,
        type: StreamerEventType.YoutubeFollower,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.YoutubeSponsor]: (event: YoutubeSponsorEventResponse, isLive: boolean): TwitchSubscriberEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        message: null,
        months: event.months,
        streak: event.months,
        total: event.months,
        displayName: event.displayName,
        tier: null,
        type: StreamerEventType.YoutubeSponsor,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.YoutubeSuperchat]: (event: YoutubeDonationEventResponse, isLive: boolean): DonationEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        amount: event.amount,
        currency: event.currency,
        displayName: event.displayName,
        attachments: null,
        message: event.message,
        nickname: event.displayName,
        type: StreamerEventType.YoutubeSuperchat,
        goal: "",
        commission: 0,
        paymentMethod: null,
        moderation: event.moderation ?? ModerationState.None,
    }),
    [StreamerEventType.PaypalDonation]: (event: PaypalDonationEventResponse, isLive: boolean): DonationEvent => ({
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: StreamerEventType.PaypalDonation,
        amount: event.amount,
        currency: event.currency,
        displayName: event.displayName,
        goal: (event.goal ? event.goal.name : ""),
        attachments: event.attachments,
        message: event.message,
        nickname: event.displayName,
        picture: event.picture?.source,
        commission: 0,
        paymentMethod: null,
        moderation: event.moderation ?? ModerationState.None,
    }),
};

function payoutEventBuilder(event: PayoutEventResponse, isLive: boolean): WithdrawalEvent {
    return {
        id: event.refId,
        date: moment(event.date),
        isLive,
        type: event.type,
        amount: event.amount,
        currency: StreamerEventCurrency.Ruble,
        commission: event.commission,
        cardNumber: event.number,
        paymentMethod: event.method,
        orderId: event.orderId,
        moderation: event.moderation ?? ModerationState.None,
    };
}
