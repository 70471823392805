import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {Currency} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {FinanceSSEService} from "../../../services/finance/finance-sse.service";
import {tap} from "rxjs/operators";

@Component({
    selector: "app-statistics",
    templateUrl: "./statistics.component.html",
    styleUrls: ["./statistics.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsComponent {

    public readonly currency = Currency.RUB;
    public readonly statistics$ = this.financeSSEService.statistics$.pipe(tap(() => {
        setTimeout(() => this.changeDetectorRef.detectChanges());
    }));

    public constructor(public readonly financeSSEService: FinanceSSEService,
                       private readonly changeDetectorRef: ChangeDetectorRef) {
    }

}
