import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {WidgetService} from "../../../services/widgets/widget.service";
import {Router} from "@angular/router";
import {WidgetPreviewService} from "../../../services/widgets/widget-preview.service";
import {ClipboardService} from "ngx-clipboard";
import {EnvironmentService} from "../../../../../../shared/src/lib/environment.service";
import {IGenericWidgetInfo} from "../../../../../../shared/src/lib/models/widget";
import {of} from "rxjs";
import {take} from "rxjs/operators";

export enum WidgetListItemAction {
    copy = "copy",
    delete = "delete",
}

export interface WidgetListItemEventPayload {
    action: WidgetListItemAction;
    widget: IGenericWidgetInfo;
}

@Component({
    selector: "app-widgets-list-item",
    templateUrl: "./widgets-list-item.component.html",
    styleUrls: ["./widgets-list-item.component.scss"]
})
export class WidgetsListItemComponent {
    @Input()
    public widget: IGenericWidgetInfo;

    @Output()
    public action = new EventEmitter<WidgetListItemEventPayload>();

    public showMenuButton = false;
    public menuOpened = false;

    public constructor(private readonly clipboardService: ClipboardService,
                       private readonly environmentService: EnvironmentService,
                       private readonly previewService: WidgetPreviewService,
                       private readonly router: Router,
                       private readonly toastr: ToastrService,
                       private readonly widgetService: WidgetService) {
    }

    public async onCopyClick(): Promise<void> {
        this.action.emit({action: WidgetListItemAction.copy, widget: this.widget});
    }

    public onCopyLinkClick(): void {
        const link = this.widgetService.formatWidgetLink(this.widget, false);
        this.clipboardService.copyFromContent(link);
        this.toastr.success("Ссылка скопирована");
    }

    public onEditClick(): void {
        this.router.navigateByUrl(`/widgets/${this.widget.getId()}/edit`);
    }

    public async onRemoveClick(): Promise<void> {
        this.action.emit({action: WidgetListItemAction.delete, widget: this.widget});
    }

    public async onTestClick(): Promise<void> {
        if (this.isTestEventInProgress) {
            return;
        }

        this.isTestEventInProgress = true;

        this.previewService.generateTestData[this.widget.getType()](of(this.widget.getProps()))
            .pipe(take(1))
            .subscribe(async (testData) => {
                try {
                    await this.widgetService.emitEvent(this.widget.getId(), "DATA", testData);
                    this.toastr.success("Тест отправлен");
                } catch (e) {
                    console.error("Test donation error", e);
                    this.toastr.error("Неизвестная ошибка");
                } finally {
                    this.isTestEventInProgress = false;
                }
            }, (e) => {
                throw e;
            });
    }

    private isTestEventInProgress = false;
}
