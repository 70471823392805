import {
    ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild
} from "@angular/core";
import {BehaviorSubject, combineLatest, merge, ReplaySubject, Subject} from "rxjs";
import {EventsWidget} from "../../../../../../../../../shared/src/lib/models/widget-props";
import {ToastrService} from "ngx-toastr";
import {AccountEventType, AccountInfo, disabledAccountEvents} from "../../../../../../services/accounts/accounts";
import {filter, map, shareReplay, switchMap, takeUntil, tap} from "rxjs/operators";
import {defaultEventsProps} from "../../../../../../default-widget-settings/defaultEventsProps";
import {WidgetHelpTagsService} from "../../../../../../services/widgets/widget-help-tags.service";
import {
    WidgetFileUploadService, WidgetUploadFileType
} from "../../../../../../services/widgets/widget-file-upload.service";
import {WidgetSettingsService} from "../../../../../../services/settings/widget-settings.service";
import {AccountsService} from "../../../../../../services/accounts/accounts.service";
import {accountEventIcons, accountEventVisuals} from "../../../../../../services/events/accountEventVisuals";
import {TagSelectorItem} from "../../../../../tags-selector/tags-selector.component";

const customChipNames: { [key in AccountEventType]?: string } = {
    [AccountEventType.DonattyDonate]: "Донаты",
    [AccountEventType.PaypalDonate]: "Донаты",
};

@Component({
    selector: "app-widget-events-tab-events",
    templateUrl: "./widget-events-tab-events.component.html",
    styleUrls: ["./widget-events-tab-events.component.scss"]
})
export class WidgetEventsTabEventsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() widgetId: string;

    @Input()
    public widgetProps: EventsWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    private readonly widgetProps$ = new ReplaySubject<EventsWidget["props"]>(1);

    private readonly propsAccounts$ = combineLatest([
        this.widgetProps$, this.accountsService.accounts$.pipe(filter<Array<AccountInfo>>(Boolean))
    ]).pipe(shareReplay(1));

    public readonly chips$ = merge(
        this.propsAccounts$,
        this.widgetSettingsService.eventSourcePropsChanged$.pipe(switchMap(() => this.propsAccounts$)))
        .pipe(
            map(([props, accounts]) => this.widgetSettingsService
                .listEnabledEventTypes(accounts, props.data)
                .filter(accountEventType => !disabledAccountEvents.has(accountEventType))
                .map((eventType: AccountEventType) => ({
                    type: eventType,
                    label: customChipNames[eventType] || accountEventVisuals[eventType].label,
                    icon: accountEventIcons[eventType],
                }))
            ),
            filter(chips => chips.length > 0),
            tap(chips => {
                if (!chips.find(chip => chip.type === this.selectedEvent$.value)) {
                    this.selectedEvent$.next(chips[0].type);
                }
            }),
        );

    public readonly selectedEvent$: BehaviorSubject<AccountEventType> = new BehaviorSubject<AccountEventType>(AccountEventType.DonattyDonate);

    public readonly allowedFormats: Array<string> = [".gif", ".jpg", ".jpeg", ".png", ".apng", ".webp", ".webm", ".mp4"];

    public readonly allowedFormatsText = this.allowedFormats.map(s => s.substring(1)).join(", ");

    public templateCaretPosition: number;

    public readonly destroy$: Subject<void> = new Subject<void>();

    public readonly customTags: Array<TagSelectorItem> = [{tag: "icon", description: `иконка события`}];

    public constructor(private readonly toastr: ToastrService,
                       private readonly widgetSettingsService: WidgetSettingsService,
                       private readonly changeDetectorRef: ChangeDetectorRef,
                       private readonly accountsService: AccountsService,
                       private readonly widgetFileUploadService: WidgetFileUploadService) {
    }

    public ngOnInit() {
        this.templateCaretPosition = this.widgetProps.style.events.eventTypes[this.selectedEvent$.value]?.template.length || 0;
        this.watchUnfilledTagFonts();
    }

    public ngOnChanges(): void {
        this.widgetProps$.next(this.widgetProps);
        this.changeDetectorRef.detectChanges();
    }

    private watchUnfilledTagFonts() {
        const fillTagFonts = (accountEventType: AccountEventType) => {
            const eventTypeProps = this.widgetProps.style.events.eventTypes[accountEventType];
            if (!eventTypeProps.tagFonts || !Object.keys(eventTypeProps.tagFonts).length) {
                eventTypeProps.tagFonts = {} as any;
                const availableTags = WidgetHelpTagsService.formatDonationTags([accountEventType], true);
                for (const eventTag of availableTags) {
                    eventTypeProps.tagFonts[eventTag.tag] = JSON.parse(JSON.stringify(defaultEventsProps.style.events.eventTypes[accountEventType].font));
                }
            }
        };
        fillTagFonts(this.selectedEvent$.value);
        this.selectedEvent$
            .pipe(takeUntil(this.destroy$))
            .subscribe(accountEventType => fillTagFonts(accountEventType));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    @ViewChild("messageTemplate")
    public messageTemplate: ElementRef<HTMLInputElement>;

    public onTagClicked(tag: string) {
        const tagTemplate = `{${tag}}`;
        this.widgetProps.style.events.eventTypes[this.selectedEvent$.value].template = [
            this.widgetProps.style.events.eventTypes[this.selectedEvent$.value].template.slice(0, this.templateCaretPosition),
            tagTemplate,
            this.widgetProps.style.events.eventTypes[this.selectedEvent$.value].template.slice(this.templateCaretPosition)
        ].join("");
        this.templateCaretPosition += tagTemplate.length;
        this.widgetPropsChange.emit(this.widgetProps);
        requestAnimationFrame(() => {
            this.messageTemplate.nativeElement.setSelectionRange(this.templateCaretPosition, this.templateCaretPosition);
            this.messageTemplate.nativeElement.focus();
        });
    }

    public formatSliderLabel(unit: string) {
        return value => value + unit;
    }

    public async setEventImage(event: Event, accountEventType: AccountEventType) {
        const file = (event.target as HTMLInputElement).files[0];
        if (!file) {
            this.widgetProps.style.events.eventTypes[this.selectedEvent$.value].iconUri = [];
            this.widgetPropsChange.emit(this.widgetProps);
            return;
        }

        const checkFileFormat = (name, formats) => {
            let result = false;
            formats.map(x => {
                if (name.toLowerCase().endsWith(x)) {
                    result = true;
                    return;
                }
            });
            if (!result) {
                this.toastr.warning(`Допустимые форматы: ${formats.join(", ")}`, "Неправильный формат файла");
            }
            return result;
        };

        if (checkFileFormat(file.name, this.allowedFormats)) {
            const uploadedFile = await this.widgetFileUploadService.uploadFile(
                this.widgetId,
                `icon-${accountEventType}`,
                WidgetUploadFileType.Image,
                file);
            this.widgetProps.style.events.eventTypes[this.selectedEvent$.value].iconUri = [uploadedFile.uri];
            this.widgetPropsChange.emit(this.widgetProps);
        }
    }

}
