import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChildren
} from "@angular/core";
import {EventsByDate} from "../transaction-list.component";
import {StreamerEvent} from "../../../../services/events/streamer-event";
import {ModerationState, StreamerEventType} from "../../../../services/events/streamer-events.api";
import {ItemDonateCardComponent} from "./item-donate-card/item-donate-card.component";
import {ModerationAct, ModerationAction, ModerationService} from "../../../../modules/moderation/moderation.service";
import {filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

type EventTemplate = "donation" | "withdrawal" | "twitch-hype-train" | "channel-points" | "default" |
    "twitch-subscriber-gift-viewer" | "twitch-subscriber-gift-channel" | "twitch-subscriber-gift-upgrade" |
    "twitch-subscriber-upgrade" | "twitch-subscriber" | "twitch-host" |
    "trovo-subscriber" | "trovo-subscriber-gift-viewer" | "trovo-subscriber-gift-channel" |
    "trovo-spell-elixir" | "trovo-spell-mana" | "follower" | "raid" |
    "youtube-sponsor" | "youtube-follower" | "youtube-superchat" | "social-donate";

const moderationActionState = {
    [ModerationAction.Allow]: ModerationState.Passed,
    [ModerationAction.Deny]: ModerationState.Blocked,
};

@Component({
    selector: "app-transaction-list-items",
    templateUrl: "./transaction-list-items.component.html",
})
export class TransactionListItemsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() events: EventsByDate;
    @Input() showTargets: boolean;
    @Output() retry = new EventEmitter<StreamerEvent>();

    private readonly destroy$ = new Subject<void>();

    @ViewChildren("item")
    public eventCards: QueryList<ItemDonateCardComponent>;

    public constructor(public readonly moderationService: ModerationService,
                       public readonly changeDetectorRef: ChangeDetectorRef) {
    }

    private readonly moderationAct$ = this.moderationService.moderationAct$.pipe(
        map<ModerationAct, [ItemDonateCardComponent, ModerationState]>(moderationAct => [
            this.eventCards.find(card => card.item.id === moderationAct.event.eventId),
            moderationActionState[moderationAct.action] ?? ModerationState.None,
        ]),
        filter(([card]) => !!card),
        takeUntil(this.destroy$),
    );

    public ngOnInit(): void {
        this.moderationAct$.subscribe(([card, moderation]) =>
            card.item.moderation = moderation
        );
    }

    public ngOnChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public selectedItem: StreamerEvent;

    public async selectItem(event: StreamerEvent): Promise<void> {
        this.selectedItem = event;
    }

    public isItemSelected(item: StreamerEvent): boolean {
        return this.selectedItem?.id === item.id;
    }

    public trackEventsBy(index: number, event: StreamerEvent) {
        return event.id;
    }

    public readonly eventTemplates: { [key in StreamerEventType]?: EventTemplate } = {
        [StreamerEventType.DonattyPayout]: "withdrawal",
        [StreamerEventType.DonattyPayoutSuspicious]: "withdrawal",
        [StreamerEventType.DonattyDonation]: "donation",
        [StreamerEventType.TwitchBit]: "social-donate",
        [StreamerEventType.PaypalDonation]: "social-donate",
        [StreamerEventType.TwitchHypeTrain]: "twitch-hype-train",
        [StreamerEventType.TwitchChannelPoints]: "channel-points",
        [StreamerEventType.TwitchHost]: "twitch-host",
        [StreamerEventType.TwitchFollower]: "follower",
        [StreamerEventType.TwitchRaid]: "raid",
        [StreamerEventType.TwitchSubscriber]: "twitch-subscriber",
        [StreamerEventType.TwitchSubscriberGiftViewer]: "twitch-subscriber-gift-viewer",
        [StreamerEventType.TwitchSubscriberGiftChannel]: "twitch-subscriber-gift-channel",
        [StreamerEventType.TwitchSubscriberGiftUpgrade]: "twitch-subscriber-gift-upgrade",
        [StreamerEventType.TwitchSubscriberUpgrade]: "twitch-subscriber-upgrade",

        [StreamerEventType.TrovoSubscriber]: "trovo-subscriber",
        [StreamerEventType.TrovoSubscriberGiftViewer]: "trovo-subscriber-gift-viewer",
        [StreamerEventType.TrovoSubscriberGiftChannel]: "trovo-subscriber-gift-channel",
        [StreamerEventType.TrovoSpellElixir]: "trovo-spell-elixir",
        [StreamerEventType.TrovoSpellMana]: "trovo-spell-mana",
        [StreamerEventType.TrovoFollower]: "follower",
        [StreamerEventType.TrovoRaid]: "raid",

        // [StreamerEventType.TrovoSpell]: "TROVO_SPELL",

        [StreamerEventType.YoutubeSponsor]: "youtube-sponsor",
        [StreamerEventType.YoutubeFollower]: "youtube-follower",
        [StreamerEventType.YoutubeSuperchat]: "youtube-superchat",
    };
}
