import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {AlertWidget} from "../../../../../../../../shared/src/lib/models/widget-props";
import {MatTabGroup} from "@angular/material/tabs";
import {WidgetVisuals} from "../widget-edit-visuals.component";
import {WidgetType} from "../../../../../../../../shared/src/lib/models/widget";

@Component({
    selector: "app-widget-visuals-donation",
    templateUrl: "./widget-visuals-donation.component.html",
})
export class WidgetVisualsDonationComponent implements WidgetVisuals {

    @Input()
    public widgetProps: AlertWidget["props"];

    @Output()
    public readonly widgetPropsChange = new EventEmitter();

    @Output()
    public readonly imageChanged = new EventEmitter<File>();

    @Output()
    public readonly audioChanged = new EventEmitter<File>();

    @ViewChild(MatTabGroup)
    public tabGroup: MatTabGroup;

    public readonly WidgetType = WidgetType;

    public isValid = true;
}
