<mat-card class="settings-form">
    <mat-card-header>
        <mat-card-title>Фильтр плохих слов</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Слова из этого списка не будут отображаться на стриме. Они будут заменяться
            на&nbsp;выбранные вами слова или символы. Фильтр плохих слов поддерживает
            <a href='https://donatty.com/help/opoveshcheniya-i-alerti/kak-nastroit-filtry-mata-i-plokhikh-slov#rec336221741'
               target='_blank'>регулярные выражения и шаблоны</a>.
        </p>
        <form [formGroup]="formGroup">
            <fieldset>
                <mat-label>Плохие слова</mat-label>
                <mat-form-field appearance="outline">
                    <textarea rows="4" matInput formControlName="blacklist"></textarea>
                    <mat-hint>Введите слова через пробел.</mat-hint>
                </mat-form-field>
            </fieldset>
            <fieldset>
                <mat-label>Слово-замена</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="replacement">
                    <mat-hint>Этим текстом или символами будут заменены слова из блеклиста.</mat-hint>
                </mat-form-field>
            </fieldset>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" type="submit"
                (click)="profanityService.saveProfanity(formGroup.getRawValue())"
                [disabled]="submitDisabled$ | async">Сохранить
        </button>
    </mat-card-actions>
</mat-card>
