<img src="/assets/icons/checkbox/eye-on.svg" class="checkbox__preload-image" />
<img src="/assets/icons/checkbox/eye-off.svg" class="checkbox__preload-image" />
<img src="/assets/icons/checkbox/check.svg" class="checkbox__preload-image" />

<div class="checkbox {{'checkbox__--' + this.type}}"
     [ngClass]="{'checkbox--checked': this.value}">
    <div #icon class="checkbox__icon" (click)="onClick($event)">
        <div #iconImage></div>
    </div>
    <div #label class="checkbox__label" [ngStyle]="labelStyle" (click)="onClick($event)">
        <ng-content></ng-content>
    </div>
</div>
