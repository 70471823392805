import {NgModule} from "@angular/core";
import {ProfanitySettingsComponent} from "./components/profanity-settings/profanity-settings.component";
import {ProfanityService} from "./profanity.servoce";
import {MatCardModule} from "@angular/material/card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {ProfanityWidgetSettingsComponent} from "./components/profanity-widget-settings/profanity-widget-settings.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [
        ProfanitySettingsComponent,
        ProfanityWidgetSettingsComponent
    ],
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        RouterModule,
        CommonModule
    ],
    exports: [
        ProfanitySettingsComponent,
        ProfanityWidgetSettingsComponent
    ],
    providers: [
        ProfanityService
    ]
})
export class ProfanityModule {
}
