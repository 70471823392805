<mat-tab-group ffScrollFix mat-align-tabs="start" dynamicHeight [disableRipple]="true">
    <mat-tab aria-label="style" label="Стиль">
        <app-widget-tab-style [widgetType]="WidgetType.Top"
                              [(widgetProps)]="widgetProps"
                              (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-tab-style>
    </mat-tab>
    <mat-tab aria-label="properties" label="Свойства">
        <app-widget-top-tab-props [(widgetProps)]="widgetProps"
                                  (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-top-tab-props>
    </mat-tab>
    <mat-tab aria-label="header" label="Заголовок">
        <app-widget-top-tab-header [(widgetProps)]="widgetProps"
                                   (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-top-tab-header>
    </mat-tab>
    <mat-tab aria-label="name" label="Ник">
        <app-widget-top-tab-name [(widgetProps)]="widgetProps"
                                 (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-top-tab-name>
    </mat-tab>
    <mat-tab aria-label="sum" label="Сумма">
        <app-widget-top-tab-sum [(widgetProps)]="widgetProps"
                                (widgetPropsChange)="widgetPropsChange.emit(widgetProps)">
        </app-widget-top-tab-sum>
    </mat-tab>
</mat-tab-group>
