import {NgModule} from "@angular/core";
import {NotificationBarComponent} from "./components/notification-bar/notification-bar.component";
import {NotificationService} from "./notification.service";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {ToastrModule} from "ngx-toastr";
import {ToastComponent} from "./components/toast/toast.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SharedModule} from "../../../../../shared/src/lib/shared.module";

@NgModule({
    declarations: [
        NotificationBarComponent,
        ToastComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        ToastrModule.forRoot({
            timeOut: 4000,
            toastComponent: ToastComponent,
            toastClass: "tst__container",
            positionClass: "toast-bottom-center",
            newestOnTop: false,
        }),
        MatProgressSpinnerModule,
    ],
    exports: [
        NotificationBarComponent,
        ToastComponent,
    ],
    providers: [
        NotificationService,
    ]
})
export class NotificationModule {
}
