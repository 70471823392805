<div class="container">
  <div class="row h-100 justify-content-center align-items-center pb-4">
    <div class="col-md-5 text-center">
      <div class="row justify-content-center">
        <div class="col-auto position-relative letter">
          <img src="/assets/icons/letter.png">
          <img class="blur" src="/assets/icons/letter.png">
        </div>
      </div>
      <div class="row">
        <div class="col pt-4">
          <img src="/assets/icons/br-blur.svg" class="background">
          <div class="row">
            <div class="col">
              <h4>Спасибо<br>
                за терпение</h4>
            </div>
          </div>
          <div class="row">
            <div class="col focus-text pt-4">
              Осталось совсем чуть-чуть. Ответ от СМЭВ приходит в течение часа, но обычно быстрее. Как только мы его
              получим, то сразу покажем в разделе Финансы.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 offset-3 pt-4">
          <app-new-button type="blue centered" [routerLink]="['/finance']">
              Продолжить
          </app-new-button>
        </div>
      </div>
    </div>
  </div>
</div>
