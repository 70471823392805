<div class="we__upload-container we__upload-container--{{layout}}">
    <div class="we__button-container">
        <app-new-button icon="{{iconName}}" type="{{type}}" (click)="onClick()">
            <ng-content></ng-content>
        </app-new-button>

        <input #fileInput (change)="proxyChange($event)" type="file" accept="{{acceptFiles}}" hidden/>

        <div class="we__button-uploaded-file" *ngIf="hasUploadedItem">
            <div class="we__icon">
                <img src="/assets/icons/attach.svg" class="icon">
            </div>
            <div class="we__text">
                {{uploadedFilename}}
            </div>
            <div class="we__remove" (click)="clearUploaded()">
                <mat-icon svgIcon="close"></mat-icon>
            </div>
        </div>
    </div>

    <div class="we__button-help-text" *ngIf="helpText.length > 0" [innerText]="helpText">
    </div>

</div>
