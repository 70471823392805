<div class="we__tab-container">
  <div class="we__header">
    Заголовок
  </div>

  <div class="we__template-row">
    <div class="we__row">
      <div class="we__label">Текст заголовка</div>
      <div class="we__controls">
        <input (ngModelChange)="widgetProps.data.headerTemplate = $event; widgetPropsChange.emit(widgetProps)"
               [ngModel]="widgetProps.data.headerTemplate"
               type="text">
      </div>
    </div>
    <div class="we__row">
    </div>
  </div>

  <app-widget-edit-font-basics [(item)]="widgetProps.style.header"
                               text="заголовка" offsetBounds="200"
                               (itemChange)="widgetProps.style.header = $event; widgetPropsChange.emit(widgetProps)">
  </app-widget-edit-font-basics>
</div>
