import {
    AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input,
    OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef
} from "@angular/core";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";
import {merge, take} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "lib-confirmation-modal",
    templateUrl: "./confirmation-modal.component.html",
    styleUrls: ["./confirmation-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements AfterViewInit, OnDestroy {

    @Input()
    public cancelText = "";

    @Input()
    public confirmIcon = "";

    @Input()
    public confirmText = "";

    @Input()
    public confirmDisabled = false;

    @Input()
    public header = "";

    @Output()
    public cancel = new EventEmitter();

    @Output()
    public confirm = new EventEmitter();

    @Output()
    public close = new EventEmitter();

    public useBackdropWorkaround: boolean;

    public constructor(private readonly viewContainerRef: ViewContainerRef,
                       private readonly overlay: Overlay) {
        this.useBackdropWorkaround = window.navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    }

    @ViewChild("templatePortalContent")
    private templatePortalContent: TemplateRef<unknown>;
    private overlayRef: OverlayRef;
    private templatePortal: TemplatePortal;

    public ngAfterViewInit(): void {
        this.templatePortal = new TemplatePortal(this.templatePortalContent, this.viewContainerRef);
    }

    public ngOnDestroy(): void {
        this.overlayRef?.dispose();
    }

    public show(): void {
        this.overlayRef?.dispose();
        this.overlayRef = this.overlay.create(new OverlayConfig());
        this.overlayRef.attach(this.templatePortal);

        merge(this.cancel, this.confirm, this.close).pipe(
            take(1),
            takeUntil(this.overlayRef.detachments())
        ).subscribe(() => this.overlayRef.dispose());

        this.overlayRef.backdropClick().pipe(
            take(1),
            takeUntil(this.overlayRef.detachments())
        ).subscribe(() => this.close.emit());
    }
}
