import {Component, ElementRef, ViewChild} from "@angular/core";
import {UserService} from "../../../services/user/user.service";
import {ClipboardService} from "ngx-clipboard";
import {ToastrService} from "ngx-toastr";
import {User} from "projects/shared/src/lib/models/user";
import {FormControl, FormGroup} from "@angular/forms";
import {filter, take} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {UserNameDialogComponent} from "./user-name-dialog/user-name-dialog.component";

@Component({
    selector: "app-user-name",
    templateUrl: "./user-name-card.component.html",
    styleUrls: ["./user-name-card.component.scss"]
})
export class UserNameCardComponent {

    @ViewChild("dialogButton")
    public dialogButton: ElementRef;
    public formGroup: FormGroup = new FormGroup({userName: new FormControl<string>({value: "", disabled: true})});

    public constructor(public readonly userService: UserService,
                       private readonly clipboardService: ClipboardService,
                       private readonly toastr: ToastrService,
                       private readonly mediaDialog: MatDialog) {
        this.userService.currentUser$
            .pipe(filter(user => !!user))
            .subscribe(user => this.formGroup.controls.userName.setValue(user.name));
    }

    public copyLink() {
        this.userService.currentUser$.pipe(filter<User>(Boolean), take(1)).subscribe(user => {
            this.clipboardService.copyFromContent(user.pageUrl);
            this.toastr.success("Ссылка скопирована");
        });
    }

    public openDialog(): void {
        const dialog = this.mediaDialog.open<UserNameDialogComponent, void>(UserNameDialogComponent, {
            minWidth: "360px",
            maxWidth: "98vw",
        });
        dialog.afterClosed().subscribe(() => {
            (this.dialogButton as any)._elementRef.nativeElement.classList.remove("cdk-program-focused");
        });
    }

}
