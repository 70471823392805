import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from "@angular/core";
import {EventSourceParam} from "../../../../../services/settings/widget-settings.service";
import {WidgetEventSourceParamControl} from "../../widget-edit-data-params/widget-edit-data-params.component";
import {
    EventSourceParamList
} from "../../../../../../../../shared/src/lib/models/widget-props";
import {MatOption} from "@angular/material/core";
import {AbstractControl, FormControl, ValidationErrors} from "@angular/forms";
import {Util} from "../../../../../../../../shared/src/lib/util";

@Component({
    selector: "app-widget-edit-event-source-param-list",
    templateUrl: "./widget-edit-event-source-param-list.component.html",
    styleUrls: ["./widget-edit-event-source-param-list.component.scss"]
})
export class WidgetEditEventSourceParamListComponent implements OnChanges, WidgetEventSourceParamControl<EventSourceParamList<string>> {
    @Input()
    public param: EventSourceParam<EventSourceParamList<string>> = null;

    @Output()
    public paramChanged = new EventEmitter<EventSourceParam<EventSourceParamList<string>>>();

    @ViewChild("selectAll", {static: true})
    private selectAll: MatOption;

    public formControl: FormControl = new FormControl<Array<string>>(
        [],
        (control: AbstractControl): ValidationErrors => {
            if (control.value.length < 1) {
                return {selectedCount: true};
            }
            return null;
        }
    );

    public ngOnChanges(changes: SimpleChanges) {
        const formValue = [];
        if (this.param.value.selectedItems.length === 0) {
            this.param.value.selectedItems = this.param.items$.value.map(i => i.value);
        }
        if (this.param.value.selectedItems.length === this.param.items$.value.length) {
            formValue.push("all");
        }
        formValue.push(...this.param.value.selectedItems);
        this.formControl.setValue(formValue);
    }

    public onClose() {
        if (!this.formControl.valid) {
            return;
        }
        this.param.value.selectedItems = this.formControl.value.filter(v => v !== "all");
        this.paramChanged.emit({...this.param});
    }

    public tosslePerOne() {
        if (this.selectAll.selected) {
            this.selectAll.deselect();
            return false;
        }
        if (this.formControl.value.length === this.param.items$.value.length) {
            this.selectAll.select();
        }
    }

    public toggleAllSelection() {
        if (this.selectAll.selected) {
            this.formControl.setValue(["all", ...this.param.items$.value.map(v => v.value)]);
        } else {
            this.formControl.setValue([]);
        }
    }

    public triggerText(selectedNumber: number): string {
        if (this.formControl.value.length === this.param.items$.value.length + 1) {
            return "Все награды";
        } else {
            return [
                Util.declineNumeral(selectedNumber, ["Выбрана", "Выбрано", "Выбрано"]),
                selectedNumber,
                Util.declineNumeral(selectedNumber, ["награда", "награды", "наград"]),
            ].join(" ");
        }
    }

}
