import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone,
    OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren
} from "@angular/core";
import {FinanceService} from "../../../services/finance/finance.service";
import * as moment from "moment";
import {BehaviorSubject, combineLatest, fromEvent, Subject} from "rxjs";
import {AuthService} from "../../../../../../shared/src/lib/auth.service";
import {
    ConfirmationModalComponent
} from "../../../../../../shared/src/lib/confirmation-modal/confirmation-modal.component";
import {EnvironmentService} from "../../../../../../shared/src/lib/environment.service";
import {HttpClient} from "@angular/common/http";
import {PlatformInfoService} from "../../../../../../shared/src/lib/platform-info.service";
import {EventsFilterPopupComponent} from "./events-filter-popup/events-filter-popup.component";
import {StreamerEvent, WithdrawalEvent} from "../../../services/events/streamer-event";
import {StreamerEventsService} from "../../../services/events/streamer-events.service";
import {SSEService} from "../../../services/sse.service";
import {StreamerEventType} from "../../../services/events/streamer-events.api";
import {delay, distinctUntilChanged, filter, map, shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {MatMenuTrigger} from "@angular/material/menu";

enum ControlState {
    Initializing = 1,
    Empty = 2,
    HasData = 3,
    DataExhausted = 4,
}

@Component({
    selector: "app-transaction-list",
    templateUrl: "./transaction-list.component.html",
    styleUrls: ["./transaction-list.component.scss"],
})
export class TransactionListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output()
    public readonly listScroll = new EventEmitter<[number, number]>();

    public readonly eventsByDate: EventsByDate[] = [];

    public topEventsContainer: EventsByDate = null;

    public readonly state$ = new BehaviorSubject<ControlState>(ControlState.Initializing);

    public readonly isEmptyState$ = this.state$.pipe(
        map(state => state === ControlState.Empty),
        distinctUntilChanged(),
        tap(() => requestAnimationFrame(() => this.ngZone.run(() => this.changeDetector.detectChanges()))),
        shareReplay(1),
    );

    public readonly isInitState$ = this.state$.pipe(
        map(state => state === ControlState.Initializing),
        distinctUntilChanged(),
        tap(() => requestAnimationFrame(() => this.ngZone.run(() => this.changeDetector.detectChanges()))),
        shareReplay(1),
    );

    private readonly listLoaderTriggerVisible$ = new BehaviorSubject<boolean>(true);
    private readonly listLoaderTrigger$ = combineLatest([this.listLoaderTriggerVisible$, this.authService.authStatus$]).pipe(
        filter(([, isAuthenticated]) => isAuthenticated),
        map(([listLoaderTriggerVisible]) => listLoaderTriggerVisible),
        distinctUntilChanged(),
        filter(Boolean),
    );
    private readonly listLoaderIntersectionObserver = new IntersectionObserver(entries => {
        this.listLoaderTriggerVisible$.next(entries[0].intersectionRatio > 0);
    });

    private readonly destroy$: Subject<void> = new Subject<void>();

    public constructor(private readonly authService: AuthService,
                       private readonly changeDetector: ChangeDetectorRef,
                       private readonly environmentService: EnvironmentService,
                       private readonly financeService: FinanceService,
                       private readonly httpClient: HttpClient,
                       public readonly platformInfoService: PlatformInfoService,
                       private readonly streamerEventsService: StreamerEventsService,
                       private readonly sseService: SSEService,
                       private readonly ngZone: NgZone) {
    }

    public ngOnInit() {
        this.filterPopup.settingsChanged.pipe(takeUntil(this.destroy$)).subscribe(() =>
            this.eventsByDate.forEach(eventByDate => eventByDate.filter(this.filterPopup.settings.enabledEvents))
        );
        this.destroy$.subscribe(() => this.listLoaderIntersectionObserver.disconnect());
    }

    @ViewChildren(MatMenuTrigger)
    public filterTrigger: QueryList<MatMenuTrigger>;

    public ngAfterViewInit(): void {
        this.authService.authStatus$
            .pipe(filter<boolean>(Boolean), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(async () => {
                await this.loadNewDataIfTriggered();
                await this.initComponent();
            });

        this.listLoaderTrigger$.subscribe(() => this.loadNewDataIfTriggered());

        this.filterTrigger.changes.pipe(takeUntil(this.destroy$)).subscribe(items => items.forEach(item =>
            item.menuOpened.pipe(delay(20)).subscribe(() =>
                fromEvent(document, "click").pipe(take(1)).subscribe(() =>
                    item.closeMenu()))));

        this.listLoaderIntersectionObserver.observe(this.listLoaderTrigger.nativeElement);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public onRetryClick(event: StreamerEvent): void {
        this.eventToRetry = event;
        this.retryDialog.show();
    }

    public onScrolled(): void {
        const isAtTop = (this.lastScrollY === 0);

        const currentScrollPos = this.eventsByDateList.nativeElement.scrollTop;
        const scrollDelta = (currentScrollPos - this.lastScrollY);
        this.lastScrollY = currentScrollPos;

        if (this.listScrolledTimeoutId) {
            this.scrollDeltaAccum += scrollDelta;
            return;
        }

        if (isAtTop) {
            window.requestAnimationFrame(() => {
                this.processScrollEvent();
            });
            return;
        }

        this.listScrolledTimeoutId = setTimeout(() => this.processScrollEvent(), 200) as any;
    }

    public retryDonation(): void {
        let url = this.environmentService.backendApiUri;
        url += "/events/";
        url += this.eventToRetry.id;
        url += "/repeat";

        this.httpClient
            .post(url, {}, this.authService.makeTokenAuthHeaders())
            .subscribe();
    }

    public trackEventsByDateBy(index: number, eventsByDate: EventsByDate) {
        return eventsByDate.id;
    }

    private async addNewStreamerEvents(events: StreamerEvent[]): Promise<void> {
        if (events.length === 0) {
            return;
        }

        // add transactions to the respective containers

        const containersToUpdate = new Set<EventsByDate>();

        for (const item of events) {
            const container = this.findEventsContainerOrCreate(item.date);
            container.addEvent(item);

            containersToUpdate.add(container);
        }

        // remove suspicious withdrawals for the corresponding successful payouts

        events
            .filter(e => (e.type === StreamerEventType.DonattyPayout))
            .map(e => e as WithdrawalEvent)
            .map(e => e.orderId)
            .forEach(orderId => {
                this.eventsByDate.forEach(
                    container => container.removeSuspiciousPayoutEventByOrderId(orderId));
            });

        // update container total values

        const tasks: Promise<void>[] = [];

        containersToUpdate.forEach(c => {
            c.filter(this.filterPopup.settings.enabledEvents);
            tasks.push(c.updateTotal(this.financeService));
        });
        await Promise.all(tasks);
    }

    private findEventsContainerOrCreate(date: moment.Moment): EventsByDate {
        let index = 0;
        for (; index < this.eventsByDate.length; ++index) {
            const container = this.eventsByDate[index];
            const isSameDay = date.isSame(container.date, "day");
            if (isSameDay) {
                // we're found the existing container for the date
                return container;
            }

            const isNextDayOrFurther = (date > container.date);
            if (isNextDayOrFurther) {
                // insert the new container right at the current index
                break;
            }
        }

        // we didn't found the suitable existing container so let's create the new one

        const newContainer = new EventsByDate(date);

        if (index === this.eventsByDate.length) {
            this.eventsByDate.push(newContainer);
        } else {
            this.eventsByDate.splice(index, 0, newContainer);
        }

        return newContainer;
    }

    private async initComponent(): Promise<void> {
        this.setTopDateItemStyle();

        this.streamerEventsService.streamerEvents$.subscribe(event => this.onStreamerEvent(event));
        if (!this.platformInfoService.isDesktop) {
            this.watchForWakeup();
        }
        this.watchForReconnect();
    }

    private watchForWakeup() {
        const interval = 3000;
        const threshold = 9000;
        let actualTime;
        let lastTime = Date.now();
        const zone = this.ngZone;
        setInterval(async () => {
            actualTime = Date.now();
            if (actualTime - lastTime > threshold) {
                await zone.run(() => this.reloadLastEvents());
            }
            lastTime = actualTime;
        }, interval);
    }

    private watchForReconnect() {
        const zone = this.ngZone;
        fromEvent(window, "online").pipe(takeUntil(this.destroy$)).subscribe(async () =>
            await zone.run(() => this.reloadLastEvents())
        );
    }

    private async reloadLastEvents(token: string = null) {
        const [events, newToken] = await this.streamerEventsService.getEvents(token, 10);
        const containersToUpdate = new Set<EventsByDate>();
        let allNew = true;
        for (const event of events) {
            const container = this.findEventsContainerOrCreate(event.date);
            const eventExists = container.getEvents().findIndex(e => e.id === event.id) > -1;
            if (eventExists) {
                allNew = false;
                continue;
            }
            container.addEvent(event);
            containersToUpdate.add(container);
        }
        if (!containersToUpdate.size) {
            return;
        }
        const tasks: Promise<void>[] = [];
        containersToUpdate.forEach(c => tasks.push(c.updateTotal(this.financeService)));
        await Promise.all(tasks);
        if (!allNew) {
            return this.reloadLastEvents(newToken);
        }
    }

    private async loadNewDataIfTriggered(): Promise<void> {
        const cancelLoading = (
            this.isLoadingData ||
            (this.state$.value === ControlState.DataExhausted) ||
            (this.state$.value !== ControlState.Initializing && !this.listLoaderTriggerVisible$.value));
        if (cancelLoading) {
            return;
        }

        this.isLoadingData = true;

        // load the next page and save the token
        const [streamerEvents, token] = await this.streamerEventsService.getEvents(this.nextPageToken, 20);
        this.nextPageToken = token;
        console.log("streamerEvents received", streamerEvents);

        if (streamerEvents.length === 0) {
            // looks like we've reached the end of the streamerEvents list
            // or there are no streamerEvents at all yet

            if (this.eventsByDate.length === 0) {
                // there are no streamerEvents yet, display empty list
                this.state$.next(ControlState.Empty);
            } else {
                // we've reached the end of streamerEvents list
                this.state$.next(ControlState.DataExhausted);
            }

            return;
        }

        await this.addNewStreamerEvents(streamerEvents);

        this.state$.next(ControlState.HasData);

        return await new Promise<void>(resolve => {
            // need to do that for some reason, Angular doesn't detect changes otherwise
            this.changeDetector.detectChanges();

            // load the next portion of data if the bottom of the list is still visible
            this.isLoadingData = false;
            const SPARCING_TIMEOUT = 300;
            setTimeout(
                async () => {
                    await this.loadNewDataIfTriggered();
                    resolve();
                },
                SPARCING_TIMEOUT);
        });
    }

    private processScrollEvent(): void {
        this.listScroll.emit([this.lastScrollY, this.scrollDeltaAccum]);
        this.scrollDeltaAccum = 0;
        this.listScrolledTimeoutId = 0;

        if (this.isWaitingToCheckLoadingTriggerVisibility) {
            return;
        }

        this.isWaitingToCheckLoadingTriggerVisibility = true;

        window.requestAnimationFrame(async () => {
            this.setTopDateItemStyle();
            this.isWaitingToCheckLoadingTriggerVisibility = false;
        });
    }

    private async onStreamerEvent(event: StreamerEvent): Promise<void> {
        console.log("next streamer event arrived", event);

        await this.addNewStreamerEvents([event]);

        if (this.state$.value === ControlState.Empty) {
            this.state$.next(ControlState.HasData);
        }

        this.setTopDateItemStyle();

        // need to do that for some reason, Angular doesn't detect changes otherwise
        this.changeDetector.detectChanges();
    }

    private setTopDateItemStyle(): void {
        let topTransactionsItem: EventsByDate =
            (this.eventsByDate?.length ? this.eventsByDate[0] : null);

        if (!this.eventsByDateList?.nativeElement) {
            this.topEventsContainer = topTransactionsItem;
            return;
        }

        const listRect = this.eventsByDateList.nativeElement.getBoundingClientRect() as DOMRect;

        const items: NodeListOf<HTMLElement> = this.eventsByDateList.nativeElement.querySelectorAll(".tl__item--date");

        if (!items.length) {
            this.topEventsContainer = topTransactionsItem;
            return;
        }

        items.forEach(item => {
            const itemRect = item.getBoundingClientRect();
            if (itemRect.top >= listRect.top) {
                return;
            }

            const transactionsIndex = +item.dataset.transactionsIndex;
            topTransactionsItem = this.eventsByDate[transactionsIndex];
        });

        this.topEventsContainer = topTransactionsItem;
    }

    @ViewChild("filterPopup", {static: true})
    public filterPopup: EventsFilterPopupComponent;

    @ViewChild("retryDialog", {static: true})
    private retryDialog: ConfirmationModalComponent;

    @ViewChild("eventsByDateList")
    private eventsByDateList: ElementRef;

    @ViewChild("listLoaderTrigger")
    private listLoaderTrigger: ElementRef;

    private eventToRetry: StreamerEvent;
    private nextPageToken: string = null;
    private isWaitingToCheckLoadingTriggerVisibility = false;
    private isLoadingData = false;
    private lastScrollY = 0;
    private listScrolledTimeoutId = 0;
    private scrollDeltaAccum = 0;
}

// --------------------------------
// EventsByDate

export class EventsByDate {
    private static _lastId = 0;
    public readonly id = EventsByDate._lastId++;
    public total = 0;

    public constructor(public readonly date: moment.Moment) {
    }

    public addEvent(streamerEvent: StreamerEvent): void {
        this.events.push(streamerEvent);
        this.events.sort((a, b) => b.date.diff(a.date));
        this.filter(this.eventsTypesFilter);
    }

    public getEvents(): StreamerEvent[] {
        return this.events;
    }

    public filter(eventTypes: StreamerEventType[]): void {
        this.eventsTypesFilter = eventTypes;

        if (this.eventsTypesFilter.length) {
            this.filteredEvents = this.events.filter(event => this.eventsTypesFilter.indexOf(event.type) >= 0);
        } else {
            this.filteredEvents = this.events;
        }

        this.hasFilteredEvents = !!this.filteredEvents.length;
    }

    public removeSuspiciousPayoutEventByOrderId(orderId?: number): void {
        for (let index = 0; index < this.events.length;) {
            const event = this.events[index];

            if (event.type !== StreamerEventType.DonattyPayoutSuspicious) {
                ++index;
                continue;
            }

            const withdrawalEvent = event as WithdrawalEvent;
            if (withdrawalEvent.orderId !== orderId) {
                ++index;
                continue;
            }

            this.events.splice(index, 1);
        }

        this.filter(this.eventsTypesFilter);
    }

    public async updateTotal(financeService: FinanceService): Promise<void> {
        if (this.events.length === 0) {
            return;
        }

        // TODO: bullshit code, rewrite it
        const startFrom: Date = this.events[0].date.toDate();
        const utcOffset = startFrom.getTimezoneOffset();
        startFrom.setHours(0, 0, 0, 0);
        const endTo: Date = moment(startFrom).clone().toDate();
        endTo.setHours(23, 59, 59, 999);
        const statistics: { fee: number, feeCommission: number }[] = await financeService.getStatistics("DAY", startFrom, endTo, utcOffset);
        if (statistics.length === 0) {
            return;
        }

        this.total = Math.max(0, statistics[0].fee - statistics[0].feeCommission);
    }

    private readonly events: StreamerEvent[] = [];
    public hasFilteredEvents = true;
    public filteredEvents: StreamerEvent[] = [];
    public eventsTypesFilter: StreamerEventType[] = [];
}
