import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from "@angular/router";

export class CustomReusingStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data?.reuse?.length > 0;
    }

    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.data.reuse, handle);
    }

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return (route.data?.reuse?.length > 0) && !!this.storedRoutes.get(route.data.reuse);
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRoutes.get(route.data.reuse);
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === current.routeConfig;
    }

}
