import {Component} from "@angular/core";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {Toast, ToastPackage, ToastrService} from "ngx-toastr";
import {IndividualConfig} from "ngx-toastr/toastr/toastr-config";

export interface ToastConfig extends IndividualConfig {
    action: string;
}

@Component({
    selector: "[toast-component]",
    styleUrls: ["./toast.component.scss"],
    templateUrl: "./toast.component.html",
    animations: [
        trigger("flyInOut", [
            state("inactive", style({
                maxHeight: "0",
                marginBottom: "0",
                opacity: "0",
            })),
            transition("inactive => active",
                animate("400ms ease-in-out", keyframes([
                    style({
                        maxHeight: "0",
                        marginBottom: "0",
                        opacity: "0",
                    }),
                    style({
                        maxHeight: "44px",
                        marginBottom: "8px",
                        opacity: "1",
                    }),
                ]))),
            transition("active => removed",
                animate("400ms ease-in-out", keyframes([
                    style({
                        maxHeight: "44px",
                        marginBottom: "8px",
                        opacity: "1",
                    }),
                    style({
                        maxHeight: "0",
                        marginBottom: "0",
                        opacity: "0",
                    }),
                ]))),
        ]),
    ],
    preserveWhitespaces: false,
})
export class ToastComponent extends Toast {

    public options: ToastConfig;

    // constructor is only necessary when not using AoT
    constructor(protected toastrService: ToastrService,
                public toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }

    public action() {
        this.toastPackage.triggerAction();
        this.close();
    }

    public close() {
        this.toastrService.clear(this.toastPackage.toastId);
    }
}
