export enum EventCategory {
    Donation = "donation",
    Identification = "uprid",
    Panel = "panel",
    Widget = "widget",
    Withdraw = "withdraw",
    Onboarding = "quest-block",
}

export enum EventAction {
    Create = "create",
    CreateGroup = "create-group",
    CopyGroup = "copy-group",
    Copy = "copy",
    Delete = "delete",
    Login = "login",
    Logout = "logout",
    Registration = "registration",
    View = "view",
    Update = "update",
    MarkedDone = "marked-done",
    MarkedUndone = "marked-undone",
    Closed = "closed",
}

export interface AnalyticsEvent {
    eventCategory: EventCategory;
    eventAction: EventAction;
    eventLabel: string;
    eventValue?: any;
}
