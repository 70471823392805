import {Injectable} from "@angular/core";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {HttpClient} from "@angular/common/http";
import {WidgetDimensions} from "../../components/widgets/edit/widget-edit.component";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";

export interface WidgetStoreRecord {
    dimensions: WidgetDimensions;
}

@Injectable({
    providedIn: "root"
})
export class WidgetStoreService {
    private readonly baseUrl = this.environmentService.backendApiUri + "/users/store/widgets/";
    public readonly defaultState: WidgetStoreRecord = {
        dimensions: {
            width: 800,
            height: 600,
            autoDetect: false,
        },
    };

    public constructor(private readonly environmentService: EnvironmentService,
                       private readonly authService: AuthService,
                       private readonly http: HttpClient) {
    }

    public loadWidgetStore(widgetId: string) {
        if (!widgetId) {
            throw new Error("Widget id required");
        }
        return this.http.get<WidgetStoreRecord>(this.baseUrl + widgetId, this.authService.makeTokenAuthHeaders()).pipe(
            catchError(e => {
                if (e.status !== 404) {
                    throw e;
                }
                return of(this.defaultState);
            })
        );
    }

    public saveWidgetStore(widgetId: string, widgetRecord: WidgetStoreRecord) {
        if (!widgetId) {
            throw new Error("Widget id required");
        }
        if (!widgetRecord) {
            throw new Error("Widget record required");
        }
        return this.http.put(this.baseUrl + widgetId, widgetRecord, this.authService.makeTokenAuthHeaders());
    }
}
