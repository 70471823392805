import {Inject, Injectable, LOCALE_ID, OnDestroy} from "@angular/core";
import {fromEvent, merge, Observable, of, Subject, switchMap} from "rxjs";
import {
    catchError, concatMap, distinctUntilChanged, filter, map, share,
    shareReplay, takeUntil, tap, withLatestFrom, take, delay,
} from "rxjs/operators";
import {ModerationNotificationsComponent} from "./moderation-alerts/moderation-notifications.component";
import {Overlay, OverlayConfig} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {SSEMessage} from "../../../../../shared/src/lib/common";
import {SSEService} from "../../services/sse.service";
import {ToastrService} from "ngx-toastr";
import {UuidService} from "../../../../../shared/src/lib/services/uuid/uuid.service";
import {WidgetPreviewService} from "../../services/widgets/widget-preview.service";
import {Util} from "projects/shared/src/lib/util";
import {UserService} from "../../services/user/user.service";
import {PushNotificationService} from "../notification/push-notification.service";
import firebase from "firebase/compat";
import MessagePayload = firebase.messaging.MessagePayload;

enum ModerationEventType {
    Donation = "MODERATION/DONATION",
}

export enum ModerationAction {
    Allow = "ALLOW",
    Deny = "DENY",
}

export const moderationActionText: { [key in ModerationAction]: string } = {
    [ModerationAction.Allow]: "Отклонить",
    [ModerationAction.Deny]: "Принять",
};

export interface ModerationSettings {
    timeoutSecs: number;
    action: ModerationAction;
    isAlertSoundEnabled: boolean;
    push: {
        isEnabled: boolean;
        isAlertSoundEnabled: boolean;
    };
}

export interface ModerationEvent {
    id: string;
    eventId: string;
    action: ModerationAction;
    amount: number;
    message: string;
    name: string;
    timeoutSecs: number;
}

export interface ModerationAct {
    event: ModerationEvent;
    action: ModerationAction;
}

@Injectable({
    providedIn: "root"
})
export class ModerationService implements OnDestroy {

    public readonly moderationAct$: Subject<ModerationAct> = new Subject<ModerationAct>();
    private readonly baseUrl = `${this.environmentService.backendApiUri}/moderation/`;
    private readonly destroy$ = new Subject<void>();

    private readonly settingsSaved$ = new Subject<ModerationSettings>();
    public readonly settings$: Observable<ModerationSettings> = this.userService.currentUserWithPermissions$.pipe(
        switchMap(() => merge(this.getSettings(), this.settingsSaved$))
    ).pipe(shareReplay(1), takeUntil(this.destroy$));

    private readonly sseModeration$ = this.sseService.message$.pipe(
        filter<SSEMessage<ModerationEvent>>(m => m.type === ModerationEventType.Donation),
        map(m => m.event),
    );
    private readonly pushModeration$ = this.pushNotificationService.pushMessage$.pipe(
        filter(message =>
            message.data?.id?.length > 0 &&
            Object.values(ModerationAction).includes(message.data.action as ModerationAction)
        ),
        map<MessagePayload, ModerationEvent>(message => ({
            id: message.data.id,
            eventId: message.data.eventId,
            action: message.data.action as ModerationAction,
            name: message.data?.name,
            message: message.data?.message,
            amount: +message.data?.amount ?? 0,
            timeoutSecs: +message.data?.timeoutSecs,
        })),
    );
    public readonly eventToModerate$: Observable<ModerationEvent> = merge(
        this.sseModeration$, this.pushModeration$
    ).pipe(
        withLatestFrom(this.settings$),
        filter(([, settings]) => settings.timeoutSecs > 0),
        map(([e]) => e),
        share(),
    );

    private readonly playSound$: Observable<ModerationEvent> = this.eventToModerate$.pipe(
        withLatestFrom(this.settings$),
        filter(([, settings]) => settings.isAlertSoundEnabled),
        map(([e]) => e),
        takeUntil(this.destroy$),
    );

    private readonly userAction$ = merge(
        fromEvent(window, "mousedown"),
        fromEvent(window, "touchend"),
    ).pipe(take(1), takeUntil(this.destroy$));

    private readonly audioContext$: Observable<AudioContext> = this.userAction$.pipe(
        delay(100),
        map(() => new (window.AudioContext || (window as any).webkitAudioContext)() as AudioContext),
        take(1),
        takeUntil(this.destroy$),
    );

    private readonly beepTrigger$: Subject<BeepParams> = new Subject<BeepParams>();

    public constructor(@Inject(LOCALE_ID) private readonly locale: string,
                       private readonly overlay: Overlay,
                       private readonly http: HttpClient,
                       private readonly userService: UserService,
                       private readonly authService: AuthService,
                       private readonly sseService: SSEService,
                       private readonly toastr: ToastrService,
                       private readonly widgetPreviewService: WidgetPreviewService,
                       private readonly uuidService: UuidService,
                       private readonly pushNotificationService: PushNotificationService,
                       private readonly environmentService: EnvironmentService) {
        this.initNotificationsOverlay();
        this.playSound$.subscribe(() => this.playSound());
        this.audioContext$.pipe(
            switchMap(ctx => this.beepTrigger$.pipe(
                map<BeepParams, [AudioContext, BeepParams]>(params => [ctx, params]),
            )),
            takeUntil(this.destroy$),
        ).subscribe(([ctx, params]) => this.beep(ctx, params));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public getSettings() {
        return this.http.get<ModerationSettings>(this.baseUrl + "settings", this.authService.makeTokenAuthHeaders());
    }

    public saveSettings(settings: ModerationSettings) {
        return this.http.post<ModerationSettings>(this.baseUrl + "settings", settings, this.authService.makeTokenAuthHeaders())
            .pipe(tap(() => this.settingsSaved$.next(settings)));
    }

    private act(event: ModerationEvent) {
        const actionMap: { [key in ModerationAction]: ModerationAction } = {
            [ModerationAction.Allow]: ModerationAction.Deny,
            [ModerationAction.Deny]: ModerationAction.Allow,
        };
        const url = this.baseUrl + event.id;
        const body = {action: actionMap[event.action]};
        return this.http.post(url, body, this.authService.makeTokenAuthHeaders()).pipe(
            catchError(e => {
                this.toastr.error(`Ошибка модерации ${body.action} "${event.id}": ${e.status}`);
                return of(null);
            }),
            tap(() => this.moderationAct$.next({action: body.action, event})),
            map(() => event),
        );
    }

    public playSound() {
        this.beepTrigger$.next({
            frequency: 261.6256,
            volume: .5,
            duration: .5,
        });
    }

    private readonly moderationItems: Array<ModerationEvent> = [];
    private readonly logout$ = this.authService.authStatus$.pipe(
        filter(isAuthed => !isAuthed),
        takeUntil(this.destroy$),
    );

    private initNotificationsOverlay() {
        const listOverlay = this.overlay.create(new OverlayConfig({
            hasBackdrop: false,
            panelClass: "moderation-alerts",
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy: this.overlay.position().global().left("0").bottom("0"),
        }));
        this.destroy$.pipe(take(1)).subscribe(() => listOverlay.dispose());
        const notificationsComponentRef = listOverlay.attach(
            new ComponentPortal<ModerationNotificationsComponent>(ModerationNotificationsComponent)
        );
        this.logout$.subscribe(() => {
            this.moderationItems.length = 0;
            notificationsComponentRef.setInput("items", this.moderationItems);
        });
        this.eventToModerate$.pipe(takeUntil(this.destroy$)).subscribe(event => {
            this.moderationItems.push(event);
            notificationsComponentRef.setInput("items", this.moderationItems);
        });
        this.settings$.pipe(takeUntil(this.destroy$)).subscribe(settings =>
            notificationsComponentRef.setInput("settings", settings)
        );

        const act$: Observable<ModerationEvent> = notificationsComponentRef.instance.moderate.pipe(
            distinctUntilChanged((prev, cur) => prev.id === cur.id),
            concatMap(event => this.act(event)),
        );
        const ignore$: Observable<ModerationEvent> = notificationsComponentRef.instance.ignore;

        merge(act$, ignore$).pipe(
            map(event => this.moderationItems.findIndex(e => e === event)),
            tap(itemIndex => this.moderationItems.splice(itemIndex, 1)),
            tap(() => notificationsComponentRef.setInput("items", this.moderationItems)),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private beep(ctx: AudioContext, params: BeepParams): Promise<Event> {
        return new Promise((resolve, reject) => {
            try {
                const amplifier = ctx.createGain();
                amplifier.connect(ctx.destination);
                amplifier.gain.value = .001;
                amplifier.gain.exponentialRampToValueAtTime(params.volume, ctx.currentTime + params.duration * .05);
                amplifier.gain.exponentialRampToValueAtTime(0.001, ctx.currentTime + params.duration);

                const oscillator = ctx.createOscillator();
                oscillator.connect(amplifier);
                oscillator.type = "sine";
                oscillator.frequency.value = params.frequency;
                oscillator.frequency.exponentialRampToValueAtTime(params.frequency * 2, ctx.currentTime + params.duration);
                oscillator.start(ctx.currentTime);
                oscillator.stop(ctx.currentTime + params.duration);
                oscillator.onended = resolve;
            } catch (error) {
                console.error("Beep error");
                reject(error);
            }
        });
    }

    public sendTest() {
        this.settings$.pipe(take(1)).subscribe(settings => {
            this.sseService.message$.next(this.buildTestMessage(settings));
        });
    }

    private buildTestMessage(settings: ModerationSettings): SSEMessage<ModerationEvent> {
        const event: ModerationEvent = {
            id: this.uuidService.genUuid(),
            eventId: this.uuidService.genUuid(),    // TODO: Get real id from the list
            action: settings.action,
            amount: Math.round(10 + Math.random() * 1000),
            message: Util.randomMessage() + [" с матюгами", " без матюгов"][Math.round(Math.random())],
            name: "Никнейм тестового чела",
            timeoutSecs: settings.timeoutSecs,
        };
        return {type: "MODERATION/DONATION", event};
    }

}

interface BeepParams {
    duration: number;
    volume: number;
    frequency: number;
}
