import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvironmentService} from "../../environment.service";
import {AuthService} from "../../auth.service";
import {shareReplay, tap} from "rxjs/operators";

interface ExternalFontInfo {
    family: string;
    uri: string;
}

@Injectable({
    providedIn: "root"
})
export class ExternalFontsService {

    public readonly fonts$: Observable<Array<ExternalFontInfo>> = this.loadList().pipe(
        tap(list => this.addFontCssLinks(list)),
        shareReplay(1),
    );

    public constructor(private readonly authService: AuthService,
                       private readonly environmentService: EnvironmentService,
                       private readonly httpClient: HttpClient) {
    }

    private addFontCssLinks(list: ExternalFontInfo[]): void {
        const head = window.document.head;

        const familyId = (font: ExternalFontInfo) =>
            ("external-fonts-" + font.family.replace(" ", "-"));

        list.filter(font => !document.getElementById(familyId(font)))
            .map(font => {
                const el = document.createElement("link") as HTMLLinkElement;
                el.rel = "stylesheet";
                el.href = font.uri;
                el.id = familyId(font);
                return el;
            })
            .forEach(el => head.appendChild(el));
    }

    private loadList(): Observable<Array<ExternalFontInfo>> {
        const baseUri = `${this.environmentService.backendApiUri}/fonts`;
        const authHeaders = this.authService.makeTokenAuthHeaders();
        return this.httpClient.get<ExternalFontInfo[]>(baseUri, authHeaders);
    }

}

