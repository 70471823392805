<div class="identity-info">
    <h5 class="white">После идентификации</h5>
    <div class="items">
        <section *ngFor="let item of items$ | async">
            <div>
                <span class="title">{{item.title}}</span>
                <div class="subtitle">{{item.subtitle}}</div>
            </div>
            <h5>{{item.amount | currency:currency:'symbol':'1.0'}}</h5>
        </section>
    </div>
</div>
