import {Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import * as moment from "moment";
import {LocaleConfig, DaterangepickerDirective} from "ngx-daterangepicker-material";

moment.locale("ru");

@Component({
    selector: "calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.scss"]
})
export class CalendarComponent implements OnInit, OnChanges {

    @Input() selected: string;
    @Output() selectedChange = new EventEmitter<string>();

    @ViewChild(DaterangepickerDirective, {static: true})
    pickerDirective: DaterangepickerDirective;

    value;
    lastSelected: string;

    constructor() {
    }

    locale: LocaleConfig = {
        format: "DD.MM.YYYY HH:mm",
        applyLabel: "Выбрать",
        customRangeLabel: " - ",
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek(),
    };

    ngOnInit() {
        this.value = { start: moment(this.selected), end: moment(this.selected) };
        this.lastSelected = this.selected;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.selected || changes.selected.isFirstChange()) {
            return;
        }

        const newSelected = changes.selected.currentValue;
        if (this.lastSelected === newSelected) {
            return;
        }

        this.value = { start: moment(newSelected), end: moment(newSelected) };
        this.lastSelected = newSelected;
    }

    changed(event) {
        if (!event || !event.end) {
            return;
        }

        this.selectedChange.emit(event.end.toISOString());
    }

    click() {
        this.pickerDirective.open();
    }
}
