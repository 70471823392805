<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="moderate-judge"></mat-icon>
</button>
<mat-menu #menu="matMenu" [hasBackdrop]="true" class="moderation-menu">
    <div class="moderation-menu-click-fix" (click)="$event.stopPropagation()">
        <span *ngIf="environmentService.isDevBuild" class="test-moderation" (click)="test()">🧪</span>
        <h6>Модерация алертов о донатах</h6>
        <form [formGroup]="form">
            <div class="label">
                <span>Время премодерации алерта</span>
                <span class="delay">
                    <ng-container *ngIf="timeout.value > 0 else delayOff">{{timeout.value}}&nbsp;сек</ng-container>
                    <ng-template #delayOff>Выкл.</ng-template>
                </span>
            </div>
            <mat-slider #timeout min="0" [max]="maxTimeout" tickInterval="20"
                        formControlName="timeoutSecs">
            </mat-slider>
            <div class="comment">
                <ng-container *ngIf="timeout.value > 0 else noModeration">
                    Премодерация {{timeout.value}} секунд
                </ng-container>
                <ng-template #noModeration>Алерт будет проигрываться автоматически без модерации</ng-template>
            </div>
            <fieldset [hidden]="timeout.value === 0">
                <mat-slide-toggle formControlName="action" [disableRipple]="true">
                    Показывать алерт по истечении времени
                </mat-slide-toggle>
                <mat-slide-toggle formControlName="isAlertSoundEnabled" [disableRipple]="true">
                    Проигрывать звук премодерации
                </mat-slide-toggle>
                <div formGroupName="push" [hidden]="!pushAvailable">
                    <mat-slide-toggle #push formControlName="isEnabled" [disableRipple]="true">
                        Отправлять push-уведомление
                    </mat-slide-toggle>
                    <mat-slide-toggle [hidden]="!push.checked" formControlName="isAlertSoundEnabled"
                                      [disableRipple]="true">
                        <div id="kocherga"></div>
                        со звуком
                    </mat-slide-toggle>
                </div>
            </fieldset>
        </form>
    </div>
</mat-menu>
