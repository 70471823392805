<div class="param__row">
    <div class="column__icon">
        <mat-icon svgIcon="logo-{{param.account.getType()}}"></mat-icon>
    </div>
    <div class="column__descr">
        <div class="descr__param">
            {{param.label}}
        </div>
        <div class="descr__nick">
            {{param.account.getNick()}}
        </div>
    </div>
    <div class="column__range">
        <mat-form-field appearance="outline">
            <mat-select [formControl]="formControl"
                        placeholder="Выберите награды"
                        [multiple]="true"
                        (closed)="onClose()"
                        panelClass="event-source-param-panel"
                        [disableOptionCentering]="true">
                <mat-select-trigger *ngIf="formControl.value.length !== 1">
                    {{triggerText(formControl.value.length)}}
                </mat-select-trigger>
                <mat-option #selectAll value="all" (click)="toggleAllSelection()">Все награды</mat-option>
                <mat-option *ngFor="let paramItem of param.items$ | async"
                            (click)="tosslePerOne()"
                            [value]="paramItem.value"
                            [matTooltip]="paramItem.text"
                            [matTooltipShowDelay]="500">
                    {{paramItem.text}}
                </mat-option>
            </mat-select>
            <mat-hint>Выберите награды за баллы канала.</mat-hint>
            <mat-error *ngIf="formControl.hasError('selectedCount')">
                Выберите награды за баллы канала
            </mat-error>
        </mat-form-field>
    </div>
</div>
