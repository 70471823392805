import {Injectable, OnDestroy} from "@angular/core";
import {merge, Observable, Subject} from "rxjs";
import {ApiWidgetInfo} from "../../../../../shared/src/lib/models/api";
import {WidgetGroupType} from "./widget_groups/widget-group";
import {distinctUntilChanged, filter, map, takeUntil, withLatestFrom} from "rxjs/operators";
import {SSEService} from "../sse.service";
import {WidgetEventType} from "../../../../../shared/src/lib/common/FinancialOperation";
import {WidgetAction, WidgetEventPayload, WidgetService} from "./widget.service";
import {WidgetGroupService} from "./widget_groups/widget-group.service";
import {SSEMessage} from "../../../../../shared/src/lib/common";

interface WidgetOnlineEvent {
    widgetRefId: string;
    isOnline: boolean;
}

interface WidgetRemovedEvent {
    widgetRefId: string;
}

interface WidgetEvent {
    widget: ApiWidgetInfo;
}

@Injectable({
    providedIn: "root"
})
export class WidgetSSEService implements OnDestroy {

    private readonly destroy$: Subject<void> = new Subject<void>();

    private readonly widgetLive$: Observable<WidgetEventPayload> = this.sseService.message$.pipe(
        filter<SSEMessage<WidgetOnlineEvent>>(m => m.type === WidgetEventType.Live),
        withLatestFrom(this.widgetGroupService.widgets$),
        map(([m, widgets]) => ({
            action: WidgetAction.change,
            widget: widgets.find(w => w.getId() === m.event.widgetRefId).setActive(m.event.isOnline),
        })),
    );

    private readonly widgetAdded$: Observable<WidgetEventPayload> = this.sseService.message$.pipe(
        filter<SSEMessage<WidgetEvent>>(m => m.type === WidgetEventType.Add),
        map(m => ({
            action: WidgetAction.create,
            widget: this.widgetService.makeWidgetInfo(m.event.widget),
        })),
    );

    private readonly widgetUpdated$: Observable<WidgetEventPayload> = this.sseService.message$.pipe(
        filter<SSEMessage<WidgetEvent>>(m => m.type === WidgetEventType.Update),
        map(m => ({
            action: WidgetAction.change,
            widget: this.widgetService.makeWidgetInfo(m.event.widget),
        })),
    );

    private readonly widgetRemoved$: Observable<WidgetEventPayload> = this.sseService.message$.pipe(
        filter<SSEMessage<WidgetRemovedEvent>>(m => m.type === WidgetEventType.Remove),
        withLatestFrom(this.widgetGroupService.widgets$),
        map(([m, widgets]) => ({
            action: WidgetAction.remove,
            widget: widgets.find(w => w.getId() === m.event.widgetRefId),
        })),
    );

    private readonly ungroupedGroupId$ = this.widgetGroupService.widgetGroups$.pipe(
        map(groups => groups.find(group => group.type === WidgetGroupType.Ungrouped)?.id),
        filter<string>(Boolean),
        distinctUntilChanged(),
    );

    private readonly widgetEvent$ = merge(this.widgetAdded$, this.widgetUpdated$, this.widgetLive$, this.widgetRemoved$).pipe(
        filter(e => !!e.widget),
        withLatestFrom(this.ungroupedGroupId$),
        map(([e, ungroupedGroupId]) => {
            if (!e.widget.getGroupId()) {
                e.widget.setGroupId(ungroupedGroupId);
            }
            return e;
        }),
        takeUntil(this.destroy$),
    );

    public constructor(private readonly sseService: SSEService,
                       private readonly widgetGroupService: WidgetGroupService,
                       private readonly widgetService: WidgetService) {
        this.widgetEvent$.subscribe(e => this.widgetService.widgetChanged$.next(e));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

}
