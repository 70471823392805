import {Injectable, NgZone, OnDestroy} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {fromEvent, merge, Observable, Subject, switchMap} from "rxjs";
import {filter, map, skip, takeUntil} from "rxjs/operators";
import {EnvironmentService} from "../../../../shared/src/lib/environment.service";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {SSEMessage} from "../../../../shared/src/lib/common";
import {NavigationService} from "./navigation.service";
import {GeneralEventType} from "../../../../shared/src/lib/common/FinancialOperation";

@Injectable({
    providedIn: "root"
})
export class SSEService implements OnDestroy {
    public readonly connect$ = new Subject<void>();
    public readonly message$ = new Subject<SSEMessage>();
    public readonly reconnect$  = new Subject<void>();

    private readonly destroy$: Subject<void> = new Subject<void>();
    private sseSource: EventSource = null;

    private readonly accessDenied$: Observable<SSEMessage> = this.message$.pipe(
        filter(m => m.type === GeneralEventType.AccessDenied),
        takeUntil(this.destroy$),
    );

    private readonly connectTrigger$: Observable<boolean> = merge(
        this.authService.authStatus$,
        fromEvent(window, "offline").pipe(map(() => false)),
        fromEvent(window, "online").pipe(switchMap(() => this.authService.authStatus$)),
    ).pipe(takeUntil(this.destroy$));

    public constructor(private readonly http: HttpClient,
                       private readonly authService: AuthService,
                       private readonly environmentService: EnvironmentService,
                       private readonly navigationService: NavigationService,
                       private readonly ngZone: NgZone) {
        this.connectTrigger$.subscribe(connect => connect ? this.connect() : this.disconnect());
        this.accessDenied$.subscribe(async () => await this.navigationService.navigateToAccountBlockPage());
        this.connect$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => this.reconnect$.next());
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private disconnect() {
        this.sseSource?.close();
        this.sseSource = null;
    }

    private connect(): void {
        const DEBOUNCE_INTERVAL = 5000;

        this.disconnect();

        const url = `${this.environmentService.backendApiUri}/users/me/sse/v2?ngsw-bypass=true&jwt=${this.authService.getToken()}`;
        try {
            this.sseSource = new EventSource(url);
        } catch (e) {
            setTimeout(() => this.connect(), DEBOUNCE_INTERVAL);
            return;
        }

        this.sseSource.onopen = () => {
            console.log("SSE, connection established, url=", url);
            this.connect$.next();
        };

        this.sseSource.onerror = () => {
            this.sseSource.onerror = null;
            this.sseSource.onopen = null;
            setTimeout(() => this.connect(), DEBOUNCE_INTERVAL);
        };

        this.sseSource.onmessage = event => {
            const message = JSON.parse(event.data);
            console.log("SSE, message=", message);
            this.ngZone.run(() => this.message$.next(message));
        };

        console.log("SSE, connection initiated, url=", url);
    }

}
