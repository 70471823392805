import {ErrorStateMatcher} from "@angular/material/core";
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";

/** Error when control is invalid. */
export class ShowAlwaysErrorStateMatcher implements ErrorStateMatcher {
    public isErrorState(control: FormControl<any> | null, form: FormGroupDirective | NgForm | null): boolean {
        return !!(control && control.invalid);
    }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class ShowWhenTouchedErrorStateMatcher implements ErrorStateMatcher {
    public isErrorState(control: FormControl<any> | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
