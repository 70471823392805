<form dense [formGroup]="formGroup">
    <mat-form-field appearance="outline" [hideRequiredMarker]="true">
        <span matPrefix>от</span>
        <input matInput required type="number" formControlName="from" />
        <span matSuffix [innerText]="units"></span>
        <mat-error *ngIf="fromControl.errors">
            <div *ngIf="fromControl.errors.isNaN">должно быть числом</div>
            <div *ngIf="fromControl.errors.required || fromControl.errors.min">должно быть ≥ {{(fromControl.errors.min || min) | number}}</div>
            <div *ngIf="fromControl.errors.max">должно быть ≤ {{fromControl.errors.max | number}}</div>
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" [hideRequiredMarker]="true">
        <span matPrefix>до</span>
        <input matInput placeholder="∞" type="number" formControlName="to" />
        <span matSuffix [innerText]="units"></span>
        <mat-error *ngIf="toControl.errors">
            <div *ngIf="toControl.errors.isNaN">должно быть числом</div>
            <div *ngIf="toControl.errors.min">должно быть ≥ {{toControl.errors.min | number}}</div>
            <div *ngIf="toControl.errors.max">должно быть ≤ {{toControl.errors.max | number}}</div>
        </mat-error>
    </mat-form-field>
</form>
