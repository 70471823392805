import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthService} from "../../../../shared/src/lib/auth.service";
import {tap} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, async (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 403 || (err.status === 401 && !request.url.endsWith("me/security"))) {
                    await this.authService.logout();
                }
            }
        }));
    }
}
