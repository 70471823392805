import {NgModule} from "@angular/core";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {SliderComponent} from "./slider.component";
import {SingleSliderComponent} from "./single/single-slider.component";

@NgModule({
    imports: [
        NgxSliderModule
    ],
    declarations: [
        SliderComponent,
        SingleSliderComponent,
    ],
    exports: [
        SliderComponent,
        SingleSliderComponent
    ],
})
export class SliderModule {
}
