import {
    AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject,
    Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from "@angular/core";
import * as moment from "moment";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {AccountType} from "../../../../services/accounts/accounts";
import {merge, Subject} from "rxjs";
import {AccountsService} from "../../../../services/accounts/accounts.service";
import {
    EventParamType, WidgetSettingsService
} from "../../../../services/settings/widget-settings.service";
import {UserService} from "../../../../services/user/user.service";
import {EventSourceParam} from "../../../../services/settings/widget-settings.service";
import {WidgetType} from "../../../../../../../shared/src/lib/models/widget";
import {get, set} from "lodash";
import {WidgetEditOpenRangeComponent} from "../widget-edit-open-range/widget-edit-open-range.component";
import {
    EventSourceParamOptions, EventSourceParamLimits, GoalWidgetData, TopWidgetData, TotalWidgetData,
    WidgetDataPeriod, WidgetEventSourcesAccountProp, WidgetPropsData, EventSourceParamList
} from "../../../../../../../shared/src/lib/models/widget-props";
import {filter, takeUntil} from "rxjs/operators";
import {FinanceSSEService} from "../../../../services/finance/finance-sse.service";
import {AccountLimits} from "../../../../services/finance/account-limits";
import widgetEditSectionAnimations from "../widget-edit-section.animations";
import {FormControl, FormGroup, Validators} from "@angular/forms";

interface WidgetDataParams {
    header: string;
    description: string;
}

type WidgetsDataParams = {
    [key in WidgetType]: WidgetDataParams;
};

interface TopWidgetDataType {
    value: TopWidgetData["dataType"];
    text: string;
}

interface TopWidgetPeriodType {
    value: TopWidgetData["period"];
    text: string;
}

export interface WidgetEventSourceParamControl<T = any> {
    param: EventSourceParam<T>;
    paramChanged: Subject<EventSourceParam<T>>;
}

@Component({
    selector: "app-widget-edit-data-params",
    templateUrl: "./widget-edit-data-params.component.html",
    styleUrls: ["./widget-edit-data-params.component.scss"],
    animations: widgetEditSectionAnimations,
})
export class WidgetEditDataParamsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input()
    public widgetPropsData: WidgetPropsData;

    @Output()
    public widgetPropsDataChange = new EventEmitter<WidgetPropsData>();

    @Input()
    public type: WidgetType;

    @Input()
    public widgetId: string;

    public readonly WidgetType = WidgetType;

    public dataParamsSettings: WidgetsDataParams = {
        [WidgetType.Alert]: {
            header: "Условия",
            description: `Укажите диапазоны для которых должны появляться оповещения.
                          Каждое условие соответствует одному источнику данных.`,
        },
        [WidgetType.Top]: {
            header: "Данные",
            description: `Выберите тип данных, которые будут отображаться в виджете,
                          и период, за который будут собираться эти данные.`,
        },
        [WidgetType.Goal]: {
            header: "Цель сбора",
            description: `Цель сбора - это то, сколько вы хотите собрать денег.
                          Эта сумма будет максимальной для прогресс-бара виджета.`,
        },
        [WidgetType.Total]: {
            header: "Данные",
            description: `Выберите период, за который будут собираться данные которые
                          будут отображаться в&nbsp;виджете.`,
        },
        [WidgetType.Events]: {
            header: "Условия",
            description: `Укажите диапазоны для которых должны появляться события в списке.
                          Каждое условие соответствует одному источнику данных.`,
        },
        [WidgetType.Media]: {
            header: "??",
            description: `???`,
        }
    };

    public readonly periods: EventSourceParamOptions<TopWidgetData["period"]> = [
        {value: "day", text: "Текущий день"},
        {value: "week", text: "Текущая неделя"},
        {value: "month", text: "Текущий месяц"},
        {value: "year", text: "Текущий год"},
        {value: "all", text: "За всё время"},
        {value: "past24h", text: "За последние 24 часа"},
        {value: "past7d", text: "За последние 7 дней"},
        {value: "past30d", text: "За последние 30 дней"},
    ];

    public readonly topWidgetDataTypes: EventSourceParamOptions<TopWidgetData["dataType"]> = [
        {value: "donators", text: "ТОП донатеров"},
        {value: "donations", text: "ТОП донатов"},
        {value: "donators-chrono", text: "Донатеры хронология"},
        {value: "donations-chrono", text: "Донаты хронология"},
    ];

    public eventSourceParams: Array<EventSourceParam> = [];
    public isBlockShown = [true, true];
    public remainsText = "";
    public alertRangeMin: number;
    public alertRangeMax: number;

    public readonly destroy$: Subject<void> = new Subject();
    public readonly changes$ = new Subject<SimpleChanges>();

    public constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly financeSSEService: FinanceSSEService,
        @Inject(LOCAL_STORAGE)
        private readonly storageService: StorageService,
        public readonly accountsService: AccountsService,
        public readonly widgetSettingsService: WidgetSettingsService,
        public readonly userService: UserService) {
    }

    public readonly goalForm = new FormGroup({
        goal: new FormControl<number>(0, {
            nonNullable: true,
            validators: [
                Validators.required, Validators.min(0), Validators.max(1e11)
            ]
        }),
        goalFrom: new FormControl<number>(0, {
            nonNullable: true,
            validators: [
                Validators.required, Validators.min(0), Validators.max(1e11)
            ]
        }),
    });

    public async ngOnInit(): Promise<void> {
        this.financeSSEService.limits$
            .pipe(filter<AccountLimits>(Boolean), takeUntil(this.destroy$))
            .subscribe(limits => {
                this.alertRangeMin = limits.current.credit.min;
                this.alertRangeMax = limits.current.credit.max;
                this.changeDetectorRef.detectChanges();
            });

        if ((this.widgetPropsData as GoalWidgetData).goalDate !== undefined) {
            this.refreshIntervalId = window.setInterval(() => this.refreshTimeout(), 1000);
        }

        merge(
            this.changes$.pipe(filter(() => !!this.widgetPropsData)),
            this.accountsService.accounts$,
            this.widgetSettingsService.eventSourcePropsChanged$
        ).pipe(takeUntil(this.destroy$)).subscribe(() => this.setGenericEventSourceParams());

        if (this.type === WidgetType.Goal) {
            this.initGoalForm();
        }

        this.setGenericEventSourceParams();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.changes$.next(changes);
    }

    public ngAfterViewInit() {
        for (const blockIndex of [...Array(2).keys()]) {
            if (!this.storageService.has(this.formatStorageKey(blockIndex))) {
                continue;
            }

            this.isBlockShown[blockIndex] =
                this.storageService.get(
                    this.formatStorageKey(blockIndex)) as boolean;
        }
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        if (this.refreshIntervalId !== null) {
            clearTimeout(this.refreshIntervalId);
        }
        this.destroy$.next();
    }

    public get alertRangeFrom(): number {
        return get(this.widgetPropsData, "switchDurationMin", this.alertRangeMin);
    }

    public set alertRangeFrom(newValue: number) {
        set(this.widgetPropsData, "switchDurationMin", newValue);
        this.widgetPropsDataChange.emit(this.widgetPropsData);
    }

    public get alertRangeTo(): number {
        return get(this.widgetPropsData, "switchDurationMax", null);
    }

    public set alertRangeTo(newValue: number) {
        set(this.widgetPropsData, "switchDurationMax", newValue);
        this.widgetPropsDataChange.emit(this.widgetPropsData);
    }

    public get areDonationEventsOn(): boolean {
        return this.widgetPropsData.sources?.accounts?.donatty?.donate?.isEnabled;
    }

    public get dataType(): TopWidgetDataType {
        if ((this.widgetPropsData as TopWidgetData).dataType) {
            return this.topWidgetDataTypes.find(
                x => (x.value === (this.widgetPropsData as TopWidgetData).dataType));
        }

        return this.topWidgetDataTypes[0];
    }

    public set dataType(newValue: TopWidgetDataType) {
        const oldValue = (this.widgetPropsData as TopWidgetData).dataType;
        (this.widgetPropsData as TopWidgetData).dataType = newValue.value as TopWidgetData["dataType"];

        if (oldValue !== newValue.value) {
            this.widgetPropsDataChange.emit(this.widgetPropsData);
        }
    }

    public get goalDate(): string {
        return (this.widgetPropsData as GoalWidgetData).goalDate;
    }

    public set goalDate(newValue: string) {
        const oldValueUtc = moment((this.widgetPropsData as GoalWidgetData).goalDate);
        const newValueUtc = moment(newValue);

        if (!newValueUtc.isSame(oldValueUtc)) {
            (this.widgetPropsData as GoalWidgetData).goalDate = newValueUtc.toISOString();
            this.widgetPropsDataChange.emit(this.widgetPropsData);
        }
    }

    public get period(): TopWidgetPeriodType {
        if ((this.widgetPropsData as TopWidgetData | TotalWidgetData).period) {
            return this.periods.find(
                x => (x.value === (this.widgetPropsData as TopWidgetData | TotalWidgetData).period));
        }

        return this.periods[0];
    }

    public set period(newValue: TopWidgetPeriodType) {
        const oldValue = (this.widgetPropsData as TopWidgetData | TotalWidgetData).period;
        (this.widgetPropsData as TopWidgetData | TotalWidgetData).period = newValue.value as WidgetDataPeriod;

        if (oldValue !== newValue.value) {
            this.widgetPropsDataChange.emit(this.widgetPropsData);
        }
    }

    public get topWidgetDataTypeName(): string {
        const isDonatersData = (
            (this.dataType === this.topWidgetDataTypes[DataTypeIndex.DonatersChrono]) ||
            (this.dataType === this.topWidgetDataTypes[DataTypeIndex.TopDonaters])
        );
        if (isDonatersData) {
            return "Донатер";
        }

        return "Донат";
    }

    public onEventSourceParamChanged(param: EventSourceParam) {
        this.paramAppliers[param.type](param, this.widgetPropsData);
        this.widgetPropsDataChange.emit(this.widgetPropsData);
    }

    private paramAppliers: { [key in EventParamType]: (param: EventSourceParam<WidgetEventSourcesAccountProp>, widgetPropsData: WidgetPropsData) => void } = {
        range: (param: EventSourceParam<EventSourceParamLimits>, widgetPropsData: WidgetPropsData) => {
            const paramNode: EventSourceParamLimits =
                widgetPropsData.sources.accounts[param.account.getId()][param.accountEventType] as EventSourceParamLimits;
            paramNode.from = param.value.from;
            paramNode.to = param.value.to;
        },
        list: (param: EventSourceParam<EventSourceParamList<string>>, widgetPropsData: WidgetPropsData) => {
            const paramNode: EventSourceParamList<string> =
                widgetPropsData.sources.accounts[param.account.getId()][param.accountEventType] as EventSourceParamList<string>;
            paramNode.selectedItems = param.value.selectedItems;
        },
    };

    public get daysRemains(): string {
        if (!this.widgetPropsData || !(this.widgetPropsData as GoalWidgetData).goalDate) {
            return "0 дней";
        }

        const goalDate = moment((this.widgetPropsData as GoalWidgetData).goalDate);
        const now = moment.now();

        const daysLeft = goalDate.diff(now, "days");
        const daysNoun = WidgetEditDataParamsComponent.getDaysNoun(daysLeft);

        return `${daysLeft} ${daysNoun}`;
    }

    public get isValid(): boolean {
        return this.goalForm.valid &&
            (!this.topTotalRange || this.topTotalRange.formGroup.valid) &&
            (!this.alertTotalRange || this.alertTotalRange.formGroup.valid);
    }

    public resetInvalidValues() {
        if (this.topTotalRange && !this.topTotalRange.formGroup.valid) {
            this.topTotalRange.formGroup.reset();
        }
        if (this.alertTotalRange && !this.alertTotalRange.formGroup.valid) {
            this.alertTotalRange.formGroup.reset();
        }
    }

    public get timerRemains(): string {
        if (!(this.widgetPropsData as GoalWidgetData)?.goalDate) {
            return "00:00:00";
        }

        const goalDate = moment((this.widgetPropsData as GoalWidgetData).goalDate);
        const diffSeconds = goalDate.diff(moment(), "seconds");
        if (diffSeconds <= 0) {
            return "00:00:00";
        }

        const hours = Math.floor(diffSeconds / 3600) % 24;
        const hoursStr = hours.toString().padStart(2, "0");

        const minutes = Math.floor(diffSeconds / 60) % 60;
        const minutesStr = minutes.toString().padStart(2, "0");

        const seconds = (diffSeconds % 60);
        const secondsStr = seconds.toString().padStart(2, "0");

        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }

    public get topRangeFrom(): number {
        return get(this.widgetPropsData, "totalFrom", 1);
    }

    public set topRangeFrom(value: number) {
        set(this.widgetPropsData, "totalFrom", value);

        this.widgetPropsDataChange.emit(this.widgetPropsData);
    }

    public get topRangeTo(): number {
        return get(this.widgetPropsData, "totalTo", null);
    }

    public set topRangeTo(value: number) {
        set(this.widgetPropsData, "totalTo", value);
        this.widgetPropsDataChange.emit(this.widgetPropsData);
    }

    public toggleCollapse(blockIndex: number) {
        this.isBlockShown[blockIndex] = !this.isBlockShown[blockIndex];
        this.changeDetectorRef.detectChanges();

        this.storageService.set(
            this.formatStorageKey(blockIndex),
            this.isBlockShown[blockIndex]);
    }

    private initGoalForm() {
        this.goalForm.setValue({
            goal: (this.widgetPropsData as GoalWidgetData).goal,
            goalFrom: (this.widgetPropsData as GoalWidgetData).goalFrom,
        });
        this.changes$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(() => {
            this.goalForm.setValue({
                goal: (this.widgetPropsData as GoalWidgetData).goal,
                goalFrom: (this.widgetPropsData as GoalWidgetData).goalFrom,
            });
        });
        this.goalForm.valueChanges.pipe(
            filter(() => this.goalForm.valid),
            takeUntil(this.destroy$),
        ).subscribe(newValue => {
            Object.assign(this.widgetPropsData, newValue);
            this.widgetPropsDataChange.emit(this.widgetPropsData);
        });
    }

    private formatStorageKey(blockIndex: number): string {
        return `widget-edit-data-params/${this.widgetId}/${blockIndex}/isShown`;
    }

    private static getDaysNoun(days: number): string {
        if ((days >= 5) && (days <= 20)) {
            return "дней";
        }

        const module10 = (days % 10);

        if (module10 === 1) {
            return "день";
        }

        if ((module10 >= 2) && (module10 <= 4)) {
            return "дня";
        }

        return "дней";
    }

    private setGenericEventSourceParams(): void {
        let params = this.widgetSettingsService.listEventSourceParams(
            this.accountsService.accounts$.value,
            this.widgetPropsData?.sources?.accounts ?? {}
        );

        // filter out Donatty params because they're not generic and hardcoded instead
        params = params.filter(param => param.account.getType() !== AccountType.Donatty);

        this.eventSourceParams = params;
        this.changeDetectorRef.detectChanges();
    }

    private refreshTimeout(): void {
        this.remainsText = `${this.daysRemains} ${this.timerRemains}`;
        this.changeDetectorRef.detectChanges();
    }

    @ViewChild("topTotalRange")
    private topTotalRange: WidgetEditOpenRangeComponent;

    @ViewChild("alertTotalRange")
    private alertTotalRange: WidgetEditOpenRangeComponent;

    private refreshIntervalId: number = null;
}

enum DataTypeIndex {
    TopDonaters,
    TopDonations,
    DonatersChrono,
    DonationsChrono
}
