import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import {ChangeContext, Options} from "@angular-slider/ngx-slider";

@Component({
    selector: "app-slider",
    templateUrl: "./slider.component.html",
    styleUrls: ["./slider.component.scss"]
})
export class SliderComponent implements OnInit, OnChanges {

    @Input()
    public unit: string = null;

    @Input()
    public min: number = null;

    @Input()
    public max: number = null;

    @Input()
    public from: number = null;

    @Input()
    public to: number = null;

    @Output()
    valueChanged = new EventEmitter<[number, number]>();

    public options: Options  = null;

    public ngOnInit() {
        this.options = {
            floor: this.min,
            ceil: this.max,
            animate: false
        };

        if (this.unit !== null) {
            this.options.translate = (value: number): string => {
                return `${value} ${this.unit}`;
            };
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.max) {
            this.options = {
                floor: this.min,
                ceil: this.max,
                animate: false
            };
        }
    }

    public onValueChanged(event: ChangeContext) {
        this.valueChanged.emit([event.value, event.highValue]);
    }
}
