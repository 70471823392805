import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import _ from "lodash";

@Component({
    selector: "lib-popup",
    templateUrl: "./popup.component.html",
    styleUrls: ["./popup.component.scss"]
})
export class PopupComponent implements OnInit {
    @Input()
    public position = TooltipPosition.Bottom;

    @Input()
    public width = 0;

    @Output()
    public visibilityChange = new EventEmitter<boolean>();

    public isVisible = false;

    public wrapperStyle: object = {};

    public constructor(private readonly thisRef: ElementRef) {
    }

    public ngOnInit(): void {
        if (this.width > 0) {
            this.wrapperStyle = {maxWidth: `${this.width}px`};
        }
    }

    public containsElement(el: Element): boolean {
        return this.thisRef.nativeElement.contains(el);
    }

    public hide(): void {
        this.isVisible = false;
        this.visibilityChange.emit(false);
    }

    public show(target: EventTarget): void {
        if (!(target instanceof HTMLElement)) {
            return;
        }

        if (this.isVisible) {
            return;
        }

        let style = this.evaluateWrapperPos(target);
        if (this.width > 0) {
            style = _.merge(style, {maxWidth: `${this.width}px`});
        }

        this.wrapperStyle = style;
        this.isVisible = true;
        this.visibilityChange.emit(true);
    }

    public toggle(target: EventTarget): boolean {
        if (this.isVisible) {
            this.hide();
            return false;
        }

        this.show(target);
        return true;
    }

    private get popupMargin() {
        return 8;
    }

    private evaluateBottomWrapperPos(target: HTMLElement): object {
        const targetRect = target.getBoundingClientRect();
        const targetCenterX = (targetRect.left + (targetRect.width / 2));
        const targetBottomY = targetRect.bottom;

        const selfRect = this.getWrapperRect();
        let selfLeft = targetCenterX - (selfRect.width / 2);
        const selfTop = targetBottomY + this.popupMargin;

        const fit = window.innerWidth - (selfLeft + selfRect.width);
        if (fit < 0) {
            selfLeft += fit + selfRect.width;
        }
        return {left: `${selfLeft}px`, top: `${selfTop}px`};
    }

    private evaluateBottomLeftWrapperPos(target: HTMLElement): object {
        const targetRect = target.getBoundingClientRect();
        const targetRightX = targetRect.right;
        const targetBottomY = targetRect.bottom;

        const selfRect = this.getWrapperRect();
        const selfLeft = (targetRightX - selfRect.width);
        const selfTop = (targetBottomY + this.popupMargin);

        return {left: `${selfLeft}px`, top: `${selfTop}px`};
    }

    private evaluateBottomRightWrapperPos(target: HTMLElement): object {
        const targetRect = target.getBoundingClientRect();
        const targetLeftX = targetRect.left;
        const targetBottomY = targetRect.bottom;

        const selfLeft = targetLeftX;
        const selfTop = (targetBottomY + this.popupMargin);

        return {left: `${selfLeft}px`, top: `${selfTop}px`};
    }

    private evaluateTopLeftWrapperPos(target: HTMLElement): object {
        const targetRect = target.getBoundingClientRect();
        const targetRightX = targetRect.right;
        const targetTopY = targetRect.top;

        const selfRect = this.getWrapperRect();
        const selfLeft = (targetRightX - selfRect.width);
        const selfBottom = (window.innerHeight - targetTopY + this.popupMargin);

        return {left: `${selfLeft}px`, bottom: `${selfBottom}px`};
    }

    private evaluateWrapperPos(target: HTMLElement): Partial<CSSStyleDeclaration> {
        switch (this.position) {
            case TooltipPosition.Bottom:
                return this.evaluateBottomWrapperPos(target);

            case TooltipPosition.BottomLeft:
                return this.evaluateBottomLeftWrapperPos(target);

            case TooltipPosition.BottomRight:
                return this.evaluateBottomRightWrapperPos(target);

            case TooltipPosition.TopLeft:
                return this.evaluateTopLeftWrapperPos(target);
        }

        throw new Error(`unknown tooltip position '${this.position}'`);
    }

    private getWrapperRect(): DOMRect {
        return this.wrapperEl.nativeElement.getBoundingClientRect();
    }

    @ViewChild("wrapper", {static: true})
    private wrapperEl: ElementRef;

}

export enum TooltipPosition {
    Bottom = "bottom",
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    TopLeft = "top-left"
}
