<div class="we__tab-container">
    <div class="we__header">
        Текст сообщения
    </div>

    <div class="we__template-row">
        <div class="we__row">
            <div class="we__label">Шаблон</div>
            <div class="we__controls">
                <input type="text"
                       (ngModelChange)="widgetProps.style.message.template = $event; widgetPropsChange.emit(widgetProps)"
                       [ngModel]="widgetProps.style.message.template"
                       #messageTemplate
                       (keyup)="caretPosition = messageTemplate.selectionStart"
                       (click)="caretPosition = messageTemplate.selectionStart"
                       (focusin)="caretPosition = messageTemplate.selectionStart">
            </div>
        </div>

        <div class="we__row">
            <div class="we__label"></div>
            <div class="we__controls">
                <app-widget-help-tags [widgetPropsData]="widgetProps.data"
                                      (tagClick)="onTagClicked($event)">
                </app-widget-help-tags>
            </div>
        </div>
    </div>

    <app-widget-edit-font-basics id="message" [(item)]="widgetProps.style.message" text="сообщения" offsetBounds="550"
                                 [showMaxWidth]="true"
                                 [showPositioning]="widgetProps.style.layout === 2"
                                 (itemChange)="widgetProps.style.message = $event; widgetPropsChange.emit(widgetProps)">
    </app-widget-edit-font-basics>
</div>
