import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {concatMap, delay, retryWhen} from "rxjs/operators";
import {of, throwError} from "rxjs";

export const retryDelay = 1000;
export const retryMaxAttempts = Infinity;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            retryWhen(e => e.pipe(
                concatMap((error, count) => {
                    if (error.status === 503 && count < retryMaxAttempts) {
                        return of(error);
                    }
                    return throwError(error);
                }),
                delay(retryDelay),
            )),
        );
    }
}
