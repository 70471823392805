import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {filter, take} from "rxjs/operators";

export enum WidgetUploadFileType {
    Audio = "audio",
    Image = "image"
}

export interface FileData {
    widgetId: string;
    tag: string;
    dataUrl: string;
}

export interface UploadError {
    widgetId: string;
    tag: string;
}

export interface UploadedFile {
    widgetId: string;
    tag: string;
    uri: string;
}

@Injectable({providedIn: "root"})
export class WidgetFileUploadService {
    public constructor(
        private readonly authService: AuthService,
        private readonly httpClient: HttpClient) {
    }

    public abortWidgetUploads(widgetId: string): void {
        console.log("abortWidgetUploads, w=", widgetId);

        if (!this.widgetsUploadOperations.has(widgetId)) {
            return;
        }

        this.widgetsUploadOperations.get(widgetId).abortAll();
        this.widgetsUploadOperations.delete(widgetId);
    }

    public async uploadFile(widgetId: string,
                            tag: string,
                            type: WidgetUploadFileType,
                            file: File): Promise<UploadedFile> {
        if (!this.widgetsUploadOperations.has(widgetId)) {
            this.widgetsUploadOperations.set(
                widgetId,
                new WidgetUploadOperations(
                    this.authService,
                    this.fileDataSubject$,
                    this.fileUploadedSubject$,
                    this.httpClient,
                    this.uploadErrorSubject$,
                    widgetId));
        }

        const ops = this.widgetsUploadOperations.get(widgetId);
        ops.beginUpload(tag, type, file);
        return this.fileUploadedSubject$.pipe(
            filter(e => e.widgetId === widgetId && e.tag === tag),
            take(1),
        ).toPromise();
    }

    public async waitForUploadCompletion(widgetId: string): Promise<void> {
        if (!this.widgetsUploadOperations.has(widgetId)) {
            return;
        }

        const ops = this.widgetsUploadOperations.get(widgetId);
        return await ops.waitForUploadCompletion();
    }

    private readonly widgetsUploadOperations = new Map<string, WidgetUploadOperations>();

    public readonly fileDataSubject$ = new Subject<FileData>();

    public readonly fileUploadedSubject$ = new Subject<UploadedFile>();

    public readonly uploadErrorSubject$ = new Subject<UploadError>();
}

class UploadOperation {
    public constructor(
        private readonly authService: AuthService,
        private readonly file: File,
        private readonly fileDataSubject$: Subject<FileData>,
        private readonly fileUploadedSubject$: Subject<UploadedFile>,
        private readonly httpClient: HttpClient,
        private readonly tag: string,
        private readonly type: WidgetUploadFileType,
        private readonly uploadErrorSubject$: Subject<UploadError>,
        private readonly widgetId: string) {
        this.readerPromise = this.readData();
        this.readerPromise
            .then(dataUrl => {
                this.fileDataSubject$.next({
                    dataUrl,
                    tag: this.tag,
                    widgetId: this.widgetId
                });

                this.beginUpload();
            })
            .catch(_ => this.onError());
    }

    public abort(): void {
        if (this.reader) {
            this.reader.abort();
        }
    }

    private beginUpload(): void {
        const formData = new FormData();
        formData.append("file", this.file, this.file.name);
        formData.append("tag", this.tag);

        const uri = `${environment.donationAPI}/widgets/${this.widgetId}/${this.type}`;
        this.uploadPromise = this.httpClient
            .post(
                uri,
                formData,
                {
                    headers: new HttpHeaders({
                        Authorization: `Bearer ${this.authService.getToken()}`
                    })
                })
            .toPromise();

        this.uploadPromise
            .then((response: UploadResponse) => {
                this.fileUploadedSubject$
                    .next({
                        tag: this.tag,
                        uri: response.response,
                        widgetId: this.widgetId
                    });
            })
            .catch(e => {
                console.error("UploadOperation.beginUpload, e=", e);
                this.onError();
            });
    }

    private onError(): void {
        this.uploadErrorSubject$.next({
            tag: this.tag,
            widgetId: this.widgetId,
        });
    }

    private readData(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.reader = new FileReader();

            this.reader.addEventListener(
                "load",
                () => {
                    const dataUrl = this.reader.result as string;
                    console.log("UploadOperation.readData, readAsDataURL succeed, len=", dataUrl.length);
                    resolve(dataUrl);
                });

            this.reader.addEventListener(
                "error",
                () => {
                    console.error("UploadOperation.readData, readAsDataURL failed, e=", this.reader.error);
                    reject(this.reader.error);
                });

            try {
                this.reader.readAsDataURL(this.file);
            } catch (e) {
                console.error("UploadOperation.readData, readAsDataURL start failed, e=", e);
                reject(e);
            }
        });
    }

    public async waitForUploadCompletion(): Promise<void> {
        if (!this.readerPromise) {
            return;
        }

        await this.readerPromise;

        if (!this.uploadPromise) {
            return;
        }

        await this.uploadPromise;
    }

    private reader: FileReader = null;
    private readonly readerPromise: Promise<string>;
    private uploadPromise: Promise<object> = null;
}

class WidgetUploadOperations {
    public constructor(
        private readonly authService: AuthService,
        private readonly fileDataSubject$: Subject<FileData>,
        private readonly fileUploadedSubject$: Subject<UploadedFile>,
        private readonly httpClient: HttpClient,
        private readonly uploadErrorSubject$: Subject<UploadError>,
        private readonly widgetId: string) {
    }

    public abortAll(): void {
        for (const [_, op] of this.operationsByTag) {
            op.abort();
        }

        this.operationsByTag.clear();
    }

    public beginUpload(tag: string,
                       type: WidgetUploadFileType,
                       file: File): void {
        if (this.operationsByTag.has(tag)) {
            this.operationsByTag.get(tag).abort();
            this.operationsByTag.delete(tag);
        }

        const op = new UploadOperation(
            this.authService,
            file,
            this.fileDataSubject$,
            this.fileUploadedSubject$,
            this.httpClient,
            tag,
            type,
            this.uploadErrorSubject$,
            this.widgetId);
        this.operationsByTag.set(tag, op);
    }

    public async waitForUploadCompletion(): Promise<void> {
        for (const [_, op] of this.operationsByTag) {
            await op.waitForUploadCompletion();
        }
    }

    private readonly operationsByTag = new Map<string, UploadOperation>();
}

interface UploadResponse {
    response: string;
}
