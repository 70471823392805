import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {EventSourceParam} from "../../../../../services/settings/widget-settings.service";
import {WidgetEventSourceParamControl} from "../../widget-edit-data-params/widget-edit-data-params.component";
import {EventSourceParamLimits} from "../../../../../../../../shared/src/lib/models/widget-props";

@Component({
    selector: "app-widget-edit-event-source-param-range",
    templateUrl: "./widget-edit-event-source-param-range.component.html",
    styleUrls: ["./widget-edit-event-source-param-range.component.scss"]
})
export class WidgetEditEventSourceParamRangeComponent implements OnInit, WidgetEventSourceParamControl<EventSourceParamLimits> {
    @Input()
    public param: EventSourceParam<EventSourceParamLimits> = null;

    @Output()
    public paramChanged = new EventEmitter<EventSourceParam<EventSourceParamLimits>>();

    public unit = "";
    public limits: EventSourceParamLimits;

    public ngOnInit(): void {
        this.unit = this.param.unit;
        this.limits = this.param.range;
    }

    public get from() {
        return this.param.value.from;
    }

    public set from(value) {
        this.param.value.from = value;
        this.paramChanged.emit({...this.param});
    }

    public get to() {
        return this.param.value.to;
    }

    public set to(value) {
        this.param.value.to = value;
        this.paramChanged.emit({...this.param});
    }

}

