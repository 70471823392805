import {Component, Input} from "@angular/core";
import {ClipboardService} from "ngx-clipboard";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "app-secure-input",
    templateUrl: "./secure-input.component.html",
    styleUrls: ["./secure-input.component.scss"]
})
export class SecureInputComponent {
    public isTextShown = false;

    @Input()
    public text: string;

    public constructor(
        private readonly clipboardService: ClipboardService,
        private readonly toastr: ToastrService) {
    }

    public copyText(): void {
        this.clipboardService.copyFromContent(this.text);
        this.toastr.success("Ссылка скопирована");
    }
}
