<section [matMenuTriggerFor]="menu">
    <div>
        <img *ngIf="avatar" [hidden]="!avatarLoaded" [alt]="emptyAvatar" [src]="avatar" (load)="avatarLoaded = true">
        <div class="avatar" *ngIf="avatarLoaded === false"
             [style.background]="backgroundColor">
            <span>{{emptyAvatar}}</span>
        </div>
    </div>
    <div class="avatar-name" [@hideName]="short ? 'hide' : 'show'">
        <span>{{name}}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
</section>
<mat-menu #menu="matMenu" [xPosition]="'before'">
    <button mat-menu-item routerLink="/profile">Настройки</button>
    <button mat-menu-item (mousedown)="openKnowledgeBase()">База знаний</button>
    <button mat-menu-item (mousedown)="logout()">Выйти</button>
</mat-menu>
