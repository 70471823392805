<div class="mc__body" [ngClass]="{loading: !(loginTokenService.mobileLoginUrl$ | async)}">
    <header>Мобильная версия</header>
    <section>
        <div class="mc__descr">
            Отсканируйте QR-код, чтобы открыть список донатов на&nbsp;телефоне, чтобы не&nbsp;пропустить новые донаты.
            Никому не&nbsp;показывайте код.
        </div>
        <div class="mc__code-container">
            <div class="mc__code-host"
                 [ngClass]="{'mc__code--revealed': isQrCodeVisible, 'mc__code--backdrop-workaround': platformInfoService.isFirefox}">
                <qrcode *ngIf="loginTokenService.mobileLoginUrl$ | async as mobileLoginUrl"
                        [qrdata]="mobileLoginUrl"
                        margin="1"
                        width="132"></qrcode>
                <div class="mc__overlay">
                    <button mat-stroked-button [hidden]="isQrCodeVisible" (click)="isQrCodeVisible = true">
                        <mat-icon svgIcon="bx-show"></mat-icon> Показать
                    </button>
                    <button mat-stroked-button [hidden]="!isQrCodeVisible" (click)="isQrCodeVisible = false">
                        <mat-icon svgIcon="bx-hide"></mat-icon> Скрыть
                    </button>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <button mat-button color="primary"
                class="mc__reset"
                (click)="isResettingQrCode = true; loginTokenService.resetMobileLoginUrl$.next()"
                [ngClass]="{'mc__in-progress': isResettingQrCode}">
            <mat-icon>autorenew</mat-icon>
            Сбросить QR-код
        </button>
    </footer>
</div>
