import {
    ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding,
    Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from "@angular/core";
import {ColorPickerService} from "ngx-color-picker";
import {PlatformInfoService} from "../../../../../shared/src/lib/platform-info.service";

type DialogPosition =
    "auto" | "top" | "bottom" | "left" | "right" | "top-left" | "top-right" | "bottom-left" | "bottom-right";

@Component({
    selector: "app-color-select",
    templateUrl: "./color-select.component.html",
    styleUrls: ["./color-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorSelectComponent implements OnInit, OnChanges {
    @Input()
    public color = "#ffffffff";

    @Input()
    @HostBinding("class.disabled")
    public disabled = false;

    @Input()
    @HostBinding("class.short")
    public short = false;

    @Input()
    public showHints = true;

    @Output()
    public readonly colorChange = new EventEmitter();

    @ViewChild("colorInput", {static: true})
    private colorInput: ElementRef;

    @ViewChild("alphaInput", {static: true})
    private alphaInput: ElementRef;

    public dialogPosition: DialogPosition = this.platformInfoService.isSafari ? "bottom-left" : "bottom-right";

    public constructor(
        private readonly platformInfoService: PlatformInfoService,
        private readonly cpService: ColorPickerService) {
    }

    public ngOnInit() {
        this.fillInputs();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.color) {
            this.fillInputs();
        }
    }

    public colorPickerChanged(opened) {
        this.fillInputs();

        if (!opened) {
            this.colorChange.emit(this.color);
        }
    }

    public colorInputChanged(_: Event): void {
        const nativeInput = this.colorInput.nativeElement as HTMLInputElement;

        const isHex = (el) =>
            ((el >= "0") && (el <= "9")) ||
            ((el >= "a") && (el <= "f")) ||
            ((el >= "A") && (el <= "F"));

        const value = nativeInput
            .value
            .split("")
            .slice(0, 6)
            .filter(isHex)
            .map(ch => ch.toUpperCase())
            .join("");

        nativeInput.value = value;

        if (value.length === 0) {
            return;
        }

        this.fillColorPicker();

        this.colorChange.emit(this.color);
    }

    public alphaInputChanged(_: Event): void {
        const nativeInput = this.alphaInput.nativeElement as HTMLInputElement;

        const isNum = (el) => ((el >= "0") && (el <= "9"));

        const value = nativeInput
            .value
            .split("")
            .slice(0, 3)
            .filter(isNum)
            .join("");

        if (value.length === 0) {
            nativeInput.value = "";
            return;
        }

        const newValue = Math.min(100, parseInt(value, 10));
        nativeInput.value = newValue.toString();

        this.fillColorPicker();

        this.colorChange.emit(this.color);
    }

    public onAlphaInputBlur(): void {
        const nativeInput = this.alphaInput.nativeElement as HTMLInputElement;
        const value = nativeInput.value;
        if (value.length !== 0) {
            return;
        }

        nativeInput.value = "100";

        this.fillColorPicker();

        this.colorChange.emit(this.color);
    }

    public onAlphaInputKeyUp(key: KeyboardEvent) {
        if (key.key !== "Tab") {
            return;
        }

        key.stopImmediatePropagation();
        key.preventDefault();

        this.colorInput.nativeElement.focus();
    }

    public onColorInputBlur(): void {
        const nativeInput = this.colorInput.nativeElement as HTMLInputElement;
        const value = nativeInput.value;
        if (value.length !== 0) {
            return;
        }

        nativeInput.value = "FFFFFF";

        this.fillColorPicker();

        this.colorChange.emit(this.color);
    }

    public onColorInputKeyUp(key: KeyboardEvent): void {
        if (key.key !== "Tab") {
            return;
        }

        key.stopImmediatePropagation();
        key.preventDefault();

        this.alphaInput.nativeElement.focus();
    }

    private fillColorPicker(): void {
        const colorInput = this.colorInput.nativeElement as HTMLInputElement;
        const alphaInput = this.alphaInput.nativeElement as HTMLInputElement;

        const color = this.cpService.stringToHsva(`#${colorInput.value}`, false);
        if (color === null) {
            return;
        }
        color.a = parseInt(alphaInput.value, 10) / 100;

        this.color = this.cpService.outputFormat(color, "'hex'", "always");
    }

    private fillInputs(): void {
        const normalColor = this.cpService.hsvaToRgba(
            this.cpService.stringToHsva(this.color, true)
        );
        const normalColorHex = this.cpService.rgbaToHex(
            this.cpService.denormalizeRGBA(normalColor), true
        );

        const colorInput = this.colorInput.nativeElement as HTMLInputElement;
        if (colorInput.value.length !== 3) {
            colorInput.value = normalColorHex.slice(1, 7).toUpperCase();
        }

        const alphaInput = this.alphaInput.nativeElement as HTMLInputElement;
        alphaInput.value = Math.round(normalColor.a * 100).toString();
    }
}
