<ng-template #templatePortalContent>
    <div class="dps__dialog-fade" (click)="close.emit()"
         [ngClass]="{'backdrop-workaround': useBackdropWorkaround}">
        <div class="dps__dialog-frame">
            <div class="dps__dialog-overlay">
                <div class="dps__header">{{header}}</div>
                <div class="dps__text">
                    <ng-content></ng-content>
                </div>
                <div class="dps__buttons">
                    <app-new-button [icon]="confirmIcon" type="blue"
                                    [disabled]="confirmDisabled"
                                    (click)="confirm.emit()">{{confirmText}}</app-new-button>
                    <app-new-button type="grey" (click)="cancel.emit()">{{cancelText}}</app-new-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
