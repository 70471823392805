import {
    AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Output, ViewChild
} from "@angular/core";
import {
    CreateWithdrawalRequest, OrderStateEvent, PayoutMethod, WithdrawalState
} from "../../../../../../../../shared/src/lib/common/FinancialOperation";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {FormControl, Validators} from "@angular/forms";
import {PhoneNumberService} from "../../../../../../../../shared/src/lib/phone-number.service";
import {Observable, Subject} from "rxjs";
import {map, shareReplay, switchMap} from "rxjs/operators";
import {FinanceService} from "../../../../../services/finance/finance.service";

@Component({
    selector: "app-payout-qiwi",
    templateUrl: "./payout-qiwi.component.html",
    styleUrls: ["../methods.scss"],
})
export class PayoutQiwiComponent implements OnInit, AfterViewInit {
    public readonly initWithdrawal$ = new Subject<string>();

    @Input() public readonly amount: number;
    @Input() public readonly method: PayoutMethod;
    @Output() public readonly order$: Observable<OrderStateEvent<WithdrawalState>> = this.initWithdrawal$.pipe(
        map(account => {
            const params: CreateWithdrawalRequest["args"]["withdrawal"] = {
                amount: this.amount,
                method: {id: this.method.id}
            };
            if (account) {
                params.method.args = {
                    account: PhoneNumberService.getFullNumber(account),
                };
            }
            return params;
        }),
        switchMap(params => this.financeService.createWithdrawal(params)),
        shareReplay(1));

    public formControl = new FormControl<string>("", Validators.compose([
        Validators.required,
        PhoneNumberService.formValidator,
    ]));

    public constructor(private readonly financeService: FinanceService,
                       @Inject(LOCAL_STORAGE)
                       private readonly storageService: StorageService) {
        this.formControl.valueChanges.subscribe(inputVal => {
            this.storageService.set(ACCOUNT_ID_KEY, inputVal);
        });
    }

    @ViewChild("numberInput")
    private numberInput: ElementRef<HTMLInputElement>;

    public ngOnInit() {
        const storedKey = this.storageService.get(ACCOUNT_ID_KEY);
        if (storedKey) {
            this.formControl.setValue(storedKey);
        }
    }

    public ngAfterViewInit() {
        requestAnimationFrame(() => this.numberInput?.nativeElement.focus());
    }

}

const ACCOUNT_ID_KEY = "payout-qiwi-account-id";
