import {Injectable} from "@angular/core";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {ApiProfanity, Profanity, ProfanityResponse} from "./profanity-api";
import {ReplaySubject, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: "root"
})
export class ProfanityService {
    public readonly profanity$: ReplaySubject<Profanity> = new ReplaySubject<Profanity>(1);

    private readonly baseUri: string;

    public constructor(private readonly environmentService: EnvironmentService,
                       private readonly http: HttpClient,
                       private readonly authService: AuthService,
                       private readonly toastr: ToastrService) {
        this.baseUri = this.environmentService.backendApiUri + "/users/store/profanity";
    }

    public saveProfanity(profanity: Profanity): Subject<Profanity> {
        const apiProfanity: ApiProfanity = {
            blacklist: profanity.blacklist.split(/\s+/),
            replacement: profanity.replacement,
        };
        this.http.post<void>(this.baseUri, apiProfanity, this.authService.makeTokenAuthHeaders())
            .subscribe(() => {
                this.toastr.success("Настройки фильтра сохранены");
                this.profanity$.next(profanity);
            });
        return this.profanity$;
    }

    public fetchProfanity(): Subject<Profanity> {
        this.http.get<ProfanityResponse>(this.baseUri, this.authService.makeTokenAuthHeaders())
            .pipe(map<ProfanityResponse, Profanity>(profanityResponse => ({
                blacklist: profanityResponse.response?.blacklist.join(" "),
                replacement: profanityResponse.response?.replacement,
            })))
            .subscribe(profanity => this.profanity$.next(profanity));
        return this.profanity$;
    }

}
