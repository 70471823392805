<div class="sid__root">
    <div class="sid__form">
        <button id="close-button" mat-fab color="" (click)="onCloseClick()">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <div class="page__header">
            Упрощённая<br>
            идентификация
        </div>

        <div class="form__step"
             [ngClass]="{'form__step--current': isIdStep, 'form__step--passed': isIdStepPassed}"
             (click)="onIdStepClick()">
            <div class="step__header">
                <div class="header__icon">
                    <img src="/assets/images/simplified-id/step-header-id.svg" *ngIf="!isIdStepPassed">
                    <img src="/assets/images/simplified-id/step-header-passed.svg" *ngIf="isIdStepPassed">
                </div>
                <span>Паспортные данные</span>
            </div>
            <div class="step__body">
                <div class="form__input"
                     [ngClass]="{'form__input--filled': isNameFilled, 'form__input--error': isNameError}">
                    <div class="input__tooltip">Фамилия Имя Отчество</div>
                    <input #nameInput
                           autofocus
                           id="sid-name-input"
                           class="input__ctrl"
                           (focus)="onNameInputFocus()"
                           (blur)="onNameInputBlur()"
                           (input)="onNameInputChange()"
                           (keydown)="onNameInputKeydown($event)">
                    <div class="input__loader"
                         [ngClass]="{'input__loader--visible': isNameLoaderVisible}">
                        <img src="/assets/images/simplified-id/input-loader.svg">
                    </div>
                    <div class="input__suggestion" [ngClass]="{'input__suggestion--visible': isNameSuggestionVisible}">
                        <div class="sugg_header">Выберите вариант или продолжите ввод</div>
                        <div class="sugg__name"
                             [ngClass]="{'name--selected': i === this.suggestionSelectionIndex}"
                             (mousedown)="onNameSuggestionClick($event, name)"
                             *ngFor="let name of nameSuggestions; index as i">
                            <span class="sugg__typed">{{fmtSuggestionTyped(name)}}</span>
                            <span>{{fmtSuggestionTail(name)}}</span>
                        </div>
                    </div>
                </div>

                <div class="form__input form__input--passport form__input--last"
                     [ngClass]="{'form__input--filled': isPassportFilled, 'form__input--error': isPassportError}">
                    <div class="input__tooltip">Серия и номер паспорта</div>
                    <input #passportInput
                           class="input__ctrl"
                           mask="00 00 000000"
                           placeholder="__ __ ______"
                           (blur)="onPassportBlur()"
                           (keydown)="onPassportInputKeydown($event)">
                </div>

                <app-new-button type="blue"
                                (click)="onContinueToAdditionalId()"
                                [disabled]="!isIdStepValid">
                    Продолжить
                </app-new-button>
            </div>
        </div>

        <div class="form__step"
             [ngClass]="{'form__step--current': isAdditionalIdStep, 'form__step--passed': isAdditionalIdStepPassed}"
             (click)="onAdditionalIdStepClick()">
            <div class="step__header">
                <div class="header__icon">
                    <img src="/assets/images/simplified-id/step-header-additional-id.svg"
                         *ngIf="!isAdditionalIdStepPassed">
                    <img src="/assets/images/simplified-id/step-header-passed.svg" *ngIf="isAdditionalIdStepPassed">
                </div>
                <span>Дополнительный документ</span>
            </div>

            <div class="step__body">
                <!--<mat-radio-group aria-label="Select an option"
                                 color="primary"
                                 [(ngModel)]="selectedAdditionalIdType"
                                 (change)="setAdditionalIdFocus()">
                    <mat-radio-button *ngFor="let item of additionalIdTypes"
                                      [value]="item.value">{{item.name}}</mat-radio-button>
                </mat-radio-group>-->
                <div class="form__input form__input--inn form__input--last"
                     [ngClass]="{
                        'form__input--hidden': isSnilsSelected,
                        'form__input--filled': isInnFilled,
                        'form__input--error': isInnError
                     }">
                    <div class="input__tooltip">ИНН</div>
                    <input #innInput class="input__ctrl"
                           mask="000000000000"
                           placeholder="____________"
                           (blur)="onInnBlur()">
                    <div class="input__help input__help--inn">
                        <mat-icon (mouseover)="setInnHelpVisible(true)"
                                  (mouseleave)="setInnHelpVisible(false)"
                                  (click)="setInnHelpVisible(true); $event.stopPropagation()"
                                  svgIcon="info-outline"></mat-icon>
                        <div class="help__bubble"
                             [ngClass]="{'help__bubble--visible': isInnHelpBubbleVisible}">
                            <img src="/assets/images/simplified-id/help-inn.png">
                        </div>
                    </div>
                    <div class="input__error">{{innErrorText}}</div>
                </div>

                <div class="form__input form__input--snils form__input--last"
                     [ngClass]="{
                        'form__input--hidden': isInnSelected,
                        'form__input--filled': isSnilsFilled,
                        'form__input--error': isSnilsError
                     }">
                    <div class="input__tooltip">СНИЛС</div>
                    <input #snilsInput
                           class="input__ctrl"
                           mask="000-000-000 00"
                           placeholder="___-___-___ __"
                           (blur)="onSnilsBlur()">
                    <div class="input__help input__help--snils">
                        <mat-icon (mouseover)="setSnilsHelpVisible(true)"
                                  (mouseleave)="setSnilsHelpVisible(false)"
                                  (click)="setSnilsHelpVisible(true); $event.stopPropagation()"
                                  svgIcon="info-outline"></mat-icon>
                        <div class="help__bubble"
                             [ngClass]="{'help__bubble--visible': isSnilsHelpBubbleVisible}">
                            <img src="/assets/images/simplified-id/help-snils.png">
                        </div>
                    </div>
                    <div class="input__error">{{snilsErrorText}}</div>
                </div>

                <app-new-button type="blue"
                                (click)="onContinueToConfirmation()"
                                [disabled]="!isAdditionalIdStepValid || isProceedingToConfirmation">
                    Продолжить
                </app-new-button>
            </div>
        </div>

        <div class="form__step" [ngClass]="{'form__step--current': isConfirmationStep}">
            <div class="step__header">
                <div class="header__icon">
                    <img src="/assets/images/simplified-id/step-header-confirmation.svg">
                </div>
                <span>Подтверждение</span>
            </div>

            <div class="step__body" [ngClass]="{'step__body--sms-sent': isWaitingForCode}">
                <div class="form__input form__input--phone form__input--last"
                     [ngClass]="{
                        'form__input--filled': isPhoneFilled,
                        'form__input--error': isPhoneError
                     }">
                    <div class="input__tooltip">Личный номер мобильного телефона</div>
                    <input #phoneInput
                           class="input__ctrl"
                           [showMaskTyped]="isPhoneMaskVisible"
                           (blur)="onPhoneBlur()"
                           mask="(000)000-00-00"
                           prefix="+7">
                    <a class="input__change"
                       href="#"
                       *ngIf="isWaitingForCode"
                       (click)="onChangeClick($event)">Изменить</a>
                </div>

                <app-new-button type="blue"
                                [disabled]="!isSendSmsEnabled"
                                (click)="onSendSms()">Отправить СМС
                </app-new-button>

                <div class="form__confirm">
                    <div class="confirm__header">Введите код подтверждения из СМС</div>

                    <app-confirmation-code #code [code]="confirmationCode" (changed)="confirmationCode = $event">
                    </app-confirmation-code>

                    <div class="confirm__footer">
                        <app-new-button type="blue"
                                        [disabled]="!isConfirmationEnabled"
                                        (click)="onConfirmCodeClicked()">
                            Подтвердить
                        </app-new-button>
                        <div class="confirm__retry">
                            <div class="retry__header">Не пришёл код?</div>
                            <div class="retry__text"
                                 [ngClass]="{'retry--visible': isTimeoutTicking}">
                                Отправить повторно через {{timeout}} сек.
                            </div>
                            <div class="retry__link"
                                 (click)="onRetrySmsCodeClick()"
                                 [ngClass]="{'retry--visible': !isTimeoutTicking}">
                                Отправить повторно
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sid__explanation">
        <div class="expl__block" [ngClass]="{'expl__block--visible': isIdStep}">
            <div class="expl__icon expl__icon--id">
                <img src="/assets/images/simplified-id/step-id.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-id.png">
            </div>
            <div class="expl__header">
                Для чего проходить упрощённую идентификацию
            </div>
            <div class="expl__text">
                Это требование закона. Чтобы вы смогли выводить деньги на карту,
                мы обязательно должны вас идентифицировать. Так сказано в 115-ФЗ.
            </div>
        </div>

        <div class="expl__block" [ngClass]="{'expl__block--visible': isAdditionalIdStep}">
            <div class="expl__icon expl__icon--additional-id">
                <img src="/assets/images/simplified-id/step-additional-id.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-additional-id.png">
            </div>
            <div class="expl__header">
                Мы не храним ваши персональные данные
            </div>
            <div class="expl__text">
                Мы отправим эти данные в СМЭВ, а потом удалим.
                Мы сохраним только имя и номер телефона, чтобы связываться
                с вами в экстренных ситуациях.
            </div>
        </div>

        <div class="expl__block" [ngClass]="{'expl__block--visible': isConfirmationStep}">
            <div class="expl__icon expl__icon--confirm">
                <img src="/assets/images/simplified-id/step-confirm.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-confirm.png">
            </div>
            <div class="expl__header">
                Про номер телефона
            </div>
            <div class="expl__text">
                Мы отправим СМС с кодом подтверждения на этот номер телефона,
                так мы будем уверены, что это именно вы. Никакого СПАМа, он нас самих раздражает.
            </div>
        </div>
    </div>
</div>
